// import repository from '@/utils/repository';

const resource = '/reading';

export default {
    getReadingInfor: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/get-reading-infor`,
            data: data,
            showProgress: true,
        });
    },
};
