import repository from "./repository";
// import { BACKGROUND } from '~/assets/images/images_base64';

export const conversationStore = defineStore('conversation', {
    namespaced: true,
    state: () => ({
        conversationInfor: {
            isLoading: true,
            isRerender: true,
            welcomeSlogan: 'Chào mừng bạn đến với phòng chat AI của Eplus365!',
            currentCustomChatCommands: [],
            autoSendCommands: [],
            defaultCommands: [
                { role: "user", content: "Kiểm tra ngữ pháp của câu tiếng anh sau:i have take your bags", isMe: true, isShow: false },
                { role: "assistant", content: 'Đánh giá ngữ pháp cho [i have take your bags].\n- Câu của bạn đang bị sai ngữ pháp!\n+chưa chia động từ đúng thì\n+thiếu "n" ở "taken"\n\nBạn có thể sửa thành: I have taken your bags.', isMe: false, isShow: false },
                { role: "user", content: "Kiểm tra ngữ pháp của câu tiếng anh sau:I will go to school tomorrow", isMe: true, isShow: false },
                { role: "assistant", content: 'Đánh giá ngữ pháp cho [I will go to school tomorrow].\nCâu của bạn đã đúng ngữ pháp!', isMe: false, isShow: false },
            ],
            hintList: [
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Sử dụng công nghệ chatbox mới nhất được phát triển bởi Chat GPT',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Bạn có thể tự do hội thoại bằng tiếng anh với mọi chủ đề',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Trong quá trình hội thoại hệ thống AI của Chat GPT cũng sẽ tự động sửa các lỗi ngữ pháp,gợi ý những cách viết hay cho bạn',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Bạn có thể lưu/học lại từ vựng trong danh sách bằng chức năng "Đã học" hoặc "Đã quên" tương ứng với tab "Ngữ Pháp Chưa Học" và "Ngữ Pháp Đã Học".',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Bên dưới mỗi từ vựng đều sẽ có danh sách các bài tập để các bạn luyện tập cho từ vựng đó.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Các bạn cũng có thể đóng góp các ví dụ bổ ích cho Eplus365,mỗi đóng góp đều rất là đáng quý đối với cộng đồng.',
                },
            ],
            customChatCommands: [
                {
                    commandLabel: 'basic',
                    commandIcon: 'hand-point-right',
                    commandTitle: 'Tiếng Anh Sơ Cấp',
                    commands: [
                        { role: "user", content: 'warning me if i do not use English in this conversation', isMe: true, isShow: false },
                        { role: "assistant", content: 'Yes, I will remind you to use English in this conversation if you do not. Please keep in mind that English is the language I am programmed to converse in, and it will ensure effective communication between us.', isMe: false, isShow: false },
                        { role: "user", content: 'Could you please speak in basic English so that I can understand you better?', isMe: true, isShow: false }
                    ],
                },
                {
                    commandLabel: 'intermediate',
                    commandIcon: 'hand-point-right',
                    commandTitle: 'Tiếng Anh Trung Cấp',
                    commands: [
                        { role: "user", content: 'warning me if i do not use English in this conversation', isMe: true, isShow: false },
                        { role: "assistant", content: 'Yes, I will remind you to use English in this conversation if you do not. Please keep in mind that English is the language I am programmed to converse in, and it will ensure effective communication between us.', isMe: false, isShow: false },
                        { role: "user", content: 'Could you please speak in intermediate English so that I can understand you better?', isMe: true, isShow: false }
                    ],
                },
                {
                    commandLabel: 'advanced',
                    commandIcon: 'hand-point-right',
                    commandTitle: 'Tiếng Anh Cao Cấp',
                    commands: [
                        { role: "user", content: 'warning me if i do not use English in this conversation', isMe: true, isShow: false },
                        { role: "assistant", content: 'Yes, I will remind you to use English in this conversation if you do not. Please keep in mind that English is the language I am programmed to converse in, and it will ensure effective communication between us.', isMe: false, isShow: false },
                        { role: "user", content: 'Could you please speak in advanced English so that I can understand you better?', isMe: true, isShow: false }
                    ],
                },
            ],
        }
    }),
    actions: {
        updateConversationInfor(conversationInfor) {
            var state = this;
            Object.keys(conversationInfor).forEach(function (key) {
                state.conversationInfor[key] = conversationInfor[key];
            })
        },
        getConversationInfor(payload) {
            var state = this;
            state.conversationInfor.isLoading = true;
            try {
                repository.getConversationInfor(payload)
                    .then((res) => {
                        // const { data } = res;
                        if (res.status === 200) {
                            var data = res.data.data;
                            state.conversationInfor.newsLists = data[0];
                            state.conversationInfor.paging = data[1][0];
                            state.conversationInfor.isLoading = false;
                            return res;
                        }
                    });
            } catch (e) {
                console.log("Action getConversationInfor: " + e.message);
            }
        },
        getNewsList(page) {
            var state = this;
            try {
                repository.getNewsList({
                    pageSize: 4,
                    page: page,
                })
                    .then((res) => {
                        // const { data } = res;
                        if (res.status === 200) {
                            var data = res.data.data;
                            state.conversationInfor.newsLists = data[0];
                            state.conversationInfor.paging = data[1][0];
                            return res;
                        }
                    });
            } catch (e) {
                console.log("Action getNewsList: " + e.message);
            }
        },
    }
});