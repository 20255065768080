// import repository from '@/utils/repository';

const resource = '/writing';

export default {
    getWritingInfor: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/get-writing-infor`,
            data: data,
            showProgress: true,
        });
    },
    saveUserPost: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/save-user-post`,
            data: data,
            showProgress: true,
        });
    },
    deleteUserPost: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/delete-user-post`,
            data: data,
            showProgress: true,
        });
    },
};
