<template>
    <vue-final-modal v-model="missionPopupInfor.showModal" :transition="missionPopupInfor.modalTransition">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" type="button" @click="missionPopupInfor.showModal = false">×</button>
                    <h5 class="modal-title">CHI TIẾT NHIỆM VỤ</h5>
                </div>
                <div v-if="missionPopupInfor.isLoading" class="modal-body">
                    <content-loader viewBox="0 0 400 200" :speed="2" primaryColor="#aaa" secondaryColor="#888888">
                        <rect x="0" y="0" rx="3" ry="3" width="100%" height="10" />
                        <rect x="0" y="15" rx="3" ry="3" width="100%" height="100" />
                        <rect x="0" y="120" rx="3" ry="3" width="100%" height="10" />
                        <rect x="0" y="135" rx="3" ry="3" width="100%" height="10" />
                        <rect x="0" y="150" rx="3" ry="3" width="100%" height="10" />
                        <rect x="0" y="180" rx="3" ry="3" width="100%" height="15" />
                    </content-loader>
                </div>
                <div v-if="!missionPopupInfor.isLoading" class="modal-body no-padding">
                    <div class="form-group" min-width="1024px">
                        <div class="row no-margin">
                            <input type="hidden" id="mission-id" :value="missionPopupInfor.missionId" />
                            <div class="col-xs-12 dialog-padding">
                                <label class="mission-title"
                                    ><span style="">{{ missionPopupInfor.missionTitle }}</span></label
                                >
                            </div>
                            <div class="col-xs-12 no-padding">
                                <div class="mission-content" v-html="missionPopupInfor.missionContent"></div>
                            </div>
                            <div class="col-xs-12 dialog-padding mission-part">
                                <div class="form-group margin-bottom">
                                    <label class="title-label">Độ khó nhiệm vụ</label>
                                    <div class="input-group">
                                        <select style="width: 100px" class="inline-block form-control input-sm" id="mission-level" :disabled="missionPopupInfor.missionCondition != '0'" v-model="missionPopupInfor.difficultLevel">
											<option v-for="level in 5">{{ level }}</option>
                                        </select>
                                        <span class="input-group-text text-follow"
                                            >(Số bài phải học : <span id="unit_per_times">{{ unitPerTimes * missionPopupInfor.difficultLevel }}</span
                                            >)</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 no-padding mission-part">
                                <div class="col-xs-12 dialog-padding">
                                    <label class="title-label">Nhiệm Vụ Thành Công</label>
                                </div>
                                <div class="col-sm-6 col-xs-12 dialog-padding">
                                    <label
                                        >Điểm kinh nghiệm: <span class="success-score" id="exp">+{{ successMissionExp }}</span></label
                                    >
                                </div>
                                <div class="col-sm-6 col-xs-12 dialog-padding">
                                    <label
                                        >Điểm đóng góp: <span class="success-score" id="ctp">+{{ successMissionCtp }}</span></label
                                    >
                                </div>
                            </div>
                            <div class="col-xs-12 no-padding">
                                <div class="col-xs-12 dialog-padding">
                                    <label class="title-label">Nhiệm Vụ Thất Bại</label>
                                </div>
                                <div class="col-sm-6 col-xs-12 dialog-padding">
                                    <label
                                        >Điểm kinh nghiệm: <span class="failed-score" id="failed_exp">-{{ failedMissionExp }}</span></label
                                    >
                                </div>
                                <div class="col-sm-6 col-xs-12 dialog-padding">
                                    <label
                                        >Điểm đóng góp: <span class="failed-score" id="failed_ctp">-{{ failedMissionCtp }}</span></label
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!missionPopupInfor.isLoading" class="modal-footer">
                    <div v-if="missionPopupInfor.missionCondition == 4">
                        <button class="btn btn-danger btn-sm" data-dismiss="modal" type="button" disabled="disabled">Bạn đã từ chối nhiệm vụ này!</button>
                        <button class="btn btn-default btn-sm" data-dismiss="modal" type="button" @click="missionPopupInfor.showModal = false">Đóng</button>
                    </div>
                    <div v-else-if="missionPopupInfor.missionCondition == 3">
                        <button class="btn btn-success btn-sm" type="button" disabled="disabled">Bạn đã hoàn thành nhiệm vụ này!</button>
                        <button class="btn btn-default btn-sm" data-dismiss="modal" type="button" @click="missionPopupInfor.showModal = false">Đóng</button>
                    </div>
                    <div v-else-if="missionPopupInfor.missionCondition == 5">
                        <button class="btn btn-danger btn-sm" type="button" disabled="disabled">Nhiệm vụ này đã thất bại!</button>
                        <button class="btn btn-default btn-sm" data-dismiss="modal" type="button" @click="missionPopupInfor.showModal = false">Đóng</button>
                    </div>
                    <div v-else-if="missionPopupInfor.missionCondition==2">
						<button class="btn btn-warning btn-sm" type="button" disabled="">{{missionPopupInfor.missionId==appInfor.doingMission.missionId?'Bạn đang thực hiện nhiệm vụ này':'Bạn đang thực hiện nhiệm vụ khác!'}}</button>
						<button class="btn btn-default btn-sm" data-dismiss="modal" type="button" @click="missionPopupInfor.showModal = false">Đóng</button>
					</div>
                    <div v-else-if="missionPopupInfor.missionCondition == 0">
                        <button class="btn btn-primary btn-sm" id="btn-accept-mission" type="button" @click="acceptMission(missionPopupInfor.missionId)">Chấp Nhận Nhiệm Vụ</button>
                        <button class="btn btn-default btn-sm" type="button" id="btn-refuse-mission" @click="refuseMission()">Từ Chối Nhiệm Vụ</button>
                    </div>
                    <div v-else>
                        <button class="btn btn-primary btn-sm" id="btn-do-mission" type="button" @click ="doMission()" :disabled="missionPopupInfor.missionTryTimesCount > missionTryTimes">Thực Hiện Nhiệm Vụ(Còn {{ missionPopupInfor.missionTryTimes - missionPopupInfor.missionTryTimesCount + "/" + missionPopupInfor.missionTryTimes }})</button>
                        <button class="btn btn-default btn-sm" data-dismiss="modal" type="button" @click="missionPopupInfor.showModal = false">Đóng</button>
                    </div>
                </div>
            </div>
        </div>
    </vue-final-modal>
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import MainMenu from '@/share-layout/menu';

/**
 * import template
 */
// import template from "./template.html";
// import store from "@/store";
import { missionPopupStore } from "./store";

/**
 * Define main layout
 */
const MissionPopup = {
    name: "MissionPopup",
    // template: template,
    components: {
        // MainMenu,
    },
    beforeCreate() {
        // if (!store.hasModule("missionPopup")) {
        // 	store.registerModule("missionPopup", missionPopupStore);
        // }
    },
    created() {},
    computed: {
        ...mapState(missionPopupStore, ["missionPopupInfor"]),
    },
    data() {
		var missionPopup = missionPopupStore();
        return {
            unitPerTimes: missionPopup.missionPopupInfor.unitPerTimes,
            difficultLevel: missionPopup.missionPopupInfor.difficultLevel,
            successMissionExp: missionPopup.missionPopupInfor.successMissionExp,
            successMissionCtp: missionPopup.missionPopupInfor.successMissionCtp,
            failedMissionExp: missionPopup.missionPopupInfor.failedMissionExp,
            failedMissionCtp: missionPopup.missionPopupInfor.failedMissionCtp,
        };
    },
    mounted() {
        this.$watch(
            () => ({
                difficultLevel: this.missionPopupInfor.difficultLevel,
                successMissionExp: this.missionPopupInfor.successMissionExp,
                successMissionCtp: this.missionPopupInfor.successMissionCtp,
                failedMissionExp: this.missionPopupInfor.failedMissionExp,
                failedMissionCtp: this.missionPopupInfor.failedMissionCtp,
            }),
            (data) => {
                this.successMissionExp = this.missionPopupInfor.successMissionExp * data.difficultLevel;
                this.successMissionCtp = this.missionPopupInfor.successMissionCtp * data.difficultLevel;
                this.failedMissionExp = this.missionPopupInfor.failedMissionExp * data.difficultLevel;
                this.failedMissionCtp = this.missionPopupInfor.failedMissionCtp * data.difficultLevel;
            },{
				immediate:true,
			}
        );
        // this.getMissionPopupInfor();
    },
    methods: {
        ...mapActions(missionPopupStore, ["showMissionPopup", "updateMissionPopupInfor","acceptMission","doMission","refuseMission"]),
        confirm() {
            // some code...
            this.show = false;
        },
        cancel(close) {
            // some code...
            close();
        },
        updateResizedScreen() {
            // this.updateMissionPopupInfor({
            // 	optionFontSize: this.$refs.optionHeader[0].clientWidth/12,
            // });
        },
    },
};
export default defineNuxtComponent(MissionPopup);
</script>
<style lang="scss">
@import "./style.scss";
</style>
