import repository from "./repository";
import { leftTabStore } from "~~/components/left-tab/store";
export const missionPopupStore = defineStore('missionPopup', {
    namespaced: true,
    state: () => ({
        missionPopupInfor: {
            isLoading: true,
            showModal: false,
            modalTransition: 'bounce',
            missionId: '1',
            missionOwnerId: '1',
            missionTitle: 'test mission',
            missionContent: 'mission content',
            unitPerTimes: '2',
            difficultLevel: '1',
            successMissionExp: '100',
            successMissionCtp: '200',
            failedMissionExp: '50',
            failedMissionCtp: '100',
            missionCondition: '1',
            missionStatus: '0',
            missionTryTimesCount: '0',
            missionTryTimes: '3',
        }
    }),
    actions: {
        updateMissionPopupInfor(missionPopupInfor) {
            var state = this;
            Object.keys(missionPopupInfor).forEach(function (key) {
                state.missionPopupInfor[key] = missionPopupInfor[key];
            })
        },
        getMission(missionId) {
            try {
                var state = this;
                var payload = {
                    mission_id: missionId,
                };
                repository.getMission(payload)
                    .then((res) => {
                        if (res.status === 200) {
                            var data = res.data.data;
                            this.updateMissionPopupInfor(data[0][0]);
                            state.missionPopupInfor.isLoading = false;
                            state.missionPopupInfor.showModal = true;
                            return res;
                        }
                    });
            } catch (e) {
                console.log("Action getMission: " + e.message);
            }
        },
        acceptMission() {
            try {
                var payload = {
                    mission_id: this.missionPopupInfor.missionId,
                    difficultLevel: this.missionPopupInfor.difficultLevel,
                };
                repository.acceptMission(payload)
                    .then((res) => {
                        if (res.status === 200) {
                            var data = res.data.data;
                            var leftTab = leftTabStore();
                            this.updateMissionPopupInfor(data[1][0]);
                            leftTab.leftTabInfor.missionLists.forEach(function(mission){
                                if(mission.missionId == data[1][0].missionId){
                                    mission.missionIcon =  'futbol';
                                    mission.missionTransistion = 'spin';
                                }
                            })
                            return res;
                        }
                    });
            } catch (e) {
                console.log("Action acceptMission: " + e.message);
            }
        },
        doMission() {
            try {
                var payload = {
                    mission_id: this.missionPopupInfor.missionId,
                };
                repository.doMission(payload)
                    .then((res) => {
                        if (res.status === 200) {
                            var data = res.data.data;
                            var route = useRouter();
                            const { $appStates,$appMethods } = useNuxtApp();
                            this.updateMissionPopupInfor(data[1][0]);
                            route.push({ path: data[1][0].missionLink });
                            $appMethods.getAppState();
                            $appStates.app.appInfor.isReloadContent = true;
                            this.missionPopupInfor.showModal = false;
                            return res;
                        }
                    });
            } catch (e) {
                console.log("Action acceptMission: " + e.message);
            }
        },
        refuseMission() {
            try {
                const { $appStates,$appMethods } = useNuxtApp();
                var _this = this;
                $appMethods.showDialog(3, 'C008', function () {
                    $appStates.dialogPopup.dialogPopupInfor.dialogButtons[0].buttonAction = function () {
                        var payload = {
                            mission_id: _this.missionPopupInfor.missionId,
                        };
                        repository.refuseMission(payload)
                            .then((res) => {
                                if (res.status === 200) {
                                    var data = res.data.data;
                                    $appMethods.getAppState();
                                    // $appStates.app.appInfor.isReloadContent = true;
                                    _this.missionPopupInfor.showModal = false;
                                    $appMethods.hideDialog();
                                    return res;
                                }
                            });
                    }
                })
            } catch (e) {
                console.log("Action acceptMission: " + e.message);
            }
        },
        // getNewsList(context, page) {
        //     context.commit('getNewsList', page);
        // },
        showMissionPopup(missionId){
            this.updateMissionPopupInfor({
                // showModal: true,
                isLoading: true,
            });
            this.getMission(missionId);
        },
    }
});