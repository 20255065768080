<template>
    <div class="col-xs-12 no-padding sentence-box margin-top">
        <slot name="extendsTop"></slot>
        <div class="col-xs-12 no-padding mean-box">
            <slot name="extendTop"></slot>
            <span v-if="!sentenceAnalysisInfor.isEditMode" class="input-title-box sub-text">Câu({{ sentenceAnalysisInfor.sentenceIndex + 1 }}/{{ sentenceAnalysisInfor.sentenceList.length }})</span>
            <span v-else class="input-title-box sub-text">Nhập Tự Do</span>
            <div class="sentence-controls margin-bottom">
                <table class="full-width">
                    <tbody>
                        <tr>
                            <td v-if="!sentenceAnalysisInfor.isEditMode" class="no-padding btn-td left" @click="prevSentence()">
                                <font-awesome-icon :icon="['fas', 'chevron-left']" />
                            </td>
                            <td class="no-padding">
                                <resize-textarea class="form-control full-width text-center" placeholder="Xin nhập nội dung" :disabled="!sentenceAnalysisInfor.isEditMode" :rows="2" :maxHeight="150" v-model="sentenceAnalysisInfor.currentText" :modelValue="sentenceAnalysisInfor.currentText"> </resize-textarea>
                                <!-- <input class="form-control input-sm no-margin" disabled="">{{ sentenceAnalysisInfor.sentenceList[sentenceAnalysisInfor.sentenceIndex] }}</input> -->
                            </td>
                            <td v-if="!sentenceAnalysisInfor.isEditMode" class="no-padding btn-td right" @click="nextSentence()">
                                <font-awesome-icon :icon="['fas', 'chevron-right']" />
                            </td>
                        </tr>
                        <tr v-if="sentenceAnalysisInfor.isCanEdit">
                            <td class="no-padding" colspan="3">
                                <button class="btn btn-sm btn-default full-width" @click="sentenceAnalysisInfor.isEditMode = !sentenceAnalysisInfor.isEditMode">{{sentenceAnalysisInfor.isEditMode?'Tắt':'Bật'}} Nhập Chữ Tự Do</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <slot name="extends"></slot>
        </div>
    </div>
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import SentenceAnalysisMenu from '@/share-layout/menu';

/**
 * import template
 */
// import template from "./template.html";
// import store from "@/store";
import { sentenceAnalysisStore } from "./store";
import { vocabularyQuizPopupStore } from "~/components/popup/vocabulary-quiz/store.js";
/**
 * Define sentenceAnalysis layout
 */
const SentenceAnalysis = {
    name: "SentenceAnalysis",
    // template: template,
    components: {
        // SentenceAnalysisMenu
    },
    beforeCreate() {},
    props: ["fullText", "isCanEdit"],
    computed: {
        ...mapState(sentenceAnalysisStore, ["sentenceAnalysisInfor"]),
    },
    mounted() {
        var { $nlp, $bus } = useNuxtApp();
        this.$watch(
            () => ({
                fullText: this.fullText,
                // quizLevel: this.quizLevel,
            }),
            (newValue) => {
                // var linkId = to.linkId !=''?to.linkId:from.linkId;
                // const segmenter = new Intl.Segmenter("en", { granularity: "sentence" });
                // var sentenceList = Array.from(segmenter.segment(this.fullText), (s) => s.segment).filter((s) => s != "\n");
                var doc = $nlp.readDoc(this.fullText);
                var sentenceList = doc.sentences().out();
                this.updateSentenceAnalysisInfor({
                    fullText: this.fullText,
                    sentenceList: sentenceList,
                    sentenceIndex: 0,
                });
                this.sentenceAnalysisInfor.currentText = this.sentenceAnalysisInfor.sentenceList[0];
                // this.updateAutoSendCommands();
            },
            {
                immediate: true,
            }
        );
        this.$watch(
            () => ({
                sentenceIndex: this.sentenceAnalysisInfor.sentenceIndex,
                isEditMode: this.sentenceAnalysisInfor.isEditMode,
            }),
            (newValue) => {
                if(newValue.isEditMode){
                    this.sentenceAnalysisInfor.currentText = "";
                }else{
                    this.sentenceAnalysisInfor.currentText = this.sentenceAnalysisInfor.sentenceList[newValue.sentenceIndex];
                }
                // this.updateAutoSendCommands();
            }
        );
        this.$watch(
            () => ({
                currentText: this.sentenceAnalysisInfor.currentText,
            }),
            (newValue) => {
                $bus.$emit("sentencesChange", this);
                // this.updateAutoSendCommands();
            }
        );
        this.sentenceAnalysisInfor.isCanEdit = this.isCanEdit ?? false;
    },
    methods: {
        ...mapActions(sentenceAnalysisStore, ["updateSentenceAnalysisInfor", "checkSentenceAnalysisAnswer"]),
        ...mapActions(vocabularyQuizPopupStore, ["checkAnswer"]),
        prevSentence() {
            if (this.sentenceAnalysisInfor.sentenceIndex == 0) {
                this.sentenceAnalysisInfor.sentenceIndex = this.sentenceAnalysisInfor.sentenceList.length - 1;
            } else {
                this.sentenceAnalysisInfor.sentenceIndex--;
            }
        },
        nextSentence() {
            if (this.sentenceAnalysisInfor.sentenceIndex == this.sentenceAnalysisInfor.sentenceList.length - 1) {
                this.sentenceAnalysisInfor.sentenceIndex = 0;
            } else {
                this.sentenceAnalysisInfor.sentenceIndex++;
            }
        },
    },
};
export default defineNuxtComponent(SentenceAnalysis);
// export default SentenceAnalysis;
</script>
<style lang="scss">
@import "./style.scss";
</style>
