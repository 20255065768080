<script setup>
definePageMeta({
    key: "static",
});
import { appStore } from "@/store/index.js";
import { listeningStore } from "./store";
const { isMobile } = useDevice();
var app = appStore();
var listening = listeningStore();
var route = useRoute();
var data = {
    target_id: route.params.id[0] ?? "",
    catalogue_id: "",
    group_id: "",
    screen_div: "/listening",
};
if (app.appInfor.isRefresh) {
    const { $repository } = useNuxtApp();
    var res = await $repository({
        method: "post",
        url: `/listening/get-listening-infor`,
        data: data,
        showProgress: true,
        headers: {
            Authorization: `Bearer ${useCookie("eplus365token").value}`, //set token for ssr request
        },
    });
    listening.setListeningInfor(res);
    app.appInfor.isRefresh = false;
}
app.appInfor.isSwitching = false;
//phai tach cac watcher ra thanh cac ham roi gọi luon
</script>
<template>
    <div class="col-xs-12 web-panel middle-content">
        <left-tab />
        <div class="col-lg-9 col-xs-12 no-padding change-content border-left">
            <div class="right-header col-xs-12 no-padding">
                <div class="col-md-8 col-xs-12 no-padding">
                    <table class="full-width">
                        <tbody>
                            <tr>
                                <td class="text-center">
                                    <h5>
                                        <template v-if="appInfor.isMissionMode">
                                            <font-awesome-icon icon="fa-solid fa-futbol" spin />
                                            Nhiệm Vụ: {{ appInfor.doingMission.missionTitle }}
                                        </template>
                                        <template v-else>
                                            <i class="glyphicon glyphicon-education"></i>
                                            HỌC NGHE TIẾNG ANH
                                        </template>
                                    </h5>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <right-tab>
                <!-- <template v-slot:extends>
                    <button class="btn btn-sm btn-primary full-width" @click="showListeningQuizPopup(listeningShowList)">{{ appInfor.isMissionMode ? "Làm Nhiệm Vụ" : "Bài Tập Tự Luyện" }}</button>
                </template> -->
            </right-tab>
            <div class="col-md-8 col-md-pull-4 col-xs-12 web-main" :class="appInfor.isMobile ? '' : 'padding-left'">
                <content-loader v-if="listeningInfor.isLoading && !appInfor.isMobile" viewBox="0 0 400 124" :speed="2" primaryColor="#aaa" secondaryColor="#888888">
                    <rect x="45%" y="3" rx="3" ry="3" width="15%" height="6" />
                    <rect x="65%" y="3" rx="3" ry="3" width="15%" height="6" />
                    <rect x="85%" y="3" rx="3" ry="3" width="15%" height="6" />
                    <rect x="0" y="13" rx="3" ry="3" width="100%" height="6" />
                    <rect x="0" y="25" rx="3" ry="3" width="100%" height="500" />
                </content-loader>
                <content-loader v-if="listeningInfor.isLoading && appInfor.isMobile" viewBox="0 0 200 124" :speed="2" primaryColor="#aaa" secondaryColor="#888888">
                    <rect x="0" y="3" rx="3" ry="3" width="100%" height="6" />
                    <rect x="0" y="13" rx="3" ry="3" width="45%" height="6" />
                    <rect x="55%" y="13" rx="3" ry="3" width="45%" height="6" />
                    <rect x="0" y="23" rx="3" ry="3" width="45%" height="6" />
                    <rect x="55%" y="23" rx="3" ry="3" width="45%" height="6" />
                    <rect x="0" y="33" rx="3" ry="3" width="100%" height="6" />
                    <rect x="0" y="45" rx="3" ry="3" width="100%" height="500" />
                </content-loader>
                <template v-if="!listeningInfor.isLoading && rightTabInfor.currentData.dataId != undefined">
                    <template v-if="rightTabInfor.currentData.dataId == 404">
                        <post-content contentType="404"></post-content>
                    </template>
                    <template v-else="!listeningInfor.isLoading && rightTabInfor.currentData.dataId != undefined">
                        <listening-content></listening-content>
                    </template>
                </template>
                <template v-else-if="!listeningInfor.isLoading && appInfor.isMissionMode">
                    <post-content contentType="missionMode"></post-content>
                </template>
                <template v-else-if="!listeningInfor.isLoading && rightTabInfor.currentData.dataId == undefined">
                    <!-- <listening-content></listening-content> -->
                    <post-content contentType="welcome"></post-content>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
import { listeningStore } from "./store";
// import { listeningQuizPopupStore } from "@/components/popup/listening-quiz/store.js";
/**
 * Define main layout
 */

const Listening = {
    name: "Listening",
    components: {},
    beforeCreate() {},
    computed: {
        ...mapState(listeningStore, ["listeningInfor", "listeningShowList"]),
    },
    mounted() {
        this.$watch(
            () => ({
                currentData: this.rightTabInfor.currentData,
            }),
            (rightTab) => {
                this.initPost(rightTab);
            },
            {
                deep: true,
            }
        );
        const { $bus } = useNuxtApp();

        $bus.$on("saveListeningSuccess", (res) => {
            this.updateWordRelationship(res);
        });
    },
    methods: {
        ...mapActions(listeningStore, ["updateListeningInfor", "getListeningInfor", "setListeningInfor", "getExample", "initPost"]),
        // ...mapActions(listeningQuizPopupStore, ["showListeningQuizPopup"]),
        refreshScreen() {
            var data = {
                target_id: "",
                catalogue_id: this.rightTabInfor.rightTabItems[0].value ?? "",
                group_id: this.rightTabInfor.rightTabItems[1].value ?? "",
                screen_div: "/listening",
            };
            this.getListeningInfor(data);
        },
        updatePagingContent(paging, page) {
            if (paging.pagingId == 1) {
                //update paging content
                var { $appMethods } = useNuxtApp();
                $appMethods.getExample(page, 1);
            }
        },
        updateWordRelationship(res) {
            if (res.popupType == 1) {
                this.listeningInfor.wordRelationshipSynonymsList = res.data[1].slice();
            } else {
                this.listeningInfor.wordRelationshipOppositeList = res.data[2].slice();
            }
        },
    },
};
export default defineNuxtComponent(Listening);
</script>
<style lang="scss">
@import "./style.scss";
</style>
