<script setup>
import { appStore as useAppStore } from "@/store/index.js";
import { homePageStore } from "./store";
var app = useAppStore();
if (app.appInfor.isRefresh) {
    var payload = {
        screen_div: "/",
    };
    const { $repository } = useNuxtApp();
    var res = await $repository({
        method: "post",
        url: `homepage/get-homepage-infor`,
        data: payload,
        headers: {
            Authorization: `Bearer ${useCookie("eplus365token").value}`, //set token for ssr request
        },
    });
    var homePage = homePageStore();
    var data = res.data.data;
    homePage.homePageInfor.newsLists = data[0];
    homePage.homePageInfor.paging = data[1][0];
    homePage.homePageInfor.isLoading = false;
    app.appInfor.isRefresh = false;
}
app.appInfor.isSwitching = false;
//phai tach cac watcher ra thanh cac ham roi gọi luon
</script>
<template>
    <div class="col-xs-12 web-panel middle-content">
        <left-tab />
        <div class="no-padding change-content border-left" :class="homePageInfor.homePageSize">
            <div class="col-xs-12 no-padding right-header no-fixed" data-target="#menu-body" data-toggle="collapse">
                <table style="width: 100%">
                    <tbody>
                        <tr>
                            <td width="95%">
                                <h5>
                                    Chào mừng bạn đến với website
                                    <span> English Plus!!! </span>
                                    Hãy cùng chúng tôi trải nghiệm 1 môi trường tiếng anh thật sự
                                    <i class="glyphicon glyphicon-fire"> </i>
                                </h5>
                            </td>
                            <td class="collapse-icon" width="5%"><i class="glyphicon glyphicon-menu-down" style="float: right; margin-right: 2px"></i></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-xs-12 no-padding right-content">
                <div class="col-md-12 collapse in no-padding open-when-small" id="menu-body">
                    <div v-for="option in homePageInfor.optionLists" class="col-md-2 col-sm-2 col-xs-4 option">
                        <div class="option-header" ref="optionHeader">
                            <h5>{{ option.optionTitle }}</h5>
                        </div>
                        <router-link :to="option.optionLink" class="option-link">
                            <!-- <img :src="homePageInfor.optionBackground" alt='acb' style="width: 100%" /> -->
                            <div class="option-body" :option-link="option.optionLink">
                                <h5 v-for="optionItem in option.optionItems" class="option-item"><i class="glyphicon glyphicon-flash"></i> {{ optionItem }}</h5>
                            </div>
                            <div class="down-triangle"></div>
                        </router-link>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="special-title" v-html="homePageInfor.introduceBox.introduceTitle"></div>
                </div>
                <div class="col-xs-12 margin-top margin-bottom" v-touch:tap="showFixedBottomBar">
                    <div class="special-box col-xs-12 margin-top">
                        <div class="special-content">
                            <h4 v-for="introduceItem in homePageInfor.introduceBox.introduceItems">{{ introduceItem }}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 no-padding right-header no-fixed">
                <h5>
                    <i class="glyphicon glyphicon-retweet"> </i>
                    Hôm nay có gì mới???
                </h5>
            </div>
            <div v-if="homePageInfor.isLoading" class="col-xs-12 no-padding" id="result">
                <div class="col-lg-6 col-md-6 col-xs-12">
                    <content-loader viewBox="0 0 400 35" :speed="3" primaryColor="#aaa" secondaryColor="#888888">
                        <circle cx="10" cy="10" r="6" />
                        <rect x="25" y="5" rx="5" ry="5" width="90%" height="10" />
                        <circle cx="10" cy="25" r="6" />
                        <rect x="25" y="20" rx="5" ry="5" width="90%" height="10" />
                    </content-loader>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12">
                    <content-loader viewBox="0 0 400 35" :speed="3" primaryColor="#aaa" secondaryColor="#888888">
                        <circle cx="10" cy="10" r="6" />
                        <rect x="25" y="5" rx="5" ry="5" width="90%" height="10" />
                        <circle cx="10" cy="25" r="6" />
                        <rect x="25" y="20" rx="5" ry="5" width="90%" height="10" />
                    </content-loader>
                </div>
            </div>
            <div v-else class="col-xs-12 no-padding" id="result">
                <div v-for="news in homePageInfor.newsLists" class="col-lg-6 col-md-6 col-xs-12 news-list">
                    <NuxtLink :to="news.newsLink + '/' + news.newsTargetId + '/' + news.newsTargetName" :title="news.newsNotes">
                        <i class="glyphicon glyphicon-hand-right"> </i>
                        {{ news.newsDescription1 }}
                        <span class="target-nm"> {{ news.newsDescription2 }} </span>
                        {{ news.newsDescription3 }}
                    </NuxtLink>
                </div>
                <!-- <ClientOnly> -->
                <Paging :pagingId="homePageInfor.paging.pagingId" :currentPage="homePageInfor.paging.currentPage" :totalPage="homePageInfor.paging.totalPage" />
                <!-- </ClientOnly> -->
            </div>
        </div>
    </div>
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import MainMenu from '@/share-layout/menu';

/**
 * import template
 */
// import template from './template.html';
import { appStore } from "@/store";
import { homePageStore } from "./store";

/**
 * Define main layout
 */
const HomePage = {
    name: "HomePage",
    // template: template,
    components: {
        // MainMenu,
    },
    beforeCreate() {
        // if (!store.hasModule('homePage')) {
        //     store.registerModule('homePage', homePageStore);
        // }
    },
    created() {
        // this.$watch(
        // 	() => ({
        // 		isRefresh: this.appInfor.isRefresh,
        // 	}),
        // 	(data) => {
        //         var route = useRoute();
        // 		if(data.isRefresh && route.meta.rootPath=='/'){
        // 			this.getHomePageInfor();
        // 		}
        // 	}
        // );
    },
    computed: {
        ...mapState(homePageStore, ["homePageInfor"]),
    },
    mounted() {
        this.updateLeftTabInfor({
            renderLessonList: false,
            renderSystemNotifyList: false,
        });
    },
    methods: {
        ...mapActions(homePageStore, ["getHomePageInfor", "updateHomePageInfor", "getNewsList"]),
        // updateResizedScreen() {
        // 	this.updateHomePageInfor({
        // 		optionFontSize: this.$refs.optionHeader[0].clientWidth/12,
        // 	});
        // },
        updatePagingContent(paging, page) {
            // if(paging.pagingId==1){
            //update paging content
            this.getNewsList(page);
            // }
        },
        refreshScreen() {
            var data = {
                screen_div: "/",
            };
            this.getHomePageInfor(data);
        },
    },
};
export default defineNuxtComponent(HomePage);
</script>
<style lang="scss">
@import "./style.scss";
</style>
