<template>
    <div class="col-xs-12 no-padding audio-recorder-wrap">
        <div class="chatbox__title right-header text-center">
            <h5><font-awesome-icon icon="microphone-lines" /> {{ audioRecorderInfor.recorderTitle }}</h5>
        </div>
        <div class="col-xs-12 condition-box no-padding">
            <div class="col-xs-12 col-sm-3 dialog-padding text-left">
                <div class="form-group">
                    <label>Loại Tiếng Anh</label>
                    <select class="form-control input-sm" v-model="audioRecorderInfor.englishDiv.selected">
                        <option v-for="option in audioRecorderInfor.englishDiv.options" :value="option.value">{{ option.text }}</option>
                    </select>
                </div>
            </div>
            <div class="col-xs-12 col-sm-3 dialog-padding text-left">
                <div class="form-group">
                    <label>Giới Tính</label>
                    <select class="form-control input-sm" v-model="audioRecorderInfor.sexDiv.selected">
                        <option v-for="option in audioRecorderInfor.sexDiv.options" :value="option.value">{{ option.text }}</option>
                    </select>
                </div>
            </div>
            <div class="col-xs-12 col-sm-3 dialog-padding text-left">
                <div class="form-group">
                    <label>Giọng Đọc</label>
                    <div class="input-group">
                        <select class="form-control input-sm no-radius-right" v-model="audioRecorderInfor.voiceDiv.selected">
                            <option value="">Ngẫu nhiên</option>
                            <option v-for="option in audioRecorderInfor.voiceDiv.options" :value="option.value">{{ option.text }}</option>
                        </select>
                        <span class="input-group-btn">
                            <button class="btn btn-sm btn-default" type="button" :disabled="audioRecorderInfor.voiceDiv.selected == ''" @click="audioRecorderInfor.voiceDiv.selected != '' && playAudio(audioRecorderInfor.audioSpeechLink + audioRecorderInfor.voiceDiv.selected + '.mp3')">Nghe thử</button>
                        </span>
                    </div>
                    <!-- /input-group -->
                </div>
            </div>
            <div class="col-xs-12 col-sm-3 dialog-padding text-left">
                <div class="form-group">
                    <label>Kiểu Phiên Âm</label>
                    <div class="input-group">
                        <select class="form-control input-sm no-radius-right" v-model="audioRecorderInfor.spellDiv.selected">
                            <option v-for="option in audioRecorderInfor.spellDiv.options" :value="option.value">{{ option.text }}</option>
                        </select>
                        <span class="input-group-btn w-70">
                            <input class="form-control input-sm no-radius-left" v-model="audioRecorderInfor.spellDiv.options.filter((el) => el.value == audioRecorderInfor.spellDiv.selected)[0].example" disabled />
                        </span>
                    </div>
                    <!-- /input-group -->
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-xs-12 audio-recorder">
            <div class="text-center">
                <!-- <h2 class="font-bold text-2xl">{{ audioRecorderInfor.recorderTitle }}</h2> -->
                <div class="icon-wrap">
                    <div class="icon-box">
                        <font-awesome-icon v-if="recording" :icon="['fas', 'stop']" @click="toggleRecording" />
                        <font-awesome-icon v-else :icon="['fas', 'microphone-lines']" @click="toggleRecording" />
                    </div>
                </div>
                <div>{{ recordedTime ? recordedTime : "00:00" }}</div>
                <div class="message-box">
                    <div class="text-sm font-bold">{{ successMessage }}</div>
                    <div class="text-sm">{{ instructionMessage }}</div>
                    <div class="text-sm text-danger">{{ errorMessage }}</div>
                </div>
                <div class="result-box">
                    <div class="full-width">
                        <audio controls :src="recordedAudio" type="audio/mpeg" class="mx-auto">
                            Your browser does not support the
                            <code>audio</code> element.
                        </audio>
                    </div>
                    <button class="btn btn-sm btn-primary margin-top full-width" :disabled="!recordedAudio" @click="recordedAudio && pronunciationAssessment()">
                        {{ audioRecorderInfor.buttonTitle ?? "Submit" }}
                    </button>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-xs-12 no-padding process-result-box">
            <div class="chatbox__title right-header text-center">
                <h5><font-awesome-icon icon="list-check" /> Đánh Giá Tổng Quan</h5>
            </div>
            <div class="col-xs-12 text-left">
                <ol>
                    <li :class="setBackgroundByScore(pronunciationResult.AccuracyScore)">
                        <span>
                            <span>Phát âm</span>
                            <span class="float-right">{{ pronunciationResult.AccuracyScore ?? 0 }}</span>
                        </span>
                    </li>
                    <li :class="setBackgroundByScore(pronunciationResult.CompletenessScore)">
                        <span>
                            <span>Độ lưu loát</span>
                            <span class="float-right">{{ pronunciationResult.CompletenessScore ?? 0 }}</span>
                        </span>
                    </li>
                    <li :class="setBackgroundByScore(pronunciationResult.FluencyScore)">
                        <span>
                            <span>Đầy đủ nội dung</span>
                            <span class="float-right">{{ pronunciationResult.FluencyScore ?? 0 }}</span>
                        </span>
                    </li>
                    <li :class="setBackgroundByScore(pronunciationResult.PronScore)">
                        <span>
                            <span>Kết luận</span>
                            <span class="float-right">{{ pronunciationResult.PronScore ?? 0 }}</span>
                        </span>
                    </li>
                    <li class="margin-top text-bold">
                        ※Kết quả trên có độ tin cậy là :
                        <i :class="setTextColorByScore(resultConfidence)">{{ resultConfidence ?? 0 }} %</i>
                    </li>
                    <li>※Hãy đảm bảo phần ghi âm của bạn to,rõ và chính xác nội dung của câu hiện tại để đạt độ tin cậy cao nhất.</li>
                </ol>
            </div>
        </div>
        <div v-if="pronunciationDetailResult" class="col-xs-12 no-padding process-result-box">
            <div class="right-header text-center">
                <h5><font-awesome-icon icon="list-check" /> Đánh Giá Chi Tiết</h5>
            </div>
            <div class="col-xs-12 text-center no-padding detail-result">
                <div v-if="audioRecorderInfor.audioTrueSpeechUrl != ''" class="icon-wrap">
                    <div class="icon-box">
                        <font-awesome-icon :icon="['fas', 'volume-high']" @click="playAudio(audioRecorderInfor.audioTrueSpeechUrl, true)" />
                    </div>
                </div>
                <h2>
                    <mark v-for="word in pronunciationDetailResult" :class="setTextColorByScore(word.PronunciationAssessment.AccuracyScore)">
                        <v-popper arrow>
                            <div class="group-text">
                                <div class="top-text">
                                    {{ word.PronunciationAssessment.AccuracyScore }}
                                </div>
                                <div class="bottom-text">
                                    {{ word.Word }}
                                </div>
                            </div>
                            <template #content>
                                <div class="text-center change-preview">
                                    <div>
                                        <div class="full-width" :class="setTextColorByScore(word.PronunciationAssessment.AccuracyScore)">{{ word.Word }}</div>
                                        <div class="full-width score-label">Điểm Theo Âm Tiết</div>
                                        <div class="inline-block mg-1">/</div>
                                        <div v-for="syllable in word.Syllables" class="inline-block group-text mg-1" :class="setTextColorByScore(syllable.PronunciationAssessment.AccuracyScore)">
                                            <div class="top-text text-center">
                                                {{ syllable.PronunciationAssessment.AccuracyScore }}
                                            </div>
                                            <div class="bottom-text">
                                                {{ syllable.Syllable }}
                                            </div>
                                        </div>
                                        <div class="inline-block mg-2">/</div>
                                        <div class="full-width score-label">Điểm Theo Âm Vị</div>
                                        <div class="inline-block mg-1">/</div>
                                        <div v-for="phoneme in word.Phonemes" class="inline-block group-text mg-1" :class="setTextColorByScore(phoneme.PronunciationAssessment.AccuracyScore)">
                                            <div class="top-text text-center">
                                                {{ phoneme.PronunciationAssessment.AccuracyScore }}
                                            </div>
                                            <div class="bottom-text">
                                                {{ phoneme.Phoneme }}
                                            </div>
                                        </div>
                                        <div class="inline-block mg-2">/</div>
                                    </div>
                                    <div class="error-mgs" v-if="word.PronunciationAssessment.ErrorType != 'None'">
                                        <div><font-awesome-icon :icon="['fas', 'triangle-exclamation']" />{{ setErrorByText(word.PronunciationAssessment.ErrorType) }}</div>
                                    </div>
                                </div>
                            </template>
                        </v-popper>
                    </mark>
                </h2>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { audioRecorderStore } from "./store";
import Service from "./api/Service.js";
import Recorder from "./lib/Recorder.js";
import convertTimeMMSS from "./lib/Utils.js";
import "./style.scss";

export default {
    name: "TapirWidget",
    props: {
        // in minutes
        audioText: { type: String, default: "" },
        time: { type: Number, default: 1 },
        bitRate: { type: Number, default: 128 },
        sampleRate: { type: Number, default: 44100 },
        backendEndpoint: { type: String },
        buttonColor: { type: String, default: "green" },

        // callback functions
        afterRecording: { type: Function },
        successfulUpload: { type: Function },
        failedUpload: { type: Function },
        customUpload: { type: Function, default: null },
    },
    components: {},
    data() {
        var audioRecorder = audioRecorderStore();
        return {
            recording: false,
            recordedAudio: null,
            recorder: null,
            successMessage: null,
            errorMessage: null,
            instructionMessage: audioRecorder.audioRecorderInfor.instructionMessage,
            pronunciationResult: [],
            pronunciationDetailResult: null,
            resultConfidence: null,
        };
    },
    computed: {
        ...mapState(audioRecorderStore, ["audioRecorderInfor"]),
        buttonClass() {
            return "icon-box";
        },
        recordedTime() {
            if (this.time && this.recorder?.duration >= this.time * 60) {
                this.toggleRecording();
            }
            return convertTimeMMSS(this.recorder?.duration);
        },
    },
    mounted() {
        this.$watch(
            () => ({
                englishDiv: this.audioRecorderInfor.englishDiv.selected,
                sexDiv: this.audioRecorderInfor.sexDiv.selected,
            }),
            (newValue) => {
                var selectedEnglishDiv = this.audioRecorderInfor.englishDiv.options.filter((val) => val.value == newValue.englishDiv)[0];
                this.audioRecorderInfor.voiceDiv.options = selectedEnglishDiv.voices[newValue.sexDiv];
                this.audioRecorderInfor.voiceDiv.selected = "";
            },
            {
                deep: true,
                immediate: true,
            }
        );
        this.$watch(
            () => ({
                audioText: this.audioText,
            }),
            (newValue) => {
                this.audioRecorderInfor.audioText = newValue.audioText;
            },
            {
                deep: true,
                immediate: true,
            }
        );
        this.$watch(
            () => ({
                pronunciationAssessment: this.audioRecorderInfor.pronunciationAssessment,
            }),
            (newValue) => {
                if (newValue.pronunciationAssessment.privPronJson) {
                    this.pronunciationResult = newValue.pronunciationAssessment.privPronJson.PronunciationAssessment;
                    this.pronunciationDetailResult = newValue.pronunciationAssessment.privPronJson.Words;
                    this.resultConfidence = Math.floor(newValue.pronunciationAssessment.privPronJson.Confidence * 100);
                    // this.pronunciationResult = newValue.pronunciationAssessment.privPronJson.PronunciationAssessment;
                }
            },
            {
                deep: true,
                // immediate: true,
            }
        );
    },
    beforeUnmount() {
        if (this.recording) {
            this.stopRecorder();
        }
    },
    methods: {
        ...mapActions(audioRecorderStore, ["updateAudioRecorderInfor", "pronunciationAssessment"]),
        toggleRecording() {
            this.recording = !this.recording;
            if (this.recording) {
                this.initRecorder();
            } else {
                this.stopRecording();
            }
        },
        initRecorder() {
            this.recorder = new Recorder({
                micFailed: this.micFailed,
                bitRate: this.bitRate,
                sampleRate: this.sampleRate,
            });
            this.recorder.start();
            this.successMessage = null;
            this.errorMessage = null;
            this.recordedAudio = null;
            this.instructionMessage = this.audioRecorderInfor.instructionMessageStop;
            this.service = new Service(this.backendEndpoint);
        },
        stopRecording() {
            this.recorder.stop();
            const recordList = this.recorder.recordList();
            this.recordedAudio = recordList[0].url;
            this.audioRecorderInfor.recordedBlob = recordList[0].blob;
            if (this.recordedAudio) {
                this.successMessage = this.audioRecorderInfor.successMessage;
                this.instructionMessage = null;
            }
            if (this.afterRecording) {
                this.afterRecording();
            }
        },
        async sendData() {
            if (!this.audioRecorderInfor.recordedBlob) {
                return;
            }

            let result = null;
            if (this.customUpload) {
                result = await this.customUpload(this.audioRecorderInfor.recordedBlob);
            } else {
                result = await this.service.postBackend(this.audioRecorderInfor.recordedBlob);
            }

            if (result) {
                this.errorMessage = null;
                this.successMessage = this.audioRecorderInfor.successMessageSubmit;
                if (this.successfulUpload) {
                    this.successfulUpload();
                }
            } else {
                // error uploading
                this.successMessage = null;
                this.errorMessage = this.audioRecorderInfor.errorSubmittingMessage;
                if (this.failedUpload) {
                    this.failedUpload();
                }
            }
        },
        micFailed() {
            this.recording = false;
            this.instructionMessage = this.audioRecorderInfor.instructionMessage;
            this.errorMessage = this.audioRecorderInfor.errorMessage;
        },
        playAudio(selectedAudio, isFullUrl) {
            isFullUrl = isFullUrl ?? false;
            try {
                if (this.appInfor.playingAudio) {
                    this.appInfor.playingAudio.pause();
                    this.appInfor.playingAudio.currentTime = 0;
                }
                if (selectedAudio == "") {
                    return;
                }
                if (isFullUrl == false) {
                    var audio = new Audio(this.appInfor.domain + selectedAudio); // Define audio file
                } else {
                    var audio = new Audio(selectedAudio); // Define audio file
                }
                this.appInfor.playingAudio = audio;
                audio.play();
            } catch (error) {
                console.log("play audio: " + error);
            }
        },
        setBackgroundByScore(score) {
            if (score < 50) {
                return "bg-danger";
            }
            if (score >= 50 && score < 70) {
                return "bg-warning";
            }
            if (score >= 70 && score < 85) {
                return "bg-info";
            }
            if (score >= 85) {
                return "bg-success";
            }
            return "bg-info";
        },
        setTextColorByScore(score) {
            if (score < 50) {
                return "text-danger";
            }
            if (score >= 50 && score < 70) {
                return "text-warning";
            }
            if (score >= 70 && score < 85) {
                return "text-info";
            }
            if (score >= 85) {
                return "text-success";
            }
            return "";
        },
        setErrorByText(text) {
            switch (text) {
                case "Omission":
                    return "Phát âm thiếu";
                    break;
                case "Insertion":
                    return "Phát âm dư";
                    break;
                case "Mispronunciation":
                    return "Phát âm sai";
                    break;
                default:
                    return "";
                    break;
            }
        },
    },
};
</script>
