// import repository from '@/utils/repository.js';
// import store from '@/store';
// import messages from '@/utils/messages';
// import { useContext } from 'nuxt-composition-api'

// const { $repository } = useContext()

const resource = '/common';

export default {
    getAppState: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/app-state`,
            data: data,
        });
    },
    refreshQuestionList: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/refresh-question-list`,
            data: data,
            showProgress: true,
        });
    },
    bookmarkData: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/bookmark-data`,
            data: data,
            // showProgress: true,
        });
    },
    unbookmarkData: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/unbookmark-data`,
            data: data,
            // showProgress: true,
        });
    },
    registerLesson: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/register-lesson`,
            data: data,
            showProgress: true,
        });
    },
    removeLesson: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/remove-lesson`,
            data: data,
            // showProgress: true,
        });
    },
    addExample: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/add-example`,
            data: data,
            showProgress: true,
        });
    },
    getExample: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/get-example`,
            data: data,
            showProgress: true,
        });
    },
    voteAction: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/vote-action`,
            data: data,
            // showProgress: true,
        });
    },
    cancelMission: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/cancel-mission`,
            data: data,
            // showProgress: true,
        });
    },
    completeMission: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/complete-mission`,
            data: data,
            // showProgress: true,
        });
    },
    addComment: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/add-comment`,
            data: data,
            showProgress: true,
        });
    },
    deleteDiscuss: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/delete-discuss`,
            data: data,
            showProgress: true,
        });
    },
    // getLibraries: (data) => {
    //     return repository.get(`${resource}/libraries`, {
    //         params: {
    //             libraryno: data
    //         }
    //     });
    // },
    // getConstruction: () => {
    //     return repository.get(`${resource}/construction`);
    // },
    // getSalesOffice: () => {
    //     return repository.get(`${resource}/sales-office`);
    // },
    // getDepartments: () => {
    //     return repository.get(`${resource}/departments`);
    // },
    // getUnitGroups: () => {
    //     return repository.get(`${resource}/unit-groups`);
    // },
    // getTeam: () => {
    //     return repository.get(`${resource}/team`);
    // },
    // getDutyAuthority: () => {
    //     return repository.get(`${resource}/duty-authority`);
    // },
    // getFunction: () => {
    //     return repository.get(`${resource}/function`);
    // },
    // exportPDF: (body, callback) => {
    //     store.commit('app/showForceLoading');
    //     repository.post(`${resource}/export/pdf`, body).then((res) => {
    //         const { data } = res;
    //         const res1 = data;
    //         if (
    //             data.Code === 200 &&
    //             data.Data.returnno != undefined &&
    //             data.Data.returnno != null &&
    //             data.Data.returnno != ''
    //         ) {
    //             let count = 0;
    //             let requesting = false;
    //             const loop = setInterval(() => {
    //                 if (!requesting) {
    //                     requesting = true;
    //                     repository
    //                         .get(`${resource}/export/pdf/file`, {
    //                             params: {
    //                                 functionid: body.functionid,
    //                                 runno: data.Data.returnno
    //                             }
    //                         })
    //                         .then((res) => {
    //                             const { data } = res;
    //                             if (
    //                                 data.Code === 200 &&
    //                                 data.Data.outputstatus != undefined &&
    //                                 data.Data.outputstatus != null
    //                             ) {
    //                                 clearInterval(loop);
    //                                 store.commit('app/hideForceLoading');
    //                                 if (data.Data.outputstatus == 1) {
    //                                     if (!body.noPreview) {
    //                                         setTimeout(() => {
    //                                             const anchor =
    //                                                 document.createElement('a');
    //                                             anchor.href = `${data.Data.outputurl}`;
    //                                             anchor.target = '_blank';
    //                                             setTimeout(() => {
    //                                                 anchor.click();
    //                                             }, 100);
    //                                         }, 100);
    //                                     }
    //                                 } else {
    //                                     store.commit('app/showHeaderError', [
    //                                         data.Data.outputmessage
    //                                     ]);
    //                                 }
    //                                 if (typeof callback == 'function') {
    //                                     callback(res1, data);
    //                                 }
    //                             }
    //                             if (count >= 60 || data.Code !== 200) {
    //                                 clearInterval(loop);
    //                                 if (count >= 60) {
    //                                     store.commit('app/hideForceLoading');
    //                                     store.commit('app/showHeaderError', [
    //                                         messages.E041
    //                                     ]);
    //                                 }
    //                                 // if (typeof callback == 'function') {
    //                                 //     callback(res1, data);
    //                                 // }
    //                             }
    //                             count++;
    //                             requesting = false;
    //                         })
    //                         //.catch((e) => {
    //                         .catch(() => {
    //                             requesting = false;
    //                             clearInterval(loop);
    //                             // if (typeof callback == 'function') {
    //                             //     callback(res1, e);
    //                             // }
    //                         });
    //                 }
    //             }, 1000);
    //         }
    //     });
    // }
};
