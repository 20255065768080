// import repository from "@/repository";
import readingRepository from "./repository";
import { appStore } from "@/store";
import { rightTabStore } from "~~/components/right-tab/store";
import { leftTabStore } from "~~/components/left-tab/store";
import { exampleStore } from "~~/components/example/store";
import { postContentStore } from "~~/components/post-content/store";
import { exerciseStore } from "~~/components/exercise/store";
export const readingStore = defineStore('reading', {
    namespaced: true,
    state: () => ({
        readingInfor: {
            isLoading: true,
            isAnalysis: false,
            questionListTitle: 'Bài Tập Tự Luyện',
            welcomeSlogan: 'Chào mừng bạn đến với khóa học đọc hiểu của Eplus365!',
            hintList: [
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Danh sách bên phải bao gồm tất cả các bài đọc hiểu có trong hệ thống.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Bạn có thể lọc và chọn bài viết bài đọc hiểu mong muốn dựa theo danh mục và nhóm ở tab bên phải.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Bạn cũng có thể lưu danh mục/nhóm bài đọc hiểu mong muốn bằng chức năng "Lưu Danh Mục/Nhóm" danh sách đã lưu sẽ được hiển thị ở bên trái.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Bạn có thể lưu/học lại bài đọc hiểu trong danh sách bằng chức năng "Đã học" hoặc "Đã quên" tương ứng với tab "Ngữ Pháp Chưa Học" và "Ngữ Pháp Đã Học".',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Mỗi bài đọc hiểu sẽ đều được phân tách thành từng câu,nhập nội dung bạn nghe được sau đó kiểm tra thành quả',
                },
            ],
            readingPostLists: [
                {
                    postId: '1',
                    postAverageRating: '0',
                    postViews: '100',
                    postPublicDate: '2022/09/08',
                    postAuthor: 'Quynd1',
                    postUpdateDate: '2022/10/10',
                    postTitle: 'Cách dùng quán từ xác định "The"1',
                    postContent: 'ありがとうございます。',
                },
            ],
            currentPostText: '',
            autoSendCommands: [],
        }
    }),
    actions: {
        updateReadingInfor(readingInfor) {
            var state = this;
            Object.keys(readingInfor).forEach(function (key) {
                state.readingInfor[key] = readingInfor[key];
            })
        },
        // updateReadingPostQuestionLists(sqlQuestionLists) {
        //     var state = this;
        //     var questionLists = state.readingInfor.readingPostQuestionLists;
        //     questionLists = sqlQuestionLists['question'];
        //     Object.keys(questionLists).forEach(function (key) {
        //         var questionAnswer = sqlQuestionLists['answer'].filter(function (el) {
        //             return el.questionId == questionLists[key].questionId;
        //         });
        //         questionLists[key]['answerLists'] = questionAnswer;
        //     })
        //     state.readingInfor.readingPostQuestionLists = questionLists;
        // },
        getReadingInfor(payload) {
            try {
                var rightTab = rightTabStore();
                var app = appStore();
                var state = this;
                // state.readingInfor.isLoading = true;
                // rightTab.updateRightTabInfor({
                //     isLoading: true,
                // });
                app.appInfor.isSwitching = true;
                readingRepository.getReadingInfor(payload)
                    .then((res) => {
                        // const { data } = res;
                        state.setReadingInfor(res);
                    });
            } catch (e) {
                console.log("Action getReadingInfor: " + e.message);
            }
        },
        setReadingInfor(res) {
            // const { data } = res;
            var app = appStore();
            var rightTab = rightTabStore();
            var leftTab = leftTabStore();
            var example = exampleStore();
            var exercise = exerciseStore();
            var state = this;
            var route = useRoute();
            var router = useRouter();
            // state.readingInfor.isLoading = true;
            // rightTab.updateRightTabInfor({
            //     isLoading: true,
            // });
            if (res.status === 200) {
                var data = res.data.data;
                if (data[9][0] && data[9][0]['missionId'] != 0) {
                    app.appInfor.isMissionMode = true;
                    app.appInfor.doingMission = data[9][0];
                    leftTab.leftTabInfor.renderLessonList = false;
                    rightTab.rightTabInfor.isDisplaySelect = false;
                    rightTab.rightTabInfor.isDisplayButton = false;
                    rightTab.rightTabInfor.isChangeRouter = false;
                    router.push(data[9][0]['missionLink']);
                } else {
                    app.appInfor.isMissionMode = false;
                    app.appInfor.doingMission = {};
                    leftTab.leftTabInfor.renderLessonList = true;
                    rightTab.rightTabInfor.isDisplaySelect = true;
                    rightTab.rightTabInfor.isDisplayButton = true;
                    rightTab.rightTabInfor.isChangeRouter = true;
                }
                rightTab.rightTabInfor.isDisplayVote = false;
                var selectedCategory = data[0].filter(obj => {
                    return obj.selected === true
                })
                rightTab.updateRightTabItems([
                    {
                        selectItems: data[0],
                        value: selectedCategory[0] ? selectedCategory[0].catalogueId : '',
                    },
                    0,
                ]);
                var selectedGroup = data[1].filter(obj => {
                    return obj.selected === true
                })
                rightTab.updateRightTabItems([
                    {
                        selectItems: data[1],
                        value: selectedGroup[0] ? selectedGroup[0].groupId : '',
                    },
                    1
                ]);
                leftTab.updateLeftTabInfor({
                    lessonLists: data[2],
                    isLessonListLoading: false,
                });
                var rightTabMenus = [];
                if (app.appInfor.isMissionMode) {
                    rightTabMenus = [
                        {
                            rightTabMenuLink: 'tab1',
                            rightTabMenuName: 'Nội Dung Nhiệm Vụ',
                            isActive: true
                        }
                    ];
                } else {
                    rightTabMenus = [
                        {
                            rightTabMenuLink: 'tab1',
                            rightTabMenuName: 'Bài Đọc Chưa Học',
                            isActive: true
                        },
                        {
                            rightTabMenuLink: 'tab2',
                            rightTabMenuName: 'Bài Đọc Đã Học',
                            isActive: false
                        },
                    ];
                }
                rightTab.updateRightTabInfor({
                    rightTabMenus: rightTabMenus,
                });
                var selectedTab = data[4].filter(obj => {
                    return obj.isActive === true
                })
                if (selectedTab[0] != undefined) {
                    rightTab.updateRightTabInfor({
                        isRememberedTab: selectedTab[0].remembered,
                    });
                }
                rightTab.updateRightTabInfor({
                    allDataTabLists: data[4],
                    isLoading: false,
                    isEncrypt: false,
                });
                rightTab.updateRightTabInfor({
                    rightTabLists: rightTab.rightTabLists,
                });
                rightTab.updateRightTabInfor({
                    unRememberedTabLists: rightTab.unRememberedTabLists,
                    rememberedTabLists: rightTab.rememberedTabLists,
                });
                rightTab.selectRightTabItem(route.params.id);
                exercise.updateQuestionLists(data[5].slice(), data[6].slice());
                // this.updateReadingPostQuestionLists({
                //     //use slice to copy array
                //     question: data[5].slice(),
                //     answer: data[6].slice(),
                // });
                example.updateExampleInfor({
                    //use slice to copy array
                    exampleLists: data[7].slice(),
                    pagingLists: data[8].slice(),
                });
                state.readingInfor.readingPostLists = data[3];
                this.initPost(rightTab.rightTabInfor);
                // if(selectedTab[0].dataId!=undefined){
                //     this.dispatch('rightTab/selectRightTabItem',selectedTab[0].dataId);
                // }
                state.readingInfor.isLoading = false;
                app.appInfor.isReloadContent = false;
                app.appInfor.isSwitching = false;
                return res;
            }
        },
        initPost(rightTab) {
            var postContent = postContentStore();
            postContent.updateWelcomeContentInfor({
                welcomeSlogan: this.readingInfor.welcomeSlogan,
                hintList: this.readingInfor.hintList,
            });
            if (rightTab.currentData.dataId != undefined && rightTab.currentData.dataId != 404) {
                var currentPost = this.readingInfor.readingPostLists.filter(function (el) {
                    return el.postId == rightTab.currentData.dataId;
                })[0];
                // var currentQuestionLists = this.readingInfor.readingPostQuestionLists.filter(function (el) {
                //     return el.dataId == rightTab.currentData.dataId;
                // });
                if (currentPost) {
                    postContent.updatePostContentInfor(currentPost);
                }
                // exercise.updateExerciseInfor({
                //     questionLists: this.readingInfor.readingPostQuestionLists,
                // });
            }
        }
    },
});