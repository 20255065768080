import node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_A0OWXRrUgq from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import _nuxt_components_plugin_mjs_KR1HBZs4kY from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/node_modules/nuxt/dist/pages/runtime/router.mjs";
import node_modules__64nuxtjs_device_dist_runtime_plugin_mjs_t2GMTTFnMT from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugins_index_vue_PU9ayRn89D from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/plugins/index.vue";
import plugins_respository_vue_t0G9io7T59 from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/plugins/respository.vue";
import plugins_client_plugins_vue_zV4XP5GYYI from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/plugins/client_plugins.vue";
export default [
  node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_A0OWXRrUgq,
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB,
  node_modules__64nuxtjs_device_dist_runtime_plugin_mjs_t2GMTTFnMT,
  plugins_index_vue_PU9ayRn89D,
  plugins_respository_vue_t0G9io7T59,
  plugins_client_plugins_vue_zV4XP5GYYI
]