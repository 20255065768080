import { fieldsLinkerStore } from "~/components/fields-linker/store.js";
import { fillTextStore } from "~/components/fill-text/store.js";
export const vocabularyQuizPopupStore = defineStore('vocabularyQuizPopup', {
    namespaced: true,
    state: () => ({
        vocabularyQuizPopupInfor: {
            isLoading: true,
            quizMode: 0,
            quizTotalLevel: 0,
            quizLevel: 0,
            questionPerLevel: 5,
            showModal: false,
            modalTransition: 'bounce',
            popupType: 0,
            vocabularyName: {
                value: '',
                isError: false,
                errorMessage: '',
            },
            vocabularyMean: {
                value: '',
                isError: false,
                errorMessage: '',
            },
            rules: {
                'vocabularyName.value': 'required',
                // 'vocabularyMean.value': 'required',
            },
            vocabularyList: [
                {
                    vocabularyIndex: '1',
                    vocabularyId: '1',
                    vocabularyName: 'hello',
                    vocabularyDiv: '1',
                    vocabularyDivName: 'Danh từ',
                    vocabularySpecializedDiv: '1',
                    vocabularySpecializedName: 'Kinh tế',
                    vocabularyFieldDiv: '1',
                    vocabularyFieldName: 'Âm nhạc',
                    vocabularySpelling: 'æmp (æmplɪfaɪə)',
                    vocabularyMean: 'Xin chào',
                    vocabularyImage: 'https://www.eplus365.com/web-content/images/plugin-icon/no-image.jpg',
                    vocabularyAudio: 'https://stream-dict-laban.zdn.vn/us/570eb1f57471ef3b1eeaede094646119/186920830bf/B/beat.mp3',
                    isVocabularyRemembered: false,
                    vocabularyCreateDate: '',
                    vocabularyUpdateDate: '',
                    isVocabularyDeleted: false,
                }
            ],
            showList: [
                {
                    vocabularyId: '1',
                    vocabularyName: 'hello',
                    vocabularyDiv: '1',
                    vocabularyDivName: 'Danh từ',
                    vocabularySpecializedDiv: '1',
                    vocabularySpecializedName: 'Kinh tế',
                    vocabularyFieldDiv: '1',
                    vocabularyFieldName: 'Âm nhạc',
                    vocabularySpelling: 'æmp (æmplɪfaɪə)',
                    vocabularyMean: 'Xin chào',
                    vocabularyImage: 'https://www.eplus365.com/web-content/images/plugin-icon/no-image.jpg',
                    vocabularyAudio: 'https://stream-dict-laban.zdn.vn/us/570eb1f57471ef3b1eeaede094646119/186920830bf/B/beat.mp3',
                    isVocabularyRemembered: false,
                    vocabularyCreateDate: '',
                    vocabularyUpdateDate: '',
                    isVocabularyDeleted: false,

                }
            ],
        }
    }),
    actions: {
        updateVocabularyQuizPopupInfor(vocabularyQuizPopupInfor) {
            var state = this;
            Object.keys(vocabularyQuizPopupInfor).forEach(function (key) {
                state.vocabularyQuizPopupInfor[key] = vocabularyQuizPopupInfor[key];
            })
        },
        getShowList() {
            try {
                var vocabularyList = this.vocabularyQuizPopupInfor.vocabularyList.slice();
                var showList = [];
                var totalLevel = 0;
                var surplus = 0;
                switch (this.vocabularyQuizPopupInfor.quizMode) {
                    case 0:
                        totalLevel = Math.floor(vocabularyList.length / this.vocabularyQuizPopupInfor.questionPerLevel);
                        surplus = vocabularyList.length % this.vocabularyQuizPopupInfor.questionPerLevel;
                        if (totalLevel == 0) {
                            //less than 5 then get all
                            showList = vocabularyList;
                        } else {
                            var sliceStart = this.vocabularyQuizPopupInfor.quizLevel * this.vocabularyQuizPopupInfor.questionPerLevel;
                            if (surplus == 0 || surplus == 1) {
                                //bigger than 5 but surplus = 1 then add last item to before array
                                totalLevel--;
                                if (this.vocabularyQuizPopupInfor.quizLevel == totalLevel) {
                                    //last array
                                    showList = vocabularyList.slice(sliceStart);
                                } else {
                                    showList = vocabularyList.slice(sliceStart, sliceStart + this.vocabularyQuizPopupInfor.questionPerLevel);
                                }
                            } else {
                                showList = vocabularyList.slice(sliceStart, sliceStart + this.vocabularyQuizPopupInfor.questionPerLevel);
                            }
                        }
                        this.vocabularyQuizPopupInfor.quizTotalLevel = totalLevel;
                        break;
                    case 1:
                        totalLevel = vocabularyList.length - 1;
                        this.vocabularyQuizPopupInfor.quizTotalLevel = totalLevel;
                        showList = this.vocabularyQuizPopupInfor.vocabularyList;
                        break;
                    default:
                        break;
                }
                return showList;
            } catch (e) {
                console.log("Action getShowList: " + e.message);
            }
        },
        checkAnswer() {
            var state = this;
            var { $callHelpers, $appStates, $appMethods } = useNuxtApp();
            var fieldsLinker = fieldsLinkerStore();
            var fillText = fillTextStore();
            switch (this.vocabularyQuizPopupInfor.quizMode) {
                case 0:
                    var result = fieldsLinker.checkFieldsLinkerAnswer();
                    switch (result.status) {
                        case 1:
                            if (state.vocabularyQuizPopupInfor.quizLevel == state.vocabularyQuizPopupInfor.quizTotalLevel) {
                                $appMethods.showDialog(2, "S013", function () {
                                    var dialogButtons= [
                                        {
                                            buttonTitle: '↩ Làm lại',
                                            buttonClass: 'btn-success',
                                            buttonAction: function () {
                                                state.vocabularyQuizPopupInfor.quizLevel = 0;
                                                state.vocabularyQuizPopupInfor.showList = state.getShowList();
                                                $appStates.dialogPopup.dialogPopupInfor.showDialog = false;
                                            },
                                        },
                                        {
                                            buttonTitle: 'Tiếp tục ➤',
                                            buttonClass: 'btn-success',
                                            buttonAction: function () {
                                                state.vocabularyQuizPopupInfor.quizMode ++;
                                                state.vocabularyQuizPopupInfor.quizLevel = 0;
                                                state.vocabularyQuizPopupInfor.showList = state.getShowList();
                                                $appStates.dialogPopup.dialogPopupInfor.showDialog = false;
                                            },
                                        },
                                    ];
                                    $appStates.dialogPopup.dialogPopupInfor.dialogMessage = result.message;
                                    $appStates.dialogPopup.dialogPopupInfor.dialogButtons = dialogButtons;
                                });
                            } else {
                                $appMethods.showDialog(2, "S006", function () {
                                    $appStates.dialogPopup.dialogPopupInfor.dialogButtons[0].buttonAction = function () {
                                        state.vocabularyQuizPopupInfor.quizLevel++;
                                        state.vocabularyQuizPopupInfor.showList = state.getShowList();
                                        $appStates.dialogPopup.dialogPopupInfor.showDialog = false;
                                    };
                                });
                            }
                            break;
                        case 2:
                            var state = this;
                            $appMethods.showDialog(3, "W004", function () {
                                $appStates.dialogPopup.dialogPopupInfor.dialogMessage = result.message;
                                $appStates.dialogPopup.dialogPopupInfor.dialogButtons.pop();
                                $appStates.dialogPopup.dialogPopupInfor.dialogButtons[0].buttonAction = function () {
                                    $appStates.dialogPopup.dialogPopupInfor.showDialog = false;
                                };
                            });
                            break;
                        default:
                            break;
                    }
                    break;
                case 1:
                    var result = fillText.checkFillTextAnswer();
                    switch (result.status) {
                        case 1:
                            if (state.vocabularyQuizPopupInfor.quizLevel == state.vocabularyQuizPopupInfor.quizTotalLevel) {
                                if($appStates.app.appInfor.isMissionMode){
                                    $appMethods.completeMission(function(){
                                        state.vocabularyQuizPopupInfor.showModal = false;
                                    });
                                }else{
                                    $appMethods.showDialog(2, "S012", function () {
                                        var dialogButtons= [
                                            {
                                                buttonTitle: '↩ Làm từ đầu',
                                                buttonClass: 'btn-success',
                                                buttonAction: function () {
                                                    state.vocabularyQuizPopupInfor.quizMode = 0;
                                                    state.vocabularyQuizPopupInfor.quizLevel = 0;
                                                    state.vocabularyQuizPopupInfor.showList = state.getShowList();
                                                    $appStates.dialogPopup.dialogPopupInfor.showDialog = false;
                                                },
                                            },
                                            {
                                                buttonTitle: '↩ Làm lại',
                                                buttonClass: 'btn-success',
                                                buttonAction: function () {
                                                    state.vocabularyQuizPopupInfor.quizLevel = 0;
                                                    state.vocabularyQuizPopupInfor.showList = state.getShowList();
                                                    $appStates.dialogPopup.dialogPopupInfor.showDialog = false;
                                                },
                                            },
                                            {
                                                buttonTitle: 'Đóng',
                                                buttonClass: 'btn-default',
                                                buttonAction: function () {
                                                    state.vocabularyQuizPopupInfor.quizMode = 0;
                                                    state.vocabularyQuizPopupInfor.quizLevel = 0;
                                                    state.vocabularyQuizPopupInfor.showList = state.getShowList();
                                                    $appStates.dialogPopup.dialogPopupInfor.showDialog = false;
                                                    state.vocabularyQuizPopupInfor.showModal = false;
                                                },
                                            },
                                        ];
                                        $appStates.dialogPopup.dialogPopupInfor.dialogButtons = dialogButtons;
                                    });
                                }
                            } else {
                                setTimeout(() => {
                                    state.vocabularyQuizPopupInfor.quizLevel++;
                                }, 500);
                            }
                            break;
                        default:
                            break;
                    }

                    break;
                default:
                    break;
            }
        },
        showVocabularyQuizPopup(vocabularyList) {
            this.updateVocabularyQuizPopupInfor({
                vocabularyList: vocabularyList,
                // showList: vocabularyList,
                // showModal: true,
                isLoading: false,
            });
            this.updateVocabularyQuizPopupInfor({
                showList: this.getShowList(),
                showModal: true,
            });
        },
    }
});