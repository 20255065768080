import repository from "./repository";
import { leftTabStore } from "~~/components/left-tab/store";
import { exampleStore } from "~~/components/example/store";
import { postContentStore } from "~~/components/post-content/store";
export const viewGrammarPopupStore = defineStore('viewGrammarPopup', {
    namespaced: true,
    state: () => ({
        viewGrammarPopupInfor: {
            isLoading: true,
            showModal: false,
            modalTransition: 'bounce',
            viewGrammarId: '1',
            viewGrammarOwnerId: '1',
            viewGrammarTitle: 'test viewGrammar',
            viewGrammarContent: 'viewGrammar content',
            unitPerTimes: '2',
            difficultLevel: '1',
            successViewGrammarExp: '100',
            successViewGrammarCtp: '200',
            failedViewGrammarExp: '50',
            failedViewGrammarCtp: '100',
            viewGrammarCondition: '1',
            viewGrammarStatus: '0',
            viewGrammarTryTimesCount: '0',
            viewGrammarTryTimes: '3',
        }
    }),
    actions: {
        updateViewGrammarPopupInfor(viewGrammarPopupInfor) {
            var state = this;
            Object.keys(viewGrammarPopupInfor).forEach(function (key) {
                state.viewGrammarPopupInfor[key] = viewGrammarPopupInfor[key];
            })
        },
        getViewGrammar(grammarId) {
            try {
                var state = this;
                var payload = {
                    grammar_id: grammarId,
                    screen_div: "/grammar",
                };
                repository.getGrammar(payload)
                    .then((res) => {
                        if (res.status === 200) {
                            var example = exampleStore();
                            var postContent = postContentStore();
                            var data = res.data.data;
                            // this.updateViewGrammarPopupInfor(data[0][0]);
                            example.updateExampleInfor({
                                //use slice to copy array
                                exampleLists: data[1].slice(),
                                pagingLists: data[2].slice(),
                            });
                            postContent.updatePostContentInfor(data[0][0]);
                            state.viewGrammarPopupInfor.isLoading = false;
                            state.viewGrammarPopupInfor.showModal = true;
                            return res;
                        }
                    });
            } catch (e) {
                console.log("Action getViewGrammar: " + e.message);
            }
        },
        // getNewsList(context, page) {
        //     context.commit('getNewsList', page);
        // },
        showViewGrammarPopup(grammarId) {
            this.updateViewGrammarPopupInfor({
                // showModal: true,
                isLoading: true,
            });
            this.getViewGrammar(grammarId);
        },
    }
});