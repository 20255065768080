<template>
    <div v-if="vocabularyContentInfor.isShowVocabularyOptions" class="col-xs-12 no-padding">
        <div class="col-xs-12 title-bar">
            <div v-for="learnOption in vocabularyContentInfor.learnVocabularyOptions" class="col-xs-2 no-padding">
                <label class="checkbox-inline">
                    <input type="checkbox" :name="'optcheckbox' + learnOption.optionId" v-model="learnOption.isChecked" :value="learnOption.optionId" />
                    {{ learnOption.optionTitle }}
                </label>
            </div>
        </div>
    </div>
    <div v-show="vocabularyContentInfor.learnVocabularyOptions['image'].isChecked" class="col-xs-12 no-padding slider-wrap">
        <!-- <span class="img-cre-user sub-text margin-bottom" target-id="">
            Đóng góp lần cuối lúc: <strong></strong>
            <button class="fa fa-history btn-link" @click="showContributeVocabularyPopup()">Lịch sử</button>
        </span> -->
        <div class="col-xs-12 slide-box margin-top">
            <carousel-3d ref="mycarousel" :width="300" :height="250" :clickable="false" :minSwipeDistance="9999">
                <slide v-for="media in vocabularyContentInfor.vocabularyLists" :index="media.vocabularyIndex - 1">
                    <img :src="appInfor.domain + (media.vocabularyImage == '' ? noImage : media.vocabularyImage)" @click="vocabularyContentInfor.learnVocabularyOptions['audio'].isChecked && currentVocabularyData['vocabularyAudio'] != '' && playAudio(media.vocabularyAudio)" />
                    <audio class="sound1 vocal-audio" :src="appInfor.domain + media.vocabularyAudio"></audio>
                </slide>
            </carousel-3d>
            <span class="prev" @click="previousRightTabItem()" aria-label="Previous slide">
                <span><font-awesome-icon icon="fa-solid fa-circle-chevron-left" /></span>
            </span>
            <span class="next" @click="nextRightTabItem()" aria-label="Next slide">
                <span><font-awesome-icon icon="fa-solid fa-circle-chevron-right" /></span>
            </span>
        </div>
        <div class="col-xs-12 no-padding hint-text">
            <span v-if="vocabularyContentInfor.learnVocabularyOptions['audio'].isChecked && currentVocabularyData['vocabularyAudio'] != ''" class="hint">Bạn có thể nhấn vào hình ảnh để nghe đọc từ vựng</span>
            <span v-if="vocabularyContentInfor.learnVocabularyOptions['audio'].isChecked && currentVocabularyData['vocabularyAudio'] == ''" class="hint">Từ vựng này hiện chưa có audio</span>
            <div class="col-xs-12 text-center">
                <NuxtLink v-if="$route.matched.some(({ meta }) => meta.rootPath !== '/dictionary')" :to="'/dictionary?type=0&query=' + rightTabInfor.currentData.dataTitle"><font-awesome-icon icon="fa-solid fa-sitemap" /> Xem Họ Từ</NuxtLink>
            </div>
            <!-- <a v-if="$route.matched.some(({ meta }) => meta.rootPath !== '/dictionary')" class="col-xs-12"><font-awesome-icon icon="fa-solid fa-sitemap" /> Xem Họ Từ</a> -->
        </div>
    </div>
    <div class="col-xs-12 no-padding vocabulary-box margin-top" target-id="">
        <template v-if="vocabularyContentInfor.learnVocabularyOptions['raw'].isChecked && currentVocabularyData['vocabularyName'] != ''">
            <span class="input-title-box sub-text">Từ Tiếng Anh</span>
            <h2 class="form-control input-sm text-center" disabled>{{ currentVocabularyData["vocabularyName"] }}</h2>
        </template>
        <template v-if="!vocabularyContentInfor.learnVocabularyOptions['raw'].isChecked && currentVocabularyData['vocabularyName'] != ''">
            <span class="input-title-box sub-text">Từ Tiếng Anh</span>
            <div class="div-check">
                <input class="form-control input-sm text-center" v-model="vocabularyContentInfor.currentVocabularyName" id="vocabularyName" ref="vocabularyName" placeholder="Nhập từ hiện tại kết thúc bằng dấu ! để kiểm tra" />
                <font-awesome-icon v-if="vocabularyContentInfor.isCorrect === true" icon="fa-solid fa-check" class="input-check text-success" />
                <font-awesome-icon v-if="vocabularyContentInfor.isCorrect === false" icon="fa-solid fa-close" class="input-check text-danger" />
            </div>
        </template>
        <template v-if="vocabularyContentInfor.learnVocabularyOptions['spell'].isChecked && currentVocabularyData['vocabularySpelling'] != ''">
            <span class="input-title-box sub-text">Phiên Âm</span>
            <h2 class="form-control input-sm" value="" disabled="">{{ currentVocabularyData["vocabularySpelling"] }}</h2>
        </template>
        <template v-if="vocabularyContentInfor.learnVocabularyOptions['type'].isChecked && currentVocabularyData['vocabularyDivName'] != ''">
            <span class="input-title-box sub-text">Loại Từ</span>
            <h2 class="form-control input-sm" value="" disabled="">{{ currentVocabularyData["vocabularyDivName"] }}</h2>
        </template>
        <template v-if="vocabularyContentInfor.learnVocabularyOptions['mean'].isChecked && currentVocabularyData['vocabularyMean'] != ''">
            <span class="input-title-box sub-text">Nghĩa</span>
            <h2 class="form-control input-sm vocal-mean" disabled="">{{ currentVocabularyData["vocabularyMean"] }}</h2>
        </template>
        <button class="btn btn-sm btn-primary full-width" @click="showContributeVocabularyPopup()">Đóng Góp Từ Vựng</button>
        <ClientOnly>
            <PopupContributeVocabulary />
        </ClientOnly>
        <!-- <div class="col-xs-12 no-padding">@include('vocabulary_relationship',array('relationship_div'=>1))</div> -->
        <!-- <div class="col-xs-12 no-padding">@include('vocabulary_relationship',array('relationship_div'=>2))</div> -->
    </div>
</template>
<script>
/**
 * Import libraries
 */
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import MainMenu from '@/share-layout/menu';

/**
 * import template
 */
// import template from './template.html';
// import store from '@/store';
import { vocabularyContentStore } from "./store";
import { mapState, mapActions } from "pinia";
import { contributeVocabularyPopupStore } from "@/components/popup/contribute-vocabulary/store.js";

/**
 * Define main layout
 */
const VocabularyContent = {
    name: "VocabularyContent",
    // template: template,
    components: {},
    beforeCreate() {
        // if (!store.hasModule('vocabularyContent')) {
        //     store.registerModule('vocabularyContent', vocabularyContentStore);
        // }
    },
    computed: {
        ...mapState(vocabularyContentStore, ["vocabularyContentInfor", "currentVocabularyData"]),
    },
    mounted() {
        this.$watch(
            () => ({
                currentData: this.rightTabInfor.currentData,
            }),
            (rightTab) => {
                if (rightTab.currentData.dataId != undefined) {
                    this.$refs.mycarousel.goSlide(this.currentVocabularyData["vocabularyIndex"] - 1);
                    if (this.vocabularyContentInfor.learnVocabularyOptions["audio"].isChecked && !import.meta.env.SSR) {
                        this.playAudio(this.currentVocabularyData["vocabularyAudio"]);
                    }
                }
            },
            {
                deep: true,
                immediate: true,
            }
        );
        this.$watch(
            () => ({
                currentVocabularyName: this.vocabularyContentInfor.currentVocabularyName,
            }),
            (newValue) => {
                if (newValue.currentVocabularyName.slice(-1) == "!") {
                    if (newValue.currentVocabularyName.slice(0, newValue.currentVocabularyName.length - 1).toLowerCase() == this.currentVocabularyData.vocabularyName.toLowerCase()) {
                        this.vocabularyContentInfor.isCorrect = true;
                    } else {
                        this.vocabularyContentInfor.isCorrect = false;
                    }
                } else {
                    this.vocabularyContentInfor.isCorrect = null;
                }
            },
            {
                immediate: true,
            }
        );
        this.$watch(
            () => ({
                rawIsCheck: this.vocabularyContentInfor.learnVocabularyOptions.raw.isChecked,
            }),
            (newValue) => {
                if (!newValue.rawIsCheck) {
                    this.rightTabInfor.isEncrypt = true;
                } else {
                    this.rightTabInfor.isEncrypt = false;
                }
            },
            {
                immediate: true,
            }
        );
    },
    props: ["contentType"],
    data: function () {
        return {
            noImage: "/web-content/images/plugin-icon/no-image.jpg",
        };
    },
    methods: {
        ...mapActions(vocabularyContentStore, ["getVocabularyDataByIndex"]),
        ...mapActions(contributeVocabularyPopupStore, ["showContributeVocabularyPopup"]),
        playAudio(selectedAudio) {
            try {
                if (this.appInfor.playingAudio) {
                    this.appInfor.playingAudio.pause();
                    this.appInfor.playingAudio.currentTime = 0;
                }
                if (selectedAudio == "") {
                    return;
                }
                var audio = new Audio(this.appInfor.domain + selectedAudio); // Define audio file
                this.appInfor.playingAudio = audio;
                audio.play();
            } catch (error) {
                console.log("play audio: " + error);
            }
        },
    },
};
export default defineNuxtComponent(VocabularyContent);
</script>
<style lang="scss">
@import "./style.scss";
</style>
