<script>
import mitt from 'mitt';
import "jquery";
import "bootstrap";
import "~/static/fieldsLinker.js";
import { VueEditor } from "vue3-editor";
export default defineNuxtPlugin((nuxtApp) => {
    const emitter = mitt();
    nuxtApp.vueApp.component('VueEditor', VueEditor);

    return {
        provide: {
            bus: {
                $on: emitter.on,
                $emit: emitter.emit,
            },
        },
    };
});
</script>
