export const footerStore = defineStore('footer',{
    namespaced: true,
    state: () => ({
        footerInfor: {
            version: 'v1',
            versionDate: '22/09/2022',
            footerSlogan: 'Miễn Phí Và Sẽ Luôn Như Vậy',
            contacts: [
                {
                    contactName: 'Facebook',
                    contactLink: '/',
                    contactIcon: 'fa-brands fa-square-facebook',
                },
                {
                    contactName: 'Twitter',
                    contactLink: '/',
                    contactIcon: 'fa-brands fa-twitter',
                },
                {
                    contactName: 'Google plus',
                    contactLink: '/',
                    contactIcon: 'fa-brands fa-google-plus-g',
                },
            ],
            tel: '0123456789',
            email: 'clientcare.eplus@gmail.com',
            birthday: '08/09/2018',
            epluser: '2114',
            privacyCookiesLink: '/',
        },
    }),
    actions: {
        updateFooterInfor(footerInfor){
            var state = this;
            Object.keys(footerInfor).forEach(function(key){
                state.footerInfor[key] = footerInfor[key];
            })
        },
    }
});