export const vocabularyContentStore = defineStore('vocabularyContent', {
    namespaced: true,
    state: () => ({
        vocabularyContentInfor: {
                currentVocabularyName :'',
                isCorrect : null,
                isShowVocabularyOptions: true,
                learnVocabularyOptions: {
                'image':{
                    optionId: '0',
                    optionTitle: 'Hình ảnh',
                    isChecked: true,
                },
                'audio':{
                    optionId: '1',
                    optionTitle: 'Âm thanh',
                    isChecked: true,
                },
                'raw':{
                    optionId: '2',
                    optionTitle: 'Từ tiếng anh',
                    isChecked: true,
                },
                'spell':{
                    optionId: '3',
                    optionTitle: 'Phiên âm',
                    isChecked: true,
                },
                'type':{
                    optionId: '4',
                    optionTitle: 'Loại từ',
                    isChecked: true,
                },
                'mean':{
                    optionId: '5',
                    optionTitle: 'Nghĩa',
                    isChecked: true,
                },
            },
            vocabularyLists: [
                {
                    vocabularyIndex: '1',
                    vocabularyId: '1',
                    vocabularyName: 'hello',
                    vocabularyNameEncrypt: '*****',
                    vocabularyDiv: '1',
                    vocabularyDivName: 'Danh từ',
                    vocabularySpecializedDiv: '1',
                    vocabularySpecializedName: 'Kinh tế',
                    vocabularyFieldDiv: '1',
                    vocabularyFieldName: 'Âm nhạc',
                    vocabularySpelling: 'æmp (æmplɪfaɪə)',
                    vocabularyMean: 'Xin chào',
                    vocabularyImage: '/web-content/images/plugin-icon/no-image.jpg',
                    vocabularyAudio: 'https://stream-dict-laban.zdn.vn/us/570eb1f57471ef3b1eeaede094646119/186920830bf/B/beat.mp3',
                    isVocabularyRemembered: false,
                    vocabularyCreateDate: '',
                    vocabularyUpdateDate: '',
                    isVocabularyDeleted: false,
                }
            ],
        },
    }),
    getters: {
        currentVocabularyData(state) {
            var { $appStates } = useNuxtApp();
            var currentVocabulary = state.vocabularyContentInfor.vocabularyLists.filter(function (el) {
                return el.vocabularyId == Number($appStates.rightTab.rightTabInfor.currentData.dataId);
            });
            return currentVocabulary[0]?currentVocabulary[0]:'';
        },
    },
    actions: {
        updateVocabularyContentInfor(vocabularyContentInfor) {
            var state = this;
            Object.keys(vocabularyContentInfor).forEach(function (key) {
                state.vocabularyContentInfor[key] = vocabularyContentInfor[key];
            })
        },
        getVocabularyDataByIndex(index) {
            var currentVocabulary = this.vocabularyContentInfor.vocabularyLists.filter(function (el) {
                return el.vocabularyIndex == index;
            });
            return currentVocabulary[0]?currentVocabulary[0]:'';
        },
    }
});