<template>
    <vue-final-modal v-model="viewGrammarPopupInfor.showModal" :transition="viewGrammarPopupInfor.modalTransition">
        <div class="modal-dialog grammar-modal">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" type="button" @click="viewGrammarPopupInfor.showModal = false">×</button>
                    <h5 class="modal-title">CHI TIẾT NGỮ PHÁP</h5>
                </div>
                <div v-if="!viewGrammarPopupInfor.isLoading" class="modal-body dialog-padding">
                    <div class="web-main no-padding">
                        <post-content :isHidePostInfor="true"></post-content>
                    </div>
                    <div class="col-xs-12 no-padding panel-group" id="example-list">
                        <Example :isSingle="true" :isCanAdd="false" :isCanVote="false" :isShowPaging="false"></Example>
                    </div>
                </div>
                <div v-if="!viewGrammarPopupInfor.isLoading" class="modal-footer">
                    <button class="btn btn-default btn-sm" data-dismiss="modal" type="button" @click="viewGrammarPopupInfor.showModal = false">Đóng</button>
                </div>
            </div>
        </div>
    </vue-final-modal>
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import MainMenu from '@/share-layout/menu';

/**
 * import template
 */
// import template from "./template.html";
// import store from "@/store";
import { viewGrammarPopupStore } from "./store";

/**
 * Define main layout
 */
const ViewGrammarPopup = {
    name: "ViewGrammarPopup",
    // template: template,
    components: {
        // MainMenu,
    },
    beforeCreate() {
        // if (!store.hasModule("viewGrammarPopup")) {
        // 	store.registerModule("viewGrammarPopup", viewGrammarPopupStore);
        // }
    },
    created() {},
    computed: {
        ...mapState(viewGrammarPopupStore, ["viewGrammarPopupInfor"]),
    },
    data() {
        var viewGrammarPopup = viewGrammarPopupStore();
        return {
            unitPerTimes: viewGrammarPopup.viewGrammarPopupInfor.unitPerTimes,
            difficultLevel: viewGrammarPopup.viewGrammarPopupInfor.difficultLevel,
            successViewGrammarExp: viewGrammarPopup.viewGrammarPopupInfor.successViewGrammarExp,
            successViewGrammarCtp: viewGrammarPopup.viewGrammarPopupInfor.successViewGrammarCtp,
            failedViewGrammarExp: viewGrammarPopup.viewGrammarPopupInfor.failedViewGrammarExp,
            failedViewGrammarCtp: viewGrammarPopup.viewGrammarPopupInfor.failedViewGrammarCtp,
        };
    },
    mounted() {
        this.$watch(
            () => ({
                difficultLevel: this.viewGrammarPopupInfor.difficultLevel,
                successViewGrammarExp: this.viewGrammarPopupInfor.successViewGrammarExp,
                successViewGrammarCtp: this.viewGrammarPopupInfor.successViewGrammarCtp,
                failedViewGrammarExp: this.viewGrammarPopupInfor.failedViewGrammarExp,
                failedViewGrammarCtp: this.viewGrammarPopupInfor.failedViewGrammarCtp,
            }),
            (data) => {
                this.successViewGrammarExp = this.viewGrammarPopupInfor.successViewGrammarExp * data.difficultLevel;
                this.successViewGrammarCtp = this.viewGrammarPopupInfor.successViewGrammarCtp * data.difficultLevel;
                this.failedViewGrammarExp = this.viewGrammarPopupInfor.failedViewGrammarExp * data.difficultLevel;
                this.failedViewGrammarCtp = this.viewGrammarPopupInfor.failedViewGrammarCtp * data.difficultLevel;
            },
            {
                immediate: true,
            }
        );
        // this.getViewGrammarPopupInfor();
    },
    methods: {
        ...mapActions(viewGrammarPopupStore, ["showViewGrammarPopup", "updateViewGrammarPopupInfor", "acceptViewGrammar", "doViewGrammar", "refuseViewGrammar"]),
        confirm() {
            // some code...
            this.show = false;
        },
        cancel(close) {
            // some code...
            close();
        },
        updateResizedScreen() {
            // this.updateViewGrammarPopupInfor({
            // 	optionFontSize: this.$refs.optionHeader[0].clientWidth/12,
            // });
        },
    },
};
export default defineNuxtComponent(ViewGrammarPopup);
</script>
<style lang="scss">
@import "./style.scss";
</style>
