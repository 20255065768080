import repository from "./repository";
import { vocabularyStore } from "~~/pages/vocabulary/store.js";
export const selectVocabularyPopupStore = defineStore('selectVocabularyPopup', {
    namespaced: true,
    state: () => ({
        selectVocabularyPopupInfor: {
            isLoading: true,
            isSearching: false,
            showModal: false,
            modalTransition: 'bounce',
            popupType: 0,
            vocabularyName: {
                value: '',
                isError: false,
                errorMessage: '',
            },
            vocabularyMean: {
                value: '',
                isError: false,
                errorMessage: '',
            },
            rules: {
                'vocabularyName.value': 'required',
                // 'vocabularyMean.value': 'required',
            },
            searchedList: [
                {
                    vocabularyIndex: '1',
                    vocabularyId: '1',
                    vocabularyName: 'hello',
                    vocabularyDiv: '1',
                    vocabularyDivName: 'Danh từ',
                    vocabularySpecializedDiv: '1',
                    vocabularySpecializedName: 'Kinh tế',
                    vocabularyFieldDiv: '1',
                    vocabularyFieldName: 'Âm nhạc',
                    vocabularySpelling: 'æmp (æmplɪfaɪə)',
                    vocabularyMean: 'Xin chào',
                    vocabularyImage: '/web-content/images/plugin-icon/no-image.jpg',
                    vocabularyAudio: 'https://stream-dict-laban.zdn.vn/us/570eb1f57471ef3b1eeaede094646119/186920830bf/B/beat.mp3',
                    isVocabularyRemembered: false,
                    vocabularyCreateDate: '',
                    vocabularyUpdateDate: '',
                    isVocabularyDeleted: false,
                }
            ],
            selectedList: [
                {
                    vocabularyId: '1',
                    vocabularyName: 'hello',
                    vocabularyDiv: '1',
                    vocabularyDivName: 'Danh từ',
                    vocabularySpecializedDiv: '1',
                    vocabularySpecializedName: 'Kinh tế',
                    vocabularyFieldDiv: '1',
                    vocabularyFieldName: 'Âm nhạc',
                    vocabularySpelling: 'æmp (æmplɪfaɪə)',
                    vocabularyMean: 'Xin chào',
                    vocabularyImage: '/web-content/images/plugin-icon/no-image.jpg',
                    vocabularyAudio: 'https://stream-dict-laban.zdn.vn/us/570eb1f57471ef3b1eeaede094646119/186920830bf/B/beat.mp3',
                    isVocabularyRemembered: false,
                    vocabularyCreateDate: '',
                    vocabularyUpdateDate: '',
                    isVocabularyDeleted: false,
                }
            ],
            paging: {
                pagingCode: "999",
                currentPage: "1",
                totalPage: "1",
                totalRecord: "0",
                pagingSize: "50",
            },
        }
    }),
    actions: {
        updateSelectVocabularyPopupInfor(selectVocabularyPopupInfor) {
            var state = this;
            Object.keys(selectVocabularyPopupInfor).forEach(function (key) {
                state.selectVocabularyPopupInfor[key] = selectVocabularyPopupInfor[key];
            })
        },
        getSelectVocabularyPopupInfor(vocabularyList, popupType) {
            try {
                var { $appStates } = useNuxtApp();
                var state = this;
                this.updateSelectVocabularyPopupInfor({
                    searchedList: [],
                    paging: { totalRecord: -1 },
                });
                var payload = {
                    source_id: $appStates.rightTab.rightTabInfor.currentData.dataId,
                    vocabulary_list: vocabularyList.filter(voc => voc.dataId == $appStates.rightTab.rightTabInfor.currentData.dataId).map(a => a.wordRelationshipId),
                    vocabulary_div: popupType
                };
                repository.getSelectVocabularyPopup(payload)
                    .then((res) => {
                        if (res.status === 200) {
                            var data = res.data.data;
                            this.updateSelectVocabularyPopupInfor({
                                selectedList: data[0]
                            });
                            state.selectVocabularyPopupInfor.showModal = true;
                            return res;
                        }
                    });
            } catch (e) {
                console.log("Action getSelectVocabularyPopupInfor: " + e.message);
            }
        },
        getVocabularyList(page) {
            try {
                const { $Validator } = useNuxtApp();
                var validator = $Validator.checkValidate(this.selectVocabularyPopupInfor.rules, this.selectVocabularyPopupInfor);
                var payload = {
                    vocabulary_nm: this.selectVocabularyPopupInfor.vocabularyName.value,
                    vocabulary_mean: this.selectVocabularyPopupInfor.vocabularyMean.value,
                    vocabulary_list: this.selectVocabularyPopupInfor.selectedList.map(a => a.vocabularyId),
                    page: page ? page : 1
                };
                if (validator) {
                    this.selectVocabularyPopupInfor.isSearching = true;
                    repository.getVocabularyList(payload)
                        .then((res) => {
                            if (res.status === 200) {
                                var data = res.data.data;
                                this.updateSelectVocabularyPopupInfor({
                                    searchedList: data[0],
                                    paging: data[1][0]
                                });
                                this.selectVocabularyPopupInfor.isSearching = false;
                                return res;
                            }
                        });
                }
            } catch (e) {
                console.log("Action getVocabularyList: " + e.message);
            }
        },
        saveVocabularyList() {
            try {
                var { $appStates, $bus } = useNuxtApp();
                var payload = {
                    target_id: $appStates.rightTab.rightTabInfor.currentData.dataId,
                    vocabulary_list: this.selectVocabularyPopupInfor.selectedList.filter(voc => voc.isCanEdit).map(a => {
                        return a.vocabularyId
                    }),
                    save_type: this.selectVocabularyPopupInfor.popupType,
                    screen_div: localStorage.getItem("currentUrl"),

                };
                repository.saveVocabularyList(payload)
                    .then((res) => {
                        if (res.status === 200) {
                            var data = res.data.data;
                            // var vocabulary = vocabularyStore();
                            // if(this.selectVocabularyPopupInfor.popupType == 1){
                            //     vocabulary.vocabularyInfor.wordRelationshipSynonymsList = data[1].slice();
                            // }else{
                            //     vocabulary.vocabularyInfor.wordRelationshipOppositeList = data[2].slice();
                            // }
                            // this.updateVocabularyInfor({
                            //     //use slice to copy array
                            //     wordRelationshipSynonymsList: data[1].slice(),
                            //     wordRelationshipOppositeList: data[6].slice(),
                            // });
                            // this.updateSelectVocabularyPopupInfor({
                            //     searchedList: data[0],
                            //     paging: data[1][0]
                            // });
                            $bus.$emit('saveVocabularySuccess', { data: data, popupType: this.selectVocabularyPopupInfor.popupType });
                            this.selectVocabularyPopupInfor.showModal = false;
                            return res;
                        }
                    });
            } catch (e) {
                console.log("Action saveVocabularyList: " + e.message);
            }
        },
        // doMission() {
        //     try {
        //         var payload = {
        //             mission_id: this.selectVocabularyPopupInfor.missionId,
        //         };
        //         repository.doMission(payload)
        //             .then((res) => {
        //                 if (res.status === 200) {
        //                     var data = res.data.data;
        //                     var route = useRouter();
        //                     const { $appStates,$appMethods } = useNuxtApp();
        //                     this.updateSelectVocabularyPopupInfor(data[1][0]);
        //                     route.push({ path: data[1][0].missionLink });
        //                     $appMethods.getAppState();
        //                     $appStates.app.appInfor.isReloadContent = true;
        //                     this.selectVocabularyPopupInfor.showModal = false;
        //                     return res;
        //                 }
        //             });
        //     } catch (e) {
        //         console.log("Action acceptMission: " + e.message);
        //     }
        // },
        // refuseMission() {
        //     try {
        //         const { $appStates,$appMethods } = useNuxtApp();
        //         var _this = this;
        //         $appMethods.showDialog(3, 'C008', function () {
        //             $appStates.dialogPopup.dialogPopupInfor.dialogButtons[0].buttonAction = function () {
        //                 var payload = {
        //                     mission_id: _this.selectVocabularyPopupInfor.missionId,
        //                 };
        //                 repository.refuseMission(payload)
        //                     .then((res) => {
        //                         if (res.status === 200) {
        //                             var data = res.data.data;
        //                             $appMethods.getAppState();
        //                             // $appStates.app.appInfor.isReloadContent = true;
        //                             _this.selectVocabularyPopupInfor.showModal = false;
        //                             $appMethods.hideDialog();
        //                             return res;
        //                         }
        //                     });
        //             }
        //         })
        //     } catch (e) {
        //         console.log("Action acceptMission: " + e.message);
        //     }
        // },
        // getNewsList(context, page) {
        //     context.commit('getNewsList', page);
        // },
        showSelectVocabularyPopup(vocabularyList, popupType) {
            this.updateSelectVocabularyPopupInfor({
                popupType: popupType,
                isLoading: false,
            });
            this.getSelectVocabularyPopupInfor(vocabularyList, popupType);
        },
    }
});