<template>
    <div :class="pagingInfor.pagingClass">
        <div class="text-center no-padding-left paging-item" target-id="1">
            <label class="panel-title hidden"></label>
            <ul class="pager">
                <li :class="currentPage == 1 ? 'pagging-disable' : ''" @click="pagingClickHander(this, 1)">
                    <span :class="currentPage == 1 ? 'pagging-disable' : ''" page="1">Đầu</span>
                </li>
                <li :class="currentPage == 1 ? 'pagging-disable' : ''" @click="pagingClickHander(this, Number(currentPage) - 1)">
                    <span :class="currentPage == 1 ? 'pagging-disable' : ''" :page="currentPage - 1">Trước</span>
                </li>
                <li v-if="!pagingInfor.isMobile" v-for="page in pagingInfor.showPages" :class="[page == currentPage ? 'active' : '', page == '..' ? 'pagging-disable' : '']" @click="pagingClickHander(this, page)">
                    <span :page="page">{{ page }}</span>
                </li>
                <li v-if="pagingInfor.isMobile">
                    <select class="form-control input-sm" @change="pagingClickHander(this, $event.target.value)" v-model="currentPage">
                        <option v-for="page in pagingInfor.showPages" :value="page">{{ page }}</option>
                    </select>
                </li>
                <li :class="currentPage == totalPage ? 'pagging-disable' : ''" @click="pagingClickHander(this, Number(currentPage) + 1)">
                    <span :class="currentPage == totalPage ? 'pagging-disable' : ''" :page="currentPage + 1">Tiếp</span>
                </li>
                <li :class="currentPage == totalPage ? 'pagging-disable' : ''" @click="pagingClickHander(this, totalPage)">
                    <span :page="totalPage" :class="currentPage == totalPage ? 'pagging-disable' : ''">Cuối</span>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
/**
 * Import libraries
 */
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import MainPaging from '@/shared/paging';

/**
 * import template
 */
// import template from "./template.html";
/**
 * Define main layout
 */
const Paging = {
    name: "Paging",
    // template: template,
    components: {
        // MainPaging
    },
    beforeCreate() {},
    created() {
        this.$watch(
            () => ({
                currentPage: this.currentPage,
                totalPage: this.totalPage,
                isMobile: this.pagingInfor.isMobile,
            }),
            () => {
                this.updateShowPages(this);
            },
            {
                immediate: true,
            }
        );
    },
    data() {
        return {
            pagingInfor: {
                pagingClass: this.pagingClass ?? "text-center margin-top col-xs-12",
                pagingTitle: "",
                isMobile: false,
                showPages: ["1", "2", "3", "4", "5", "..", "13"],
            }
        };
    },
    mounted() {
        this.updateShowPages(this);
    },
    props: ["pagingId", "currentPage", "totalPage", "pagingClass"],
    computed: {},
    methods: {
        pagingClickHander(paging, page) {
            this.updateAppInfor({
                paging: paging,
                page: page,
            });
        },
        updateShowPages(paging) {
            if (paging.pagingInfor.isMobile) {
                paging.pagingInfor.showPages = [...Array(Number(paging.totalPage)).keys()].map((x) => x + 1);
            } else {
                if (Number(paging.totalPage) < 6) {
                    paging.pagingInfor.showPages = [...Array(Number(paging.totalPage)).keys()].map((x) => x + 1);
                } else {
                    if (Number(paging.currentPage) < 5) {
                        paging.pagingInfor.showPages = [...Array(5).keys()].map((x) => x + 1);
                        paging.pagingInfor.showPages.push("..");
                        paging.pagingInfor.showPages.push(Number(paging.totalPage));
                    } else {
                        if (Number(paging.currentPage) + 3 >= Number(paging.totalPage)) {
                            paging.pagingInfor.showPages = [...Array(5).keys()].map((x) => Number(paging.totalPage) - x).reverse();
                            paging.pagingInfor.showPages.unshift(1, "..");
                        } else {
                            paging.pagingInfor.showPages = [...Array(5).keys()].map((x) => paging.currentPage - 2 + x);
                            paging.pagingInfor.showPages.unshift(1, "..");
                            paging.pagingInfor.showPages.push("..");
                            paging.pagingInfor.showPages.push(Number(paging.totalPage));
                        }
                    }
                }
            }
        },
        updateResizedScreen() {
            if (window.innerWidth < 768) {
                this.pagingInfor.isMobile = true;
            } else {
                this.pagingInfor.isMobile = false;
            }
        },
    },
};
export default defineNuxtComponent(Paging);
</script>
<style lang="scss">
@import "./style.scss";
</style>
