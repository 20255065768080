<script setup>
import { appStore } from "@/store/index.js";
var app = appStore();
app.appInfor.isSwitching = false;
//phai tach cac watcher ra thanh cac ham roi gọi luon
</script>
<template>
    <div class="col-xs-12 web-panel header-content" style="text-align: center;">
        <img class="not-found" :src="image">
    </div>
</template>
<script>
/**
 * Import template
 */
// import template from './template.html';
/**
 * Define content of 404 page
 */
var PageNotFound = {
    // template: template,
    data: function () {
        var image = "https://www.eplus365.com/web-content/images/icon/404.png"
        return {
            image: image
        }
    },
};
export default PageNotFound;
</script>
<style lang="scss">
@import "./style.scss";
</style>
