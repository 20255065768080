// import repository from '@/utils/repository';

const resource = '/popup';

export default {
    getContributeDiscussPopup: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/get-contribute-discuss-popup`,
            data: data,
            showProgress: true,
        });
    },
    uploadCropImage: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/upload-crop-image`,
            data: data,
            // showProgress: true,
        });
    },
    saveDiscussContribute: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/save-discuss-contribute`,
            data: data,
            showProgress: true,
            // showProgress: true,
        });
    },
};
