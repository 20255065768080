import { appStore } from "~~/store";
export default defineNuxtRouteMiddleware((to, from) => {
    const app = appStore();
    const privatePages = ['/profile'];
    const publicPages = ['/register'];
    const authRequired = privatePages.includes(to.path);
    const authNotRequired = publicPages.includes(to.path);
    const { $getToken } = useNuxtApp();
    //const isMobile = to.path.startsWith('mobile');
    // setPageLayout(to.meta.layout)
    let loggedIn = false;
    if (!import.meta.env.SSR) {
        const eplus365token = $getToken('eplus365token');
        const eplus365user = $getToken('eplus365user');
        var currentDate = new Date().getTime();
        if (eplus365token.value && eplus365user.value && new Date(eplus365user.value.tokenTimeout + currentDate) >= new Date()) {
            loggedIn = true;
        } else {
            eplus365token.value = null;
            eplus365user.value = null;
            app.updateAppInfor({
                isLogined: false,
            });
        }
    }
    // if (authRequired && !loggedIn) {
    //     localStorage.setItem('beforeUrl', window.location.pathname);
    //     return next('/login');
    // }

    //not allow to show that pages if not login ->redirect to homepage
    if (authRequired && loggedIn == false) {
        if (!import.meta.env.SSR) {
            localStorage.setItem('currentUrl', '/');
        }
        return navigateTo('/');
    }

    //not allow to show that pages if logined ->redirect to homepage
    if (authNotRequired && loggedIn == true) {
        if (!import.meta.env.SSR) {
            localStorage.setItem('currentUrl', '/');
        }
        return navigateTo('/');
    }
    // if (to.path == '/') {
    //   const url = localStorage.getItem('urlAfterLogin');
    //   if (url) {
    //     return next(url);
    //   }
    // }
    // document.title = (to.meta.title || 'EPLUS365');
    useHead({
        // as a string,
        // where `%s` is replaced with the title
        titleTemplate: to.meta.title || 'EPLUS365',
        // ... or as a function
        // titleTemplate: (productCategory) => {
        //     return productCategory ? `${productCategory} - Site Title` : "Site Title";
        // },
    });
    if (!import.meta.env.SSR) {
        localStorage.setItem('currentUrl', to.meta.rootPath);
    }
    if (from.meta.rootPath != to.meta.rootPath) {
        app.getAppState();
        app.appInfor.isRefresh = true;
        app.appInfor.isSwitching = true;
    }
    if (from.fullPath == to.fullPath) {
        app.appInfor.isRefresh = true;
    }
    // next();
})
