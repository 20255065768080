<template>
    <div v-if="exampleInfor.isCanAdd" class="panel panel-default panel-contribute">
        <div class="panel-heading" data-toggle="collapse" data-parent="#example-list" href="#collapse-contribute" aria-expanded="true">
            <span class="panel-title">
                <font-awesome-icon :icon="exampleInfor.exampleLabelIcon" class="example-label-icon" />
                <span>Đóng góp ví dụ</span>
            </span>
        </div>
        <div id="collapse-contribute" class="panel-collapse collapse in" aria-expanded="true" style="">
            <div class="panel-body">
                <div class="col-xs-12 no-padding">
                    <div class="form-group">
                        <div class="input-group" v-tooltip="exampleInfor.exampleInputEnglish.isError ? exampleInfor.exampleInputEnglish.errorMessage : ''">
                            <input type="text" name="" v-model="exampleInfor.exampleInputEnglish.value" class="form-control input-sm" :class="exampleInfor.exampleInputEnglish.isError ? 'input-error' : ''" placeholder="Nội dung câu tiếng anh" />
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 no-padding">
                    <div class="form-group">
                        <div class="input-group" v-tooltip="exampleInfor.exampleInputVietNamese.isError ? exampleInfor.exampleInputVietNamese.errorMessage : ''">
                            <input type="text" name="" v-model="exampleInfor.exampleInputVietNamese.value" class="form-control input-sm" :class="exampleInfor.exampleInputVietNamese.isError ? 'input-error' : ''" placeholder="Nghĩa của câu đã nhập" />
                        </div>
                    </div>
                </div>
                <div class="float-left" v-tooltip="callHelpers('getPermissionText', appInfor.userPermission.isCanAddExample)">
                    <button class="btn btn-sm btn-danger btn-contribute-exa" :disabled="appInfor.userPermission.isCanAddExample != 1" @click="appInfor.userPermission.isCanAddExample == '1' && addExample()" type="button">Đóng Góp</button>
                </div>
            </div>
        </div>
    </div>
    <table v-if="exampleInfor.exampleDisplayLists.length > 0" class="table vocabulary-table table-hover table-bordered table-body margin-bottom">
        <tbody v-for="exampleList in exampleInfor.exampleDisplayLists" class="example-item commentList">
            <tr class="commentItem">
                <td rowspan="3" class="auto-resize">
                    <div class="commenterImage no-padding">
                        <img :src="exampleList.exampleContributeUserAvartaImage" />
                    </div>
                </td>
                <td colspan="1" class="text-left">
                    <div class="commentHeader">
                        <span class="sub-text">{{ exampleList.exampleContributeUserName }}</span>
                        <span class="rank">{{ exampleList.exampleContributeUserRank }}</span>
                        <span class="date sub-text float-right comment-date no-padding">{{ exampleList.exampleContributeDateTime }}</span>
                    </div>
                </td>
                <template v-if="exampleInfor.isCanVote">
                    <td class="no-padding auto-resize">
                        <button class="btn btn-sm btn-default btn-vote vote-down" :disabled="appInfor.userPermission.isCanVoteForExample == -1" @click="appInfor.userPermission.isCanVoteForExample && exampleList.myVote != -1 && voteExample({ example: exampleList, voteValue: -1 })">
                            <font-awesome-icon icon="down-long" :class="exampleList.myVote == -1 ? 'active' : ''" />
                        </button>
                    </td>
                    <td class="no-padding auto-resize">
                        <button class="btn btn-sm btn-default btn-vote vote-up" :disabled="!appInfor.userPermission.isCanVoteForExample" @click="appInfor.userPermission.isCanVoteForExample == 1 && exampleList.myVote != 1 && voteExample({ example: exampleList, voteValue: 1 })">
                            <font-awesome-icon icon="up-long" :class="exampleList.myVote == 1 ? 'active' : ''" />
                        </button>
                    </td>
                </template>
                <template v-else>
                    <td width="50px" rowspan="3">
                        <span style="font-family: Jersey" class="rating-value">{{ exampleList.exampleRatingValue }}</span>
                    </td>
                </template>
            </tr>
            <tr>
                <td class="target_id hidden">{{ exampleList.exampleId }}</td>
                <td class="text-left">
                    <span>{{ exampleList.exampleEnglish }}</span>
                </td>
                <template v-if="exampleInfor.isCanVote">
                    <td class="auto-resize" width="50px" rowspan="2" colspan="2">
                        <span style="font-family: Jersey" class="rating-value">{{ exampleList.exampleRatingValue }}</span>
                    </td>
                </template>
            </tr>
            <tr>
                <td class="text-left">{{ exampleList.exampleVietNamese }}</td>
            </tr>
        </tbody>
    </table>
    <Paging pagingId="1" v-if="exampleInfor.currentPaging.currentPage != undefined && exampleInfor.isShowPaging" :currentPage="exampleInfor.currentPaging.currentPage" :totalPage="exampleInfor.currentPaging.totalPage"></Paging>
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import ExampleMenu from '@/share-layout/menu';

/**
 * import template
 */
// import template from './template.html';
// import store from '@/store';
import { exampleStore } from "./store";

/**
 * Define example layout
 */
const Example = {
    name: "Example",
    // template: template,
    components: {
        // ExampleMenu
    },
    beforeCreate() {
        // if (!store.hasModule('example')) {
        //     store.registerModule('example', exampleStore);
        // }
    },
    props: ["isSingle", "isCanAdd", "isCanVote","isShowPaging"],
    computed: {
        ...mapState(exampleStore, ["exampleInfor"]),
    },
    created() {
        var example = exampleStore();
        example.exampleInfor.isCanAdd = this.isCanAdd ?? true;
        example.exampleInfor.isCanVote = this.isCanVote ?? true;
        example.exampleInfor.isShowPaging = this.isShowPaging ?? true;
    },
    mounted() {
        this.$watch(
            () => ({
                currentData: this.rightTabInfor.currentData,
                exampleLists: this.exampleInfor.exampleLists,
            }),
            (rightTab) => {
                if (rightTab.currentData.dataId != undefined && !this.isSingle) {
                    var currentExampleList = this.exampleInfor.exampleLists.filter(function (el) {
                        return el.dataId == rightTab.currentData.dataId;
                    });
                    this.updateExampleInfor({
                        exampleDisplayLists: currentExampleList,
                    });
                    var currentPaging = this.exampleInfor.pagingLists.filter(function (el) {
                        return el.dataId == rightTab.currentData.dataId;
                    });
                    this.updateExampleInfor({
                        currentPaging: currentPaging.length > 0 ? currentPaging[0] : {},
                    });
                }
                if (this.isSingle) {
                    this.updateExampleInfor({
                        exampleDisplayLists: this.exampleInfor.exampleLists,
                    });
                    this.updateExampleInfor({
                        currentPaging: this.exampleInfor.pagingLists.length > 0 ? this.exampleInfor.pagingLists[0] : {},
                    });
                }
            },
            {
                deep: true,
                immediate: true,
            }
        );
        // var temp = this.getPermissionText('-1');
        // console.log(temp);
    },
    methods: {
        ...mapActions(exampleStore, ["updateExampleInfor", "addExample", "getExample", "voteExample"]),
    },
};
export default Example;
</script>
<style lang="scss">
@import "./style.scss";
</style>
