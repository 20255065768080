<script setup>
definePageMeta({
    key: "static",
});
import { appStore } from "@/store/index.js";
import { writingStore } from "./store";
const { isMobile } = useDevice();
var app = appStore();
var writing = writingStore();
var route = useRoute();
var data = {
    target_id: route.params.id[0] ?? "",
    screen_div: "/writing",
};
if (app.appInfor.isRefresh) {
    const { $repository } = useNuxtApp();
    var res = await $repository({
        method: "post",
        url: `/writing/get-writing-infor`,
        data: data,
        showProgress: true,
        headers: {
            Authorization: `Bearer ${useCookie("eplus365token").value}`, //set token for ssr request
        },
    });
    writing.setWritingInfor(res);
    app.appInfor.isRefresh = false;
}
app.appInfor.isSwitching = false;
//phai tach cac watcher ra thanh cac ham roi gọi luon
</script>
<template>
    <div class="col-xs-12 web-panel middle-content">
        <left-tab>
            <template v-slot:extendTop>
                <div class="left-header" data-target=".user-post-list" data-toggle="collapse" :class="leftTabInfor.isCollapsed ? 'collapsed' : ''" @click="writingInfor.isUserPostCollapsed = !writingInfor.isUserPostCollapsed">
                    <table style="width: 100%">
                        <tbody>
                            <tr>
                                <td width="95%">
                                    <h5><span>BÀI VIẾT CỦA BẠN</span></h5>
                                </td>
                                <td class="collapse-icon" width="5%">
                                    <font-awesome-icon v-if="writingInfor.isUserPostCollapsed" icon="chevron-up" pull="right" />
                                    <font-awesome-icon v-else icon="chevron-down" pull="right" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="user-post-list collapse close-when-small" :class="leftTabInfor.isCollapsed ? '' : 'in'">
                    <table class="table table-left table-bordered table-click">
                        <tbody>
                            <tr v-if="writingInfor.writingPostLists.length > 0" v-for="userPost in writingInfor.writingPostLists" :class="userPost.isActive ? 'selected-row' : ''">
                                <td class="text-success text-center auto-resize">
                                    <font-awesome-icon icon="hand-point-right" />
                                </td>
                                <td class="text-success text-center post-link">
                                    <NuxtLink :to="$route.meta.rootPath + '/' + userPost.postId + '/' + callHelpers('toSlug', [userPost.postTitle])">{{ userPost.postTitle }}</NuxtLink>
                                </td>
                                <td class="text-center no-padding auto-resize">
                                    <button type="button" class="btn btn-sm btn-danger btn-del-lesson" @click="deleteUserPost(userPost)">
                                        <font-awesome-icon icon="xmark" />
                                    </button>
                                </td>
                            </tr>
                            <tr v-else class="no-data">
                                <td>
                                    <font-awesome-icon icon="hand-point-right" />
                                </td>
                                <td colspan="3">Bạn chưa đăng nhập hoặc chưa lưu bài viết nào!</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
        </left-tab>
        <div class="col-lg-9 col-xs-12 no-padding change-content border-left writing-box">
            <div class="col-md-4 col-md-push-8 col-xs-12 right-tab no-padding">
                <chat-gpt-box :chatBoxTitle="'GÓP Ý CHO CÂU TIẾNG ANH CỦA BẠN'" :isHideInput="true" :defaultCommands="writingInfor.rightTabDefaultCommands" :autoSendCommands="writingInfor.rightTabAutoSendCommands" apiKey="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">
                    <template v-slot:chatBoxWelcome>
                        <div class="right-chat-box">Góp ý về ngữ pháp,cách hành văn cho từng câu mà bạn viết.</div>
                    </template>
                </chat-gpt-box>
            </div>
            <div class="col-md-8 col-md-pull-4 col-xs-12 no-padding">
                <div class="right-header col-xs-12 no-padding">
                    <table class="full-width">
                        <tbody>
                            <tr>
                                <td class="text-center">
                                    <h5>
                                        <template v-if="appInfor.isMissionMode">
                                            <font-awesome-icon icon="fa-solid fa-futbol" spin />
                                            Nhiệm Vụ: Trùm Ngữ Pháp
                                        </template>
                                        <template v-else>
                                            <i class="glyphicon glyphicon-education"></i>
                                            HỌC VIẾT TIẾNG ANH
                                        </template>
                                    </h5>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-xs-12 web-main no-padding" :class="appInfor.isMobile ? '' : 'padding-left'">
                    <div v-tooltip="writingInfor.writingText.isError ? writingInfor.writingText.errorMessage : ''">
                        <resize-textarea class="form-control full-width" :class="writingInfor.writingText.isError ? 'input-error' : ''" placeholder="Xin nhập nội dung bài viết" :rows="10" :maxHeight="500" v-model="writingInfor.writingText.value" :modelValue="writingInfor.writingText.value" @update:modelValue="suggestGrammar"> </resize-textarea>
                    </div>
                    <div class="col-xs-12 no-padding sentence-box margin-top">
                        <div class="col-xs-12 no-padding mean-box">
                            <span class="input-title-box sub-text">Gợi Ý Ngữ Pháp</span>
                            <div class="col-xs-12 no-padding suggest-box">
                                <div v-for="grammarSuggest in writingInfor.grammarSuggestList" class="col-xs-12 col-md-12">
                                    <span class="btn-link" @click="showViewGrammarPopup(grammarSuggest.postId)">{{ grammarSuggest.postTitle }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 no-padding margin-top" :class="appInfor.isMobile ? '' : 'padding-left'">
                    <tapir-widget :time="2" backendEndpoint="https://your-endpoint.com/.netlify/functions/audio-message" buttonColor="green" />
                </div>
                <div class="col-xs-12 no-padding margin-top help-box" :class="appInfor.isMobile ? '' : 'padding-left'">
                    <chat-gpt-box :chatBoxTitle="'TRỢ GIÚP TỪ EPLUS365'" :isClearOld="true" :isHideInput="true" :autoSendCommands="writingInfor.autoSendCommands" chatBoxIcon="handshake-angle">
                        <template v-slot:customCommand>
                            <div class="col-xs-12 no-padding chatbox__chatcommand">
                                <div class="form-group col-xs-12 col-md-4">
                                    <select class="form-control input-sm" v-model="writingInfor.englishLevel.selected">
                                        <option v-for="englishLevel in writingInfor.englishLevel.options" :value="englishLevel.libId">{{ englishLevel.libContent }}</option>
                                    </select>
                                </div>
                                <div class="form-group col-xs-12 col-md-4">
                                    <input class="form-control input-sm" placeholder="Số từ của bài viết" v-model="writingInfor.numberOfWords" />
                                </div>
                                <div class="form-group col-xs-12" v-tooltip="writingInfor.writingDescription.isError ? writingInfor.writingDescription.errorMessage : ''">
                                    <resize-textarea class="form-control full-width" :class="writingInfor.writingDescription.isError ? 'input-error' : ''" placeholder="Nhập mô tả về chủ đề của bài viết" :rows="2" :maxHeight="500" v-model="writingInfor.writingDescription.value" :modelValue="writingInfor.writingDescription.value"> </resize-textarea>
                                </div>
                            </div>
                            <div class="col-xs-12 no-padding chatbox__chatcommand">
                                <div class="col-xs-12 col-md-3 margin-bottom">
                                    <button class="btn btn-sm full-width btn-primary" @click="saveUserPost()">Lưu Bài Viết</button>
                                </div>
                                <div v-for="customChatCommand in writingInfor.customChatCommands" class="col-xs-12 col-md-3 margin-bottom">
                                    <button class="btn btn-sm full-width" :class="writingInfor.currentCustomCommandLabel == customChatCommand.commandLabel ? 'btn-danger' : 'btn-primary'" @click="createCustomCommand(customChatCommand)">{{ customChatCommand.commandTitle }}</button>
                                </div>
                            </div>
                        </template>
                        <template v-slot:chatBoxWelcome>
                            <div class="right-chat-box">Nơi cung cấp cho bạn những sự trợ giúp hữu ích bằng hệ thống AI</div>
                        </template>
                    </chat-gpt-box>
                </div>
            </div>
        </div>
    </div>
    <ClientOnly>
        <PopupViewGrammar />
    </ClientOnly>
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
import { writingStore } from "./store";
import { viewGrammarPopupStore } from "@/components/popup/view-grammar/store.js";
import _debounce from "lodash.debounce";
/**
 * Define main layout
 */

const Writing = {
    name: "Writing",
    components: {},
    beforeCreate() {},
    computed: {
        ...mapState(writingStore, ["writingInfor"]),
    },
    data: function () {
        var _this = this;
        var writing = writingStore();
        return {
            updateSentenceList: _debounce(function (inputValue) {
                const { $nlp } = useNuxtApp();
                var doc = $nlp.readDoc(inputValue);
                var sentenceList = doc.sentences().out();
                writing.writingInfor.currentTextSentences = sentenceList;
                writing.writingInfor.currentTextSentenceslength = sentenceList.length;
            }, 1000),
        };
    },
    mounted() {
        this.$watch(
            () => ({
                linkId: this.$route.params,
            }),
            (to) => {
                var linkId = Array.isArray(to.linkId.id) ? to.linkId.id[0] : to.linkId.id;
                this.selectUserPost(linkId);
            }
        );
        this.$watch(
            () => ({
                // currentData: this.rightTabInfor.currentData,
                currentTextSentenceslength: this.writingInfor.currentTextSentenceslength,
            }),
            (to, from) => {
                if (to.currentTextSentenceslength - from.currentTextSentenceslength == 1 && to.currentTextSentenceslength > 1) {
                    this.writingInfor.rightTabAutoSendCommands = [{ role: "user", content: "Kiểm tra ngữ pháp của câu tiếng anh sau:" + this.writingInfor.currentTextSentences[this.writingInfor.currentTextSentences.length - 2], isMe: true, isShow: false }];
                }
            },
            {
                // deep: true,
                // immediate: true,
            }
        );
        // pronunciationAssessment.main(settings);
    },
    methods: {
        ...mapActions(writingStore, ["updateWritingInfor", "getWritingInfor", "setWritingInfor", "getExample", "initPost", "saveUserPost", "deleteUserPost"]),
        ...mapActions(viewGrammarPopupStore, ["showViewGrammarPopup"]),
        suggestGrammar(inputValue) {
            var lastWord = inputValue.trim().split(" ").pop();
            var suggestList = this.writingInfor.grammarPostTagLists.filter((tag) => lastWord.toLowerCase().includes(tag.tagName.toLowerCase().replace("_", "")));
            this.writingInfor.grammarSuggestList = suggestList;
            this.updateSentenceList(inputValue);
        },
        createCustomCommand(customChatCommand) {
            const { $Validator } = useNuxtApp();
            var validator = null;
            var commands = [];
            var conditions = "";
            var topic = "";
            var englishLevel = this.writingInfor.englishLevel.options.filter((Level) => Level.libId == this.writingInfor.englishLevel.selected);
            topic += this.writingInfor.writingDescription.value != "" ? "\nCó chủ đề về [" + this.writingInfor.writingDescription.value + "]" : "";
            conditions += " ";
            conditions += this.writingInfor.englishLevel.selected != "" ? " viết bằng trình độ [" + englishLevel[0]["text_remark1"] + "]" : "";
            conditions += this.writingInfor.numberOfWords != "" ? " và" : "";
            conditions += this.writingInfor.numberOfWords != "" ? " khoảng [" + this.writingInfor.numberOfWords + "] từ" : "";
            conditions += "?";
            switch (customChatCommand.commandLabel) {
                case "create":
                    validator = $Validator.checkValidate(this.writingInfor.writingDescriptionRules, this.writingInfor);
                    if (validator) {
                        commands = customChatCommand.commands.slice();
                        commands.push({
                            role: "user",
                            content: "Bạn có thể giúp tôi tạo một bài viết Tiếng Anh" + topic + conditions,
                            isMe: true,
                            isShow: false,
                        });
                        this.writingInfor.autoSendCommands = commands;
                        this.writingInfor.currentCustomCommandLabel = customChatCommand.commandLabel;
                    }
                    break;
                case "improve":
                    validator = $Validator.checkValidate(this.writingInfor.writingTextRules, this.writingInfor);
                    if (validator) {
                        commands = customChatCommand.commands.slice();
                        commands.push({
                            role: "user",
                            content: "Bạn có thể giúp tôi chỉnh sửa bài viết Tiếng Anh sau đây:'" + this.writingInfor.writingText.value + "'" + topic + conditions,
                            isMe: true,
                            isShow: false,
                        });
                        this.writingInfor.autoSendCommands = commands;
                        this.writingInfor.currentCustomCommandLabel = customChatCommand.commandLabel;
                    }
                    break;
                case "suggest":
                    validator = $Validator.checkValidate(this.writingInfor.writingTextRules, this.writingInfor);
                    if (validator) {
                        commands = customChatCommand.commands.slice();
                        commands.push({
                            role: "user",
                            content: "Bạn có thể chỉ cho tôi những điểm cần cải thiện của bài viết Tiếng Anh sau đây bằng Tiếng Việt:'" + this.writingInfor.writingText.value + "'" + topic + conditions,
                            isMe: true,
                            isShow: false,
                        });
                        this.writingInfor.autoSendCommands = commands;
                        this.writingInfor.currentCustomCommandLabel = customChatCommand.commandLabel;
                    }
                    break;

                default:
                    break;
            }
        },
        selectUserPost(userPostId) {
            this.writingInfor.writingPostLists.forEach(function (post) {
                if (post.postId == userPostId) {
                    post.isActive = true;
                } else {
                    post.isActive = false;
                }
            });
            this.initPost();
        },
        refreshScreen() {
            var data = {
                target_id: "",
                screen_div: "/writing",
            };
            this.getWritingInfor(data);
        },
        updatePagingContent(paging, page) {
            if (paging.pagingId == 1) {
                //update paging content
                var { $appMethods } = useNuxtApp();
                $appMethods.getExample(page);
            }
        },
    },
};
export default defineNuxtComponent(Writing);
</script>
<style lang="scss">
@import "./style.scss";
</style>
