<!-- <script setup>
import { postContentStore } from "./store";
const route = useRoute()
var payload = {
    target_id: route.params.id ?? '',
    screen_div: route.meta.rootPath,
};
const { $repository } = useNuxtApp();
var res = await $repository({
    method: "post",
    url: `common/get-post-content`,
    data: payload,
});
var postContent = postContentStore();
var data = res.data.data;
postContent.postContentInfor = data[0][0];
// postContent.postContentInfor.paging = data[1][0];
// postContent.postContentInfor.isLoading = false;
//phai tach cac watcher ra thanh cac ham roi gọi luon
</script> -->
<template>
    <template v-if="contentType == 'missionMode'">
        <div class="col-xs-12 no-padding">
            <div class="main-content margin-top">
                <table class="table table-welcome">
                    <tbody>
                        <tr>
                            <td>
                                <h3 class="text-right text-success">
                                    <font-awesome-icon icon="sun" />
                                </h3>
                            </td>
                            <td>
                                <h3 class="text-center text-success">Nhiệm vụ dành cho bạn đã bắt đầu hãy chinh phục nó nhé!</h3>
                            </td>
                            <td>
                                <h3 class="text-left text-success">
                                    <font-awesome-icon icon="moon" />
                                </h3>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="text-center icon-box">
                    <!-- <img :src="welcomeImage" style="width:100%" alt="welcomeImage"/> -->
                    <font-awesome-icon icon="star" />
                    <font-awesome-icon icon="gift" />
                    <font-awesome-icon icon="hat-wizard" />
                    <font-awesome-icon icon="puzzle-piece" />
                    <font-awesome-icon icon="gamepad" />
                    <font-awesome-icon icon="glasses" />
                    <font-awesome-icon icon="user-astronaut" />
                </div>
                <h4 class="text-left text-success">
                    <font-awesome-icon icon="person-military-pointing" flip="horizontal" />
                    Gợi ý dành cho bạn:
                </h4>
                <ul class="hint-list">
                    <li v-for="hint in missionModeContentInfor.hintList">
                        <font-awesome-icon :icon="hint.hintIcon" />
                        {{ hint.hintText }}
                    </li>
                </ul>
            </div>
        </div>
    </template>
    <template v-else-if="!contentType">
        <!-- <div v-if="appInfor.isMobile && !isHidePostInfor" class="col-xs-12 divider divider-post"></div> -->
        <div class="col-xs-12 no-padding main-content margin-top" v-touch:tap="showFixedBottomBar">
            <div class="col-xs-12 no-padding social-box" target-id="5">
                <div class="col-xs-12 no-padding">
                    <div class="title-header">
                        <h1 class="text-center">{{ postContentInfor.postTitle }}</h1>
                    </div>
                </div>
                <div v-if="!isHidePostInfor" class="post-infor post-text">
                    <div class="infor">
                        <span class="infor-title">Lượt xem: </span>
                        <span class="infor-value">{{ postContentInfor.postViews }}</span>
                    </div>
                    <div class="infor">
                        <span class="infor-title">Tác giả: </span>
                        <span class="infor-value">{{ postContentInfor.postAuthor }}</span>
                    </div>
                    <div class="infor">
                        <span class="infor-title">Ngày đăng: </span>
                        <span class="infor-value">{{ postContentInfor.postPublicDate }}</span>
                    </div>
                    <div class="infor">
                        <span class="infor-title">Ngày sửa: </span>
                        <span class="infor-value">{{ postContentInfor.postUpdateDate }}</span>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 no-padding">
                <div class="post-text" id="currentPostText">
                    <div v-html="postContentInfor.postContent"></div>
                </div>
            </div>
        </div>
    </template>
    <template v-else-if="contentType == '404'">
        <div class="col-xs-12 no-padding">
            <div class="main-content margin-top">
                <h4 class="text-center text-warning">
                    <font-awesome-icon icon="file-contract" />
                    Dữ liệu {{ notFoundContentInfor.screenName }} bạn truy xuất đã bị xóa hoặc không tồn tại!
                </h4>
                <h4 class="text-center text-warning">
                    <img :src="notFoundImage" style="width: 50%" />
                </h4>
                <h4 class="text-left text-success">
                    <font-awesome-icon icon="person-military-pointing" flip="horizontal" />
                    Gợi ý dành cho bạn:
                </h4>
                <ul class="hint-list">
                    <li v-for="hint in notFoundContentInfor.hintList">
                        <font-awesome-icon :icon="hint.hintIcon" />
                        {{ hint.hintText }}
                    </li>
                </ul>
            </div>
        </div>
    </template>
    <template v-else="contentType == 'welcome'">
        <div class="col-xs-12 no-padding">
            <div class="main-content margin-top">
                <table class="table table-welcome">
                    <tbody>
                        <tr>
                            <td>
                                <h3 class="text-right text-success">
                                    <font-awesome-icon icon="sun" />
                                </h3>
                            </td>
                            <td>
                                <h3 class="text-center text-success">{{ welcomeContentInfor.welcomeSlogan }}</h3>
                            </td>
                            <td>
                                <h3 class="text-left text-success">
                                    <font-awesome-icon icon="moon" />
                                </h3>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="text-center icon-box">
                    <!-- <img :src="welcomeImage" style="width:100%" alt="welcomeImage"/> -->
                    <font-awesome-icon icon="star" />
                    <font-awesome-icon icon="gift" />
                    <font-awesome-icon icon="hat-wizard" />
                    <font-awesome-icon icon="puzzle-piece" />
                    <font-awesome-icon icon="gamepad" />
                    <font-awesome-icon icon="glasses" />
                    <font-awesome-icon icon="user-astronaut" />
                </div>
                <h4 class="text-left text-success">
                    <font-awesome-icon icon="person-military-pointing" flip="horizontal" />
                    Gợi ý dành cho bạn:
                </h4>
                <ul class="hint-list">
                    <li v-for="hint in welcomeContentInfor.hintList">
                        <font-awesome-icon :icon="hint.hintIcon" />
                        {{ hint.hintText }}
                    </li>
                </ul>
            </div>
        </div>
    </template>
</template>
<script>
/**
 * Import libraries
 */
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import MainMenu from '@/share-layout/menu';

/**
 * import template
 */
// import template from './template.html';
// import store from '@/store';
import { postContentStore } from "./store";
import { mapState } from "pinia";

/**
 * Define main layout
 */
const PostContent = {
    name: "PostContent",
    // template: template,
    components: {
        // MainMenu,
    },
    beforeCreate() {
        // if (!store.hasModule('postContent')) {
        //     store.registerModule('postContent', postContentStore);
        // }
    },
    computed: {
        ...mapState(postContentStore, ["notFoundContentInfor", "welcomeContentInfor", "missionModeContentInfor"]),
    },
    mounted() {
        this.notFoundImage = this.appInfor.domain + "web-content/images/icon/no-result.png";
        this.welcomeImage = this.appInfor.domain + "web-content/images/icon/welcome.png";
    },
    props: ["contentType", "isHidePostInfor"],
    data: function () {
        return {
            notFoundImage: null,
            welcomeImage: null,
        };
    },
};
export default defineNuxtComponent(PostContent);
</script>
<style lang="scss">
@import "./style.scss";
</style>
