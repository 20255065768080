<script>
// @ is an alias to /src
import axios from "axios";
import _ from "lodash-uuid";
import { headerErrorBarStore } from "@/components/header-error-bar/store.js";

// Make sure to pick a unique name for the flag
// so it won't conflict with any other mixin.
export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig()
    const domain = process.dev?config.public.VUE_APP_BASE_DOMAIN_CLIENT:config.public.VUE_APP_BASE_DOMAIN;
    const baseUrl = domain + config.public.VUE_APP_BASE_PATH;
    const apiKey = config.public.VUE_APP_API_KEY;
    const timeout = config.public.VUE_APP_API_TIMEOUT;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    var repository = axios.create({
        baseURL: baseUrl,
        timeout: timeout,
        showProgress: false,
        cancelToken: source.token,
        headers: {
            "x-api-key": apiKey,
        },
    });
    var headerErrorBar = headerErrorBarStore();
    const { $callHelpers,$getToken } = useNuxtApp();
    repository.interceptors.request.use(
        function (config) {
            // source.cancel('Operation canceled by the user.');
            if (!import.meta.env.SSR && config.showProgress) {
                nuxtApp.vueApp.config.globalProperties.$Progress.start();
            }
            config.headers["x-request-id"] = _.uuid();
            var token = '';
            if (!import.meta.env.SSR) {
                token = $getToken('eplus365token').value??'';
            }
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    );

    repository.interceptors.response.use(
        function (response) {
            if (!import.meta.env.SSR && response.config.showProgress) {
                nuxtApp.vueApp.config.globalProperties.$Progress.finish();
            }
            return response;
        },
        function (error) {
            var resError = error.response??[];
            switch (resError.status ?? 999) {
                case 401:
                    headerErrorBar.updateHeaderErrorBarInfor({
                        isShowHeaderErrorBar: true,
                        errorMessage: $callHelpers("getMessage", ["I001"]),
                    });
                    break;
                case 500:
                    headerErrorBar.updateHeaderErrorBarInfor({
                        isShowHeaderErrorBar: true,
                        errorMessage: $callHelpers("getMessage", ["E999"]),
                    });
                    break;
                case 599:
                    headerErrorBar.updateHeaderErrorBarInfor({
                        isShowHeaderErrorBar: true,
                        errorMessage: $callHelpers("getMessage", ["E998"]),
                    });
                    break;
                case 0:
                    // headerErrorBar.updateHeaderErrorBarInfor({
                    //     isShowHeaderErrorBar: true,
                    //     errorMessage: $callHelpers("getMessage", ["E998"]),
                    // });
                    break;
                default:
                    headerErrorBar.updateHeaderErrorBarInfor({
                        isShowHeaderErrorBar: true,
                        errorMessage: $callHelpers("getMessage", ["E999"]),
                    });
                    break;
            }
            if (!import.meta.env.SSR && resError.config.showProgress) {
                nuxtApp.vueApp.config.globalProperties.$Progress.finish();
            }
            return Promise.reject(error);
        }
    );
    return {
        provide: {
            repository: repository,
        },
    };
});

// Vue.mixin(mixins) // Set up your mixin then
// Vue.component('vue-final-modal', VueFinalModal);

// export default {}
</script>
