import { appStore } from "@/store";
export const musicPlayerStore = defineStore('musicPlayer', {
    namespaced: true,
    state: () => ({
        audio: null,
        circleLeft: null,
        barWidth: null,
        duration: null,
        currentTime: null,
        startTime: null,
        endTime: null,
        isTimerPlaying: false,
        tracks: null,
        currentTrack: null,
        currentTrackIndex: 0,
        transitionName: null,
    }),
    // mutations: {
    //     updateMusicPlayerInfor(state, musicPlayerInfor) {
    //         Object.keys(musicPlayerInfor).forEach(function (key) {
    //             state.musicPlayerInfor[key] = musicPlayerInfor[key];
    //         })
    //     },
    // },
    // getters: {
    //     remainQuestionIdList: state => state.musicPlayerInfor.remainQuestionIdList,
    // },
    actions: {
        updateMusicPlayerInfor(musicPlayerInfor) {
            var state = this;
            Object.keys(musicPlayerInfor).forEach(function (key) {
                state.musicPlayerInfor[key] = musicPlayerInfor[key];
            })
        },
        play() {
            if (this.audio.paused) {
                this.audio.play();
                this.isTimerPlaying = true;
            } else {
                this.audio.pause();
                this.isTimerPlaying = false;
            }
        },
        seek(time) {
            this.audio.pause();
            this.audio.currentTime = this.audio.currentTime + time;
            this.audio.play();
        },
        generateTime() {
            let width = (100 / this.audio.duration) * this.audio.currentTime;
            this.barWidth = width + "%";
            this.circleLeft = width + "%";
            let durmin = Math.floor(this.audio.duration / 60);
            let dursec = Math.floor(this.audio.duration - durmin * 60);
            let curmin = Math.floor(this.audio.currentTime / 60);
            let cursec = Math.floor(this.audio.currentTime - curmin * 60);
            if (durmin < 10) {
                durmin = "0" + durmin;
            }
            if (dursec < 10) {
                dursec = "0" + dursec;
            }
            if (curmin < 10) {
                curmin = "0" + curmin;
            }
            if (cursec < 10) {
                cursec = "0" + cursec;
            }
            this.duration = durmin + ":" + dursec;
            this.currentTime = curmin + ":" + cursec;
        },
        prevTrack() {
            this.transitionName = "scale-in";
            this.isShowCover = false;
            if (this.currentTrackIndex > 0) {
                this.currentTrackIndex--;
            } else {
                this.currentTrackIndex = this.tracks.length - 1;
            }
            this.currentTrack = this.tracks[this.currentTrackIndex];
            this.resetPlayer();
        },
        nextTrack() {
            this.transitionName = "scale-out";
            this.isShowCover = false;
            if (this.currentTrackIndex < this.tracks.length - 1) {
                this.currentTrackIndex++;
            } else {
                this.currentTrackIndex = 0;
            }
            this.currentTrack = this.tracks[this.currentTrackIndex];
            this.resetPlayer();
        },
        resetPlayer() {
            var app = appStore();
            this.barWidth = 0;
            this.circleLeft = 0;
            this.audio.currentTime = 0;
            this.audio.src = app.appInfor.domain + this.currentTrack.listeningAudioSource;
            var _this = this;
            setTimeout(() => {
                if (_this.isTimerPlaying) {
                    _this.audio.play();
                } else {
                    _this.audio.pause();
                }
            }, 300);
        },
    }
});