<script>
// @ is an alias to /src
import { mapState, mapActions } from "pinia";
// import router from "@/router/index.js";
import mixins from "@/globalMixins.vue";
import { VueFinalModal } from "vue-final-modal";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library, config } from "@fortawesome/fontawesome-svg-core";
import directives from "@/directives/";
import Validator from "@/utils/validator";
import VueProgressBar from "@aacassandra/vue3-progressbar";
import Vue3TouchEvents from "vue3-touch-events";
import vSelect from "vue-select";
import Carousel3d from "vue3-carousel-3d";
import Loading from "vue-loading-overlay";
import vueImageCropUpload from "vue-image-crop-upload";
import Popper from "vue3-popper";
// Load "wink-nlp" package.
import winkNLP from "wink-nlp";
import model from "wink-eng-lite-web-model";
// import { VueEditor } from "vue3-editor";
// import TapirWidget from 'vue-audio-tapir';
// import 'vue-audio-tapir/dist/vue-audio-tapir.css';
// const winkNLP = require('wink-nlp');
// Load english language model — light version.
// const model = require('wink-eng-lite-web-model');
// Instantiate wink-nlp.
const nlp = winkNLP(model);

// Code for Hello World!
// var text = '<p>Chào mừng bạn đến với&nbsp;giáo trình học tiếng anh của eplus365</p>';
// var doc = nlp.readDoc(text);
// console.log(doc.sentences().out());

const options = {
    color: "#d9534f",
    failedColor: "#874b4b",
    thickness: "5px",
    transition: {
        speed: "0.2s",
        opacity: "0.6s",
        termination: 300,
    },
    autoRevert: true,
    location: "top",
    inverse: false,
    autoFinish: false,
};
// import "scss/common/screencontroller.scss";
// import "../node_modules/jquery/dist/jquery.js";
// import "../node_modules/bootstrap/dist/js/bootstrap.min.js";

// Make sure to pick a unique name for the flag
// so it won't conflict with any other mixin.
export default defineNuxtPlugin((nuxtApp) => {
    config.autoAddCss = false;
    library.add(fas, fab, far);
    // store.callHelpers = mixins.methods.callHelpers;
    // nuxtApp.vueApp.use(mixins)
    directives(nuxtApp.vueApp);
    // nuxtApp.vueApp.use(router);
    nuxtApp.vueApp.use(VueProgressBar, options);
    nuxtApp.vueApp.use(Vue3TouchEvents);
    nuxtApp.vueApp.use(Carousel3d);
    // nuxtApp.vueApp.use(debounce);
    nuxtApp.vueApp.component("v-loading", Loading);
    nuxtApp.vueApp.component("vue-final-modal", VueFinalModal);
    nuxtApp.vueApp.component("font-awesome-icon", FontAwesomeIcon);
    nuxtApp.vueApp.component("v-select", vSelect);
    nuxtApp.vueApp.component("v-image-crop-upload", vueImageCropUpload);
    nuxtApp.vueApp.component("v-popper", Popper);
    // nuxtApp.vueApp.component('tapir-widget', TapirWidget );
    nuxtApp.vueApp.mixin(mixins);
    return {
        provide: {
            app: nuxtApp,
            callHelpers: mixins.methods.callHelpers,
            Validator: Validator,
            getToken: mixins.methods.getToken,
            appStates: nuxtApp.payload.pinia,
            appMethods: nuxtApp.vueApp._context.mixins[0].methods,
            nlp: nlp,
        },
    };
});
// Vue.mixin(mixins) // Set up your mixin then
// Vue.component('vue-final-modal', VueFinalModal);

// export default {}
</script>
