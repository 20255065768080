<template>
    <div class="chatbox">
        <div class="chatbox__title right-header text-center">
            <h5><font-awesome-icon :icon="chatBoxIcon ?? 'comment-dots'" /> {{ chatBoxTitle ?? "" }}</h5>
        </div>
        <slot name="customCommand"></slot>
        <template v-if="chatList.length == (defaultCommands ? defaultCommands.length : 0)">
            <slot name="chatBoxWelcome"></slot>
        </template>
        <div v-if="chatList.length > (defaultCommands ? defaultCommands.length : 0)" class="chatbox__chatList" ref="chatContainer" :style="{ height: 'calc(100vh - ' + (appInfor.footerHeight + 231) + 'px)' }">
            <div v-for="(message, index) in chatList" :key="index">
                <div v-if="message.isShow" class="chatbox__message" :class="{ 'chatbox__message--outgoing': message.isMe }">
                    <div class="chatbox__message-text" :class="{ 'full-width': isHideInput }">{{ message.content }}</div>
                </div>
            </div>
            <div v-if="chatList.length > 0 && isGettingResponse">
                <div class="chatbox__message">
                    <div class="chatbox__message-text"><font-awesome-icon :icon="['fas', 'comment-dots']" bounce /></div>
                </div>
            </div>
        </div>
        <div v-if="chatList.length > 0 && !isHideInput" class="chatbox__input">
            <div class="input-group">
                <template v-if="!appInfor.isMobile">
                    <resize-textarea class="form-control full-width" placeholder="Nhấn enter để gửi hoặc shift+enter để xuống hàng" @keydown.enter.exact.prevent @keyup.enter.exact="sendInputMessage()" @keydown.enter.shift.exact="newline" :rows="1" :cols="4" :maxHeight="150" v-model="inputText" :modelValue="inputText"> </resize-textarea>
                </template>
                <template v-else>
                    <resize-textarea class="form-control full-width" placeholder="Xin nhập nội dung sau đó nhấn gửi" :rows="1" :maxHeight="150" v-model="inputText" :modelValue="inputText"> </resize-textarea>
                </template>
                <!-- <input type="text" class="form-control" v-model="inputText" @keydown.enter.prevent="sendInputMessage()" placeholder="Nhập tin nhắn sau đó nhấn enter..." /> -->
                <span class="input-group-btn" @click="inputText.trim() != '' && sendInputMessage()">
                    <!-- <button class="btn btn-default" type="button" @click="sendInputMessage()">Gửi Tin Nhắn</button> -->
                    <font-awesome-icon :class="inputText.trim() == '' ? 'disabled' : ''" :icon="['fas', 'paper-plane']" />
                </span>
            </div>
            <!-- /input-group -->
            <!-- <input class="" type="text" v-model="inputText" @keydown.enter.prevent="sendMessage" placeholder="Type your message..." /> -->
            <!-- <button @click="sendMessage">Send</button> -->
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    props: ["chatBoxTitle", "isHideInput", "autoSendCommands", "apiKey", "dynamicCommands", "chatBoxIcon", "isClearOld", "defaultCommands"],
    data() {
        return {
            inputText: "",
            chatList: this.defaultCommands ? this.defaultCommands.slice() : [],
            isGettingResponse: false,
        };
    },
    methods: {
        sendInputMessage() {
            if (this.inputText.trim() === "") {
                return;
            }
            this.chatList.push({ role: "user", content: this.inputText, isMe: true, isShow: true });
            this.$emit("after-send-message", this.inputText);
            this.sendMessage().then((response) => {
                var responseText = "";
                responseText = response.data.choices[0].message.content.trim();
                this.chatList.push({ role: "assistant", content: responseText, isMe: false, isShow: true });
            });
        },
        sendAutoSendMessage() {
            var inputValue = "";
            if (this.autoSendCommands.length === 0) {
                return;
            }
            if (this.isClearOld) {
                this.chatList = this.defaultCommands ? this.defaultCommands.slice() : [];
            }
            Array.prototype.push.apply(this.chatList, this.autoSendCommands);
            this.sendMessage().then((response) => {
                var responseText = "";
                responseText = response.data.choices[0].message.content.trim();
                this.chatList.push({ role: "assistant", content: responseText, isMe: false, isShow: true });
            });
        },
        sendDynamicMessage() {
            if (this.dynamicCommands.length === 0) {
                return;
            }
            Object.assign(this.chatList, this.dynamicCommands);
            this.sendMessage().then((response) => {
                var responseText = "";
                responseText = response.data.choices[0].message.content.trim();
                this.chatList.push({ role: "assistant", content: responseText, isMe: false, isShow: true });
            });
        },
        sendHiddenMessage() {
            if (this.dynamicCommands.length === 0) {
                return;
            }
            Object.assign(this.chatList, this.dynamicCommands);
            this.sendMessage().then((response) => {
                var responseText = "";
                responseText = response.data.choices[0].message.content.trim();
                this.chatList.push({ role: "assistant", content: responseText, isMe: false, isShow: false });
            });
        },
        async sendMessage() {
            this.isGettingResponse = true;
            this.inputText = "";
            const response = await axios.post(
                "https://api.openai.com/v1/chat/completions",
                {
                    model: "gpt-3.5-turbo",
                    messages: this.chatList.map(({ isMe, isShow, ...keepAttrs }) => keepAttrs),
                    temperature: 0.7,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.apiKey ?? "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"}`,
                    },
                }
            );
            this.isGettingResponse = false;
            return response;
        },
    },
    mounted() {
        this.$watch(
            () => ({
                autoSendCommands: this.autoSendCommands,
            }),
            (newValue) => {
                this.sendAutoSendMessage();
            },
            {
                deep: true,
                // immediate: true,
            }
        );
        this.$watch(
            () => ({
                dynamicCommands: this.dynamicCommands,
            }),
            (newValue) => {
                if (newValue.dynamicCommands.length > 0) {
                    this.sendDynamicMessage();
                } else {
                    this.chatList = [];
                }
            },
            {
                deep: true,
                // immediate: true,
            }
        );
        this.$watch(
            () => ({
                chatList: this.chatList,
            }),
            (newValue) => {
                var _this = this;
                this.$nextTick(function () {
                    const chatContainer = _this.$refs.chatContainer;
                    if (chatContainer) {
                        chatContainer.scrollTop = chatContainer.scrollHeight;
                    }
                });
            },
            {
                deep: true,
                // immediate: true,
            }
        );
    },
};
</script>
<style lang="scss">
@import "./style.scss";
</style>
