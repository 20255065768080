import repository from "@/repository";
// import store from "@/store";
import { rightTabStore } from "../right-tab/store";
export const exampleStore = defineStore('example', {
    namespaced: true,
    state: () => ({
        exampleInfor: {
            isLoading: false,
            isCanAdd: true,
            isCanVote: true,
            isShowPaging: true,
            exampleLabel: 'Bài Tập Tự Luyện',
            exampleInputEnglish: {
                value: '',
                isError: false,
                errorMessage:'',
            },
            exampleInputVietNamese: {
                value: '',
                isError: false,
                errorMessage:'',
            },
            exampleLabelIcon: 'user-plus',
            exampleResource: 'common',
            exampleLists: [
                {
                    dataId: '1',
                    exampleId: '1',
                    exampleContributeUserName: 'Kirito',
                    exampleContributeUserRank: 'S+',
                    exampleContributeUserAvartaImage: '/web-content/images/avarta/default_avarta.jpg',
                    exampleContributeDateTime: '17/10/2022 14:46',
                    exampleEnglish: 'trust your self',
                    exampleVietNamese: 'tin vào bản thân',
                    exampleRatingValue: '2114',
                    myVote: 0,
                },
            ],
            exampleDisplayLists: [],
            currentPaging: {},
            pagingLists: [
                {
                    dataId: "1",
                    pagingId: "1",
                    currentPage: "3",
                    totalPage: "3",
                    totalRecord: "1",
                    pagingSize: "4",
                },
            ],
            rules:{
                'exampleInputEnglish.value': 'required',
                'exampleInputVietNamese.value': 'required',
            },
        }
    }),
    actions: {
        updateExampleInfor(exampleInfor) {
            var state = this;
            Object.keys(exampleInfor).forEach(function (key) {
                state.exampleInfor[key] = exampleInfor[key];
            })
        },
        addExample() {
            try {
                var rightTab = rightTabStore();
                var state = this;
                const { $Validator } = useNuxtApp();
                var validator = $Validator.checkValidate(state.exampleInfor.rules,state.exampleInfor);
                if(validator){
                    var currentDataId = rightTab.currentDataId;
                    var payload = {
                        target_id: currentDataId,
                        target_div: 2, //example for a post
                        screen_div: localStorage.getItem("currentUrl"),
                        english_clause: state.exampleInfor.exampleInputEnglish.value ?? '',
                        vietnamese_clause: state.exampleInfor.exampleInputVietNamese.value ?? '',
                    };
                    repository.addExample(payload)
                        .then((res) => {
                            // const { data } = res;
                            if (res.status === 200) {
                                var data = res.data.data;
                                state.exampleInfor.exampleLists.unshift(data[1][0]);
                                state.exampleInfor.exampleInputEnglish.value = '';
                                state.exampleInfor.exampleInputVietNamese.value = '';
                                return res;
                            }
                        });
                }
            } catch (e) {
                console.log("Action addExample: " + e.message);
            }
        },
        getExample(page,target_div) {
            try {
                var rightTab = rightTabStore();
                var state = this;
                var currentDataId = rightTab.currentDataId;
                var payload = {
                    target_id: {targetId:currentDataId},
                    target_div: target_div?target_div:2, //example for a post
                    screen_div: localStorage.getItem("currentUrl"),
                    page: page,
                    page_size: 5,
                };
                repository.getExample(payload)
                    .then((res) => {
                        // const { data } = res;
                        if (res.status === 200) {
                            var data = res.data.data;
                            var currentExampleList = state.exampleInfor.exampleLists.filter(function (el) {
                                return el.dataId !== currentDataId;
                            });
                            //convert from vue object to normal object
                            currentExampleList = JSON.parse(JSON.stringify(currentExampleList));
                            // state.exampleInfor.grammarPostExampleLists = data[0].concat(currentExampleList);
                            this.updateExampleInfor({
                                exampleLists: data[0].concat(currentExampleList),
                            });
                            var currentPaging = state.exampleInfor.pagingLists.filter(function (el) {
                                return el.dataId != currentDataId;
                            });
                            //convert from vue object to normal object
                            currentPaging = JSON.parse(JSON.stringify(currentPaging));
                            state.exampleInfor.pagingLists = data[1].concat(currentPaging);
                            this.updateExampleInfor({
                                currentPaging: data[1][0],
                            });
                            return res;
                        }
                    });
            } catch (e) {
                console.log("Action getExamplePage: " + e.message);
            }
        },
        voteExample(payload) {
            try {
                var state = this;
                var params = {
                    source_id: payload.example.dataId,
                    target_id: payload.example.exampleId,
                    my_vote: payload.voteValue, //example for a post
                    execute_div: 3, //this is a vote for example
                    screen_div: localStorage.getItem("currentUrl"),
                };
                repository.voteAction(params)
                    .then((res) => {
                        // const { data } = res;
                        if (res.status === 200) {
                            var data = res.data.data;
                            var currentExampleList = state.exampleInfor.exampleDisplayLists.filter(function (el) {
                                return el.exampleId == payload.example.exampleId;
                            });
                            if(currentExampleList[0]!=undefined){
                                var index = state.exampleInfor.exampleDisplayLists.indexOf(currentExampleList[0]);
                                state.exampleInfor.exampleDisplayLists[index].myVote = data[1][0].myVote;
                                state.exampleInfor.exampleDisplayLists[index].exampleRatingValue = data[1][0].voteValue;
                            }
                            return res;
                        }
                    });
            } catch (e) {
                console.log("Action voteExample: " + e.message);
            }
        },
    }
});