<script setup>
definePageMeta({
    key: "static",
});
import { appStore } from "@/store/index.js";
import { grammarStore } from "./store";
const { isMobile } = useDevice();
var app = appStore();
var grammar = grammarStore();
var route = useRoute();
var data = {
    target_id: route.params.id[0] ?? "",
    catalogue_id: "",
    group_id: "",
    screen_div: "/grammar",
};
if (app.appInfor.isRefresh) {
    const { $repository } = useNuxtApp();
    var res = await $repository({
        method: "post",
        url: `/grammar/get-grammar-infor`,
        data: data,
        showProgress: true,
        headers: {
            Authorization: `Bearer ${useCookie("eplus365token").value}`, //set token for ssr request
        },
    });
    grammar.setGrammarInfor(res);
    app.appInfor.isRefresh = false;
}
app.appInfor.isSwitching = false;
//phai tach cac watcher ra thanh cac ham roi gọi luon
</script>
<template>
    <div class="col-xs-12 web-panel middle-content">
        <left-tab />
        <div class="col-lg-9 col-xs-12 no-padding change-content border-left">
            <div class="right-header col-xs-12 no-padding">
                <div class="col-md-8 col-xs-12 no-padding">
                    <table class="full-width">
                        <tbody>
                            <tr>
                                <td v-if="!appInfor.isMobile" class="text-left">
                                    <h5 class="noselect" id="btn_prev" @click="previousRightTabItem()"><i class="glyphicon glyphicon-fast-backward"></i> TRƯỚC</h5>
                                </td>
                                <td class="text-center">
                                    <h5>
                                        <template v-if="appInfor.isMissionMode">
                                            <font-awesome-icon icon="fa-solid fa-futbol" spin />
                                            Nhiệm Vụ: Trùm Ngữ Pháp
                                        </template>
                                        <template v-else>
                                            <i class="glyphicon glyphicon-education"></i>
                                            HỌC NGỮ PHÁP TIẾNG ANH
                                        </template>
                                    </h5>
                                </td>
                                <td v-if="!appInfor.isMobile" class="text-right">
                                    <h5 class="margin-right float-right noselect" id="btn_next" @click="nextRightTabItem()">TIẾP <i class="glyphicon glyphicon-fast-forward"></i></h5>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <right-tab />
            <div class="col-md-8 col-md-pull-4 col-xs-12 web-main" :class="appInfor.isMobile ? '' : 'padding-left'">
                <content-loader v-if="grammarInfor.isLoading && !appInfor.isMobile" viewBox="0 0 400 124" :speed="2" primaryColor="#aaa" secondaryColor="#888888">
                    <rect x="45%" y="3" rx="3" ry="3" width="15%" height="6" />
                    <rect x="65%" y="3" rx="3" ry="3" width="15%" height="6" />
                    <rect x="85%" y="3" rx="3" ry="3" width="15%" height="6" />
                    <rect x="0" y="13" rx="3" ry="3" width="100%" height="6" />
                    <rect x="0" y="25" rx="3" ry="3" width="100%" height="500" />
                </content-loader>
                <content-loader v-if="grammarInfor.isLoading && appInfor.isMobile" viewBox="0 0 200 124" :speed="2" primaryColor="#aaa" secondaryColor="#888888">
                    <rect x="0" y="3" rx="3" ry="3" width="100%" height="6" />
                    <rect x="0" y="13" rx="3" ry="3" width="45%" height="6" />
                    <rect x="55%" y="13" rx="3" ry="3" width="45%" height="6" />
                    <rect x="0" y="23" rx="3" ry="3" width="45%" height="6" />
                    <rect x="55%" y="23" rx="3" ry="3" width="45%" height="6" />
                    <rect x="0" y="33" rx="3" ry="3" width="100%" height="6" />
                    <rect x="0" y="45" rx="3" ry="3" width="100%" height="500" />
                </content-loader>
                <template v-if="!grammarInfor.isLoading && rightTabInfor.currentData.dataId != undefined">
                    <template v-if="rightTabInfor.currentData.dataId == 404">
                        <post-content contentType="404"></post-content>
                    </template>
                    <template v-else="!grammarInfor.isLoading && rightTabInfor.currentData.dataId != undefined">
                        <post-content></post-content>
                        <div class="col-xs-12 no-padding panel-group" id="example-list">
                            <Exercise></Exercise>
                            <template v-if="!appInfor.isMissionMode">
                                <Example></Example>
                            </template>
                        </div>
                    </template>
                </template>
                <template v-else-if="!grammarInfor.isLoading && appInfor.isMissionMode">
                    <post-content contentType="missionMode"></post-content>
                </template>
                <template v-else-if="!grammarInfor.isLoading && rightTabInfor.currentData.dataId == undefined">
                    <post-content contentType="welcome"></post-content>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
import { grammarStore } from "./store";
/**
 * Define main layout
 */

const Grammar = {
    name: "Grammar",
    components: {},
    beforeCreate() {},
    computed: {
        ...mapState(grammarStore, ["grammarInfor"]),
    },
    mounted() {
        this.$watch(
            () => ({
                currentData: this.rightTabInfor.currentData,
            }),
            (rightTab) => {
                this.initPost(rightTab);
            },
            {
                deep: true,
            }
        );
    },
    methods: {
        ...mapActions(grammarStore, ["updateGrammarInfor", "getGrammarInfor", "setGrammarInfor", "getExample", "initPost"]),
        refreshScreen() {
            var data = {
                target_id: "",
                catalogue_id: this.rightTabInfor.rightTabItems[0].value ?? "",
                group_id: this.rightTabInfor.rightTabItems[1].value ?? "",
                screen_div: "/grammar",
            };
            this.getGrammarInfor(data);
        },
        updatePagingContent(paging,page){
            if (paging.pagingId == 1) {
                //update paging content
                var { $appMethods } = useNuxtApp();
                $appMethods.getExample(page);
            }
        },
    },
};
export default defineNuxtComponent(Grammar);
</script>
<style lang="scss">
@import "./style.scss";
</style>
