import Validator from 'validatorjs';
import en from 'validatorjs/src/lang/en';

Validator.setMessages('en', en);
const message = {
    'required': 'Trường này không được để trống',
    'min': 'Trường này phải có tối thiểu :min ký tự.',
};

export default{
    checkValidate(rules,targets){
        this.clearValidateError(rules,targets);
        const validator = new Validator(targets, rules, message);
        if(!validator.check()){
            this.setValidateError(validator.errors.errors,targets);
        }
        return validator.check();
    },
	/* eslint-disable */
    setValidateError(errors,targets){
        Object.keys(errors).forEach(element => {
            var key = element.split('.')[0];
            var target = 'targets.'+key;
            eval(target).isError = true;
            eval(target).errorMessage = typeof errors[element] == 'string'?errors[element]:errors[element][0];
        });
    },
    clearValidateError(rules,targets){
        Object.keys(rules).forEach(element => {
            var key = element.split('.')[0];
            var target = 'targets.'+key;
            eval(target).isError = false;
            eval(target).errorMessage = '';
        });
    }
	/* eslint-enable */
};