<script setup>
import { appStore as useAppStore } from "@/store/index.js";
import { conversationStore } from "./store";
import { postContentStore } from "~~/components/post-content/store";
var app = useAppStore();

if (app.appInfor.isRefresh) {
    // var payload = {
    //     screen_div: "/",
    // };
    // const { $repository } = useNuxtApp();
    // var res = await $repository({
    //     method: "post",
    //     url: `conversation/get-conversation-infor`,
    //     data: payload,
    //     headers: {
    //         Authorization: `Bearer ${useCookie("eplus365token").value}`, //set token for ssr request
    //     },
    // });
    var postContent = postContentStore();
    var conversation = conversationStore();
    postContent.updateWelcomeContentInfor({
        welcomeSlogan: conversation.conversationInfor.welcomeSlogan,
        hintList: conversation.conversationInfor.hintList,
    });
    // var data = res.data.data;
    // conversation.conversationInfor.newsLists = data[0];
    conversation.conversationInfor.currentCustomChatCommands = null;
    conversation.conversationInfor.isLoading = false;
    app.appInfor.isRefresh = false;
}
app.appInfor.isSwitching = false;
//phai tach cac watcher ra thanh cac ham roi gọi luon
</script>
<template>
    <div class="col-xs-12 web-panel middle-content">
        <left-tab />
        <div v-if="conversationInfor.isRerender" class="col-lg-9 col-xs-12 no-padding change-content border-left">
            <!-- <div class="right-header col-xs-12 no-padding">
                <div class="col-md-8 col-xs-12 no-padding">
                    <table class="full-width">
                        <tbody>
                            <tr>
                                <td class="text-center">
                                    <h5>
                                        <template v-if="appInfor.isMissionMode">
                                            <font-awesome-icon icon="fa-solid fa-futbol" spin />
                                            Nhiệm Vụ: Trùm Ngữ Pháp
                                        </template>
                                        <template v-else>
                                            <i class="glyphicon glyphicon-education"></i>
                                            HỌC HỘI THOẠI TIẾNG ANH
                                        </template>
                                    </h5>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> -->
            <div class="col-md-8 col-xs-12 web-main" :class="appInfor.isMobile ? '' : 'padding-left'">
                <chat-gpt-box :chatBoxTitle="'NỘI DUNG CUỘC HỘI THOẠI'" chatBoxIcon="comments" @after-send-message="createAutoSendCommands" :dynamicCommands="conversationInfor.currentCustomChatCommands">
                    <template v-slot:customCommand>
                        <div class="col-xs-12 no-padding chatbox__chatcommand">
                            <div v-for="customChatCommand in conversationInfor.customChatCommands" class="col-xs-6 col-md-3 no-padding-left">
                                <NuxtLink class="btn btn-sm full-width" :class="JSON.stringify(conversationInfor.currentCustomChatCommands) === JSON.stringify(customChatCommand.commands) ? 'btn-danger' : 'btn-primary'" :to="$route.meta.rootPath + '?level=' + customChatCommand.commandLabel">{{ customChatCommand.commandTitle }}</NuxtLink>
                            </div>
                        </div>
                    </template>
                    <template v-slot:chatBoxWelcome>
                        <post-content contentType="welcome"></post-content>
                    </template>
                </chat-gpt-box>
            </div>
            <div class="col-md-4 col-xs-12 right-tab no-padding">
                <chat-gpt-box :chatBoxTitle="'GÓP Ý CHO CÂU TIẾNG ANH CỦA BẠN'" :isHideInput="true" :autoSendCommands="conversationInfor.autoSendCommands" :defaultCommands="conversationInfor.defaultCommands" apiKey="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">
                    <template v-slot:chatBoxWelcome>
                        <div class="right-chat-box">Trong quá trình bạn hội thoại với AI bằng tiếng anh hệ thống sẽ tự động kiểm tra ngữ pháp cũng như cách hành văn của bạn trong hộp thoại này để có thể đưa ra những chỉnh sửa, gợi ý tốt nhất giúp bạn cải thiện khả năng hội thoại!</div>
                    </template>
                </chat-gpt-box>
            </div>
        </div>
    </div>
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import MainMenu from '@/share-layout/menu';

/**
 * import template
 */
// import template from './template.html';
import { appStore } from "@/store";
import { conversationStore } from "./store";

/**
 * Define main layout
 */
const Conversation = {
    name: "Conversation",
    // template: template,
    components: {
        // MainMenu,
    },
    beforeCreate() {
        // if (!store.hasModule('conversation')) {
        //     store.registerModule('conversation', conversationStore);
        // }
    },
    created() {
        // this.$watch(
        // 	() => ({
        // 		isRefresh: this.appInfor.isRefresh,
        // 	}),
        // 	(data) => {
        //         var route = useRoute();
        // 		if(data.isRefresh && route.meta.rootPath=='/'){
        // 			this.getConversationInfor();
        // 		}
        // 	}
        // );
    },
    computed: {
        ...mapState(conversationStore, ["conversationInfor"]),
    },
    mounted() {
        this.$watch(
            () => ({
                level: this.$route.query.level,
            }),
            (to) => {
                var level = this.conversationInfor.customChatCommands.filter((obj) => {
                    return obj.commandLabel === to.level;
                });
                if (level.length > 0) {
                    this.forceRender().then((response) => {
                        this.conversationInfor.currentCustomChatCommands = level[0].commands;
                    });
                } else {
                    this.conversationInfor.currentCustomChatCommands = [];
                    this.forceRender();
                }
            },
            {
                deep: true,
                immediate: true,
            }
        );
        this.updateLeftTabInfor({
            renderLessonList: false,
            renderSystemNotifyList: false,
        });
    },
    methods: {
        ...mapActions(conversationStore, ["getConversationInfor", "updateConversationInfor", "getNewsList"]),
        // updateResizedScreen() {
        // 	this.updateConversationInfor({
        // 		optionFontSize: this.$refs.optionHeader[0].clientWidth/12,
        // 	});
        // },
        updatePagingContent(paging, page) {
            // if(paging.pagingId==1){
            //update paging content
            this.getNewsList(page);
            // }
        },
        refreshScreen() {
            var data = {
                screen_div: "/",
            };
            this.getConversationInfor(data);
        },
        async forceRender() {
            this.conversationInfor.isRerender = false;
            await this.$nextTick();
            this.conversationInfor.isRerender = true;
        },
        createAutoSendCommands(inputText) {
            this.conversationInfor.autoSendCommands = [];
            this.conversationInfor.autoSendCommands.push({ role: "user", content: "Kiểm tra ngữ pháp của câu tiếng anh sau:" + inputText, isMe: true, isShow: false });
        },
    },
};
export default defineNuxtComponent(Conversation);
</script>
<style lang="scss">
@import "./style.scss";
</style>
