// import repository from '@/utils/repository';

const resource = '/popup';

export default {
    getMission: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/get-mission`,
            data: data,
            showProgress: true,
        });
    },
    acceptMission: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/accept-mission`,
            data: data,
            showProgress: true,
        });
    },
    refuseMission: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/refuse-mission`,
            data: data,
            showProgress: true,
        });
    },
    doMission: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/do-mission`,
            data: data,
            showProgress: true,
        });
    },
};
