// import repository from '@/utils/repository';

const resource = '/popup';

export default {
    getContributeVocabularyPopup: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/get-contribute-vocabulary-popup`,
            data: data,
            showProgress: true,
        });
    },
    uploadCropImage: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/upload-crop-image`,
            data: data,
            // showProgress: true,
        });
    },
    saveVocabularyContribute: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/save-vocabulary-contribute`,
            data: data,
            contentType: false,
            processData: false,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            },
            // showProgress: true,
        });
    },
};
