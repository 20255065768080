// import repository from "@/repository";
import listeningRepository from "./repository";
import { appStore } from "@/store";
import { rightTabStore } from "~~/components/right-tab/store";
import { leftTabStore } from "~~/components/left-tab/store";
import { exampleStore } from "~~/components/example/store";
import { postContentStore } from "~~/components/post-content/store";
import { exerciseStore } from "~~/components/exercise/store";
import { listeningContentStore } from "~~/components/listening-content/store";
import { wordRelationshipStore } from "~~/components/word-relationship/store";
export const listeningStore = defineStore('listening', {
    namespaced: true,
    state: () => ({
        listeningInfor: {
            isLoading: false,
            questionListTitle: 'Bài Tập Tự Luyện',
            welcomeSlogan: 'Chào mừng bạn đến với khóa học nghe của Eplus365!',
            hintList: [
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Danh sách bên phải bao gồm tất cả các bài nghe có trong hệ thống.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Bạn có thể lọc và chọn bài viết bài nghe mong muốn dựa theo danh mục và nhóm ở tab bên phải.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Bạn cũng có thể lưu danh mục/nhóm bài nghe mong muốn bằng chức năng "Lưu Danh Mục/Nhóm" danh sách đã lưu sẽ được hiển thị ở bên trái.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Bạn có thể lưu/học lại bài nghe trong danh sách bằng chức năng "Đã học" hoặc "Đã quên" tương ứng với tab "Ngữ Pháp Chưa Học" và "Ngữ Pháp Đã Học".',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Mỗi bài nghe sẽ đều được phân tách thành từng câu,nhập nội dung bạn nghe được sau đó kiểm tra thành quả',
                },
            ],
            listeningPostLists: [
                {
                    listeningId: '1',
                    listeningTitle: 'hello',
                    listeningDiv: '1',
                    listeningDivName: 'Danh từ',
                    listeningSpecializedDiv: '1',
                    listeningSpecializedName: 'Kinh tế',
                    listeningFieldDiv: '1',
                    listeningFieldName: 'Âm nhạc',
                    listeningSpelling: 'æmp (æmplɪfaɪə)',
                    listeningMean: 'Xin chào',
                    listeningAudio: '',
                    listeningRemembered: false,
                },
            ],
        }
    }),
    getters: {
        listeningShowList(state) {
            var { $appStates } = useNuxtApp();
            var rightTabInfor = $appStates.rightTab.rightTabInfor;
            var listeningShowList = state.listeningInfor.listeningPostLists.filter(function (el) {
                var catalogueId = rightTabInfor.rightTabItems[0].value ?? '';
                var groupId = rightTabInfor.rightTabItems[1].value ?? '';
                catalogueId = (catalogueId == '' ? el.catalogueId : catalogueId);
                groupId = (groupId == '' ? el.groupId : groupId);
                return el.catalogueId == catalogueId && el.groupId == groupId;
            });
            return listeningShowList;
        },
    },
    actions: {
        updateListeningInfor(listeningInfor) {
            var state = this;
            Object.keys(listeningInfor).forEach(function (key) {
                state.listeningInfor[key] = listeningInfor[key];
            })
        },
        // updateListeningPostQuestionLists(sqlQuestionLists) {
        //     var state = this;
        //     var questionLists = state.listeningInfor.listeningPostQuestionLists;
        //     questionLists = sqlQuestionLists['question'];
        //     Object.keys(questionLists).forEach(function (key) {
        //         var questionAnswer = sqlQuestionLists['answer'].filter(function (el) {
        //             return el.questionId == questionLists[key].questionId;
        //         });
        //         questionLists[key]['answerLists'] = questionAnswer;
        //     })
        //     state.listeningInfor.listeningPostQuestionLists = questionLists;
        // },
        getListeningInfor(payload) {
            try {
                var app = appStore();
                // var rightTab = rightTabStore();
                var state = this;
                //     state.listeningInfor.isLoading = true;
                //     rightTab.updateRightTabInfor({
                //         isLoading: true,
                //     });
                app.appInfor.isSwitching = true;
                listeningRepository.getListeningInfor(payload)
                    .then((res) => {
                        // const { data } = res;
                        state.setListeningInfor(res);
                    });
            } catch (e) {
                console.log("Action getListeningInfor: " + e.message);
            }
        },
        setListeningInfor(res) {
            // const { data } = res;
            var app = appStore();
            var rightTab = rightTabStore();
            var leftTab = leftTabStore();
            var example = exampleStore();
            var exercise = exerciseStore();
            var listeningContent = listeningContentStore();
            var wordRelationship = wordRelationshipStore();
            var state = this;
            var route = useRoute();
            var router = useRouter();
            state.listeningInfor.isLoading = true;
            rightTab.updateRightTabInfor({
                isLoading: true,
            });
            if (res.status === 200) {
                var data = res.data.data;
                if (data[7][0] && data[7][0]['missionId'] != 0) {
                    app.appInfor.isMissionMode = true;
                    app.appInfor.doingMission = data[7][0];
                    leftTab.leftTabInfor.renderLessonList = false;
                    rightTab.rightTabInfor.isDisplaySelect = false;
                    rightTab.rightTabInfor.isDisplayButton = false;
                    rightTab.rightTabInfor.isChangeRouter = false;
                    listeningContent.listeningContentInfor.minListenProcessDone = 80;
                    router.push(data[7][0]['missionLink']);
                } else {
                    app.appInfor.isMissionMode = false;
                    app.appInfor.doingMission = {};
                    leftTab.leftTabInfor.renderLessonList = true;
                    rightTab.rightTabInfor.isDisplaySelect = true;
                    rightTab.rightTabInfor.isDisplayButton = true;
                    rightTab.rightTabInfor.isChangeRouter = true;
                    listeningContent.listeningContentInfor.minListenProcessDone = 50;
                }
                rightTab.rightTabInfor.isDisplayVote = false;
                listeningContent.listeningContentInfor.isShowListeningOptions = true;
                var selectedCategory = data[0].filter(obj => {
                    return obj.selected === true
                })
                rightTab.updateRightTabItems([
                    {
                        selectItems: data[0],
                        value: selectedCategory[0] ? selectedCategory[0].catalogueId : '',
                    },
                    0,
                ]);
                var selectedGroup = data[1].filter(obj => {
                    return obj.selected === true
                })
                rightTab.updateRightTabItems([
                    {
                        selectItems: data[1],
                        value: selectedGroup[0] ? selectedGroup[0].groupId : '',
                    },
                    1
                ]);
                leftTab.updateLeftTabInfor({
                    lessonLists: data[2],
                    isLessonListLoading: false,
                });
                var rightTabMenus = [];
                if (app.appInfor.isMissionMode) {
                    rightTabMenus = [
                        {
                            rightTabMenuLink: 'tab1',
                            rightTabMenuName: 'Nội Dung Nhiệm Vụ',
                            isActive: true
                        }
                    ];
                } else {
                    rightTabMenus = [
                        {
                            rightTabMenuLink: 'tab1',
                            rightTabMenuName: 'Từ Vựng Chưa Học',
                            isActive: true
                        },
                        {
                            rightTabMenuLink: 'tab2',
                            rightTabMenuName: 'Từ Vựng Đã Học',
                            isActive: false
                        },
                    ];
                }
                rightTab.updateRightTabInfor({
                    rightTabMenus: rightTabMenus,
                });
                var selectedTab = data[4].filter(obj => {
                    return obj.isActive === true
                })
                if (selectedTab[0] != undefined) {
                    rightTab.updateRightTabInfor({
                        isRememberedTab: selectedTab[0].remembered,
                    });
                }
                rightTab.updateRightTabInfor({
                    allDataTabLists: data[4],
                    isLoading: false,
                    // isEncrypt: !listeningContent.listeningContentInfor.learnListeningOptions.raw.isChecked,
                });
                rightTab.updateRightTabInfor({
                    rightTabLists: rightTab.rightTabLists,
                });
                rightTab.updateRightTabInfor({
                    unRememberedTabLists: rightTab.unRememberedTabLists,
                    rememberedTabLists: rightTab.rememberedTabLists,
                });
                rightTab.selectRightTabItem(route.params.id);
                listeningContent.updateListeningContentInfor({
                    listeningList: data[3].slice(),
                    listeningSentenceList: data[6].slice(),
                    newWordList: data[5].slice(),
                });
                // exercise.updateQuestionLists(data[5].slice(),data[6].slice());
                // this.updateListeningInfor({
                //     //use slice to copy array
                //     newWordList: data[5].slice(),
                // });
                state.listeningInfor.listeningPostLists = data[3];
                this.initPost();
                // if(selectedTab[0].dataId!=undefined){
                //     this.dispatch('rightTab/selectRightTabItem',selectedTab[0].dataId);
                // }
                state.listeningInfor.isLoading = false;
                app.appInfor.isReloadContent = false;
                app.appInfor.isSwitching = false;
                return res;
            }
        },
        initPost() {
            var postContent = postContentStore();
            postContent.updateWelcomeContentInfor({
                welcomeSlogan: this.listeningInfor.welcomeSlogan,
                hintList: this.listeningInfor.hintList,
            });
        }
    },
});