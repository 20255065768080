<template>
    <div class="panel panel-default panel-contribute">
        <div class="panel-heading" data-toggle="collapse" :data-parent="'#example-list' + popupType" :href="'#collapse-contribute' + popupType">
            <span class="panel-title">
                <font-awesome-icon :icon="wordRelationshipLabelIcon" class="example-label-icon" />
                <span>{{ wordRelationshipLabel }}</span>
            </span>
        </div>
        <div :id="'collapse-contribute' + popupType" class="panel-collapse collapse" style="">
            <div class="panel-body">
                <table v-if="wordRelationshipDisplayCount() > 0" class="table vocabulary-table table-hover table-bordered table-body margin-bottom">
                    <template v-for="wordRelationshipList in wordRelationshipLists">
                        <tbody v-if="wordRelationshipList.dataId == rightTabInfor.currentData.dataId" class="example-item commentList">
                            <tr class="commentItem">
                                <td rowspan="2" class="auto-resize">
                                    <div class="commenterImage no-padding">
                                        <img :src="wordRelationshipList.wordRelationshipContributeUserAvartaImage" />
                                    </div>
                                </td>
                                <td colspan="4" class="text-left">
                                    <div class="commentHeader">
                                        <span class="sub-text">{{ wordRelationshipList.wordRelationshipContributeUserName }}</span>
                                        <span class="rank">{{ wordRelationshipList.wordRelationshipContributeUserRank }}</span>
                                        <span class="date sub-text float-right comment-date no-padding">{{ wordRelationshipList.wordRelationshipContributeDateTime }}</span>
                                    </div>
                                </td>
                                <td class="no-padding auto-resize">
                                    <button class="btn btn-sm btn-default vote-down btn-vote" data-toggle="tooltip" data-placement="bottom" data-original-title="Từ vựng không chuẩn xác" @click="appInfor.userPermission.isCanVoteForExample && wordRelationshipList.myVote != -1 && voteWordRelationship({ wordRelationship: wordRelationshipList, voteValue: -1, voteDiv: popupType == 1 ? 4 : 5 }, this)">
                                        <font-awesome-icon icon="down-long" :class="wordRelationshipList.myVote == -1 ? 'active' : ''" />
                                    </button>
                                </td>
                                <td class="no-padding auto-resize">
                                    <button class="btn btn-sm btn-default vote-up btn-vote" data-toggle="tooltip" data-placement="bottom" data-original-title="Từ vựng chuẩn xác" @click="appInfor.userPermission.isCanVoteForExample && wordRelationshipList.myVote != 1 && voteWordRelationship({ wordRelationship: wordRelationshipList, voteValue: 1, voteDiv: popupType == 1 ? 4 : 5 }, this)">
                                        <font-awesome-icon icon="up-long" :class="wordRelationshipList.myVote == 1 ? 'active' : ''" />
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td class="target_id hidden">{{ wordRelationshipList.wordRelationshipId }}</td>
                                <td class="text-center">
                                    <NuxtLink to="#" target="_blank">{{ wordRelationshipList.wordRelationshipName }}</NuxtLink>
                                </td>
                                <td class="text-center">
                                    <span>{{ wordRelationshipList.wordRelationshipSpell }}</span>
                                </td>
                                <td class="text-left">
                                    <span>{{ wordRelationshipList.wordRelationshipMean }}</span>
                                </td>
                                <td class="auto-resize" colspan="3">
                                    <span style="font-family: Jersey" class="rating-value">{{ wordRelationshipList.wordRelationshipRatingValue }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </table>
                <h5 v-else class="text-center">Hiện chưa có {{ wordRelationshipLabel.toLowerCase() }} nào trong hệ thống!</h5>
                <button type="button" class="btn btn-sm btn-primary full-width" @click="showSelectVocabularyPopup(wordRelationshipLists,popupType)">Đóng Góp {{ wordRelationshipLabel }}</button>
            </div>
        </div>
    </div>
    <!-- <Paging pagingId="1" v-if="wordRelationshipInfor.currentPaging.currentPage != undefined" :currentPage="wordRelationshipInfor.currentPaging.currentPage" :totalPage="wordRelationshipInfor.currentPaging.totalPage"></Paging> -->
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import WordRelationshipMenu from '@/share-layout/menu';

/**
 * import template
 */
// import template from './template.html';
// import store from '@/store';
import { wordRelationshipStore } from "./store";
import { selectVocabularyPopupStore } from "@/components/popup/select-vocabulary/store.js";

/**
 * Define wordRelationship layout
 */
const WordRelationship = {
    name: "WordRelationship",
    // template: template,
    components: {
        // WordRelationshipMenu
    },
    beforeCreate() {
        // if (!store.hasModule('wordRelationship')) {
        //     store.registerModule('wordRelationship', wordRelationshipStore);
        // }
    },
    // props: ["questionResource","isHaveHint","isHaveExplan"],
    computed: {
        ...mapState(wordRelationshipStore, ["wordRelationshipInfor"]),
    },
    created() {
        // this.$watch(
        // 	() => ({
        // 		questionLists: this.wordRelationshipInfor.questionLists,
        // 	}),
        // 	() => {
        // 		this.initQuestion();
        // 	}
        // );
        // this.updateWordRelationshipInfor({
        //     questionResource: this.questionResource!== undefined?this.questionResource:'common',
        //     isHaveHint: this.isHaveHint!== undefined?this.isHaveHint:true,
        //     isHaveExplan: this.isHaveExplan!== undefined?this.isHaveExplan:true,
        // })
    },
    props: ["wordRelationshipLabel", "wordRelationshipLabelIcon", "wordRelationshipLists", "popupType"],
    mounted() {
        // this.$watch(
        //     () => ({
        //         currentData: this.rightTabInfor.currentData,
        //         wordRelationshipLists: this.wordRelationshipLists,
        //     }),
        //     (rightTab) => {
        //         if (rightTab.currentData.dataId != undefined) {
        //             var currentWordRelationshipList = this.wordRelationshipLists.filter(function (el) {
        //                 return el.dataId == rightTab.currentData.dataId;
        //             });
        //             // this.updateWordRelationshipInfor({
        //             //     wordRelationshipLists: currentWordRelationshipList,
        //             // });
        //             // var currentPaging = this.wordRelationshipInfor.pagingLists.filter(function (el) {
        //             //     return el.dataId == rightTab.currentData.dataId;
        //             // });
        //             // this.updateWordRelationshipInfor({
        //             //     currentPaging: currentPaging.length > 0 ? currentPaging[0] : {},
        //             // });
        //         }
        //     },
        //     {
        //         deep: true,
        //         immediate: true,
        //     }
        // );
        // var temp = this.getPermissionText('-1');
        // console.log(temp);
    },
    methods: {
        ...mapActions(wordRelationshipStore, ["updateWordRelationshipInfor", "addWordRelationship", "getWordRelationship", "voteWordRelationship"]),
        ...mapActions(selectVocabularyPopupStore, ["showSelectVocabularyPopup"]),
        wordRelationshipDisplayCount() {
            var { $appStates } = useNuxtApp();
            var wordRelationshipDisplayCount = this.wordRelationshipLists.filter(function (el) {
                return el.dataId == Number($appStates.rightTab.rightTabInfor.currentData.dataId);
            });
            return wordRelationshipDisplayCount.length;
        },
    },
};
export default WordRelationship;
</script>
<style lang="scss">
@import "./style.scss";
</style>
