import repository from "./repository";
// import { BACKGROUND } from '~/assets/images/images_base64';

export const homePageStore = defineStore('homePage', {
    namespaced: true,
    state: () => ({
        homePageInfor: {
            isLoading: true,
            homePageSize: 'col-lg-9 col-xs-12',
            // optionBackground: 'data:image/png;base64,'+ BACKGROUND.optionBackground,
            optionFontSize: '',
            optionLists: [
                {
                    optionTitle: 'Trang Chủ',
                    optionLink: '/',
                    optionItems: ['Đa dạng','Nhiều chủ đề','Cập nhật liên tục','Dễ học dễ nhớ','Hình ảnh,âm thanh','Đóng góp ví dụ']
                },
                {
                    optionTitle: 'Học Ngữ Pháp',
                    optionLink: '/grammar',
                    optionItems: ['Đa dạng','Nhiều chủ đề','Cập nhật liên tục','Dễ học dễ nhớ','Hình ảnh,âm thanh','Đóng góp ví dụ']
                },
                {
                    optionTitle: 'Common Review',
                    optionLink: '/portal',
                    optionItems: ['Đa dạng','Nhiều chủ đề','Cập nhật liên tục','Dễ học dễ nhớ','Hình ảnh,âm thanh','Đóng góp ví dụ']
                },
                {
                    optionTitle: 'Học Từ Vựng',
                    optionLink: '',
                    optionItems: ['Đa dạng','Nhiều chủ đề','Cập nhật liên tục','Dễ học dễ nhớ','Hình ảnh,âm thanh','Đóng góp ví dụ']
                },
                {
                    optionTitle: 'Học Từ Vựng',
                    optionLink: '',
                    optionItems: ['Đa dạng','Nhiều chủ đề','Cập nhật liên tục','Dễ học dễ nhớ','Hình ảnh,âm thanh','Đóng góp ví dụ']
                },
                {
                    optionTitle: 'Học Từ Vựng',
                    optionLink: '',
                    optionItems: ['Đa dạng','Nhiều chủ đề','Cập nhật liên tục','Dễ học dễ nhớ','Hình ảnh,âm thanh','Đóng góp ví dụ']
                },
            ],
            introduceBox: {
                introduceTitle: 'SỰ KHÁC BIỆT CỦA <span class="text-danger">EPLUS</span>',
                introduceItems: [
                    'Là 1 thế giới nơi việc học tiếng anh cũng như chơi game',
                    'Là 1 thế giới nơi việc học tiếng anh cũng như chơi game',
                    'Là 1 thế giới nơi việc học tiếng anh cũng như chơi game',
                    'Là 1 thế giới nơi việc học tiếng anh cũng như chơi game',
                    'Là 1 thế giới nơi việc học tiếng anh cũng như chơi game',
                    'Là 1 thế giới nơi việc học tiếng anh cũng như chơi game',
                    'Là 1 thế giới nơi việc học tiếng anh cũng như chơi game',
                ]
            },
            newsLists: [
                {
                    newsTargetId: '1',
                    newsTargetName: 'quynd',
                    newsLink: '/vocabulary',
                    newsNotes: 'abc',
                    newsDescription1: 'Ngữ pháp',
                    newsDescription2: 'Verb (động từ)',
                    newsDescription3: 'đã được cập nhật',
                    newsIcon: '',
                    newsCreateDate: '',
                    newsSpecialParam: '',
                },
            ],
            paging: {
                pagingId: "1",
                currentPage: "3",
                totalPage: "8",
                totalRecord: "1",
                pagingSize: "4",
            },
        }
    }),
    actions: {
        updateHomePageInfor(homePageInfor){
            var state = this;
            Object.keys(homePageInfor).forEach(function(key){
                state.homePageInfor[key] = homePageInfor[key];
            })
        },
        getHomePageInfor(payload){
            var state = this;
            state.homePageInfor.isLoading = true;
            try {
                repository.getHomePageInfor(payload)
                .then((res) => {
                    // const { data } = res;
                    if (res.status === 200) {
                        var data = res.data.data;
                        state.homePageInfor.newsLists = data[0];
                        state.homePageInfor.paging = data[1][0];
                        state.homePageInfor.isLoading = false;
                        return res;
                    }
                });
            } catch (e) {
                console.log("Action getHomePageInfor: " + e.message);
            }
        },
        getNewsList(page){
            var state = this;
            try {
                repository.getNewsList({
                    pageSize: 4,
                    page: page,
                })
                .then((res) => {
                    // const { data } = res;
                    if (res.status === 200) {
                        var data = res.data.data;
                        state.homePageInfor.newsLists = data[0];
                        state.homePageInfor.paging = data[1][0];
                        return res; 
                    }
                });
            } catch (e) {
                console.log("Action getNewsList: " + e.message);
            }
        },
    }
});