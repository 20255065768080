export const dialogPopupStore = defineStore('dialogPopup', {
    namespaced: true,
    state: () => ({
        dialogPopupInfor: {
            isLoading: true,
            showDialog: false,
            dialogTransition: 'fade',
            isShowCancelButon: true,
            dialogColor: 'bg-info',
            dialogIcon: 'check',
            dialogTitle: 'message dialog',
            dialogMessage: 'this is message',
            dialogMessageParams: [],
            dialogButtons: [
                {
                    buttonTitle: 'Đồng ý',
                    buttonClass: 'btn-default',
                    buttonAction: function () {
                    },
                },
            ],
        }
    }),
    actions: {
        updateDialogPopupInfor(dialogPopupInfor) {
            var _this = this;
            Object.keys(dialogPopupInfor).forEach(function (key) {
                _this.dialogPopupInfor[key] = dialogPopupInfor[key];
            })
        },
        showDialog(messageType, messageNo, payload) {
            this.initDialog(messageType, messageNo, payload);
            this.dialogPopupInfor.showDialog = true;
        },
        hideDialog() {
            this.dialogPopupInfor.showDialog = false;
        },
        initDialog(messageType, messageNo, calllback) {
            calllback = calllback?calllback:function(){};
            const { $callHelpers } = useNuxtApp();
            this.dialogPopupInfor.dialogTitle = $callHelpers('getMessageTitle', messageNo);
            this.dialogPopupInfor.dialogMessage = $callHelpers('getMessage', messageNo);
            var dialogButtons = [];
            var _this = this;
            switch (messageType) {
                case 1:
                    //confirm
                    this.dialogPopupInfor.dialogColor = 'bg-info';
                    this.dialogPopupInfor.dialogIcon = 'question';
                    dialogButtons= [
                        {
                            buttonTitle: 'Đồng ý',
                            buttonClass: 'btn-info',
                            buttonAction: function () {
                            },
                        },
                        {
                            buttonTitle: 'Hủy bỏ',
                            buttonClass: 'btn-default',
                            buttonAction: function () {
                                _this.dialogPopupInfor.showDialog = false;
                            },
                        },
                    ];
                    break;
                case 2:
                    //success
                    this.dialogPopupInfor.dialogColor = 'bg-success';
                    this.dialogPopupInfor.dialogIcon = 'check';
                    dialogButtons= [
                        {
                            buttonTitle: 'Đồng ý',
                            buttonClass: 'btn-success',
                            buttonAction: function () {
                                _this.dialogPopupInfor.showDialog = false;
                            },
                        },
                    ];
                    break;
                case 3:
                    //success
                    this.dialogPopupInfor.dialogColor = 'bg-warning';
                    this.dialogPopupInfor.dialogIcon = 'exclamation';
                    dialogButtons= [
                        {
                            buttonTitle: 'Đồng ý',
                            buttonClass: 'btn-warning',
                            buttonAction: function () {
                            },
                        },
                        {
                            buttonTitle: 'Hủy bỏ',
                            buttonClass: 'btn-default',
                            buttonAction: function () {
                                _this.dialogPopupInfor.showDialog = false;
                            },
                        },
                    ];
                    break;
                case 4:
                    //success
                    this.dialogPopupInfor.dialogColor = 'bg-error';
                    this.dialogPopupInfor.dialogIcon = 'xmark';
                    dialogButtons= [
                        {
                            buttonTitle: 'Đã Hiểu',
                            buttonClass: 'btn-error',
                            buttonAction: function () {
                                _this.dialogPopupInfor.showDialog = false;
                            },
                        },
                    ];
                    break;
                default:
                    break;
            }
            this.dialogPopupInfor.dialogButtons = dialogButtons;
            calllback();
        }
    }
});