export const listeningContentStore = defineStore('listeningContent', {
    namespaced: true,
    state: () => ({
        listeningContentInfor: {
            currentListeningName: '',
            isCorrect: null,
            isShowListeningOptions: true,
            audioStartPoint: null,
            audioEndPoint: null,
            activeSentenceId: null,
            isShowListeningContent: false,
            isRerender: true,
            minListenProcessDone: 80,
            listeningList: [
                {
                    listeningId: "07759980",
                    listeningName: "Mekanın Sahibi",
                    listeningArtist: "Norm Ender",
                    listeningCoverImage: "https://raw.githubusercontent.com/muhammederdem/mini-player/master/img/1.jpg",
                    listeningAudioSource: "https://raw.githubusercontent.com/muhammederdem/mini-player/master/mp3/1.mp3",
                    isListeningDeleted: false,
                }
            ],
            listeningSentenceList: [
                {
                    listeningSentenceIndex: '1',
                    listeningId: '07759980',
                    listeningSentenceId: '1',
                    listeningSentenceContent: 'hello',
                    listeningSentenceStart: 0,
                    listeningSentenceEnd: 0,
                    listeningSentenceDoneProcess: 0,
                    listeningSentenceDoneStatus: 0,
                }
            ],
            newWordList: [],
        },
    }),
    getters: {
        currentListeningData(state) {
            var { $appStates } = useNuxtApp();
            var currentListening = state.listeningContentInfor.listeningList.filter(function (el) {
                return el.listeningId == Number($appStates.rightTab.rightTabInfor.currentData.dataId);
            });
            return currentListening[0] ? currentListening[0] : '';
        },
        currentListeningSentenceData(state) {
            var { $appStates } = useNuxtApp();
            var listeningSentenceList = state.listeningContentInfor.listeningSentenceList.filter(function (el) {
                return el.listeningId == Number($appStates.rightTab.rightTabInfor.currentData.dataId);
            });
            return listeningSentenceList ? listeningSentenceList : '';
        },
        currentNewWordList(state) {
            var { $appStates } = useNuxtApp();
            var rightTabInfor = $appStates.rightTab.rightTabInfor;
            var currentNewWordList = state.listeningContentInfor.newWordList.filter(function (el) {
                return el.listeningId == rightTabInfor.currentData.dataId;
            });
            return currentNewWordList;
        },
        incompletedSentenceList(state) {
            var incompletedSentenceList = state.currentListeningSentenceData.filter(function (el) {
                return el.listeningSentenceDoneStatus != 1;
            });
            return incompletedSentenceList;
        },
    },
    actions: {
        updateListeningContentInfor(listeningContentInfor) {
            var state = this;
            Object.keys(listeningContentInfor).forEach(function (key) {
                state.listeningContentInfor[key] = listeningContentInfor[key];
            })
        },
        getListeningDataByIndex(index) {
            var currentListening = this.listeningContentInfor.listeningList.filter(function (el) {
                return el.vocabularyIndex == index;
            });
            return currentListening[0] ? currentListening[0] : '';
        },
    }
});