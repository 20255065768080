<template>
    <div class="panel panel-default panel-contribute">
        <div class="panel-heading" data-toggle="collapse" data-parent="#example-list" href="#collapse6">
            <span class="panel-title">
                <span>
                    <font-awesome-icon :icon="exerciseInfor.exerciseLabelIcon" class="exercise-label-icon" />
                    {{ exerciseInfor.exerciseLabel }}
                </span>
            </span>
        </div>
        <div id="collapse6" class="panel-collapse question-list collapse" style="">
            <div class="panel-body">
                <div class="form-group" min-width="1024px">
                    <div class="question-content">
                        <div v-if="exerciseInfor.isLoading" class="question-content">
                            <content-loader viewBox="0 0 800 85" :speed="3" primaryColor="#aaa" secondaryColor="#888888">
                                <rect x="0" y="5" rx="5" ry="5" width="100%" height="15" />
                                <circle cx="7" cy="33" r="7" />
                                <rect x="3%" y="28" rx="5" ry="5" width="97%" height="10" />
                                <circle cx="7" cy="48" r="7" />
                                <rect x="3%" y="43" rx="5" ry="5" width="97%" height="10" />
                                <circle cx="7" cy="63" r="7" />
                                <rect x="3%" y="58" rx="5" ry="5" width="97%" height="10" />
                                <circle cx="7" cy="78" r="7" />
                                <rect x="3%" y="73" rx="5" ry="5" width="97%" height="10" />
                            </content-loader>
                        </div>
                        <template v-if="!exerciseInfor.isLoading && exerciseInfor.questionLists.length > 0" v-for="(question, index) in currentDataQuestions">
                            <div v-if="question.dataId == rightTabInfor.currentData.dataId"  class="question-box" :class="[question.answerResult]" :target-id="question.dataId">
                                <label>Câu hỏi {{ index + 1 + ": " + question.questionTitle }}</label>
                                <div v-if="question.questionDiv == 0" class="answer-box">
                                    <span class="result-icon">
                                        <font-awesome-icon v-if="question.answerResult == 'wrong-answer'" icon="xmark" />
                                        <font-awesome-icon v-if="question.answerResult == 'right-answer'" icon="check" />
                                    </span>
                                    <label v-for="answer in question.answerLists" class="radio cool-link">
                                        <input type="radio" :name="'optradio' + answer.questionAnswerId" v-model="question.userAnsweredList" :value="answer.questionAnswerId" />
                                        {{ answer.questionAnswer }}
                                    </label>
                                </div>
                                <div v-else class="answer-box">
                                    <span class="result-icon">
                                        <font-awesome-icon v-if="question.answerResult == 'wrong-answer'" icon="xmark" />
                                        <font-awesome-icon v-if="question.answerResult == 'right-answer'" icon="check" />
                                    </span>
                                    <label v-for="answer in question.answerLists" class="checkbox cool-link">
                                        <input type="checkbox" :name="'optcheckbox' + answer.questionAnswerId" v-model="question.userAnsweredList" :value="answer.questionAnswerId" />
                                        {{ answer.questionAnswer }}
                                    </label>
                                </div>
                                <div v-if="question.answerResult == 'right-answer' && question.isShowExplan" class="explan-content margin-bottom">{{ question.explan }}</div>
                            </div>
                        </template>
                        <h5 v-if="!exerciseInfor.isLoading && currentDataQuestions.length == 0" class="text-center">Hiện chưa có bài tập nào trong hệ thống!</h5>
                    </div>
                </div>
            </div>
            <div v-if="!exerciseInfor.isLoading && currentDataQuestions.length != 0" class="panel-bottom margin-bottom">
                <template v-if="!appInfor.isMissionMode">
                    <button class="btn btn-primary btn-sm btn-refresh margin-left" type="button" @click="refreshQuestionList()">Làm mới câu hỏi</button>
                </template>
                <button class="btn btn-danger btn-sm btn-check-answer margin-left" type="button" @click="checkQuestionAnswer()">Xem kết quả</button>
            </div>
        </div>
    </div>
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import ExerciseMenu from '@/share-layout/menu';

/**
 * import template
 */
// import template from './template.vue';
// import store from '@/store';
import { exerciseStore } from "./store";

/**
 * Define exercise layout
 */
const Exercise = {
    name: "Exercise",
    // template: template,
    components: {
        // ExerciseMenu
    },
    beforeCreate() {
        // if (!store.hasModule('exercise')) {
        //     store.registerModule('exercise', exerciseStore);
        // }
    },
    props: ["questionResource", "isHaveHint", "isHaveExplan"],
    computed: {
        ...mapState(exerciseStore, ["exerciseInfor","currentDataQuestions"]),
    },
    mounted() {
        this.$watch(
            () => ({
                questionLists: this.exerciseInfor.questionLists,
            }),
            () => {
                this.initQuestion();
            }
        );
        this.updateExerciseInfor({
            questionResource: this.questionResource !== undefined ? this.questionResource : "common",
            isHaveHint: this.isHaveHint !== undefined ? this.isHaveHint : true,
            isHaveExplan: this.isHaveExplan !== undefined ? this.isHaveExplan : true,
        });
    },
    methods: {
        ...mapActions(exerciseStore, ["updateExerciseInfor", "checkQuestionAnswer", "initQuestion","refreshQuestionList","updateQuestionLists"]),
    },
};
export default defineNuxtComponent(Exercise);
</script>
<style lang="scss">
@import "./style.scss";
</style>
