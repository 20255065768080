<template>
    <div class="no-padding left-tab" :class="leftTabInfor.leftTabSize" style="padding-right: 1px">
        <ul class="nav nav-tabs nav-justified col-xs-12 no-padding">
            <li class="col-xs-6 no-padding inline-block" v-for="(leftTabMenu, tabIndex) in leftTabInfor.leftTabMenus" :class="[leftTabInfor.leftTabMenuSize, leftTabMenu.isActive ? 'active' : '']" @click="changeLeftTabMenu(tabIndex)">
                <a data-toggle="tab" :href="'#' + leftTabMenu.leftTabMenuLink" aria-expanded="true">{{ leftTabMenu.leftTabMenuName }}</a>
            </li>
        </ul>
        <div class="tab-content focusable col-xs-12 no-padding">
            <div id="sectionA" class="tab-pane fade" :class="leftTabInfor.leftTabMenus[0].isActive ? 'active in' : ''">
                <slot name="extendTop"></slot>
                <div v-if="leftTabInfor.renderLessonList" class="left-header" data-target=".lesson-list" data-toggle="collapse" :class="leftTabInfor.isCollapsed ? 'collapsed' : ''" @click="updateLeftTabInfor({ isLessonListCollapsed: !leftTabInfor.isLessonListCollapsed })">
                    <table style="width: 100%">
                        <tbody>
                            <tr>
                                <td width="95%">
                                    <h5><span>BÀI HỌC ĐÃ ĐĂNG KÝ</span></h5>
                                </td>
                                <td class="collapse-icon" width="5%">
                                    <font-awesome-icon v-if="leftTabInfor.isLessonListCollapsed" icon="chevron-up" pull="right" />
                                    <font-awesome-icon v-else icon="chevron-down" pull="right" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="leftTabInfor.renderLessonList" class="lesson-list collapse close-when-small" :class="leftTabInfor.isCollapsed ? '' : 'in'">
                    <table class="table table-left table-bordered table-click">
                        <!-- <thead>
                            <th width="30px"></th>
                            <th>Danh Mục</th>
                            <th width="50%">Nhóm</th>
                            <th width="30px">Xóa</th>
                        </thead> -->
                        <tbody v-if="leftTabInfor.isLessonListLoading">
                            <tr>
                                <td colspan="4">
                                    <content-loader viewBox="0 0 400 15" style="width: 100%; height: 100%" :speed="3" primaryColor="#aaa" secondaryColor="#888888">
                                        <circle cx="10" cy="7" r="7" />
                                        <rect x="6%" y="0" rx="5" ry="5" width="40%" height="15" />
                                        <rect x="50%" y="0" rx="5" ry="5" width="40%" height="15" />
                                        <rect x="94%" y="0" rx="5" ry="5" width="5%" height="15" />
                                    </content-loader>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr v-if="leftTabInfor.lessonLists.length > 0" v-for="lesson in leftTabInfor.lessonLists" :class="lesson.selected ? 'selected-row' : ''" @click="selectRegistedLesson(lesson)">
                                <td class="text-success text-center auto-resize">
                                    <font-awesome-icon icon="hand-point-right" />
                                </td>
                                <td class="text-success text-center">{{ lesson.catalogueName }}</td>
                                <td class="text-success text-center">{{ lesson.groupName }}</td>
                                <td class="text-center no-padding auto-resize">
                                    <button type="button" class="btn btn-sm btn-danger btn-del-lesson" @click="removeLesson(lesson.registedLessonId)">
                                        <font-awesome-icon icon="xmark" />
                                    </button>
                                </td>
                            </tr>
                            <tr v-else class="no-data">
                                <td>
                                    <font-awesome-icon icon="hand-point-right" />
                                </td>
                                <td colspan="3">Bạn chưa đăng nhập hoặc chưa đăng ký mục nào</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="leftTabInfor.renderMissionList" class="left-header" data-target=".mission" data-toggle="collapse" :class="leftTabInfor.isCollapsed ? 'collapsed' : ''" @click="updateLeftTabInfor({ isMissionListCollapsed: !leftTabInfor.isMissionListCollapsed })">
                    <table style="width: 100%">
                        <tbody>
                            <tr>
                                <td width="95%">
                                    <h5><span>BẢNG NHIỆM VỤ</span></h5>
                                </td>
                                <td class="collapse-icon" width="5%">
                                    <font-awesome-icon v-if="leftTabInfor.isMissionListCollapsed" icon="chevron-up" pull="right" />
                                    <font-awesome-icon v-else icon="chevron-down" pull="right" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="leftTabInfor.renderMissionList" class="mission collapse close-when-small" :class="leftTabInfor.isCollapsed ? '' : 'in'">
                    <table class="table table-left">
                        <tbody v-if="appInfor.isLoading">
                            <tr>
                                <td colspan="4">
                                    <content-loader viewBox="0 0 400 35" :speed="3" primaryColor="#aaa" secondaryColor="#888888">
                                        <circle cx="10" cy="8" r="8" />
                                        <rect x="6%" y="0" rx="5" ry="5" width="93%" height="15" />
                                        <circle cx="10" cy="27" r="8" />
                                        <rect x="6%" y="20" rx="5" ry="5" width="93%" height="15" />
                                    </content-loader>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr v-if="leftTabInfor.missionLists.length > 0" v-for="mission in leftTabInfor.missionLists">
                                <td>
                                    <a style="font-family: HapnaSlab" class="text-success btn-popup" popup-id="popup-box4" mission_id="mission.missionId" @click="showMissionPopup(mission.missionId)" type="button">
                                        <font-awesome-icon :icon="mission.missionIcon" :size="mission.missionIconSize" :[mission.missionTransistion]="mission.missionTransistion" />
                                        {{ mission.missionTitle }}
                                    </a>
                                </td>
                            </tr>
                            <tr v-else class="no-data">
                                <td>Bạn chưa đăng nhập hoặc không có nhiệm vụ nào</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="leftTabInfor.renderQuestionList" class="left-header" data-target=".left-tab-question" data-toggle="collapse" :class="leftTabInfor.isCollapsed ? 'collapsed' : ''" @click="updateLeftTabInfor({ isQuestionListCollapsed: !leftTabInfor.isQuestionListCollapsed })">
                    <table style="width: 100%">
                        <tbody>
                            <tr>
                                <td width="90%">
                                    <h5><span>VIỆT NAM - ĐẤT NƯỚC - CON NGƯỜI</span></h5>
                                </td>
                                <td class="collapse-icon" width="10%">
                                    <font-awesome-icon v-if="leftTabInfor.isQuestionListCollapsed" icon="chevron-up" pull="right" />
                                    <font-awesome-icon v-else icon="chevron-down" pull="right" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="leftTabInfor.renderQuestionList" class="collapse left-tab-question close-when-small" :class="[leftTabInfor.isCollapsed ? '' : 'in']">
                    <div v-if="appInfor.isLoading" class="question-content">
                        <content-loader viewBox="0 0 400 85" :speed="3" primaryColor="#aaa" secondaryColor="#888888">
                            <rect x="0" y="5" rx="5" ry="5" width="99%" height="15" />
                            <circle cx="7" cy="33" r="7" />
                            <rect x="5%" y="28" rx="5" ry="5" width="94%" height="10" />
                            <circle cx="7" cy="48" r="7" />
                            <rect x="5%" y="43" rx="5" ry="5" width="94%" height="10" />
                            <circle cx="7" cy="63" r="7" />
                            <rect x="5%" y="58" rx="5" ry="5" width="94%" height="10" />
                            <circle cx="7" cy="78" r="7" />
                            <rect x="5%" y="73" rx="5" ry="5" width="94%" height="10" />
                        </content-loader>
                    </div>
                    <div v-else class="question-content" :class="[leftTabInfor.currentQuestion.answerResult]">
                        <div v-if="!leftTabInfor.isLoading && leftTabInfor.questionLists.length > 0" class="question-box" :target-id="leftTabInfor.currentQuestion.rowId">
                            <label>Câu hỏi {{ ": " + leftTabInfor.currentQuestion.questionTitle }}</label>
                            <div v-if="leftTabInfor.currentQuestion.questionDiv == 0" class="answer-box">
                                <span class="result-icon">
                                    <font-awesome-icon v-if="leftTabInfor.currentQuestion.answerResult == 'wrong-answer'" icon="xmark" />
                                    <font-awesome-icon v-if="leftTabInfor.currentQuestion.answerResult == 'right-answer'" icon="check" />
                                </span>
                                <label v-for="answer in leftTabInfor.currentQuestion.answerLists" class="radio cool-link">
                                    <input type="radio" :name="'optradio' + answer.questionAnswerId" v-model="leftTabInfor.currentQuestion.userAnsweredList" :value="answer.questionAnswerId" />
                                    {{ answer.questionAnswer }}
                                </label>
                            </div>
                            <div v-else class="answer-box">
                                <span class="result-icon">
                                    <font-awesome-icon v-if="leftTabInfor.currentQuestion.answerResult == 'wrong-answer'" icon="xmark" />
                                    <font-awesome-icon v-if="leftTabInfor.currentQuestion.answerResult == 'right-answer'" icon="check" />
                                </span>
                                <label v-for="answer in leftTabInfor.currentQuestion.answerLists" class="checkbox cool-link">
                                    <input type="checkbox" :name="'optcheckbox' + answer.questionAnswerId" v-model="leftTabInfor.currentQuestion.userAnsweredList" :value="answer.questionAnswerId" />
                                    {{ answer.questionAnswer }}
                                </label>
                            </div>
                            <div v-if="leftTabInfor.currentQuestion.answerResult == 'right-answer' && leftTabInfor.isShowExplan" class="explan-content margin-bottom">{{ leftTabInfor.currentQuestion.explan }}</div>
                        </div>
                        <h5 v-if="!leftTabInfor.isLoading && leftTabInfor.questionLists.length == 0" class="text-center">Hiện chưa có câu hỏi nào trong hệ thống!</h5>
                        <div class="inline-block" :tooltip="callHelpers('getPermissionText', appInfor.userPermission.isCanAnswerLeftTabQuestion)" position="right">
                            <button v-if="!leftTabInfor.isShowExplan" class="btn btn-sm btn-primary margin-bottom" type="button" :disabled="appInfor.userPermission.isCanAnswerLeftTabQuestion != 1" @click="appInfor.userPermission.isCanAnswerLeftTabQuestion == '1' && checkQuestionAnswer()">Trả Lời</button>
                        </div>
                        <button v-if="leftTabInfor.isShowExplan" class="btn btn-sm btn-default margin-bottom" type="button" @click="initQuestion()">Tải Câu Hỏi Khác</button>
                    </div>
                    <div class="left-hint">
                        <h6>Trả lời câu hỏi để hiểu thêm về đất nước Việt Nam xinh đẹp và đạt được những phần thưởng bất ngờ!</h6>
                    </div>
                    <slot name="extendBottom"></slot>
                </div>
            </div>
            <div id="sectionB" class="tab-pane fade" :class="leftTabInfor.leftTabMenus[1].isActive ? 'active in' : ''">
                <div v-if="leftTabInfor.renderMyNotifyList" class="left-header" data-target=".newsfeed" data-toggle="collapse" :class="leftTabInfor.isCollapsed ? 'collapsed' : ''" @click="updateLeftTabInfor({ isMyNotifyListCollapsed: !leftTabInfor.isMyNotifyListCollapsed })">
                    <table style="width: 100%">
                        <tbody>
                            <tr>
                                <td width="95%">
                                    <h5><span>Thông Báo Của Bạn</span></h5>
                                </td>
                                <td class="collapse-icon" width="5%">
                                    <font-awesome-icon v-if="leftTabInfor.isMyNotifyListCollapsed" icon="chevron-up" pull="right" />
                                    <font-awesome-icon v-else icon="chevron-down" pull="right" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="leftTabInfor.renderMyNotifyList" class="newsfeed collapse close-when-small" :class="leftTabInfor.isCollapsed ? '' : 'in'">
                    <table class="table table-hover table-center">
                        <tbody>
                            <tr v-if="leftTabInfor.myNotifyLists.length > 0" v-for="myNotify in leftTabInfor.myNotifyLists">
                                <td>
                                    <a notify_id="myNotify.notifyId">
                                        <span :class="myNotify.notifyCondition == 0 ? 'active-notify' : ''">
                                            <font-awesome-icon icon="hand-point-right" />
                                            <span class="notify_content">{{ myNotify.accountName + (myNotify.notifyCount != 0 ? " và " + myNotify.notifyCount + " người khác " : " ") + myNotify.notifyContent }}</span>
                                        </span>
                                    </a>
                                </td>
                            </tr>
                            <tr v-else class="no-data">
                                <td>Bạn không có thông báo nào</td>
                            </tr>
                        </tbody>
                    </table>
                    <button class="btn btn-sm btn-default full-width btn-refresh">Làm mới thông báo</button>
                </div>
                <div v-if="leftTabInfor.renderGoldenBoardList" class="left-header" data-target=".top-rank" data-toggle="collapse" :class="leftTabInfor.isCollapsed ? 'collapsed' : ''" @click="updateLeftTabInfor({ isGoldenBoardListCollapsed: !leftTabInfor.isGoldenBoardListCollapsed })">
                    <table style="width: 100%">
                        <tbody>
                            <tr>
                                <td width="95%">
                                    <h5><span>Bảng Vàng</span></h5>
                                </td>
                                <td class="collapse-icon" width="5%">
                                    <font-awesome-icon v-if="leftTabInfor.isGoldenBoardListCollapsed" icon="chevron-up" pull="right" />
                                    <font-awesome-icon v-else icon="chevron-down" pull="right" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="leftTabInfor.renderGoldenBoardList" class="top-rank collapse close-when-small" :class="leftTabInfor.isCollapsed ? '' : 'in'">
                    <table class="table table-hover table-center">
                        <tbody>
                            <tr v-if="leftTabInfor.goldenBoardLists.length > 0" v-for="goldenBoard in leftTabInfor.goldenBoardLists" class="golden-row">
                                <td width="35px">
                                    <font-awesome-icon icon="certificate" size="2x" class="rank-icon" />
                                    <span class="rank_number">{{ goldenBoard.rankNumber }}</span>
                                </td>
                                <td class="text-left">
                                    <span>{{ goldenBoard.accountName }}</span>
                                </td>
                                <td width="50px">
                                    <span>{{ goldenBoard.eplusPoint }}</span>
                                </td>
                            </tr>
                            <tr v-else class="no-data">
                                <td>Bảng vàng đang trống</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="leftTabInfor.renderSystemNotifyList" class="left-header" data-target=".news" data-toggle="collapse" :class="leftTabInfor.isCollapsed ? 'collapsed' : ''" @click="updateLeftTabInfor({ isSystemNotifyListCollapsed: !leftTabInfor.isSystemNotifyListCollapsed })">
                    <table style="width: 100%">
                        <tbody>
                            <tr>
                                <td width="95%">
                                    <h5><span>Tin Tức Mới Nhất</span></h5>
                                </td>
                                <td class="collapse-icon" width="5%">
                                    <font-awesome-icon v-if="leftTabInfor.isSystemNotifyListCollapsed" icon="chevron-up" pull="right" />
                                    <font-awesome-icon v-else icon="chevron-down" pull="right" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="leftTabInfor.renderSystemNotifyList" class="news collapse close-when-small" :class="leftTabInfor.isCollapsed ? '' : 'in'">
                    <table class="table table-hover table-center">
                        <tbody>
                            <tr v-if="leftTabInfor.systemNotifyLists.length > 0" v-for="systemNotify in leftTabInfor.systemNotifyLists">
                                <td>
                                    <a>
                                        <span :class="systemNotify.notifyCondition == 0 ? 'active-notify' : ''">
                                            <font-awesome-icon icon="hand-point-right" />
                                            <span class="notify_content">{{ systemNotify.accountName + " " + systemNotify.notifyContent }}</span>
                                        </span>
                                    </a>
                                </td>
                            </tr>
                            <tr v-else class="no-data">
                                <td>Không có thông báo nào</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <ClientOnly>
        <PopupMission />
    </ClientOnly>
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import LeftTabMenu from '@/share-layout/menu';
import { missionPopupStore } from "@/components/popup/mission/store.js";

/**
 * import template
 */
// import template from './template.html';
import { appStore } from "@/store";
import { leftTabStore } from "./store";

/**
 * Define leftTab layout
 */
const LeftTab = {
    name: "LeftTab",
    // template: template,
    components: {
        // MissionPopup,
    },
    beforeCreate() {
        // if (!store.hasModule('leftTab')) {
        //     store.registerModule('leftTab', leftTabStore);
        // }
    },
    computed: {
        ...mapState(missionPopupStore, ["missionPopupInfor"]),
    },
    mounted() {
        this.$watch(
            () => ({
                questionLists: this.leftTabInfor.questionLists,
            }),
            () => {
                this.initQuestion();
            }
        );
        this.$watch(
            () => ({
                catalogueId: this.rightTabInfor.rightTabItems[0].value,
                groupId: this.rightTabInfor.rightTabItems[1].value,
            }),
            (data) => {
                this.updateSelectLesson({
                    catalogueId: data.catalogueId,
                    groupId: data.groupId,
                });
            }
        );
        this.$watch(
            () => ({
                isProfileTab: this.leftTabInfor.isProfileTab,
            }),
            (leftTab) => {
                // var dataId = "";
                if (leftTab.isProfileTab) {
                    this.leftTabInfor.leftTabMenus[0].isActive = false;
                    this.leftTabInfor.leftTabMenus[1].isActive = true;
                } else {
                    this.leftTabInfor.leftTabMenus[0].isActive = true;
                    this.leftTabInfor.leftTabMenus[1].isActive = false;
                }
            }
        );
    },
    methods: {
        ...mapActions(leftTabStore, ["checkQuestionAnswer", "initQuestion", "removeLesson", "selectRegistedLesson", "updateSelectLesson"]),
        ...mapActions(missionPopupStore, ["showMissionPopup"]),
        updateResizedScreen() {
            if (window.innerWidth < 1025) {
                this.updateLeftTabInfor({
                    isCollapsed: true,
                    isLessonListCollapsed: true,
                    isMissionListCollapsed: true,
                    isQuestionListCollapsed: true,
                    isMyNotifyListCollapsed: true,
                    isGoldenBoardListCollapsed: true,
                    isSystemNotifyListCollapsed: true,
                });
            }
        },
        changeLeftTabMenu(tabIndex) {
            this.updateLeftTabInfor({
                isProfileTab: tabIndex == 0 ? false : true,
            });
            // this.setLinkSelectItem('');
        },
    },
};
export default defineNuxtComponent(LeftTab);
</script>
<style lang="scss">
@import "./style.scss";
</style>
