<template>
    <div class="col-xs-12 no-padding slider-wrap">
        <div class="col-xs-12 audio-box margin-top margin-bottom">
            <ClientOnly>
                <music-player :startPoint="listeningContentInfor.audioStartPoint" :endPoint="listeningContentInfor.audioEndPoint" :currentTrackId="rightTabInfor.currentData.dataId" :trackList="listeningContentInfor.listeningList" />
                <span class="prev" @click="previousRightTabItem()" aria-label="Previous slide">
                    <span><font-awesome-icon icon="fa-solid fa-circle-chevron-left" /></span>
                </span>
                <span class="next" @click="nextRightTabItem()" aria-label="Next slide">
                    <span><font-awesome-icon icon="fa-solid fa-circle-chevron-right" /></span>
                </span>
            </ClientOnly>
        </div>
        <new-word :wordList="currentNewWordList" class="margin-bottom" />
        <div v-if="listeningContentInfor.isRerender" class="col-xs-12 no-padding panel-group no-margin" id="listen-list">
            <div class="panel panel-default">
                <div class="panel-heading collapsed" data-toggle="collapse" data-parent="#listen-list" href="#collapse0" aria-expanded="false" @click="toggleActiveSentence()">
                    <h5 class="panel-title">
                        <span><font-awesome-icon :icon="['fas', 'headphones-simple']" class="text-danger" /> Toàn bộ bài nghe</span>
                    </h5>
                </div>
                <div id="collapse0" class="panel-collapse collapse" aria-expanded="false" style="height: 0px">
                    <div class="input-group">
                        <div v-if="listeningContentInfor.isShowListeningContent" class="listening-content full-width" v-html="currentListeningData.listeningContent"></div>
                        <div class="listen-group-btn">
                            <div class="col-xs-6 no-padding" :tooltip="incompletedSentenceList.length > 0 ? callHelpers('getMessage', ['I005', [incompletedSentenceList.length]]) : ''">
                                <button class="btn btn-default full-width btn-sm btn-check-listen" type="button" :disabled="incompletedSentenceList.length > 0" @click="incompletedSentenceList.length == 0 && (listeningContentInfor.isShowListeningContent = !listeningContentInfor.isShowListeningContent)">{{ listeningContentInfor.isShowListeningContent ? "Ẩn" : "Hiện" }} Toàn Bộ Bài Nghe</button>
                            </div>
                            <div class="col-xs-6 btn-listen no-padding" type="button">
                                <button class="btn btn-default btn-sm col-xs-4 btn-check-listen" type="button" @click="seek(-1)"><font-awesome-icon :icon="['fas', 'backward']" /> 1s</button>
                                <button class="btn btn-default btn-sm col-xs-4 btn-check-listen" type="button" @click="play()">
                                    <font-awesome-icon v-if="!isTimerPlaying" :icon="['fas', 'play']" />
                                    <font-awesome-icon v-else :icon="['fas', 'pause']" />
                                </button>
                                <button class="btn btn-default btn-sm col-xs-4 btn-check-listen" type="button" @click="seek(1)"><font-awesome-icon :icon="['fas', 'forward']" /> 1s</button>
                            </div>
                        </div>
                    </div>
                    <!-- /input-group -->
                </div>
            </div>
            <div v-for="listeningSentence in currentListeningSentenceData" class="panel panel-default listen-cut-box">
                <div class="panel-heading collapsed" data-toggle="collapse" data-parent="#listen-list" :href="'#collapse' + listeningSentence.listeningSentenceId" aria-expanded="false" @click="toggleActiveSentence(listeningSentence)">
                    <h5 class="panel-title">
                        <span><font-awesome-icon :icon="['fas', 'headphones-simple']" class="text-danger" /> Phần {{ listeningSentence.listeningSentenceIndex }}</span>
                        <span v-if="listeningSentence.listeningSentenceDoneProcess != ''" class="float-right">
                            <span v-if="listeningSentence.listeningSentenceDoneStatus == 0" class="text-warning inline-block done-process">
                                <font-awesome-icon :icon="['fas', 'triangle-exclamation']" />
                                <span class="float-right">
                                    {{ listeningSentence.listeningSentenceDoneProcess + "%" }}
                                </span>
                            </span>
                            <span v-else class="text-success inline-block done-process">
                                <font-awesome-icon :icon="['fas', 'circle-check']" />
                                <span class="float-right">
                                    {{ listeningSentence.listeningSentenceDoneProcess + "%" }}
                                </span>
                            </span>
                        </span>
                    </h5>
                </div>
                <div :id="'collapse' + listeningSentence.listeningSentenceId" class="panel-collapse collapse" aria-expanded="false" style="height: 0px">
                    <input type="text" class="form-control input-sm" placeholder="Nội dung nghe được" v-model="listeningSentence.listeningSentenceUserInput" />
                    <div class="listen-group-btn">
                        <div class="col-xs-3 no-padding" :tooltip="listeningSentence.listeningSentenceDoneProcess < listeningContentInfor.minListenProcessDone ? callHelpers('getMessage', ['I006', [listeningContentInfor.minListenProcessDone]]) : ''" position="right">
                            <button class="btn btn-warning btn-sm full-width btn-show-answer" :disabled="listeningSentence.listeningSentenceDoneProcess < listeningContentInfor.minListenProcessDone" type="button" @click="listeningSentence.listeningSentenceDoneProcess >= listeningContentInfor.minListenProcessDone && (listeningSentence.listeningSentenceUserInput = listeningSentence.listeningSentenceContent)">Đáp án</button>
                        </div>
                        <button class="btn btn-primary btn-sm col-xs-3 btn-check-listen" type="button" @click="similarity(listeningSentence)">Kiểm tra</button>
                        <div class="col-xs-6 btn-listen no-padding" type="button">
                            <button class="btn btn-default btn-sm col-xs-4 btn-check-listen" type="button" @click="seek(-1)"><font-awesome-icon :icon="['fas', 'backward']" /> 1s</button>
                            <button class="btn btn-default btn-sm col-xs-4 btn-check-listen" type="button" @click="play()">
                                <font-awesome-icon v-if="!isTimerPlaying" :icon="['fas', 'play']" />
                                <font-awesome-icon v-else :icon="['fas', 'pause']" />
                            </button>
                            <button class="btn btn-default btn-sm col-xs-4 btn-check-listen" type="button" @click="seek(1)"><font-awesome-icon :icon="['fas', 'forward']" /> 1s</button>
                        </div>
                    </div>
                    <!-- /input-group -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/**
 * Import libraries
 */
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import MainMenu from '@/share-layout/menu';

/**
 * import template
 */
// import template from './template.html';
// import store from '@/store';
import { listeningContentStore } from "./store";
import { mapState, mapActions } from "pinia";
import { contributeVocabularyPopupStore } from "@/components/popup/contribute-vocabulary/store.js";
import { musicPlayerStore } from "@/components/music-player/store.js";

/**
 * Define main layout
 */
const ListeningContent = {
    name: "ListeningContent",
    // template: template,
    components: {},
    beforeCreate() {
        // if (!store.hasModule('listeningContent')) {
        //     store.registerModule('listeningContent', listeningContentStore);
        // }
    },
    computed: {
        ...mapState(listeningContentStore, ["listeningContentInfor", "currentListeningData", "currentListeningSentenceData", "currentNewWordList", "incompletedSentenceList"]),
        ...mapState(musicPlayerStore, ["isTimerPlaying"]),
    },
    mounted() {
        this.$watch(
            () => ({
                currentData: this.rightTabInfor.currentData,
            }),
            (rightTab) => {
                if (rightTab.currentData.dataId != undefined) {
                    this.listeningContentInfor.isShowListeningContent = false;
                    this.listeningContentInfor.audioStartPoint = null;
                    this.listeningContentInfor.audioEndPoint = null;
                    this.forceRender();
                }
            },
            {
                deep: true,
                immediate: true,
            }
        );
    },
    props: ["contentType"],
    data: function () {
        return {
            noImage: "/web-content/images/plugin-icon/no-image.jpg",
        };
    },
    methods: {
        ...mapActions(listeningContentStore, ["getVocabularyDataByIndex"]),
        ...mapActions(contributeVocabularyPopupStore, ["showContributeVocabularyPopup"]),
        ...mapActions(musicPlayerStore, ["play", "seek"]),
        playAudio(selectedAudio) {
            try {
                if (this.appInfor.playingAudio) {
                    this.appInfor.playingAudio.pause();
                    this.appInfor.playingAudio.currentTime = 0;
                }
                if (selectedAudio == "") {
                    return;
                }
                var audio = new Audio(this.appInfor.domain + selectedAudio); // Define audio file
                this.appInfor.playingAudio = audio;
                audio.play();
            } catch (error) {
                console.log("play audio: " + error);
            }
        },
        toggleActiveSentence(listeningSentence) {
            if (!listeningSentence || this.listeningContentInfor.activeSentenceId == listeningSentence.listeningSentenceId) {
                this.listeningContentInfor.activeSentenceId = null;
                this.listeningContentInfor.audioStartPoint = null;
                this.listeningContentInfor.audioEndPoint = null;
            } else {
                this.listeningContentInfor.activeSentenceId = listeningSentence.listeningSentenceId;
                this.listeningContentInfor.audioStartPoint = listeningSentence.listeningSentenceStart;
                this.listeningContentInfor.audioEndPoint = listeningSentence.listeningSentenceEnd;
            }
        },
        similarity(listeningSentence) {
            var s1 = listeningSentence.listeningSentenceContent
                .trim()
                .replace(/[&\/\\#,+()$~%.":*?<>{}]/g, "")
                .toLowerCase();
            var s2 = listeningSentence.listeningSentenceUserInput
                .trim()
                .replace(/[&\/\\#,+()$~%.":*?<>{}]/g, "")
                .toLowerCase();
            var longer = s1;
            var shorter = s2;
            if (s1.length < s2.length) {
                longer = s2;
                shorter = s1;
            }
            var longerLength = longer.length;
            if (longerLength == 0) {
                return 1.0;
            }
            listeningSentence.listeningSentenceDoneProcess = Math.floor(((longerLength - this.editDistance(longer, shorter)) / parseFloat(longerLength)) * 100);
            if (listeningSentence.listeningSentenceDoneProcess >= 80) {
                listeningSentence.listeningSentenceDoneStatus = 1;
            } else {
                listeningSentence.listeningSentenceDoneStatus = 0;
            }
            var activeData = this.rightTabInfor.rightTabLists.filter(function (el) {
                return el.isActive == true;
            });
            if (this.incompletedSentenceList.length == 0) {
                activeData[0].dataIcon = "circle-check";
            } else {
                activeData[0].dataIcon = "hand-point-right";
            }
            var unCompletedListening = this.rightTabInfor.rightTabLists.filter(function (el) {
                return el.dataIcon == "hand-point-right";
            });
            if (this.appInfor.isMissionMode && unCompletedListening.length == 0) {
                this.completeMission();
            }
        },
        editDistance(s1, s2) {
            s1 = s1.toLowerCase();
            s2 = s2.toLowerCase();

            var costs = new Array();
            for (var i = 0; i <= s1.length; i++) {
                var lastValue = i;
                for (var j = 0; j <= s2.length; j++) {
                    if (i == 0) costs[j] = j;
                    else {
                        if (j > 0) {
                            var newValue = costs[j - 1];
                            if (s1.charAt(i - 1) != s2.charAt(j - 1)) newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
                            costs[j - 1] = lastValue;
                            lastValue = newValue;
                        }
                    }
                }
                if (i > 0) costs[s2.length] = lastValue;
            }
            return costs[s2.length];
        },
        async forceRender() {
            this.listeningContentInfor.isRerender = false;
            await this.$nextTick();
            this.listeningContentInfor.isRerender = true;
        },
    },
};
export default defineNuxtComponent(ListeningContent);
</script>
<style lang="scss">
@import "./style.scss";
</style>
