import repository from "./repository";
import { discussStore } from "~~/pages/discuss/store.js";
export const contributeDiscussPopupStore = defineStore('contributeDiscussPopup', {
    namespaced: true,
    state: () => ({
        contributeDiscussPopupInfor: {
            isLoading: true,
            isSearching: false,
            showModal: false,
            showImageCropModal: false,
            modalTransition: 'bounce',
            popupType: 0,
            editor: null,
            noImage: '/web-content/images/plugin-icon/no-image.jpg',
            discussId: '',
            discussTitle: {
                value: '',
                isError: false,
                errorMessage: '',
            },
            discussContent: {
                value: '',
                isError: false,
                errorMessage: '',
            },
            discussTagList: {
                selected: [],
                isError: false,
                errorMessage: '',
                options: [],
            },
            rules: {
                'discussTitle.value': 'required',
                'discussContent.value': 'required',
            },
            currentDiscuss: {
                postId: '1',
                postAverageRating: '0',
                postViews: '100',
                postPublicDate: '2022/09/08',
                postAuthor: 'Quynd1',
                postUpdateDate: '2022/10/10',
                postTitle: 'Cách dùng quán từ xác định "The"1',
                postContent: 'ありがとうございます。',
            },
        }
    }),
    actions: {
        updateContributeDiscussPopupInfor(contributeDiscussPopupInfor) {
            var state = this;
            Object.keys(contributeDiscussPopupInfor).forEach(function (key) {
                state.contributeDiscussPopupInfor[key] = contributeDiscussPopupInfor[key];
            })
        },
        getContributeDiscussPopupInfor(discussId, popupType) {
            try {
                var state = this;
                var { $appStates } = useNuxtApp();
                var payload = {
                    post_id: discussId,
                    popup_type: popupType,
                    screen_div: "/discuss",
                };
                // state.contributeDiscussPopupInfor.editor.setContents('', 'silent');
                repository.getContributeDiscussPopup(payload)
                    .then((res) => {
                        var data = res.data.data;
                        // state.contributeDiscussPopupInfor.discussTagList.options = data[0];
                        state.contributeDiscussPopupInfor.targetId = data[1][0] ? data[1][0]['targetId'] : $appStates.rightTab.rightTabInfor.currentData.dataId;
                        state.contributeDiscussPopupInfor.discussId = data[1][0] ? data[1][0]['discussId'] : '';
                        state.contributeDiscussPopupInfor.discussTitle.value = data[1][0] ? data[1][0]['discussTitle'] : '';
                        state.contributeDiscussPopupInfor.discussContent.value = data[1][0] ? data[1][0]['discussContent'] : '';
                        // state.contributeDiscussPopupInfor.editor.setContents((data[1][0] ? data[1][0]['discussContent'] : ''), 'silent');
                        state.contributeDiscussPopupInfor.discussTagList.selected = data[2].map((item) => item.tagName);
                        state.contributeDiscussPopupInfor.popupType = popupType;
                        state.contributeDiscussPopupInfor.showModal = true;
                        return res;
                    });
            } catch (e) {
                console.log("Action getContributeDiscussPopupInfor: " + e.message);
            }
        },
        saveDiscussContribute() {
            try {
                var state = this;
                var { $appStates, $bus } = useNuxtApp();
                var discuss = discussStore();
                var paging = discuss.discussInfor.pagingList.filter((item) => item.dataId == $appStates.rightTab.rightTabInfor.currentData.dataId)[0];
                var page = paging ? paging['currentPage'] : 1;
                var payload = {
                    targetId: state.contributeDiscussPopupInfor.targetId,
                    discussId: state.contributeDiscussPopupInfor.discussId,
                    discussTitle: state.contributeDiscussPopupInfor.discussTitle.value,
                    discussContent: state.contributeDiscussPopupInfor.discussContent.value,
                    discussTagList: state.contributeDiscussPopupInfor.discussTagList.selected,
                    screen_div: localStorage.getItem("currentUrl"),
                    page: page,
                    page_size: 10,
                    popup_type: state.contributeDiscussPopupInfor.popupType,
                    records_num: discuss.discussInfor.numberOfNormalPost * discuss.discussInfor.extendTimesOfNormalPost,
                    followed_num: discuss.discussInfor.numberOfFollowedPost * discuss.discussInfor.extendTimesOfFollowedPost,
                };
                repository.saveDiscussContribute(payload)
                    .then((res) => {
                        if (res.status === 200) {
                            var data = res.data.data;
                            var _this = this;
                            // $appMethods.showDialog(2, 'S014', function () {
                            $bus.$emit("discussSaved", { data: data, popupType: state.contributeDiscussPopupInfor.popupType });
                            _this.contributeDiscussPopupInfor.showModal = false;
                            // })
                            return res;
                        }
                    });
            } catch (e) {
                console.log("Action saveContribute: " + e.message);
            }
        },
        uploadCropImage(imageDataUrl, field) {
            try {
                var payload = {
                    img: imageDataUrl,
                };
                repository.uploadCropImage(payload)
                    .then((res) => {
                        if (res.status === 200) {
                            this.contributeDiscussPopupInfor.displayWord.vocabularyImage = res.data.url;
                            return res;
                        }
                    });
            } catch (e) {
                console.log("Action saveDiscussList: " + e.message);
            }
        },
        // doMission() {
        //     try {
        //         var payload = {
        //             mission_id: this.contributeDiscussPopupInfor.missionId,
        //         };
        //         repository.doMission(payload)
        //             .then((res) => {
        //                 if (res.status === 200) {
        //                     var data = res.data.data;
        //                     var route = useRouter();
        //                     const { $appStates,$appMethods } = useNuxtApp();
        //                     this.updateContributeDiscussPopupInfor(data[1][0]);
        //                     route.push({ path: data[1][0].missionLink });
        //                     $appMethods.getAppState();
        //                     $appStates.app.appInfor.isReloadContent = true;
        //                     this.contributeDiscussPopupInfor.showModal = false;
        //                     return res;
        //                 }
        //             });
        //     } catch (e) {
        //         console.log("Action acceptMission: " + e.message);
        //     }
        // },
        // refuseMission() {
        //     try {
        //         const { $appStates,$appMethods } = useNuxtApp();
        //         var _this = this;
        //         $appMethods.showDialog(3, 'C008', function () {
        //             $appStates.dialogPopup.dialogPopupInfor.dialogButtons[0].buttonAction = function () {
        //                 var payload = {
        //                     mission_id: _this.contributeDiscussPopupInfor.missionId,
        //                 };
        //                 repository.refuseMission(payload)
        //                     .then((res) => {
        //                         if (res.status === 200) {
        //                             var data = res.data.data;
        //                             $appMethods.getAppState();
        //                             // $appStates.app.appInfor.isReloadContent = true;
        //                             _this.contributeDiscussPopupInfor.showModal = false;
        //                             $appMethods.hideDialog();
        //                             return res;
        //                         }
        //                     });
        //             }
        //         })
        //     } catch (e) {
        //         console.log("Action acceptMission: " + e.message);
        //     }
        // },
        // getNewsList(context, page) {
        //     context.commit('getNewsList', page);
        // },
        showContributeDiscussPopup(discussId, popupType) {
            discussId = discussId ?? '';
            popupType = popupType ?? 0;
            // this.updateContributeDiscussPopupInfor({
            //     popupType: popupType,
            //     showModal: true,
            // });
            this.getContributeDiscussPopupInfor(discussId, popupType);
        },
    }
});