import { appStore } from "@/store";
import repository from "./repository";
export const audioRecorderStore = defineStore('audioRecorder', {
    namespaced: true,
    state: () => ({
        audioRecorderInfor: {
            recorderTitle: 'Công Cụ Luyện Phát Âm',
            recorderHint: 'Thu âm bài phát âm của bạn để bắt đầu phân tích',
            instructionMessage: 'Xin nhấn vào micro để bắt đầu thu âm',
            instructionMessageStop: 'Xin nhấn thêm lần nữa để tạm dừng',
            errorMessage: 'Đã xảy ra lỗi khi sử dụng Micro.Bạn có thể làm mới trang và thử lại!',
            successMessage: 'Ghi âm thành công',
            successMessageSubmit: 'Đang phân tích phần phát âm của bạn...',
            errorSubmittingMessage: 'Đã có lỗi trong quá trình phân tích bài phát âm của bạn.Bạn có thể làm mới trang và thử lại!',
            buttonTitle: 'Đánh Giá Bài Phát Âm',
            recordedBlob: null,
            englishDiv: {
                selected: "en-US",
                isError: false,
                errorMessage: '',
                options: [
                    {
                        value: 'en-US',
                        text: "Anh - Mỹ",
                        voices: [
                            [
                                { value: "en-US-AIGenerate1Neural", text: "Giọng nam 1" },
                                { value: "en-US-BrandonNeural", text: "Giọng nam 2" },
                                { value: "en-US-ChristopherNeural", text: "Giọng nam 3" },
                                { value: "en-US-DavisNeural", text: "Giọng nam 4" },
                                { value: "en-US-EricNeural", text: "Giọng nam 5" },
                                { value: "en-US-GuyNeural", text: "Giọng nam 6" },
                                { value: "en-US-JacobNeural", text: "Giọng nam 7" },
                                { value: "en-US-JasonNeural", text: "Giọng nam 8" },
                                { value: "en-US-RogerNeural", text: "Giọng nam 9" },
                                { value: "en-US-SteffanNeural", text: "Giọng nam 10" },
                                { value: "en-US-TonyNeural", text: "Giọng nam 11" }
                            ],
                            [
                                { value: "en-US-AIGenerate2Neural", text: "Giọng nữ 1" },
                                { value: "en-US-AmberNeural", text: "Giọng nữ 2" },
                                { value: "en-US-AnaNeural", text: "Giọng nữ 3(thiếu nhi)" },
                                { value: "en-US-AriaNeural", text: "Giọng nữ 4" },
                                { value: "en-US-AshleyNeural", text: "Giọng nữ 5" },
                                { value: "en-US-CoraNeural", text: "Giọng nữ 6" },
                                { value: "en-US-ElizabethNeural", text: "Giọng nữ 7" },
                                { value: "en-US-JaneNeural", text: "Giọng nữ 8" },
                                { value: "en-US-JennyMultilingualNeural", text: "Giọng nữ 9" },
                                { value: "en-US-JennyNeural", text: "Giọng nữ 10" },
                                { value: "en-US-MichelleNeural", text: "Giọng nữ 11" },
                                { value: "en-US-MonicaNeural", text: "Giọng nữ 12" },
                                { value: "en-US-NancyNeural", text: "Giọng nữ 13" },
                                { value: "en-US-SaraNeural", text: "Giọng nữ 14" },
                            ]
                        ]
                    },
                    {
                        value: 'en-GB',
                        text: "Anh - Anh",
                        voices: [
                            [
                                { value: "en-GB-AlfieNeural", text: "Giọng nam 1" },
                                { value: "en-GB-ElliotNeural", text: "Giọng nam 2" },
                                { value: "en-GB-EthanNeural", text: "Giọng nam 3" },
                                { value: "en-GB-NoahNeural", text: "Giọng nam 4" },
                                { value: "en-GB-OliverNeural", text: "Giọng nam 5" },
                                { value: "en-GB-RyanNeural", text: "Giọng nam 6" },
                                { value: "en-GB-ThomasNeural", text: "Giọng nam 7" }
                            ],
                            [
                                { value: "en-GB-AbbiNeural", text: "Giọng nữ 1" },
                                { value: "en-GB-BellaNeural", text: "Giọng nữ 2" },
                                { value: "en-GB-HollieNeural", text: "Giọng nữ 3" },
                                { value: "en-GB-LibbyNeural", text: "Giọng nữ 4" },
                                { value: "en-GB-MaisieNeural", text: "Giọng nữ 5(thiếu nhi)" },
                                { value: "en-GB-OliviaNeural", text: "Giọng nữ 6" },
                                { value: "en-GB-SoniaNeural", text: "Giọng nữ 7" },
                            ]
                        ]
                    },
                ],
            },
            sexDiv: {
                selected: 0,
                isError: false,
                errorMessage: '',
                options: [
                    {
                        value: 0,
                        text: "Nam",
                    },
                    {
                        value: 1,
                        text: "Nữ",
                    },
                ],
            },
            voiceDiv: {
                selected: "",
                isError: false,
                errorMessage: '',
                options: [],
            },
            spellDiv: {
                selected: "IPA",
                isError: false,
                errorMessage: '',
                options: [
                    {
                        value: 'IPA',
                        text: "IPA",
                        example: "həˈləu",
                    },
                    {
                        value: 'SAPI',
                        text: "SAPI",
                        example: "he-loh",
                    },
                ],
            },
            audioText: "",
            markedAudioText: "",
            audioSpeechLink: "/web-content/audio/speechAudio/",
            audioTrueSpeechUrl: "",
            pronunciationAssessment: null,
            synthesis: null,
        }
    }),
    // mutations: {
    //     updateAudioRecorderInfor(state, audioRecorderInfor) {
    //         Object.keys(audioRecorderInfor).forEach(function (key) {
    //             state.audioRecorderInfor[key] = audioRecorderInfor[key];
    //         })
    //     },
    // },
    // getters: {
    //     remainQuestionIdList: state => state.audioRecorderInfor.remainQuestionIdList,
    // },
    actions: {
        updateAudioRecorderInfor(audioRecorderInfor) {
            var state = this;
            Object.keys(audioRecorderInfor).forEach(function (key) {
                state.audioRecorderInfor[key] = audioRecorderInfor[key];
            })
        },
        pronunciationAssessment() {
            var state = this;
            var voiceDiv = state.audioRecorderInfor.voiceDiv.selected;
            if (voiceDiv == "") {
                var selectedEnglishDiv = this.audioRecorderInfor.englishDiv.options.filter((val) => val.value == state.audioRecorderInfor.englishDiv.selected)[0];
                // this.audioRecorderInfor.voiceDiv.options = selectedEnglishDiv.voices[newValue.sexDiv];
                voiceDiv = selectedEnglishDiv.voices[state.audioRecorderInfor.sexDiv.selected][Math.floor(Math.random() * selectedEnglishDiv.voices.length)]['value'];
            }
            var formData = new FormData();
            formData.append("audio", state.audioRecorderInfor.recordedBlob);
            var payload = {
                userInfor: useCookie("eplus365user").value.account_id + "_" + useCookie("eplus365user").value.account_nm,
                englishDiv: state.audioRecorderInfor.englishDiv.selected,
                voiceDiv: voiceDiv,
                spellDiv: state.audioRecorderInfor.spellDiv.selected,
                audioText: state.audioRecorderInfor.audioText,
            };
            formData.append("payload", JSON.stringify(payload));
            try {
                repository.pronunciationAssessment(formData)
                    .then((res) => {
                        const { data } = res;
                        state.audioRecorderInfor.audioTrueSpeechUrl = data.synthesis.filePath;
                        state.audioRecorderInfor.pronunciationAssessment = data.pronunciationAssessment.data;
                        state.audioRecorderInfor.synthesis = data.synthesis.data;
                        //     if (res.status === 200) {
                        //     console.log(res);
                        //     // var data = res.data.data;
                        //     // state.conversationInfor.newsLists = data[0];
                        //     // state.conversationInfor.paging = data[1][0];
                        //     // state.conversationInfor.isLoading = false;
                        //     return res;
                        // }
                    });
            } catch (e) {
                console.log("Action getConversationInfor: " + e.message);
            }
        },
    }
});