export const sentenceAnalysisStore = defineStore('sentenceAnalysis', {
    namespaced: true,
    state: () => ({
        sentenceAnalysisInfor: {
            sentenceAnalysis: '',
            fullText: '',
            sentenceIndex: null,
            sentenceList: [],
            isCorrect: null,
            showText: '',
            showTextmean: '',
            showCharracters: [],
            showList: [],
            isShowBottomBar: false,
            lastScrollPosition: 0,
            componentTitle: 'Thử Thách Điền Từ',
            currentText: "",
            isCanEdit: false,
            isEditMode: false,
        }
    }),
    mutations: {
        updateSentenceAnalysisInfor(state, sentenceAnalysisInfor) {
            Object.keys(sentenceAnalysisInfor).forEach(function (key) {
                state.sentenceAnalysisInfor[key] = sentenceAnalysisInfor[key];
            })
        },
    },
    // getters: {
    //     remainQuestionIdList: state => state.sentenceAnalysisInfor.remainQuestionIdList,
    // },
    actions: {
        updateSentenceAnalysisInfor(sentenceAnalysisInfor) {
            var state = this;
            Object.keys(sentenceAnalysisInfor).forEach(function (key) {
                state.sentenceAnalysisInfor[key] = sentenceAnalysisInfor[key];
            })
        },
        checkSentenceAnalysisAnswer() {
            var { $callHelpers, $appStates, $appMethods } = useNuxtApp();
            var result = {
                status: 0,
                message: ''
            };
            //delete all none utf-8 charracter,space then uppercase to compare
            if ($callHelpers('cleanString', [this.sentenceAnalysisInfor.filledText]).replace(/\s/g, "").toUpperCase() === $callHelpers('cleanString', [this.sentenceAnalysisInfor.showText]).replace(/\s/g, "").toUpperCase()) {
                result.status = 1;
                result.message = 'nhap chinh xac';
                this.sentenceAnalysisInfor.isCorrect = true;
            } else {
                result.status = 0;
                result.message = 'chua nhap dung';
                this.sentenceAnalysisInfor.isCorrect = false;
            }
            return result;
        }
        // backToTop() {
        // 	window.scrollTo({
        // 		top: 0,
        // 		left: 0,
        // 		behavior: "smooth",
        // 	});
        //     // setTimeout(() => {
        //     //     context.commit('updateSentenceAnalysisInfor',{
        //     //         isShowBottomBar:false
        //     //     });
        //     // }, 500);
        // },
        // showSentenceAnalysis(){
        //     var app = appStore();
        // 	this.updateSentenceAnalysisInfor({
        //         isShowBottomBar:app.appInfor.isMobile
        //     });
        // },
    }
});