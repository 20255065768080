import { appStore } from "@/store";

export const fieldsLinkerStore = defineStore('fieldsLinker', {
    namespaced: true,
    state: () => ({
        fieldsLinkerInfor: {
            fieldsLinker: {},
            showList: [],
            isShowBottomBar: false,
            lastScrollPosition: 0,
            componentTitle: 'Thử Thách Nối Từ',
        }
    }),
    mutations: {
        updateFieldsLinkerInfor(state, fieldsLinkerInfor) {
            Object.keys(fieldsLinkerInfor).forEach(function (key) {
                state.fieldsLinkerInfor[key] = fieldsLinkerInfor[key];
            })
        },
    },
    // getters: {
    //     remainQuestionIdList: state => state.fieldsLinkerInfor.remainQuestionIdList,
    // },
    actions: {
        updateFieldsLinkerInfor(fieldsLinkerInfor) {
            var state = this;
            Object.keys(fieldsLinkerInfor).forEach(function (key) {
                state.fieldsLinkerInfor[key] = fieldsLinkerInfor[key];
            })
        },
        checkFieldsLinkerAnswer() {
            var _this = this;
            var count = 0;
            var result = {
                status: 0,
                message: ''
            };
            var answeredList = _this.fieldsLinkerInfor.fieldsLinker.work.getLinks().links;
            var { $callHelpers, $appStates } = useNuxtApp();
            if (answeredList.length != 0) {
                answeredList.forEach(function (answer) {
                    var filteredList = _this.fieldsLinkerInfor.showList.filter((a) => a.vocabularyName == answer.from && a.vocabularyMean == answer.to);
                    if (filteredList.length > 0) {
                        count++;
                    }
                })
            }
            switch (answeredList.length) {
                case 0:
                    result.status = 0;
                    result.message = 'chua noi cai nao';
                    break;
                case count:
                    if (count == this.fieldsLinkerInfor.showList.length) {
                        var messageParams = [
                            'S013',
                            [
                                this.fieldsLinkerInfor.componentTitle,
                            ]
                        ];
                        result.status = 1;
                        result.message = $callHelpers('getMessage', messageParams);
                    } else {
                        var messageParams = [
                            'W004',
                            [
                                count,
                                this.fieldsLinkerInfor.showList.length,
                            ]
                        ];
                        result.status = 2;
                        result.message = $callHelpers('getMessage', messageParams);
                    }
                    break;
                default:
                    var messageParams = [
                        'W004',
                        [
                            count,
                            this.fieldsLinkerInfor.showList.length,
                        ]
                    ];
                    result.status = 2;
                    result.message = $callHelpers('getMessage', messageParams);
                    break;
            }
            return result;
        }
        // backToTop() {
        // 	window.scrollTo({
        // 		top: 0,
        // 		left: 0,
        // 		behavior: "smooth",
        // 	});
        //     // setTimeout(() => {
        //     //     context.commit('updateFieldsLinkerInfor',{
        //     //         isShowBottomBar:false
        //     //     });
        //     // }, 500);
        // },
        // showFieldsLinker(){
        //     var app = appStore();
        // 	this.updateFieldsLinkerInfor({
        //         isShowBottomBar:app.appInfor.isMobile
        //     });
        // },
    }
});