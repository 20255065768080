<script>
// @ is an alias to /src
import { mapState, mapActions } from "pinia";
// import exerciseContent from "@/components/exercise";
// import exampleContent from "@/components/example";
// import PostContent from "@/share-layout/post-content";
import { leftTabStore } from "@/components/left-tab/store.js";
// import rightTab from "@/share-layout/right-tab";
// import mainPagingStore from "@/components/paging/store.js";
import { fixedBottomBarStore } from "@/components/fixed-bottom-bar/store.js";
import { headerErrorBarStore } from "@/components/header-error-bar/store.js";
import { ContentLoader } from "vue-content-loader";

import { appStore } from "@/store/index.js";
// import store from "@/store";
import { postContentStore } from "@/components/post-content/store";
import { rightTabStore } from "@/components/right-tab/store.js";
import { exampleStore } from "@/components/example/store";
import { wordRelationshipStore } from "@/components/word-relationship/store";
import { exerciseStore } from "@/components/exercise/store.js";
import { dialogPopupStore } from "@/components/popup/dialog/store.js";
import { dictionaryStore } from "@/pages/dictionary/store.js";
// import fixedBottomBarStore from "@/components/fixed-bottom-bar/store";
// import headerErrorBarStore from "@/components/header-error-bar/store";
import helpers from "@/utils/helpers.js";

export default {
	beforeCreate() {
		// if (!store.hasModule("postContent")) {
		// 	store.registerModule("postContent", postContentStore);
		// }
		// if (!store.hasModule("example")) {
		// 	store.registerModule("example", exampleContentStore);
		// }
		// if (!store.hasModule("rightTab")) {
		// 	store.registerModule("rightTab", rightTabStore);
		// }
		// if (!store.hasModule("exercise")) {
		// 	store.registerModule("exercise", exerciseContentStore);
		// }
		// if (!store.hasModule("fixedBottomBar")) {
		// 	store.registerModule("fixedBottomBar", fixedBottomBarStore);
		// }
		// if (!store.hasModule("headerErrorBar")) {
		// 	store.registerModule("headerErrorBar", headerErrorBarStore);
		// }
	},
	created() {
		this.$watch(
			() => ({
				paging: this.appInfor.paging,
				page: this.appInfor.page,
			}),
			(app) => {
				this.updatePagingContent(app.paging, app.page);
			}
		);
		this.$watch(
			() => ({
				isLogined: this.appInfor.isLogined,
			}),
			(data) => {
				this.refreshScreen();
			}
		);
		this.$watch(
			() => ({
				isReloadContent: this.appInfor.isReloadContent,
			}),
			(data) => {
				if(data.isReloadContent){
					this.refreshScreen();
				}
			}
		);
		this.$watch(
			() => ({
				isRightTabDataChange: this.appInfor.isRightTabDataChange,
			}),
			(data) => {
				if(data.isRightTabDataChange){
					this.changeContentFollowRightTab();
				}
			}
		);
	},
	unmounted() {
		window.removeEventListener("resize", this.updateResizedScreen);
		window.removeEventListener("scroll", this.updateWhenScroll);
	},
	mounted() {
		window.addEventListener("resize", this.updateResizedScreen);
		window.addEventListener("scroll", this.updateWhenScroll);
		this.updateResizedScreen();
	},
	components: {
		ContentLoader,
		// PostContent,
		// exerciseContent,
		// exampleContent,
		// leftTab,
		// rightTab,
		// mainPaging,
		// fixedBottomBar,
		// headerErrorBar,
	},

	computed: {
		...mapState(appStore, ["appInfor"]),
		...mapState(leftTabStore, ["leftTabInfor"]),
		...mapState(rightTabStore, ["rightTabInfor"]),
		// ...mapState("menu", ["menuInfor"]),
		...mapState(postContentStore, ["postContentInfor"]),
		...mapState(dialogPopupStore, ["dialogPopupInfor"]),
	},
	methods: {
		...mapActions(appStore, ["updateAppInfor", "getPermissionText","getAppState","completeMission"]),
		...mapActions(leftTabStore, ["updateLeftTabInfor"]),
		...mapActions(dictionaryStore, ["voteDictionaryWord"]),
		...mapActions(rightTabStore, [
			"updateRightTabInfor",
			"selectRightTabItem",
			"nextRightTabItem",
			"previousRightTabItem",
			"updateRightTabItems",
			"setLinkSelectItem",
		]),
		...mapActions(exerciseStore, ["updateExerciseInfor", "initQuestion"]),
		...mapActions(exampleStore, ["updateExampleInfor","getExample"]),
		...mapActions(wordRelationshipStore, ["updateWordRelationshipInfor"]),
		...mapActions(fixedBottomBarStore, ["backToTop","showFixedBottomBar"]),
		...mapActions(headerErrorBarStore, ["updateHeaderErrorBarInfor"]),
		...mapActions(dialogPopupStore, ["showDialog","hideDialog"]),
		updateResizedScreen() {},
		updateWhenScroll() {},
		/* eslint-disable */
		updatePagingContent(paging, page) {},
		// /* eslint-enable */
		refreshScreen() {},
		changeContentFollowRightTab() {},
		callHelpers(helperName, params) {
			//apply params to function as array
			params = params ?? [];
			params = typeof params == "string" ? [params] : params;
			return helpers[helperName].apply(null, params);
		},
		getToken(tokenName) {
			return useCookie(tokenName);
		},
		// touchEventHandle(){
		// 	console.log(1);
		// },
	},
};
</script>
