import repository from "@/repository";
import { rightTabStore } from "../right-tab/store";

export const leftTabStore = defineStore('leftTab', {
    namespaced: true,
    state: () => ({
        leftTabInfor: {
            leftTabSize: 'col-lg-3 col-xs-12',
            isCollapsed: true,
            isProfileTab: false,
            leftTabMenus: [
                {
                    leftTabMenuLink: 'sectionA',
                    leftTabMenuName: 'Góc Học Tập',
                    isActive: true
                },
                {
                    leftTabMenuLink: 'sectionB',
                    leftTabMenuName: 'ndq809',
                    isActive: false
                },
            ],
            renderLessonList: false,
            isLessonListCollapsed: false,
            isLessonListLoading: true,
            lessonLists: [
                {
                    selected: false,
                    registedLessonId: '1',
                    catalogueName: 'catalogueName',
                    groupName: 'groupName',
                }
            ],
            renderMissionList: true,
            isMissionListCollapsed: false,
            missionLists: [
                {
                    missionId: '1',
                    missionCondition: '1',
                    missionTitle: 'missionTitle',
                    missionIcon: 'bullseye',
                    missionIconSize: 'lg',
                    missionTransistion: 'spin',
                }
            ],
            renderQuestionList: true,
            isQuestionListCollapsed: false,
            answeredLists: [],
            remainQuestionIdList: [],
            currentQuestion: [],
            isShowExplan: false,
            questionLists: [
                {
                    rowId: '1',
                    questionId: '1',
                    questionTitle: 'Đại tướng Võ Nguyên Giáp sinh ra ở đâu',
                    answerResult: '',
                    questionDiv: 0,
                    explan: 'xyz',
                    userAnsweredList: [],
                    answerLists: [
                        {
                            questionAnswerId: '1',
                            questionAnswer: 'Hà Nội',
                            isTrueAnswer: false,
                        },
                        {
                            questionAnswerId: '2',
                            questionAnswer: 'Quảng Bình',
                            isTrueAnswer: true,
                        },
                        {
                            questionAnswerId: '3',
                            questionAnswer: 'Thái Nguyên',
                            isTrueAnswer: false,
                        },
                        {
                            questionAnswerId: '4',
                            questionAnswer: 'Quảng Trị',
                            isTrueAnswer: false,
                        },
                    ],
                },
            ],
            renderMyNotifyList: true,
            isMyNotifyListCollapsed: false,
            myNotifyLists: [
                {
                    notifyId: '1',
                    notifyCondition: '0',
                    notifyOwnerUser: '0',
                    accountName: 'kirito',
                    notifyCount: '100',
                    notifyContent: 'abcsdasd',
                    notifyUrl: 'abcsdasd',
                    notifyCreateDate: 'abcsdasd',
                }
            ],
            renderGoldenBoardList: true,
            isGoldenBoardListCollapsed: false,
            goldenBoardLists: [
                {
                    rankNumber: '1',
                    accountId: 'kirito',
                    accountName: 'kirito',
                    eplusPoint: '2114',
                }
            ],
            renderSystemNotifyList: true,
            isSystemNotifyListCollapsed: false,
            systemNotifyLists: [
                {
                    notifyId: '1',
                    notifyCondition: '0',
                    notifyOwnerUser: '0',
                    accountName: 'kirito',
                    notifyCount: '100',
                    notifyContent: 'abcsdasd',
                    notifyUrl: 'abcsdasd',
                    notifyCreateDate: 'abcsdasd',
                    targetId: '',
                }
            ],
        }
    }),
    getters: {
        remainQuestionIdList: state => state.leftTabInfor.remainQuestionIdList,
    },
    actions: {
        updateLeftTabInfor(leftTabInfor) {
            var state = this;
            Object.keys(leftTabInfor).forEach(function (key) {
                state.leftTabInfor[key] = leftTabInfor[key];
            })
        },
        updateQuestionLists(sqlQuestionLists) {
            var state = this;
            var questionLists = state.leftTabInfor.questionLists;
            questionLists = sqlQuestionLists['question'];
            Object.keys(questionLists).forEach(function (key) {
                var questionAnswer = sqlQuestionLists['answer'].filter(function (el) {
                    return el.questionId == questionLists[key].questionId;
                });
                questionLists[key]['answerLists'] = questionAnswer;
            })
            state.leftTabInfor.questionLists = questionLists;
        },
        checkQuestionAnswer() {
            var state = this;
            var question = state.leftTabInfor.currentQuestion;
            if (question.questionDiv == 0) {
                var userAnswered = question.answerLists.filter(function (el) {
                    return el.questionAnswerId == Number(question.userAnsweredList);
                });
                //if user do not checked any answer-->skip
                if (userAnswered[0] === undefined) {
                    return;
                }
                //if user checked any answer-->check
                if (userAnswered[0].isTrueAnswer) {
                    question.answerResult = 'right-answer';
                    setTimeout(() => {
                        state.leftTabInfor.isShowExplan = true;
                    }, 500);
                } else {
                    question.answerResult = 'wrong-answer';
                    state.leftTabInfor.isShowExplan = false;
                }
            } else {
                //if user do not checked any answer-->skip
                if (question.userAnsweredList.length == 0) {
                    return;
                }
                //if user checked any answer-->check
                //get true answer object
                var filtered = question.answerLists.filter(function (el) {
                    return el.isTrueAnswer == true
                });
                //get true answer rowId list
                var trueAnswerList = filtered.flatMap((object) => object.questionAnswerId);
                //compare true answer rowId list with user answered list
                if (JSON.stringify(trueAnswerList) == JSON.stringify(question.userAnsweredList)) {
                    question.answerResult = 'right-answer';
                    setTimeout(() => {
                        state.leftTabInfor.isShowExplan = true;
                    }, 500);
                } else {
                    question.answerResult = 'wrong-answer';
                    state.leftTabInfor.isShowExplan = false;
                }
            }
        },
        initQuestion() {
            if (this.remainQuestionIdList.length !== 0) {
                this.updateQuestion();
            } else {
                this.refreshQuestionList();
            }
        },
        updateQuestion() {
            var state = this;
            state.leftTabInfor.answeredLists = [];
            state.leftTabInfor.answerResult = '';
            state.leftTabInfor.isShowExplan = false;
            var questionLists = state.leftTabInfor.questionLists;
            state.leftTabInfor.currentQuestion = questionLists.filter(function (el) {
                return el.questionId == state.leftTabInfor.remainQuestionIdList[0].questionId;
            })[0];
            state.leftTabInfor.remainQuestionIdList.shift();
        },
        refreshQuestionList() {
            try {
                var state = this;
                repository.refreshQuestionList({
                    // screenid: state.screenId,
                })
                    .then((res) => {
                        // const { data } = res;
                        if (res.status === 200) {
                            var data = res.data.data;
                            state.leftTabInfor.currentQuestion = [];
                            state.leftTabInfor.remainQuestionIdList = data[0];
                            this.updateQuestionLists({
                                question: data[0].slice(),
                                answer: data[1].slice(),
                            });
                            return res;
                        }
                    });
            } catch (e) {
                console.log("Action refreshQuestionList: " + e.message);
            }
        },
        removeLesson(lessonId) {
            var state = this;
            const { $appStates,$appMethods } = useNuxtApp();
            $appMethods.showDialog(3,'W001',function(){
                $appStates.dialogPopup.dialogPopupInfor.dialogButtons[0].buttonAction = function(){
                    var payload = {
                        lession_id: lessonId,
                    };
                    try {
                        repository.removeLesson(payload)
                            .then((res) => {
                                // const { data } = res;
                                if (res.status === 200) {
                                    var index = state.leftTabInfor.lessonLists.map(function (el) { return el.registedLessonId; }).indexOf(lessonId);
                                    state.leftTabInfor.lessonLists.splice(index, 1);
                                    $appStates.dialogPopup.dialogPopupInfor.showDialog = false;
                                    return res;
                                }
                            });
                    } catch (e) {
                        console.log("Action removeLesson: " + e.message);
                    }
                }
            })
        },
        selectRegistedLesson(lesson) {
            var rightTab = rightTabStore();
            rightTab.updateRightTabItems([
                {
                    value: lesson.catalogueId,
                },
                0,
            ]);
            rightTab.updateRightTabItems([
                {
                    value: lesson.groupId,
                },
                1,
            ]);
        },
        updateSelectLesson(payload) {
            var state = this;
            state.leftTabInfor.lessonLists.map(function (lesson) {
                lesson.selected = false;
                return lesson
            });
            var lesson = state.leftTabInfor.lessonLists.filter(function (el) {
                return el.catalogueId == payload.catalogueId && el.groupId == payload.groupId;
            })[0];
            var index = state.leftTabInfor.lessonLists.indexOf(lesson);
            if (index != -1) { //value exists
                state.leftTabInfor.lessonLists[index].selected = true;
            }
        },
    }
});