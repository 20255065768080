<template>
    <div class="col-md-4 col-md-push-8 col-xs-12 right-tab no-padding">
        <slot name="extendTop"></slot>
        <template v-if="rightTabInfor.isDisplaySelect">
            <div v-if="rightTabInfor.isLoading" class="col-md-12 no-padding select-group">
                <content-loader viewBox="0 0 400 105" style="width: 100%; height: 100%" :speed="3" primaryColor="#aaa" secondaryColor="#888888">
                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="32" />
                    <rect x="0" y="35" rx="5" ry="5" width="100%" height="32" />
                    <rect x="0" y="70" rx="5" ry="5" width="100%" height="32" />
                </content-loader>
            </div>
            <div v-else class="col-md-12 no-padding select-group">
                <div class="form-group margin-bottom" v-tooltip="rightTabInfor.rightTabItems[0].isError ? rightTabInfor.rightTabItems[0].errorMessage : ''">
                    <v-select :placeholder="rightTabInfor.rightTabItems[0].itemName" :options="rightTabInfor.rightTabItems[0].selectItems" label="catalogueName" :reduce="(item) => item.catalogueId" v-model="rightTabInfor.rightTabItems[0].value" :class="rightTabInfor.rightTabItems[0].isError ? 'input-error' : ''">
                        <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching"> Không có kết quả trùng khớp. </template>
                            <template v-else>
                                <em style="opacity: 0.5">Nhập từ khóa để tìm kiếm.</em>
                            </template>
                        </template>
                    </v-select>
                </div>
                <div class="form-group margin-bottom" v-tooltip="rightTabInfor.rightTabItems[1].isError ? rightTabInfor.rightTabItems[1].errorMessage : ''">
                    <v-select :placeholder="rightTabInfor.rightTabItems[1].itemName" :options="displaySelectItems" label="groupName" :reduce="(item) => item.groupId" v-model="rightTabInfor.rightTabItems[1].value" :class="rightTabInfor.rightTabItems[1].isError ? 'input-error' : ''">
                        <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching"> Không có kết quả trùng khớp. </template>
                            <template v-else>
                                <em style="opacity: 0.5">Nhập từ khóa để tìm kiếm.</em>
                            </template>
                        </template>
                    </v-select>
                </div>
                <div v-if="rightTabInfor.rightTabLists.length > 0" v-tooltip="callHelpers('getPermissionText', appInfor.userPermission.isCanRegisterLesson)">
                    <button class="btn btn-sm btn-primary full-width" :disabled="appInfor.userPermission.isCanRegisterLesson != 1" @click="appInfor.userPermission.isCanRegisterLesson && registerLesson()">Lưu bài học này</button>
                </div>
            </div>
        </template>
        <template v-if="rightTabInfor.rightTabLists.length > 0">
            <ul class="nav nav-tabs col-xs-12 no-padding">
                <li class="no-padding inline-block" v-for="(rightTabMenu, tabIndex) in rightTabInfor.rightTabMenus" :class="[rightTabMenu.isActive ? 'active' : '', rightTabInfor.rightTabMenus.length == 1 ? 'col-xs-12' : 'col-xs-6']" @click="changeTabMenu(tabIndex)">
                    <a class="text-center" data-toggle="tab" :href="'#' + rightTabMenu.rightTabMenuLink" aria-expanded="true">{{ rightTabMenu.rightTabMenuName }}</a>
                </li>
            </ul>
            <div class="tab-content focusable col-xs-12 no-padding" id="result1">
                <div id="tab1" class="tab-pane fade" :style="{ height: 'calc(100vh - ' + (appInfor.footerHeight + 274) + 'px)' }" :class="rightTabInfor.rightTabMenus[0].isActive ? 'active in' : ''">
                    <table class="table table-right">
                        <template v-if="rightTabInfor.isLoading">
                            <tbody class="loading-tbody">
                                <tr>
                                    <td colspan="2">
                                        <content-loader viewBox="0 0 400 15" style="width: 100%; height: 25px" :speed="3" primaryColor="#aaa" secondaryColor="#888888">
                                            <circle cx="10" cy="7" r="7" />
                                            <rect x="6%" y="0" rx="5" ry="5" width="75%" height="15" />
                                            <rect x="84%" y="0" rx="5" ry="5" width="15%" height="15" />
                                        </content-loader>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <content-loader viewBox="0 0 400 15" style="width: 100%; height: 25px" :speed="3" primaryColor="#aaa" secondaryColor="#888888">
                                            <circle cx="10" cy="7" r="7" />
                                            <rect x="6%" y="0" rx="5" ry="5" width="75%" height="15" />
                                            <rect x="84%" y="0" rx="5" ry="5" width="15%" height="15" />
                                        </content-loader>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <content-loader viewBox="0 0 400 15" style="width: 100%; height: 25px" :speed="3" primaryColor="#aaa" secondaryColor="#888888">
                                            <circle cx="10" cy="7" r="7" />
                                            <rect x="6%" y="0" rx="5" ry="5" width="75%" height="15" />
                                            <rect x="84%" y="0" rx="5" ry="5" width="15%" height="15" />
                                        </content-loader>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                        <template v-else>
                            <tbody v-if="rightTabInfor.unRememberedTabLists.length > 0" v-for="rightTabList in rightTabInfor.unRememberedTabLists" :class="rightTabList.isActive ? 'activeItem' : ''" :id="rightTabList.dataId" @click="setLinkSelectItem(rightTabList.dataId)">
                                <tr>
                                    <td rowspan="2" :ref="rightTabList.dataId">
                                        <span class="radio-inline btn-link"
                                            ><font-awesome-icon :icon="rightTabList.dataIcon" /> <span> {{ rightTabInfor.isEncrypt ? rightTabList.dataTitleEncrypt ?? "" : rightTabList.dataTitle }}</span>
                                        </span>
                                    </td>
                                    <template v-if="rightTabInfor.isDisplayButton">
                                        <td rowspan="2" class="auto-resize" :tooltip="callHelpers('getPermissionText', appInfor.userPermission.isCanBookmark)" position="left">
                                            <button class="btn btn-sm btn-primary btn-intr" @click="appInfor.userPermission.isCanBookmark && bookmarkData(rightTabList)" :disabled="appInfor.userPermission.isCanBookmark != 1">{{ rememberText ?? "Đã học" }}</button>
                                        </td>
                                    </template>
                                    <template v-if="rightTabInfor.isDisplayVote">
                                        <td class="auto-resize no-padding has-border" :tooltip="callHelpers('getPermissionText', appInfor.userPermission.isCanVoteForDictonaryWord)" position="left">
                                            <button class="vote-down btn btn-sm btn-default" @click="appInfor.userPermission.isCanVoteForDictonaryWord && voteDictionaryWord({ dictionary: rightTabList, voteValue: -1 })" :disabled="appInfor.userPermission.isCanVoteForDictonaryWord != 1">
                                                <font-awesome-icon icon="down-long" :class="rightTabList.myVote == -1 ? 'active' : ''" />
                                            </button>
                                        </td>
                                        <td class="auto-resize no-padding has-border" :tooltip="callHelpers('getPermissionText', appInfor.userPermission.isCanVoteForDictonaryWord)" position="left">
                                            <button class="vote-up btn btn-sm btn-default" @click="appInfor.userPermission.isCanVoteForDictonaryWord && voteDictionaryWord({ dictionary: rightTabList, voteValue: 1 })" :disabled="appInfor.userPermission.isCanVoteForDictonaryWord != 1">
                                                <font-awesome-icon icon="up-long" :class="rightTabList.myVote == 1 ? 'active' : ''" />
                                            </button>
                                        </td>
                                    </template>
                                </tr>
                                <template v-if="rightTabInfor.isDisplayVote">
                                    <tr>
                                        <td colspan="2" class="no-padding has-border">
                                            <div class="text-center">
                                                <span style="font-family: Jersey" class="rating-value">{{ rightTabList.totalVote }}</span>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                            <tbody v-else>
                                <tr id="-100" class="no-row activeItem">
                                    <td colspan="2">
                                        <span class="radio-inline btn-link"><i class="glyphicon glyphicon-exclamation-sign"> </i> <span> Không có dữ liệu !</span> </span>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </table>
                </div>
                <template v-if="rightTabInfor.rightTabMenus.length > 1">
                    <div id="tab2" class="tab-pane fade" :style="{ height: 'calc(100vh - ' + (appInfor.footerHeight + 274) + 'px)' }" :class="rightTabInfor.rightTabMenus[1].isActive ? 'active in' : ''">
                        <table class="table table-right">
                            <template v-if="rightTabInfor.isLoading">
                                <tbody class="loading-tbody">
                                    <tr>
                                        <td colspan="2">
                                            <content-loader viewBox="0 0 400 15" style="width: 100%; height: 25px" :speed="3" primaryColor="#aaa" secondaryColor="#888888">
                                                <circle cx="10" cy="7" r="7" />
                                                <rect x="6%" y="0" rx="5" ry="5" width="75%" height="15" />
                                                <rect x="84%" y="0" rx="5" ry="5" width="15%" height="15" />
                                            </content-loader>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <content-loader viewBox="0 0 400 15" style="width: 100%; height: 25px" :speed="3" primaryColor="#aaa" secondaryColor="#888888">
                                                <circle cx="10" cy="7" r="7" />
                                                <rect x="6%" y="0" rx="5" ry="5" width="75%" height="15" />
                                                <rect x="84%" y="0" rx="5" ry="5" width="15%" height="15" />
                                            </content-loader>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <content-loader viewBox="0 0 400 15" style="width: 100%; height: 25px" :speed="3" primaryColor="#aaa" secondaryColor="#888888">
                                                <circle cx="10" cy="7" r="7" />
                                                <rect x="6%" y="0" rx="5" ry="5" width="75%" height="15" />
                                                <rect x="84%" y="0" rx="5" ry="5" width="15%" height="15" />
                                            </content-loader>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                            <template v-else>
                                <tbody v-if="rightTabInfor.rememberedTabLists.length > 0" v-for="rightTabList in rightTabInfor.rememberedTabLists" :class="rightTabList.isActive ? 'activeItem' : ''" :id="rightTabList.dataId" @click="setLinkSelectItem(rightTabList.dataId)">
                                    <tr>
                                        <td rowspan="2" :ref="rightTabList.dataId">
                                            <span class="radio-inline btn-link"
                                                ><font-awesome-icon :icon="rightTabList.dataIcon" /> <span> {{ rightTabInfor.isEncrypt ? rightTabList.dataTitleEncrypt ?? "" : rightTabList.dataTitle }}</span>
                                            </span>
                                        </td>
                                        <template v-if="rightTabInfor.isDisplayButton">
                                            <td rowspan="2" class="auto-resize" :tooltip="callHelpers('getPermissionText', appInfor.userPermission.isCanUnbookmark)" position="left">
                                                <button class="btn btn-sm btn-primary btn-intr" @click="appInfor.userPermission.isCanUnbookmark && unbookmarkData(rightTabList)" :disabled="appInfor.userPermission.isCanUnbookmark != 1">{{ unrememberText ?? "Đã quên" }}</button>
                                            </td>
                                        </template>
                                        <template v-if="rightTabInfor.isDisplayVote">
                                            <td class="auto-resize no-padding has-border" :tooltip="callHelpers('getPermissionText', appInfor.userPermission.isCanVoteForDictonaryWord)" position="left">
                                                <button class="vote-down btn btn-sm btn-default" @click="appInfor.userPermission.isCanVoteForDictonaryWord && voteDictionaryWord({ dictionary: rightTabList, voteValue: -1 })" :disabled="appInfor.userPermission.isCanVoteForDictonaryWord != 1">
                                                    <font-awesome-icon icon="down-long" :class="rightTabList.myVote == -1 ? 'active' : ''" />
                                                </button>
                                            </td>
                                            <td class="auto-resize no-padding has-border" :tooltip="callHelpers('getPermissionText', appInfor.userPermission.isCanVoteForDictonaryWord)" position="left">
                                                <button class="vote-up btn btn-sm btn-default" @click="appInfor.userPermission.isCanVoteForDictonaryWord && voteDictionaryWord({ dictionary: rightTabList, voteValue: 1 })" :disabled="appInfor.userPermission.isCanVoteForDictonaryWord != 1">
                                                    <font-awesome-icon icon="up-long" :class="rightTabList.myVote == 1 ? 'active' : ''" />
                                                </button>
                                            </td>
                                        </template>
                                    </tr>
                                    <template v-if="rightTabInfor.isDisplayVote">
                                        <tr>
                                            <td colspan="2" class="no-padding has-border">
                                                <div class="text-center">
                                                    <span style="font-family: Jersey" class="rating-value">{{ rightTabList.totalVote }}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody v-else>
                                    <tr id="-100" class="no-row activeItem">
                                        <td colspan="2">
                                            <span class="radio-inline btn-link"><i class="glyphicon glyphicon-exclamation-sign"> </i> <span> Không có dữ liệu !</span> </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </table>
                    </div>
                </template>
            </div>
            <slot name="extends"></slot>
        </template>
        <template v-else>
            <div class="tab-content focusable col-xs-12 no-padding">
                <div class="tab-pane fade active in" :style="{ height: 'calc(100vh - ' + (appInfor.footerHeight + 270) + 'px)' }">
                    <h5 class="no-margin no-data-title">Chọn danh mục và nhóm để hiển thị bài viết</h5>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
/**
 * Import libraries
 */
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import RightTabMenu from '@/share-layout/menu';

/**
 * import template
 */
// import template from "./template.vue";
// import store from "@/store";
import { appStore } from "@/store";
import { rightTabStore } from "./store";
import { mapGetters, mapActions } from "pinia";

/**
 * Define rightTab layout
 */
const RightTab = {
    name: "RightTab",
    // template: template,
    components: {
        // RightTabMenu
    },
    beforeCreate() {
        // if (!store.hasModule("rightTab")) {
        // 	store.registerModule("rightTab", rightTabStore);
        // }
    },
    props: ["rememberText", "unrememberText", "preventSwitchTab"],
    created() {
        var rightTab = rightTabStore();
        rightTab.updateRightTabInfor({
            currentLinkId: this.$route.params.id,
        });
    },
    mounted() {
        this.$watch(
            () => ({
                linkId: this.$route.params,
            }),
            (to) => {
                var linkId = Array.isArray(to.linkId.id) ? to.linkId.id[0] : to.linkId.id;
                if (linkId == "" && to.linkId.description != "") {
                    linkId = 404;
                }
                if (linkId == "" && to.linkId.description == "") {
                    this.updateRightTabItems([
                        {
                            value: "",
                        },
                        0,
                    ]);
                    this.updateRightTabItems([
                        {
                            value: "",
                        },
                        1,
                    ]);
                }
                this.updateRightTabInfor({
                    currentLinkId: linkId,
                });
            }
        );
        this.$watch(
            () => ({
                currentLinkId: this.rightTabInfor.currentLinkId,
                rightTabLists: this.rightTabInfor.rightTabLists,
            }),
            (newValue) => {
                // var linkId = to.linkId !=''?to.linkId:from.linkId;
                this.selectRightTabItem(newValue.currentLinkId);
            }
        );
        this.$watch(
            () => ({
                isRememberedTab: this.rightTabInfor.isRememberedTab,
            }),
            (rightTab) => {
                // var dataId = "";
                if (!this.preventSwitchTab) {
                    var app = appStore();
                    if (rightTab.isRememberedTab) {
                        this.rightTabInfor.rightTabMenus[0].isActive = false;
                    } else {
                        this.rightTabInfor.rightTabMenus[0].isActive = true;
                    }
                    if (this.rightTabInfor.rightTabMenus.length > 1) {
                        if (rightTab.isRememberedTab) {
                            this.rightTabInfor.rightTabMenus[1].isActive = true;
                        } else {
                            this.rightTabInfor.rightTabMenus[1].isActive = false;
                        }
                    }
                }
            },
            {
                immediate: true,
            }
        );
        // this.$watch(
        //     () => ({
        //         allDataTabLists: this.rightTabInfor.allDataTabLists,
        //     }),
        //     () => {
        //         this.updateRightTabInfor({
        //             rightTabLists: this.rightTabLists,
        //         });
        //     }
        // );
        this.$watch(
            () => ({
                rightTabLists: this.rightTabInfor.rightTabLists,
            }),
            (newValue) => {
                this.updateRightTabInfor({
                    unRememberedTabLists: this.unRememberedTabLists,
                    rememberedTabLists: this.rememberedTabLists,
                });
                var selectedTab = newValue.rightTabLists.filter((obj) => {
                    return obj.isActive === true;
                });
                var dataId = this.rightTabInfor.currentLinkId != 404 ? (selectedTab[0] != undefined ? selectedTab[0].dataId : "") : this.rightTabInfor.currentLinkId;
                if (this.rightTabInfor.isRememberedTab) {
                    if (this.rightTabInfor.rememberedTabLists.length == 0) {
                        this.rightTabInfor.isRememberedTab = false;
                    } else {
                        if (dataId == 404 || selectedTab[0] != undefined) {
                            this.setLinkSelectItem(dataId);
                        }
                    }
                } else {
                    if (this.rightTabInfor.unRememberedTabLists.length == 0) {
                        this.rightTabInfor.isRememberedTab = true;
                    } else {
                        if (dataId == 404 || selectedTab[0] != undefined) {
                            this.setLinkSelectItem(dataId);
                        }
                    }
                }
            }
        );
        this.$watch(
            () => ({
                unRememberedTabLists: this.rightTabInfor.unRememberedTabLists,
                rememberedTabLists: this.rightTabInfor.rememberedTabLists,
            }),
            () => {
                if (this.rightTabInfor.unRememberedTabLists.length == 0) {
                    this.rightTabInfor.isRememberedTab = true;
                    return;
                }
                if (this.rightTabInfor.rememberedTabLists.length == 0) {
                    this.rightTabInfor.isRememberedTab = false;
                    return;
                }
            },
            {
                immediate: true,
            }
        );
        this.$watch(
            () => ({
                catalogueId: this.rightTabInfor.rightTabItems[0].value,
            }),
            (newValues, oldValues) => {
                this.updateRightTabItems([
                    {
                        value: null,
                    },
                    1,
                ]);
                // if (newValues.catalogueId || newValues.catalogueId == "") {
                //     const router = useRouter();
                //     router.push(router.currentRoute.value.meta.rootPath);
                //     // this.appInfor.isReloadContent = true;
                // }
            }
            // {
            //     immediate: true,
            // }
        );
        this.$watch(
            () => ({
                groupId: this.rightTabInfor.rightTabItems[1].value,
            }),
            (newValues, oldValues) => {
                if (newValues.groupId != "") {
                    // const router = useRouter();
                    // router.push(router.currentRoute.value.meta.rootPath);
                    // this.rightTabInfor.currentLinkId = "";
                    this.appInfor.isReloadContent = true;
                }
                if (this.rightTabInfor.rightTabItems[0].value || this.rightTabInfor.rightTabItems[0].value == "") {
                    const router = useRouter();
                    router.push(router.currentRoute.value.meta.rootPath);
                }
                // else {
                //     const router = useRouter();
                //     router.push(router.currentRoute.value.meta.rootPath);
                // }
            }
            // {
            //     immediate: true,
            // }
        );
        // window.onpopstate = function () {
        //     this_.updateRightTabItems([
        //         {
        //             value: "",
        //         },
        //         0,
        //     ]);
        //     this_.updateRightTabItems([
        //         {
        //             value: "",
        //         },
        //         1,
        //     ]);
        // };
        //scroll to current selected ID
        let element = document.getElementById(this.rightTabInfor.currentLinkId);
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    },
    computed: {
        ...mapGetters(rightTabStore, ["unRememberedTabLists", "rememberedTabLists", "rightTabLists", "displaySelectItems"]),
    },
    methods: {
        ...mapActions(rightTabStore, ["bookmarkData", "unbookmarkData", "registerLesson"]),
        countRightTabList(payload) {
            const count = payload.list.filter((obj) => {
                if (obj.remembered === payload.countValue) {
                    return true;
                }
                return false;
            }).length;
            return count;
        },
        changeTabMenu(tabIndex) {
            this.updateRightTabInfor({
                isRememberedTab: tabIndex == 0 ? false : true,
            });
            // this.setLinkSelectItem('');
        },
    },
};
export default defineNuxtComponent(RightTab);
</script>
<style lang="scss">
@import "./style.scss";
</style>
