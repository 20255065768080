export const postContentStore = defineStore('postContent', {
    namespaced: true,
    state: () => ({
        postContentInfor: {
            postId: '1',
            postAverageRating: '0',
            postViews: '100',
            postPublicDate: '2022/09/08',
            postAuthor: 'Quynd',
            postUpdateDate: '2022/10/10',
            postTitle: 'Cách dùng quán từ xác định "The"',
            postContent: 'ありがとうございます。',
        },
        notFoundContentInfor: {
            screenName: 'Ngữ Pháp',
            hintList: [
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Có thể 1 số ngữ pháp cần được chỉnh sửa nên hệ thống đã tạm ẩn và bạn hiện không thể xem.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Bạn hãy kiểm tra lại ID bài viết(dãy số phía trên đường link) để chắc chắn đang xem đúng ngữ pháp mình cần.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Bạn có thể lọc và chọn lại bài viết ngữ pháp mong muốn ở tab bên phải.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Khi không có bài viết nào ở tab bạn đang chọn phía bên phải màn hình thì màn hình này cũng sẽ được hiển thị.',
                },
            ],
        },
        welcomeContentInfor: {
            welcomeSlogan: 'Chào mừng bạn đến với khóa học ngữ pháp của Eplus365!',
            hintList: [
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Danh sách bên phải bao gồm tất cả các ngữ pháp có trong hệ thống.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Bạn có thể lọc và chọn bài viết ngữ pháp mong muốn dựa theo danh mục và nhóm ở tab bên phải.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Bạn cũng có thể lưu danh mục/nhóm ngữ pháp mong muốn bằng chức năng "Lưu Danh Mục/Nhóm" danh sách đã lưu sẽ được hiển thị ở bên trái.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Bạn có thể lưu/học lại ngữ pháp trong danh sách bằng chức năng "Đã học" hoặc "Đã quên" tương ứng với tab "Ngữ Pháp Chưa Học" và "Ngữ Pháp Đã Học".',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Bên dưới mỗi ngữ pháp đều sẽ có danh sách các bài tập để các bạn luyện tập cho ngữ pháp đó.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Các bạn cũng có thể đóng góp các ví dụ bổ ích cho Eplus365,mỗi đóng góp đều rất là đáng quý đối với cộng đồng.',
                },
            ],
        },
        missionModeContentInfor: {
            postId: '1',
            hintList: [
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Danh sách bên phải bao gồm tất cả các phần phải hoàn thành trong nhiệm vụ.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Thực hiện nhiệm vụ bằng cách trả lời các câu hỏi của mỗi ngữ pháp.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Bạn cũng có thể lưu danh mục/nhóm ngữ pháp mong muốn bằng chức năng "Lưu Danh Mục/Nhóm" danh sách đã lưu sẽ được hiển thị ở bên trái.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Bạn có thể lưu/học lại ngữ pháp trong danh sách bằng chức năng "Đã học" hoặc "Đã quên" tương ứng với tab "Ngữ Pháp Chưa Học" và "Ngữ Pháp Đã Học".',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Bên dưới mỗi ngữ pháp đều sẽ có danh sách các bài tập để các bạn luyện tập cho ngữ pháp đó.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Các bạn cũng có thể đóng góp các ví dụ bổ ích cho Eplus365,mỗi đóng góp đều rất là đáng quý đối với cộng đồng.',
                },
            ],
        }
    }),
    actions: {
        updatePostContentInfor(postContentInfor) {
            var state = this;
            Object.keys(postContentInfor).forEach(function (key) {
                state.postContentInfor[key] = postContentInfor[key];
            })
        },
        updateNotFoundContentInfor(notFoundContentInfor) {
            var state = this;
            Object.keys(notFoundContentInfor).forEach(function (key) {
                state.notFoundContentInfor[key] = notFoundContentInfor[key];
            })
        },
        updateWelcomeContentInfor(welcomeContentInfor) {
            var state = this;
            Object.keys(welcomeContentInfor).forEach(function (key) {
                state.welcomeContentInfor[key] = welcomeContentInfor[key];
            })
        },
    }
});