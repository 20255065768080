import repository from "~/repository/index.js";
import { headerStore } from "~~/components/header/store";
import { footerStore } from "~~/components/footer/store";
import { leftTabStore } from "~~/components/left-tab/store";
export const appStore = defineStore('app', {
	namespaced: true,
	state: () => ({
		appInfor: {
			isLoading: true,
			isMobile: false,
			isRefresh: false,
			isSwitching: false,
			isMissionMode: false,
			isReloadContent: false,
			isRightTabDataChange: false,
			domain: '',
			playingAudio: null,
			footerHeight: 82,
			paging: {},
			page: 0,
			isLogined: false,
			userPermission: {},
			doingMission: {
				"missionId": "45704",
				"missionOwnerId": "46976",
				"missionTitle": "trùm ngữ pháp",
				"missionContent": "<p> trùm ngữ pháp</p>",
				"successMissionExp": "100",
				"failedMissionExp": "20",
				"successMissionCtp": "30",
				"failedMissionCtp": "0",
				"unitPerTimes": "3",
				"missionTryTimes": "3",
				"unitThisTimes": "3",
				"missionTryTimesCount": "0",
				"missionCondition": "0",
				"missionStatus": "1"
			}
		},
	}),
	getters: {
		permissionText(state) {
			return state.appInfor.permissionText;
		}
	},
	actions: {
		updateAppInfor(appInfor) {
			var _this = this;
			Object.keys(appInfor).forEach(function (key) {
				_this.appInfor[key] = appInfor[key];
			})
		},
		getAppState() {
			var header = headerStore();
			var footer = footerStore();
			var leftTab = leftTabStore();
			var route = useRouter();
			// store.dispatch("app/updateAppInfor", {
			// 	isLoading: true,
			// });
			try {
				var data = {
					screen_div: route.currentRoute.value.meta.rootPath,
				};
				repository
					.getAppState(data)
					.then((res) => {
						// const { data } = res;
						if (res.status === 200) {
							var data = res.data.data;
							this.updateAppInfor({
								userPermission: data[0][0],
							});
							header.updateHeaderInfor(data[6][0]);
							footer.updateFooterInfor(data[7][0]);
							footer.updateFooterInfor({
								contacts: data[8],
								epluser: data[5][0]["epluser"],
							});
							leftTab.updateLeftTabInfor({
								missionLists: data[4],
							});
							leftTab.updateQuestionLists({
								//use slice to copy array
								question: data[9].slice(),
								answer: data[10].slice(),
							});
							leftTab.updateLeftTabInfor({
								remainQuestionIdList: data[9].slice(),
								goldenBoardLists: data[2],
								myNotifyLists: data[1],
								systemNotifyLists: data[3],
							});
							this.updateAppInfor({
								isLoading: false,
								// isRefresh: true,
							});
							// this.appInfor.isLoading = false;
							// this.appInfor.isRefresh = true;
							return data;
						}
					});
			} catch (e) {
				console.log("Action getAppState: " + e.message);
			}
		},
		completeMission(callback) {
			callback = (callback == undefined) ? function () { } : callback;
			var { $callHelpers, $appStates, $appMethods } = useNuxtApp();
			repository.completeMission({
				mission_id: $appStates.app.appInfor.doingMission.missionId,
			})
				.then((res) => {
					const { data } = res.data;
					if (res.status === 200) {
						$appMethods.showDialog(2, 'S007', function () {
							var messageParams = [
								'S007',
								[
									data[1][0].successMissionExp * data[1][0].difficultLevel,
									data[1][0].successMissionCtp * data[1][0].difficultLevel,
								]
							];
							$appStates.dialogPopup.dialogPopupInfor.dialogMessage = $callHelpers('getMessage', messageParams);
							$appStates.dialogPopup.dialogPopupInfor.dialogButtons[0].buttonAction = function () {
								$appMethods.getAppState();
								$appStates.app.appInfor.isReloadContent = true;
								$appMethods.hideDialog();
								callback();
							}
						});
						return res;
					}
				});
		}
	},
});

// export default createStore({
// 	modules: {
// 		app: AppStore
// 	},
// });
