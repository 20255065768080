<template>
    <vue-final-modal v-model="contributeVocabularyPopupInfor.showModal" :transition="contributeVocabularyPopupInfor.modalTransition" :clickToClose="false">
        <div class="modal-dialog contribute-vocabulary" ref="abc">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">ĐÓNG GÓP TỪ VỰNG</h5>
                    <!-- <button class="close" data-dismiss="modal" type="button" @click="contributeVocabularyPopupInfor.showModal = false">×</button> -->
                </div>
                <div class="modal-body no-padding">
                    <!-- <div class="col-xs-12 dialog-padding no-fixed search-panel" data-target="#select-condition" data-toggle="collapse">
                        <h5>
                            <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
                            Điều Kiện Tìm Kiếm
                        </h5>
                    </div> -->
                    <div class="col-xs-12 no-padding margin-top collapse in" min-width="1024px" id="select-condition">
                        <div class="col-xs-12 dialog-padding">
                            <div class="form-group">
                                <label class="col-xs-12 no-padding">Loại đóng góp</label>
                                <template v-for="option in contributeVocabularyPopupInfor.formInput.contributeType.options">
                                    <div v-if="option.libContent != ''" class="col-xs-12 col-md-4 no-padding margin-bottom">
                                        <label class="radio-inline"> <input type="radio" name="inlineRadioOptions" id="inlineRadio1" :value="option.libId" v-model="contributeVocabularyPopupInfor.displayWord.contributeTypeId" :selected="option.isActive" />{{ option.libContent }}</label>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-4 dialog-padding">
                            <div class="form-group">
                                <label>Loại Từ Vựng</label>
                                <select class="form-control input-sm submit-item" v-model="contributeVocabularyPopupInfor.displayWord.vocabularyDivId">
                                    <option v-for="option in contributeVocabularyPopupInfor.formInput.vocabularyDiv.options" :value="option.libId">{{ option.libContent }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-4 dialog-padding">
                            <div class="form-group">
                                <label>Chuyên Nghành</label>
                                <select class="form-control input-sm submit-item" v-model="contributeVocabularyPopupInfor.displayWord.vocabularySpecializedDivId">
                                    <option v-for="option in contributeVocabularyPopupInfor.formInput.vocabularySpecialized.options" :value="option.libId">{{ option.libContent }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-4 dialog-padding">
                            <div class="form-group">
                                <label>Lĩnh vực</label>
                                <select class="form-control input-sm submit-item" v-model="contributeVocabularyPopupInfor.displayWord.vocabularyFieldDivId">
                                    <option v-for="option in contributeVocabularyPopupInfor.formInput.vocabularyField.options" :value="option.libId">{{ option.libContent }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 dialog-padding">
                            <div class="form-group">
                                <label>Từ tiếng anh</label>
                                <input type="text" id="engword" class="form-control input-sm submit-item required" v-model="contributeVocabularyPopupInfor.displayWord.vocabularyName" :disabled="contributeVocabularyPopupInfor.formInput.contributeType.value != 3" />
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 dialog-padding">
                            <div class="form-group">
                                <label>Phiên âm</label>
                                <input type="text" id="word-spelling" class="form-control input-sm submit-item text-left required" v-model="contributeVocabularyPopupInfor.displayWord.vocabularySpelling" />
                            </div>
                        </div>
                        <div class="col-xs-12 dialog-padding">
                            <div class="form-group">
                                <label>Nghĩa</label>
                                <textarea id="word-mean" class="form-control input-sm submit-item text-left required" rows="2" v-model="contributeVocabularyPopupInfor.displayWord.vocabularyMean"></textarea>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 dialog-padding">
                            <div class="form-group">
                                <label>Hình ảnh</label>
                                <div class="col-xs-12 no-padding form-group">
                                    <a @click="contributeVocabularyPopupInfor.showImageCropModal = true">
                                        <img :src="appInfor.domain + contributeVocabularyPopupInfor.displayWord.vocabularyImage" alt="uploadedImage" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 dialog-padding">
                            <form enctype="multipart/form-data" id="upload_form" role="form" method="POST" action="">
                                <div class="form-group">
                                    <label>Âm Thanh</label>
                                    <div class="input-group">
                                        <input class="margin-bottom custom-file-input btn btn-sm btn-default" type="file" accept="audio/*" @change="handleFileUpload($event)" />
                                        <audio id="audio-preview" :src="appInfor.domain + contributeVocabularyPopupInfor.displayWord.vocabularyAudio" controls v-show="file" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-xs-12 dialog-padding no-fixed result-panel">
                        <h5>
                            <font-awesome-icon icon="list-ul" />
                            Lịch Sử Đóng Góp
                        </h5>
                        <div class="table-fixed-width no-padding-left" min-width="1500px">
                            <table class="table table-hover table-bordered table-preview table-custom">
                                <thead>
                                    <tr>
                                        <th width="50px">No</th>
                                        <th width="110px">Người Đóng Góp</th>
                                        <th width="130px">Thời Gian Thay Đổi</th>
                                        <th>Nội Dung Thay Đổi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="contributeVocabularyPopupInfor.historyList.length > 0">
                                        <tr v-for="(selectedItem, index) in contributeVocabularyPopupInfor.historyList">
                                            <td class="refer-item text-center">{{ selectedItem.versionCode }}</td>
                                            <td>{{ selectedItem.vocabularyCreateUser }}</td>
                                            <td>{{ selectedItem.vocabularyCreateDate }}</td>
                                            <td>
                                                <v-popper arrow>
                                                    <span class="btn btn-sm btn-link">Xem Chi Tiết</span>
                                                    <template #content>
                                                        <div class="text-left change-preview">
                                                            <div v-if="selectedItem.vocabularyName != ''" v-html="selectedItem.vocabularyName"></div>
                                                            <div v-if="selectedItem.vocabularyDiv != ''" v-html="selectedItem.vocabularyDiv"></div>
                                                            <div v-if="selectedItem.vocabularySpecializedDiv != ''" v-html="selectedItem.vocabularySpecializedDiv"></div>
                                                            <div v-if="selectedItem.vocabularyFieldDiv != ''" v-html="selectedItem.vocabularyFieldDiv"></div>
                                                            <div v-if="selectedItem.vocabularySpelling != ''" v-html="selectedItem.vocabularySpelling"></div>
                                                            <div v-if="selectedItem.vocabularyMean != ''" v-html="selectedItem.vocabularyMean"></div>
                                                            <div v-if="selectedItem.vocabularyImage != ''">
                                                                Tên Trường: Hình Ảnh<br>
                                                                <img :src="appInfor.domain + selectedItem.oldVocabularyImage" alt="oldVocabularyImage" />
                                                                <font-awesome-icon icon="right-long" class="inline-block"/>
                                                                <img :src="appInfor.domain + selectedItem.newVocabularyImage" alt="newVocabularyImage" />
                                                            </div>
                                                            <div v-if="selectedItem.vocabularyAudio != ''">
                                                                Tên Trường: Âm Thanh<br>
                                                                <audio :src="appInfor.domain + selectedItem.oldVocabularyAudio" controls />
                                                                <div class="text-center">
                                                                    <font-awesome-icon icon="down-long" class="inline-block"/>
                                                                </div>
                                                                <audio :src="appInfor.domain + selectedItem.newVocabularyAudio" controls />
                                                            </div>
                                                        </div>
                                                    </template>
                                                </v-popper>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-else-if="contributeVocabularyPopupInfor.paging.totalRecord == 0">
                                        <tr>
                                            <td colspan="11">Không có dữ liệu nào khớp với điều kiện tìm kiếm</td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td colspan="11">Xin nhập điều kiện tìm kiếm</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <ClientOnly>
                            <Paging v-if="contributeVocabularyPopupInfor.paging.totalRecord > 0" :pagingId="contributeVocabularyPopupInfor.paging.pagingCode" :currentPage="contributeVocabularyPopupInfor.paging.currentPage" :totalPage="contributeVocabularyPopupInfor.paging.totalPage" />
                        </ClientOnly>
                    </div>
                </div>
                <div class="modal-footer">
                    <!-- <button class="btn btn-danger btn-sm float-left" id="btn-do-mission" type="button" @click="saveVocabularyContribute()">
                        <font-awesome-icon icon="download" />
                        Lưu Đóng Góp
                    </button> -->
                    <button class="btn btn-primary btn-sm" id="btn-do-mission" type="button" @click="saveVocabularyContribute()">
                        <font-awesome-icon icon="download" />
                        <!-- <font-awesome-icon icon="fa-solid fa-magnifying-glass" /> -->
                        Lưu Đóng Góp
                    </button>
                    <button class="btn btn-default btn-sm" data-dismiss="modal" type="button" @click="contributeVocabularyPopupInfor.showModal = false">
                        <font-awesome-icon icon="close" />
                        Đóng
                    </button>
                </div>
            </div>
            <ClientOnly>
                <v-loading :active="contributeVocabularyPopupInfor.isSearching" :is-full-page="false">
                    <slot name="default">
                        <font-awesome-icon :icon="['fas', 'compact-disc']" spin size="3x" style="color: #777" />
                    </slot>
                </v-loading>
                <a class="btn" @click="toggleShow">Set Avatar</a>
                <v-image-crop-upload field="img" @crop-success="uploadCropImage" @crop-upload-success="cropUploadSuccess" @crop-upload-fail="cropUploadFail" :modelValue="contributeVocabularyPopupInfor.showImageCropModal" v-model="contributeVocabularyPopupInfor.showImageCropModal" langType="vi" :width="300" :height="250" url="" :noCircle="true" :noSquare="true"> </v-image-crop-upload>
            </ClientOnly>
        </div>
    </vue-final-modal>
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import MainMenu from '@/share-layout/menu';

/**
 * import template
 */
// import template from "./template.html";
// import store from "@/store";
import { contributeVocabularyPopupStore } from "./store";
import { vocabularyStore } from "~~/pages/vocabulary/store.js";

/**
 * Define main layout
 */
const ContributeVocabularyPopup = {
    name: "ContributeVocabularyPopup",
    // template: template,
    components: {
        // MainMenu,
    },
    beforeCreate() {
        // if (!store.hasModule("contributeVocabularyPopup")) {
        // 	store.registerModule("contributeVocabularyPopup", contributeVocabularyPopupStore);
        // }
    },
    created() {},
    computed: {
        ...mapState(contributeVocabularyPopupStore, ["contributeVocabularyPopupInfor"]),
    },
    data() {
        // var contributeVocabularyPopup = contributeVocabularyPopupStore();
        return {
            file: null,
            // difficultLevel: contributeVocabularyPopup.contributeVocabularyPopupInfor.difficultLevel,
            // successMissionExp: contributeVocabularyPopup.contributeVocabularyPopupInfor.successMissionExp,
            // successMissionCtp: contributeVocabularyPopup.contributeVocabularyPopupInfor.successMissionCtp,
            // failedMissionExp: contributeVocabularyPopup.contributeVocabularyPopupInfor.failedMissionExp,
            // failedMissionCtp: contributeVocabularyPopup.contributeVocabularyPopupInfor.failedMissionCtp,
        };
    },
    mounted() {
        this.$watch(
            () => ({
                showModal: this.contributeVocabularyPopupInfor.showModal,
            }),
            (newValue) => {
                this.previewAudio();
                this.contributeVocabularyPopupInfor.displayWord.vocabularyImage = this.contributeVocabularyPopupInfor.displayWord.vocabularyImage == "" ? this.contributeVocabularyPopupInfor.noImage : this.contributeVocabularyPopupInfor.displayWord.vocabularyImage;
                // this.file = this.contributeVocabularyPopupInfor.displayWord.vocabularyAudio;
                // var linkId = to.linkId !=''?to.linkId:from.linkId;
                // if (newValue.showModal) {
                //     var _this = this;
                //     setTimeout(() => {
                //         _this.$refs.vocabularyName.focus();
                //     }, 500);
                // }
            }
        );
    },
    methods: {
        ...mapActions(contributeVocabularyPopupStore, ["showContributeVocabularyPopup", "updateContributeVocabularyPopupInfor", "uploadCropImage", "saveVocabularyContribute"]),
        confirm() {
            // some code...
            this.show = false;
        },
        cancel(close) {
            // some code...
            close();
        },
        previewAudio(mode) {
            mode = mode ?? 0;
            let audio = document.getElementById("audio-preview");
            let reader = new FileReader();
            if (mode == 1 && this.file) {
                reader.readAsDataURL(this.file);
                reader.addEventListener("load", function () {
                    audio.src = reader.result;
                });
            } else {
                this.file = this.contributeVocabularyPopupInfor.displayWord.vocabularyAudio;
                audio.src = this.appInfor.domain + this.file;
            }
        },
        handleFileUpload(event) {
            this.file = event.target.files[0];
            this.contributeVocabularyPopupInfor.displayWord.vocabularyAudio = this.file;
            this.previewAudio(1);
        },
        cropUploadSuccess(jsonData, field) {
            console.log(jsonData);
            console.log(field);
        },
        // cropSuccess(imageDataUrl, field){
        //     this.
        // },
        // updatePagingContent(paging, page) {
        //     if (paging.pagingId == 999) {
        //         //update paging content
        //         this.getVocabularyList(page);
        //     }
        // },
        // updateResizedScreen() {
        //     // this.updateContributeVocabularyPopupInfor({
        //     // 	optionFontSize: this.$refs.optionHeader[0].clientWidth/12,
        //     // });
        // },
        // contributeVocabulary(vocabulary, index) {
        //     this.contributeVocabularyPopupInfor.historyList.splice(index, 1);
        //     this.contributeVocabularyPopupInfor.selectedList.unshift(vocabulary);
        // },
        // uncontributeVocabulary(vocabulary, index) {
        //     this.contributeVocabularyPopupInfor.selectedList.splice(index, 1);
        //     this.contributeVocabularyPopupInfor.historyList.push(vocabulary);
        // },
    },
};
export default defineNuxtComponent(ContributeVocabularyPopup);
</script>
<style lang="scss">
@import "./style.scss";
</style>
