export const headerErrorBarStore = defineStore('headerErrorBar',{
    namespaced: true,
    state: () => ({
        headerErrorBarInfor: {
            isShowHeaderErrorBar: false,
            // lastScrollPosition:0,
            errorMessage:'',
        }
    }),
    mutations: {
        updateHeaderErrorBarInfor(state, headerErrorBarInfor) {
            Object.keys(headerErrorBarInfor).forEach(function (key) {
                state.headerErrorBarInfor[key] = headerErrorBarInfor[key];
            })
        },
    },
    // getters: {
    //     remainQuestionIdList: state => state.headerErrorBarInfor.remainQuestionIdList,
    // },
    actions: {
        updateHeaderErrorBarInfor(headerErrorBarInfor) {
            var state = this;
            Object.keys(headerErrorBarInfor).forEach(function (key) {
                state.headerErrorBarInfor[key] = headerErrorBarInfor[key];
            })
        },
    }
});