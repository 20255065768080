import repository from "@/repository";
import dictionaryRepository from "./repository";
import { appStore } from "@/store";
import { rightTabStore } from "~~/components/right-tab/store";
import { leftTabStore } from "~~/components/left-tab/store";
import { exampleStore } from "~~/components/example/store";
import { postContentStore } from "~~/components/post-content/store";
import { exerciseStore } from "~~/components/exercise/store";
import { vocabularyContentStore } from "~~/components/vocabulary-content/store";
import { wordRelationshipStore } from "~~/components/word-relationship/store";
export const dictionaryStore = defineStore('dictionary', {
    namespaced: true,
    state: () => ({
        dictionaryInfor: {
            isLoading: false,
            isSearched: false,
            questionListTitle: 'Bài Tập Tự Luyện',
            welcomeSlogan: 'Chào mừng bạn đến với Từ Điển Eplus365!',
            hintList: [
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Nhập từ vựng trên ô tìm kiếm lựa chọn từ thích hợp được gợi ý để tiến hành tìm kiếm.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Danh sách bên phải bao gồm tất cả các nghĩa của từ vựng được chọn được phân chia dựa theo từng chuyên nghành ,lĩnh vực.',
                },
                // {
                //     hintIcon: 'hand-point-right',
                //     hintText: 'Bạn cũng có thể lưu danh mục/nhóm từ vựng mong muốn bằng chức năng "Lưu Danh Mục/Nhóm" danh sách đã lưu sẽ được hiển thị ở bên trái.',
                // },
                // {
                //     hintIcon: 'hand-point-right',
                //     hintText: 'Bạn có thể lưu/học lại từ vựng trong danh sách bằng chức năng "Đã học" hoặc "Đã quên" tương ứng với tab "Ngữ Pháp Chưa Học" và "Ngữ Pháp Đã Học".',
                // },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Bên dưới mỗi từ vựng đều sẽ có danh sách các bài tập để các bạn luyện tập cho từ vựng đó.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Các bạn cũng có thể đóng góp các ví dụ bổ ích cho Eplus365,mỗi đóng góp đều rất là đáng quý đối với cộng đồng.',
                },
            ],
            searchInput: '',
            dictionaryType: {
                itemId: 'dictionaryType',
                itemType: 'select',
                selected: '',
                value: '0',
                selectItems: [
                    { catalogueName: 'Canada2', catalogueId: '1' },
                    { catalogueName: 'Canada3', catalogueId: '2' },
                    { catalogueName: 'Canada4', catalogueId: '3' },
                ],
            },
            searchedList: [],
            searchHistory: [
                {
                    searchValue: 'hello',
                    dictionaryType: '0',
                },
            ],
            dictionaryPostLists: [
                {
                    dictionaryId: '1',
                    dictionaryTitle: 'hello',
                    dictionaryDiv: '1',
                    dictionaryDivName: 'Danh từ',
                    dictionarySpecializedDiv: '1',
                    dictionarySpecializedName: 'Kinh tế',
                    dictionaryFieldDiv: '1',
                    dictionaryFieldName: 'Âm nhạc',
                    dictionarySpelling: 'æmp (æmplɪfaɪə)',
                    dictionaryMean: 'Xin chào',
                    dictionaryAudio: '',
                    dictionaryRemembered: false,
                },
            ],
            wordRelationshipSynonymsList: [],
            wordRelationshipOppositeList: [],
        }
    }),
    getters: {
        dictionaryShowList(state) {
            var { $appStates } = useNuxtApp();
            var rightTabInfor = $appStates.rightTab.rightTabInfor;
            var dictionaryShowList = state.dictionaryInfor.dictionaryPostLists.filter(function (el) {
                var catalogueId = rightTabInfor.rightTabItems[0].value ?? '';
                var groupId = rightTabInfor.rightTabItems[1].value ?? '';
                catalogueId = (catalogueId == '' ? el.catalogueId : catalogueId);
                groupId = (groupId == '' ? el.groupId : groupId);
                return el.catalogueId == catalogueId && el.groupId == groupId;
            });
            return dictionaryShowList;
        },
    },
    actions: {
        updateDictionaryInfor(dictionaryInfor) {
            var state = this;
            Object.keys(dictionaryInfor).forEach(function (key) {
                state.dictionaryInfor[key] = dictionaryInfor[key];
            })
        },
        // updateVocabularyPostQuestionLists(sqlQuestionLists) {
        //     var state = this;
        //     var questionLists = state.dictionaryInfor.dictionaryPostQuestionLists;
        //     questionLists = sqlQuestionLists['question'];
        //     Object.keys(questionLists).forEach(function (key) {
        //         var questionAnswer = sqlQuestionLists['answer'].filter(function (el) {
        //             return el.questionId == questionLists[key].questionId;
        //         });
        //         questionLists[key]['answerLists'] = questionAnswer;
        //     })
        //     state.dictionaryInfor.dictionaryPostQuestionLists = questionLists;
        // },
        searchVocabulary(keyWord,loading) {
            try {
                loading(true);
                if(keyWord!=''){
                    dictionaryRepository.searchVocabulary({
                        query: keyWord,
                        dictionary_type: this.dictionaryInfor.dictionaryType.value,
                    })
                    .then((res) => {
                        const { data } = res.data;
                        this.dictionaryInfor.searchedList = data[0];
                        loading(false);
                    });
                }else{
                    this.dictionaryInfor.searchedList = [];
                    loading(false);
                }
            } catch (e) {
                console.log("Action searchVocabulary: " + e.message);
            }
        },
        deleteBookmark(word) {
            try {
                const { $appStates,$appMethods } = useNuxtApp();
                var _this = this;
                $appMethods.showDialog(1,'C012',function(){
                    $appStates.dialogPopup.dialogPopupInfor.dialogButtons[0].buttonAction = function(){
                        dictionaryRepository.deleteBookmark({
                            word: word,
                            dictionary_type: _this.dictionaryInfor.dictionaryType.value,
                        })
                        .then((res) => {
                            const { data } = res.data;
                            _this.dictionaryInfor.searchHistory = data[1];
                            if(word == _this.dictionaryInfor.searchInput){
                                _this.dictionaryInfor.searchInput = '';
                            }
                            $appStates.dialogPopup.dialogPopupInfor.showDialog = false;
                        });
                    }
                })
            } catch (e) {
                console.log("Action deleteBookmark: " + e.message);
            }
        },
        voteDictionaryWord(payload) {
            try {
                var state = this;
                var params = {
                    source_id: state.dictionaryInfor.searchInput,
                    target_id: payload.dictionary.dataId,
                    my_vote: payload.voteValue, //example for a post
                    execute_div: state.dictionaryInfor.dictionaryType.value, //this is a vote for example
                    screen_div: localStorage.getItem("currentUrl"),
                };
                repository.voteAction(params)
                    .then((res) => {
                        // const { data } = res;
                        if (res.status === 200) {
                            var data = res.data.data;
                            var rightTab = rightTabStore();
                            var currentWord = rightTab.rightTabInfor.rightTabLists.filter(function (el) {
                                return el.dataId == payload.dictionary.dataId;
                            });
                            if(currentWord[0]!=undefined){
                                var index = rightTab.rightTabInfor.rightTabLists.indexOf(currentWord[0]);
                                rightTab.rightTabInfor.rightTabLists[index].myVote = data[1][0].myVote;
                                rightTab.rightTabInfor.rightTabLists[index].totalVote = data[1][0].voteValue;
                            }
                            return res;
                        }
                    });
            } catch (e) {
                console.log("Action voteExample: " + e.message);
            }
        },
        getDictionaryInfor(payload) {
            try {
                // var rightTab = rightTabStore();
                var state = this;
                // state.dictionaryInfor.isLoading = true;
                // rightTab.updateRightTabInfor({
                //     isLoading: true,
                // });
                dictionaryRepository.getDictionaryInfor(payload)
                    .then((res) => {
                        // const { data } = res;
                        state.setDictionaryInfor(res);
                    });
            } catch (e) {
                console.log("Action getDictionaryInfor: " + e.message);
            }
        },
        setDictionaryInfor(res) {
            // const { data } = res;
            var app = appStore();
            var rightTab = rightTabStore();
            var leftTab = leftTabStore();
            var example = exampleStore();
            var exercise = exerciseStore();
            var vocabularyContent = vocabularyContentStore();
            var wordRelationship = wordRelationshipStore();
            var state = this;
            var route = useRoute();
            var router = useRouter();
            // state.dictionaryInfor.isLoading = true;
            // rightTab.updateRightTabInfor({
            //     isLoading: true,
            // });
            if (res.status === 200) {
                var data = res.data.data;
                if (data[9][0] && data[9][0]['missionId'] != 0) {
                    app.appInfor.isMissionMode = true;
                    app.appInfor.doingMission = data[9][0];
                    leftTab.leftTabInfor.renderLessonList = false;
                    router.push(data[9][0]['missionLink']);
                } else {
                    app.appInfor.isMissionMode = false;
                    app.appInfor.doingMission = {};
                    leftTab.leftTabInfor.renderLessonList = true;
                }
                rightTab.rightTabInfor.isChangeRouter = false;
                rightTab.rightTabInfor.isDisplaySelect = false;
                rightTab.rightTabInfor.isDisplayButton = false;
                rightTab.rightTabInfor.isDisplayVote = true;
                vocabularyContent.vocabularyContentInfor.isShowVocabularyOptions = false;
                // var selectedCategory = data[0].filter(obj => {
                //     return obj.selected === true
                // })
                // rightTab.updateRightTabItems([
                //     {
                //         selectItems: data[0],
                //         value: selectedCategory[0] ? selectedCategory[0].catalogueId : '',
                //     },
                //     0,
                // ]);
                // var selectedGroup = data[1].filter(obj => {
                //     return obj.selected === true
                // })
                // rightTab.updateRightTabItems([
                //     {
                //         selectItems: data[1],
                //         value: selectedGroup[0] ? selectedGroup[0].groupId : '',
                //     },
                //     1
                // ]);
                leftTab.updateLeftTabInfor({
                    renderLessonList: false,
                    // lessonLists: data[2],
                    // isLessonListLoading: false,
                });
                var rightTabMenus = [];
                if (app.appInfor.isMissionMode) {
                    rightTabMenus = [
                        {
                            rightTabMenuLink: 'tab1',
                            rightTabMenuName: 'Nội Dung Nhiệm Vụ',
                            isActive: true
                        }
                    ];
                } else {
                    rightTabMenus = [
                        {
                            rightTabMenuLink: 'tab1',
                            rightTabMenuName: 'Danh Sách Kết Quả',
                            isActive: true
                        }
                        // {
                        //     rightTabMenuLink: 'tab2',
                        //     rightTabMenuName: 'Đóng Góp Từ Vựng',
                        //     isActive: false
                        // },
                    ];
                }
                rightTab.updateRightTabInfor({
                    rightTabMenus: rightTabMenus,
                });
                // var selectedTab = data[4].filter(obj => {
                //     return obj.isActive === true
                // })
                // if (selectedTab[0] != undefined) {
                //     rightTab.updateRightTabInfor({
                //         isRememberedTab: selectedTab[0].remembered,
                //     });
                // }
                rightTab.updateRightTabInfor({
                    isRememberedTab: false,
                });
                rightTab.updateRightTabInfor({
                    allDataTabLists: data[4].slice(),
                    isLoading: false,
                    // isEncrypt: !vocabularyContent.vocabularyContentInfor.learnVocabularyOptions.raw.isChecked,
                });
                rightTab.updateRightTabInfor({
                    rightTabLists: rightTab.rightTabLists,
                });
                rightTab.updateRightTabInfor({
                    unRememberedTabLists: rightTab.unRememberedTabLists,
                    rememberedTabLists: rightTab.rememberedTabLists,
                });
                // rightTab.selectRightTabItem(route.params.id);
                vocabularyContent.updateVocabularyContentInfor({
                    vocabularyLists: data[3].slice()
                });
                // exercise.updateQuestionLists(data[5].slice(),data[6].slice());
                this.updateDictionaryInfor({
                    //use slice to copy array
                    searchHistory: data[0].slice(),
                    searchInput: data[2][0]['searchValue'],
                    wordRelationshipSynonymsList: data[5].slice(),
                    wordRelationshipOppositeList: data[6].slice(),
                });
                this.dictionaryInfor.dictionaryType.selectItems = data[1].slice();
                this.dictionaryInfor.dictionaryType.value = data[2][0]['dictionaryType'];
                example.updateExampleInfor({
                    //use slice to copy array
                    exampleLists: data[7].slice(),
                    pagingLists: data[8].slice(),
                });
                state.dictionaryInfor.dictionaryPostLists = data[3];
                if(data[2][0]['searchValue']!=''){
                    state.dictionaryInfor.isSearched = true;
                    router.push({ path: 'dictionary', query: { type:this.dictionaryInfor.dictionaryType.value,query: data[2][0]['searchValue'] } })
                }else{
                    state.dictionaryInfor.isSearched = false;
                    router.push({ path: 'dictionary' })
                }
                this.initPost();
                // if(selectedTab[0].dataId!=undefined){
                //     this.dispatch('rightTab/selectRightTabItem',selectedTab[0].dataId);
                // }
                var selectedItem = data[4].filter(obj => {
                    return obj.isActive === true
                })
                // rightTab.selectRightTabItem(selectedItem[0]?selectedItem[0]['dataId']:'');
                setTimeout(() => {
                    rightTab.selectRightTabItem(selectedItem[0]?selectedItem[0]['dataId']:'');
                    // state.dictionaryInfor.isLoading = false;
                }, 50);
                app.appInfor.isReloadContent = false;
                return res;
            }
        },
        initPost() {
            var postContent = postContentStore();
            postContent.updateWelcomeContentInfor({
                welcomeSlogan: this.dictionaryInfor.welcomeSlogan,
                hintList: this.dictionaryInfor.hintList,
            });
        }
    },
});