// import repository from '@/utils/repository';

const resource = '/vocabulary';

export default {
    getVocabularyInfor: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/get-vocabulary-infor`,
            data: data,
            showProgress: true,
        });
    },
};
