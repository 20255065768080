import repository from "./repository";
import { vocabularyStore } from "~~/pages/vocabulary/store.js";
export const contributeVocabularyPopupStore = defineStore('contributeVocabularyPopup', {
    namespaced: true,
    state: () => ({
        contributeVocabularyPopupInfor: {
            isLoading: true,
            isSearching: false,
            showModal: false,
            showImageCropModal: false,
            modalTransition: 'bounce',
            popupType: 0,
            noImage: '/web-content/images/plugin-icon/no-image.jpg',
            formInput: {
                contributeType: {
                    isError: false,
                    errorMessage: '',
                    options: [],
                },
                vocabularyDiv: {
                    isError: false,
                    errorMessage: '',
                    options: [],
                },
                vocabularySpecialized: {
                    isError: false,
                    errorMessage: '',
                    options: [],
                },
                vocabularyField: {
                    isError: false,
                    errorMessage: '',
                    options: [],
                },
                vocabularyName: {
                    isError: false,
                    errorMessage: '',
                },
                vocabularySpelling: {
                    isError: false,
                    errorMessage: '',
                },
                vocabularyMean: {
                    isError: false,
                    errorMessage: '',
                },
                vocabularyImage: {
                    isError: false,
                    errorMessage: '',
                },
                vocabularyAudio: {
                    isError: false,
                    errorMessage: '',
                },
            },
            rules: {
                'formInput.vocabularyName.value': 'required',
                // 'vocabularyMean.value': 'required',
            },
            currentWord: {
                vocabularyIndex: '1',
                vocabularyId: '1',
                vocabularyName: 'hello',
                vocabularyDiv: '1',
                vocabularyDivName: 'Danh từ',
                vocabularySpecializedDiv: '1',
                vocabularySpecializedName: 'Kinh tế',
                vocabularyFieldDiv: '1',
                vocabularyFieldName: 'Âm nhạc',
                vocabularySpelling: 'æmp (æmplɪfaɪə)',
                vocabularyMean: 'Xin chào',
                vocabularyImage: '/web-content/images/plugin-icon/no-image.jpg',
                vocabularyAudio: 'https://stream-dict-laban.zdn.vn/us/570eb1f57471ef3b1eeaede094646119/186920830bf/B/beat.mp3',
                isVocabularyRemembered: false,
                vocabularyCreateDate: '',
                vocabularyUpdateDate: '',
                isVocabularyDeleted: false,
            },
            displayWord: {
                vocabularyIndex: '1',
                vocabularyId: '1',
                vocabularyName: 'hello',
                vocabularyDiv: '1',
                vocabularyDivName: 'Danh từ',
                vocabularySpecializedDiv: '1',
                vocabularySpecializedName: 'Kinh tế',
                vocabularyFieldDiv: '1',
                vocabularyFieldName: 'Âm nhạc',
                vocabularySpelling: 'æmp (æmplɪfaɪə)',
                vocabularyMean: 'Xin chào',
                vocabularyImage: '/web-content/images/plugin-icon/no-image.jpg',
                vocabularyAudio: 'https://stream-dict-laban.zdn.vn/us/570eb1f57471ef3b1eeaede094646119/186920830bf/B/beat.mp3',
                isVocabularyRemembered: false,
                vocabularyCreateDate: '',
                vocabularyUpdateDate: '',
                isVocabularyDeleted: false,
            },
            historyList: [
                {
                    vocabularyId: '1',
                    vocabularyName: 'hello',
                    vocabularyDiv: '1',
                    vocabularyDivName: 'Danh từ',
                    vocabularySpecializedDiv: '1',
                    vocabularySpecializedName: 'Kinh tế',
                    vocabularyFieldDiv: '1',
                    vocabularyFieldName: 'Âm nhạc',
                    vocabularySpelling: 'æmp (æmplɪfaɪə)',
                    vocabularyMean: 'Xin chào',
                    vocabularyImage: '/web-content/images/plugin-icon/no-image.jpg',
                    vocabularyAudio: 'https://stream-dict-laban.zdn.vn/us/570eb1f57471ef3b1eeaede094646119/186920830bf/B/beat.mp3',
                    isVocabularyRemembered: false,
                    vocabularyCreateDate: '',
                    vocabularyUpdateDate: '',
                    isVocabularyDeleted: false,
                }
            ],
            paging: {
                pagingCode: "999",
                currentPage: "1",
                totalPage: "1",
                totalRecord: "0",
                pagingSize: "50",
            },
        }
    }),
    actions: {
        updateContributeVocabularyPopupInfor(contributeVocabularyPopupInfor) {
            var state = this;
            Object.keys(contributeVocabularyPopupInfor).forEach(function (key) {
                state.contributeVocabularyPopupInfor[key] = contributeVocabularyPopupInfor[key];
            })
        },
        getContributeVocabularyPopupInfor() {
            try {
                var { $appStates } = useNuxtApp();
                var state = this;
                this.updateContributeVocabularyPopupInfor({
                    searchedList: [],
                    paging: { totalRecord: -1 },
                });
                var payload = {
                    word_id: $appStates.rightTab.rightTabInfor.currentData.dataId,
                };
                repository.getContributeVocabularyPopup(payload)
                    .then((res) => {
                        if (res.status === 200) {
                            var data = res.data.data;
                            state.contributeVocabularyPopupInfor.formInput.contributeType.options = data[0];
                            state.contributeVocabularyPopupInfor.formInput.vocabularyDiv.options = data[1];
                            state.contributeVocabularyPopupInfor.formInput.vocabularySpecialized.options = data[2];
                            state.contributeVocabularyPopupInfor.formInput.vocabularyField.options = data[3];
                            state.contributeVocabularyPopupInfor.currentWord = data[4][0];
                            state.contributeVocabularyPopupInfor.displayWord = data[4][0];
                            state.contributeVocabularyPopupInfor.historyList = data[5];
                            state.contributeVocabularyPopupInfor.showModal = true;
                            return res;
                        }
                    });
            } catch (e) {
                console.log("Action getContributeVocabularyPopupInfor: " + e.message);
            }
        },
        saveVocabularyContribute() {
            try {
                var { $appMethods } = useNuxtApp();
                let formData = new FormData();
                formData.append('contributeTypeId', this.contributeVocabularyPopupInfor.displayWord.contributeTypeId);
                formData.append('vocabularyId', this.contributeVocabularyPopupInfor.displayWord.vocabularyId);
                formData.append('vocabularyDivId', this.contributeVocabularyPopupInfor.displayWord.vocabularyDivId);
                formData.append('vocabularySpecializedDivId', this.contributeVocabularyPopupInfor.displayWord.vocabularySpecializedDivId);
                formData.append('vocabularyFieldDivId', this.contributeVocabularyPopupInfor.displayWord.vocabularyFieldDivId);
                formData.append('vocabularyName', this.contributeVocabularyPopupInfor.displayWord.vocabularyName);
                formData.append('vocabularySpelling', this.contributeVocabularyPopupInfor.displayWord.vocabularySpelling);
                formData.append('vocabularyMean', this.contributeVocabularyPopupInfor.displayWord.vocabularyMean);
                formData.append('vocabularyImage', this.contributeVocabularyPopupInfor.displayWord.vocabularyImage);
                formData.append('vocabularyAudio', this.contributeVocabularyPopupInfor.displayWord.vocabularyAudio);
                var payload = formData;
                repository.saveVocabularyContribute(payload)
                    .then((res) => {
                        if (res.status === 200) {
                            var data = res.data.data;
                            var _this = this;
                            $appMethods.showDialog(2, 'S014', function () {
                                _this.contributeVocabularyPopupInfor.showModal = false;
                            })
                            return res;
                        }
                    });
            } catch (e) {
                console.log("Action saveContribute: " + e.message);
            }
        },
        uploadCropImage(imageDataUrl, field) {
            try {
                var payload = {
                    img: imageDataUrl,
                };
                repository.uploadCropImage(payload)
                    .then((res) => {
                        if (res.status === 200) {
                            this.contributeVocabularyPopupInfor.displayWord.vocabularyImage = res.data.url;
                            return res;
                        }
                    });
            } catch (e) {
                console.log("Action saveVocabularyList: " + e.message);
            }
        },
        // doMission() {
        //     try {
        //         var payload = {
        //             mission_id: this.contributeVocabularyPopupInfor.missionId,
        //         };
        //         repository.doMission(payload)
        //             .then((res) => {
        //                 if (res.status === 200) {
        //                     var data = res.data.data;
        //                     var route = useRouter();
        //                     const { $appStates,$appMethods } = useNuxtApp();
        //                     this.updateContributeVocabularyPopupInfor(data[1][0]);
        //                     route.push({ path: data[1][0].missionLink });
        //                     $appMethods.getAppState();
        //                     $appStates.app.appInfor.isReloadContent = true;
        //                     this.contributeVocabularyPopupInfor.showModal = false;
        //                     return res;
        //                 }
        //             });
        //     } catch (e) {
        //         console.log("Action acceptMission: " + e.message);
        //     }
        // },
        // refuseMission() {
        //     try {
        //         const { $appStates,$appMethods } = useNuxtApp();
        //         var _this = this;
        //         $appMethods.showDialog(3, 'C008', function () {
        //             $appStates.dialogPopup.dialogPopupInfor.dialogButtons[0].buttonAction = function () {
        //                 var payload = {
        //                     mission_id: _this.contributeVocabularyPopupInfor.missionId,
        //                 };
        //                 repository.refuseMission(payload)
        //                     .then((res) => {
        //                         if (res.status === 200) {
        //                             var data = res.data.data;
        //                             $appMethods.getAppState();
        //                             // $appStates.app.appInfor.isReloadContent = true;
        //                             _this.contributeVocabularyPopupInfor.showModal = false;
        //                             $appMethods.hideDialog();
        //                             return res;
        //                         }
        //                     });
        //             }
        //         })
        //     } catch (e) {
        //         console.log("Action acceptMission: " + e.message);
        //     }
        // },
        // getNewsList(context, page) {
        //     context.commit('getNewsList', page);
        // },
        showContributeVocabularyPopup() {
            // this.updateContributeVocabularyPopupInfor({
            //     popupType: popupType,
            //     showModal: true,
            // });
            this.getContributeVocabularyPopupInfor();
        },
    }
});