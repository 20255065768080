import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47common_47404_47index_46vueMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/common/404/index.vue?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47conversation_47index_46vueMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/conversation/index.vue?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47conversation_47repository_46jsMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/conversation/repository.js?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47conversation_47store_46jsMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/conversation/store.js?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47dictionary_47index_46vueMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/dictionary/index.vue?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47dictionary_47repository_46jsMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/dictionary/repository.js?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47dictionary_47store_46jsMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/dictionary/store.js?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47discuss_47index_46vueMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/discuss/index.vue?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47discuss_47repository_46jsMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/discuss/repository.js?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47discuss_47store_46jsMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/discuss/store.js?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47grammar_47index_46vueMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/grammar/index.vue?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47grammar_47repository_46jsMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/grammar/repository.js?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47grammar_47store_46jsMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/grammar/store.js?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47homepage_47index_46vueMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/homepage/index.vue?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47homepage_47repository_46jsMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/homepage/repository.js?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47homepage_47store_46jsMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/homepage/store.js?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47listening_47index_46vueMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/listening/index.vue?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47listening_47repository_46jsMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/listening/repository.js?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47listening_47store_46jsMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/listening/store.js?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47pronunciation_47index_46vueMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/pronunciation/index.vue?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47pronunciation_47repository_46jsMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/pronunciation/repository.js?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47pronunciation_47store_46jsMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/pronunciation/store.js?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47reading_47index_46vueMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/reading/index.vue?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47reading_47repository_46jsMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/reading/repository.js?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47reading_47store_46jsMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/reading/store.js?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47vocabulary_47index_46vueMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/vocabulary/index.vue?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47vocabulary_47repository_46jsMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/vocabulary/repository.js?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47vocabulary_47store_46jsMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/vocabulary/store.js?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47writing_47index_46vueMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/writing/index.vue?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47writing_47repository_46jsMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/writing/repository.js?macro=true";
import { default as D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47writing_47store_46jsMeta } from "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/writing/store.js?macro=true";
export default [
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47common_47404_47index_46vueMeta?.name ?? "common-404",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47common_47404_47index_46vueMeta?.path ?? "/common/404",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/common/404/index.vue",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47common_47404_47index_46vueMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47common_47404_47index_46vueMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47common_47404_47index_46vueMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/common/404/index.vue").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47conversation_47index_46vueMeta?.name ?? "conversation",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47conversation_47index_46vueMeta?.path ?? "/conversation",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/conversation/index.vue",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47conversation_47index_46vueMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47conversation_47index_46vueMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47conversation_47index_46vueMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/conversation/index.vue").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47conversation_47repository_46jsMeta?.name ?? "conversation-repository",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47conversation_47repository_46jsMeta?.path ?? "/conversation/repository",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/conversation/repository.js",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47conversation_47repository_46jsMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47conversation_47repository_46jsMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47conversation_47repository_46jsMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/conversation/repository.js").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47conversation_47store_46jsMeta?.name ?? "conversation-store",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47conversation_47store_46jsMeta?.path ?? "/conversation/store",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/conversation/store.js",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47conversation_47store_46jsMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47conversation_47store_46jsMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47conversation_47store_46jsMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/conversation/store.js").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47dictionary_47index_46vueMeta?.name ?? "dictionary",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47dictionary_47index_46vueMeta?.path ?? "/dictionary",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/dictionary/index.vue",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47dictionary_47index_46vueMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47dictionary_47index_46vueMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47dictionary_47index_46vueMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/dictionary/index.vue").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47dictionary_47repository_46jsMeta?.name ?? "dictionary-repository",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47dictionary_47repository_46jsMeta?.path ?? "/dictionary/repository",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/dictionary/repository.js",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47dictionary_47repository_46jsMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47dictionary_47repository_46jsMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47dictionary_47repository_46jsMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/dictionary/repository.js").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47dictionary_47store_46jsMeta?.name ?? "dictionary-store",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47dictionary_47store_46jsMeta?.path ?? "/dictionary/store",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/dictionary/store.js",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47dictionary_47store_46jsMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47dictionary_47store_46jsMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47dictionary_47store_46jsMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/dictionary/store.js").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47discuss_47index_46vueMeta?.name ?? "discuss",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47discuss_47index_46vueMeta?.path ?? "/discuss",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/discuss/index.vue",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47discuss_47index_46vueMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47discuss_47index_46vueMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47discuss_47index_46vueMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/discuss/index.vue").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47discuss_47repository_46jsMeta?.name ?? "discuss-repository",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47discuss_47repository_46jsMeta?.path ?? "/discuss/repository",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/discuss/repository.js",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47discuss_47repository_46jsMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47discuss_47repository_46jsMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47discuss_47repository_46jsMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/discuss/repository.js").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47discuss_47store_46jsMeta?.name ?? "discuss-store",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47discuss_47store_46jsMeta?.path ?? "/discuss/store",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/discuss/store.js",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47discuss_47store_46jsMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47discuss_47store_46jsMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47discuss_47store_46jsMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/discuss/store.js").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47grammar_47index_46vueMeta?.name ?? "grammar",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47grammar_47index_46vueMeta?.path ?? "/grammar",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/grammar/index.vue",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47grammar_47index_46vueMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47grammar_47index_46vueMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47grammar_47index_46vueMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/grammar/index.vue").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47grammar_47repository_46jsMeta?.name ?? "grammar-repository",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47grammar_47repository_46jsMeta?.path ?? "/grammar/repository",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/grammar/repository.js",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47grammar_47repository_46jsMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47grammar_47repository_46jsMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47grammar_47repository_46jsMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/grammar/repository.js").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47grammar_47store_46jsMeta?.name ?? "grammar-store",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47grammar_47store_46jsMeta?.path ?? "/grammar/store",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/grammar/store.js",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47grammar_47store_46jsMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47grammar_47store_46jsMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47grammar_47store_46jsMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/grammar/store.js").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47homepage_47index_46vueMeta?.name ?? "homepage",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47homepage_47index_46vueMeta?.path ?? "/homepage",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/homepage/index.vue",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47homepage_47index_46vueMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47homepage_47index_46vueMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47homepage_47index_46vueMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/homepage/index.vue").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47homepage_47repository_46jsMeta?.name ?? "homepage-repository",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47homepage_47repository_46jsMeta?.path ?? "/homepage/repository",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/homepage/repository.js",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47homepage_47repository_46jsMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47homepage_47repository_46jsMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47homepage_47repository_46jsMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/homepage/repository.js").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47homepage_47store_46jsMeta?.name ?? "homepage-store",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47homepage_47store_46jsMeta?.path ?? "/homepage/store",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/homepage/store.js",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47homepage_47store_46jsMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47homepage_47store_46jsMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47homepage_47store_46jsMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/homepage/store.js").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47listening_47index_46vueMeta?.name ?? "listening",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47listening_47index_46vueMeta?.path ?? "/listening",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/listening/index.vue",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47listening_47index_46vueMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47listening_47index_46vueMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47listening_47index_46vueMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/listening/index.vue").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47listening_47repository_46jsMeta?.name ?? "listening-repository",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47listening_47repository_46jsMeta?.path ?? "/listening/repository",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/listening/repository.js",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47listening_47repository_46jsMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47listening_47repository_46jsMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47listening_47repository_46jsMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/listening/repository.js").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47listening_47store_46jsMeta?.name ?? "listening-store",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47listening_47store_46jsMeta?.path ?? "/listening/store",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/listening/store.js",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47listening_47store_46jsMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47listening_47store_46jsMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47listening_47store_46jsMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/listening/store.js").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47pronunciation_47index_46vueMeta?.name ?? "pronunciation",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47pronunciation_47index_46vueMeta?.path ?? "/pronunciation",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/pronunciation/index.vue",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47pronunciation_47index_46vueMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47pronunciation_47index_46vueMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47pronunciation_47index_46vueMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/pronunciation/index.vue").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47pronunciation_47repository_46jsMeta?.name ?? "pronunciation-repository",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47pronunciation_47repository_46jsMeta?.path ?? "/pronunciation/repository",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/pronunciation/repository.js",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47pronunciation_47repository_46jsMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47pronunciation_47repository_46jsMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47pronunciation_47repository_46jsMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/pronunciation/repository.js").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47pronunciation_47store_46jsMeta?.name ?? "pronunciation-store",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47pronunciation_47store_46jsMeta?.path ?? "/pronunciation/store",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/pronunciation/store.js",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47pronunciation_47store_46jsMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47pronunciation_47store_46jsMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47pronunciation_47store_46jsMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/pronunciation/store.js").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47reading_47index_46vueMeta?.name ?? "reading",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47reading_47index_46vueMeta?.path ?? "/reading",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/reading/index.vue",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47reading_47index_46vueMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47reading_47index_46vueMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47reading_47index_46vueMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/reading/index.vue").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47reading_47repository_46jsMeta?.name ?? "reading-repository",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47reading_47repository_46jsMeta?.path ?? "/reading/repository",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/reading/repository.js",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47reading_47repository_46jsMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47reading_47repository_46jsMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47reading_47repository_46jsMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/reading/repository.js").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47reading_47store_46jsMeta?.name ?? "reading-store",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47reading_47store_46jsMeta?.path ?? "/reading/store",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/reading/store.js",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47reading_47store_46jsMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47reading_47store_46jsMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47reading_47store_46jsMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/reading/store.js").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47vocabulary_47index_46vueMeta?.name ?? "vocabulary",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47vocabulary_47index_46vueMeta?.path ?? "/vocabulary",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/vocabulary/index.vue",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47vocabulary_47index_46vueMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47vocabulary_47index_46vueMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47vocabulary_47index_46vueMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/vocabulary/index.vue").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47vocabulary_47repository_46jsMeta?.name ?? "vocabulary-repository",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47vocabulary_47repository_46jsMeta?.path ?? "/vocabulary/repository",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/vocabulary/repository.js",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47vocabulary_47repository_46jsMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47vocabulary_47repository_46jsMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47vocabulary_47repository_46jsMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/vocabulary/repository.js").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47vocabulary_47store_46jsMeta?.name ?? "vocabulary-store",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47vocabulary_47store_46jsMeta?.path ?? "/vocabulary/store",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/vocabulary/store.js",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47vocabulary_47store_46jsMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47vocabulary_47store_46jsMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47vocabulary_47store_46jsMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/vocabulary/store.js").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47writing_47index_46vueMeta?.name ?? "writing",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47writing_47index_46vueMeta?.path ?? "/writing",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/writing/index.vue",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47writing_47index_46vueMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47writing_47index_46vueMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47writing_47index_46vueMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/writing/index.vue").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47writing_47repository_46jsMeta?.name ?? "writing-repository",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47writing_47repository_46jsMeta?.path ?? "/writing/repository",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/writing/repository.js",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47writing_47repository_46jsMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47writing_47repository_46jsMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47writing_47repository_46jsMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/writing/repository.js").then(m => m.default || m)
  },
  {
    name: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47writing_47store_46jsMeta?.name ?? "writing-store",
    path: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47writing_47store_46jsMeta?.path ?? "/writing/store",
    file: "D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/writing/store.js",
    children: [],
    meta: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47writing_47store_46jsMeta,
    alias: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47writing_47store_46jsMeta?.alias || [],
    redirect: D_58_47QUYND_47Training_47EPLUS_NUXT_47eplus365_nuxt3_47pages_47writing_47store_46jsMeta?.redirect || undefined,
    component: () => import("D:/QUYND/Training/EPLUS_NUXT/eplus365_nuxt3/pages/writing/store.js").then(m => m.default || m)
  }
]