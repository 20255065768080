<script setup>
definePageMeta({
    key: "static",
});
import { appStore } from "@/store/index.js";
import { discussStore } from "./store";
const { isMobile } = useDevice();
var app = appStore();
var discuss = discussStore();
var route = useRoute();
var data = {
    target_id: route.params.id[0] ?? "",
    tag_list: "",
    screen_div: "/discuss",
    page: 1,
    page_size: 10,
    refer_div: 0,
    records_num: discuss.discussInfor.numberOfNormalPost * discuss.discussInfor.extendTimesOfNormalPost,
    followed_num: discuss.discussInfor.numberOfFollowedPost * discuss.discussInfor.extendTimesOfFollowedPost,
};
if (app.appInfor.isRefresh) {
    const { $repository } = useNuxtApp();
    var res = await $repository({
        method: "post",
        url: `/discuss/get-discuss-infor`,
        data: data,
        showProgress: true,
        headers: {
            Authorization: `Bearer ${useCookie("eplus365token").value}`, //set token for ssr request
        },
    });
    discuss.setDiscussInfor(res);
    app.appInfor.isRefresh = false;
}
app.appInfor.isSwitching = false;
//phai tach cac watcher ra thanh cac ham roi gọi luon
</script>
<template>
    <div class="col-xs-12 web-panel middle-content">
        <left-tab />
        <div class="col-lg-9 col-xs-12 no-padding change-content border-left">
            <div class="right-header col-xs-12 no-padding">
                <div class="col-md-8 col-xs-12 no-padding">
                    <table class="full-width">
                        <tbody>
                            <tr>
                                <td v-if="!appInfor.isMobile" class="text-left">
                                    <h5 class="noselect" id="btn_prev" @click="previousRightTabItem()"><i class="glyphicon glyphicon-fast-backward"></i> TRƯỚC</h5>
                                </td>
                                <td class="text-center">
                                    <h5>
                                        <template v-if="appInfor.isMissionMode">
                                            <font-awesome-icon icon="fa-solid fa-futbol" spin />
                                            Nhiệm Vụ: Trùm Ngữ Pháp
                                        </template>
                                        <template v-else>
                                            <i class="glyphicon glyphicon-education"></i>
                                            HỌC NGỮ PHÁP TIẾNG ANH
                                        </template>
                                    </h5>
                                </td>
                                <td v-if="!appInfor.isMobile" class="text-right">
                                    <h5 class="margin-right float-right noselect" id="btn_next" @click="nextRightTabItem()">TIẾP <i class="glyphicon glyphicon-fast-forward"></i></h5>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <right-tab rememberText="Theo dõi" unrememberText="Bỏ theo dõi" :preventSwitchTab="true">
                <template v-slot:extendTop>
                    <div class="col-md-12 no-padding select-group">
                        <div class="form-group margin-bottom">
                            <v-select placeholder="Tìm kiếm câu hỏi theo tag" multiple :options="discussInfor.tagList.selectItems" label="tagName" :reduce="(item) => item.tagId" v-model="discussInfor.tagList.value" :class="rightTabInfor.rightTabItems[1].isError ? 'input-error' : ''">
                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching"> Không có kết quả trùng khớp. </template>
                                    <template v-else>
                                        <em style="opacity: 0.5">Lựa chọn tag để tìm kiếm.</em>
                                    </template>
                                </template>
                            </v-select>
                        </div>
                        <div v-if="rightTabInfor.rightTabLists.length > 0" v-tooltip="callHelpers('getPermissionText', appInfor.userPermission.isCanRegisterLesson)">
                            <button class="btn btn-sm btn-primary full-width" :disabled="appInfor.userPermission.isCanRegisterLesson != 1" @click="appInfor.userPermission.isCanRegisterLesson && searchQuestion()">Tìm kiếm</button>
                        </div>
                    </div>
                </template>
                <template v-slot:extends>
                    <button class="btn btn-sm btn-default full-width" type="button" @click="getMorePost()">Tải thêm bài viết</button>
                </template>
            </right-tab>
            <div class="col-md-8 col-md-pull-4 col-xs-12 web-main" :class="appInfor.isMobile ? '' : 'padding-left'">
                <content-loader v-if="discussInfor.isLoading && !appInfor.isMobile" viewBox="0 0 400 124" :speed="2" primaryColor="#aaa" secondaryColor="#888888">
                    <rect x="45%" y="3" rx="3" ry="3" width="15%" height="6" />
                    <rect x="65%" y="3" rx="3" ry="3" width="15%" height="6" />
                    <rect x="85%" y="3" rx="3" ry="3" width="15%" height="6" />
                    <rect x="0" y="13" rx="3" ry="3" width="100%" height="6" />
                    <rect x="0" y="25" rx="3" ry="3" width="100%" height="500" />
                </content-loader>
                <content-loader v-if="discussInfor.isLoading && appInfor.isMobile" viewBox="0 0 200 124" :speed="2" primaryColor="#aaa" secondaryColor="#888888">
                    <rect x="0" y="3" rx="3" ry="3" width="100%" height="6" />
                    <rect x="0" y="13" rx="3" ry="3" width="45%" height="6" />
                    <rect x="55%" y="13" rx="3" ry="3" width="45%" height="6" />
                    <rect x="0" y="23" rx="3" ry="3" width="45%" height="6" />
                    <rect x="55%" y="23" rx="3" ry="3" width="45%" height="6" />
                    <rect x="0" y="33" rx="3" ry="3" width="100%" height="6" />
                    <rect x="0" y="45" rx="3" ry="3" width="100%" height="500" />
                </content-loader>
                <template v-if="!discussInfor.isLoading && rightTabInfor.currentData.dataId != undefined">
                    <template v-if="rightTabInfor.currentData.dataId == 404">
                        <post-content contentType="404"></post-content>
                    </template>
                    <template v-else="!discussInfor.isLoading && rightTabInfor.currentData.dataId != undefined">
                        <div class="questions">
                            <qa-content :key="currentQuestion.discussId ?? ''" :question="currentQuestion" :paging="currentPaging">
                                <template v-slot:tagList>
                                    <template v-if="currentTagList.length > 0">
                                        <div class="btn btn-sm btn-link tag-item no-padding-left" v-for="tag in currentTagList" @click="searchQuestionByTag(tag.tagId)">#{{ tag.tagName }}</div>
                                    </template>
                                </template>
                                <template v-slot:extendBottom>
                                    <template v-for="answer in discussInfor.answerLists">
                                        <qa-content v-if="answer.targetId == currentQuestion.discussId" :isAnswer="true" :key="answer.discussId" :question="answer"></qa-content>
                                    </template>
                                </template>
                            </qa-content>
                        </div>
                    </template>
                </template>
                <template v-else-if="!discussInfor.isLoading && appInfor.isMissionMode">
                    <post-content contentType="missionMode"></post-content>
                </template>
                <template v-else-if="!discussInfor.isLoading && rightTabInfor.currentData.dataId == undefined">
                    <post-content contentType="welcome"></post-content>
                </template>
            </div>
        </div>
    </div>
    <ClientOnly>
        <PopupContributeDiscuss />
    </ClientOnly>
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
import { discussStore } from "./store";
import { contributeDiscussPopupStore } from "@/components/popup/contribute-discuss/store.js";
import repository from "@/repository";
import discussRepository from "./repository";
/**
 * Define main layout
 */

const Discuss = {
    name: "Discuss",
    components: {},
    beforeCreate() {},
    computed: {
        ...mapState(discussStore, ["discussInfor", "currentQuestion", "currentPaging", "currentTagList"]),
    },
    data() {
        return {
            showQuestionForm: false,
            newQuestion: {
                title: "",
                description: "",
                votes: 0,
                answers: [],
            },
            questions: [
                {
                    id: 1,
                    title: "How to use Vue Router?",
                    description:
                        "I am new to Vue.js and want to learn how to use Vue Router. Can anyone provide a step-by-step guide?I am new to Vue.js and want to learn how to use Vue Router. Can anyone provide a step-by-step guide?I am new to Vue.js and want to learn how to use Vue Router. Can anyone provide a step-by-step guide?I am new to Vue.js and want to learn how to use Vue Router. Can anyone provide a step-by-step guide?I am new to Vue.js and want to learn how to use Vue Router. Can anyone provide a step-by-step guide?I am new to Vue.js and want to learn how to use Vue Router. Can anyone provide a step-by-step guide?I am new to Vue.js and want to learn how to use Vue Router. Can anyone provide a step-by-step guide?I am new to Vue.js and want to learn how to use Vue Router. Can anyone provide a step-by-step guide?I am new to Vue.js and want to learn how to use Vue Router. Can anyone provide a step-by-step guide?",
                    votes: 0,
                    answers: [
                        { id: 1, content: "You can follow the official Vue Router documentation.", votes: 0 },
                        { id: 2, content: "There are many tutorials available on YouTube as well.", votes: 0 },
                    ],
                },
                // Add more example questions here
            ],
        };
    },
    mounted() {
        var { $bus } = useNuxtApp();
        var state = this;
        this.$watch(
            () => ({
                currentData: this.rightTabInfor.currentData,
            }),
            (rightTab) => {
                this.initPost(rightTab);
            },
            {
                deep: true,
            }
        );
        $bus.$on("submitedAnswer", (data) => {
            var answer = data[1][0];
            answer["comments"] = [];
            this.currentPaging.totalRecord = parseInt(this.currentPaging.totalRecord) + 1;
            this.discussInfor.answerLists.push(answer);
            // this.discussInfor.answerText = "";
        });
        $bus.$on("discussSaved", (res) => {
            var discussPostListsTemp = state.discussInfor.discussPostLists.filter((item) => item.targetId != res.data[2][0]["targetId"]);
            Array.prototype.push.apply(discussPostListsTemp, state.updateQuestionLists(res.data[2].slice(), res.data[4].slice()));
            state.discussInfor.discussPostLists = discussPostListsTemp;
            if (res.popupType == 0 && res.data[2][0]["targetId"] != state.rightTabInfor.currentData.dataId) {
                var rightTabListsTemp = state.rightTabInfor.rightTabLists.slice();
                rightTabListsTemp.push(res.data[3][0]);
                state.updateRightTabInfor({
                    rightTabLists: rightTabListsTemp,
                });
                state.selectRightTabItem(res.data[2][0]["targetId"]);
            }
            if (res.popupType == 1) {
                state.discussInfor.answerLists = state.updateQuestionLists(res.data[5].slice(), res.data[6].slice());
            }
        });
        $bus.$on("deletedDiscuss", (res) => {
            switch (res.delete_div) {
                case 0:
                    state.nextRightTabItem();
                    var discussPostListsTemp = state.discussInfor.discussPostLists.filter((item) => item.targetId != res.discuss.targetId);
                    state.discussInfor.discussPostLists = discussPostListsTemp;
                    var rightTabListsTemp = state.rightTabInfor.rightTabLists.filter((item) => item.dataId != res.discuss.targetId);
                    state.updateRightTabInfor({
                        rightTabLists: rightTabListsTemp,
                    });
                    break;
                case 1:
                    var discussPostListsTemp = state.discussInfor.answerLists.filter((item) => item.discussId != res.discuss.discussId);
                    state.discussInfor.answerLists = discussPostListsTemp;
                    break;
                case 2:
                    if (res.discuss.targetId == res.discuss.replyId) {
                        //comment of question
                        var currentPostTemp = state.discussInfor.discussPostLists.filter((item) => item.targetId == res.discuss.targetId);
                        var currentPostCommentTemp = currentPostTemp[0]["comments"].filter((item) => item.discussId != res.discuss.discussId);
                        currentPostTemp[0]["comments"] = currentPostCommentTemp;
                        var discussPostListsTemp = state.discussInfor.discussPostLists.filter((item) => item.targetId != res.discuss.targetId);
                        Array.prototype.push.apply(discussPostListsTemp, currentPostTemp);
                        state.discussInfor.discussPostLists = discussPostListsTemp;
                    } else {
                        var currentAnswerTemp = state.discussInfor.answerLists.filter((item) => item.targetId == res.discuss.targetId);
                        var currentAnswerCommentTemp = currentAnswerTemp[0]["comments"].filter((item) => item.discussId != res.discuss.discussId);
                        currentAnswerTemp[0]["comments"] = currentAnswerCommentTemp;
                        var discussAnswerListsTemp = state.discussInfor.answerLists.filter((item) => item.targetId != res.discuss.targetId);
                        Array.prototype.push.apply(discussAnswerListsTemp, currentAnswerTemp);
                        state.discussInfor.answerLists = discussAnswerListsTemp;
                    }
                    break;
                default:
                    break;
            }
        });
    },
    methods: {
        ...mapActions(discussStore, ["updateDiscussInfor", "getDiscussInfor", "setDiscussInfor", "getExample", "initPost", "updateQuestionLists"]),
        ...mapActions(contributeDiscussPopupStore, ["showContributeDiscussPopup"]),
        refreshScreen() {
            var data = {
                target_id: this.rightTabInfor.currentData.dataId,
                tag_list: this.discussInfor.tagList.value,
                screen_div: "/discuss",
                page: 1,
                page_size: 10,
                refer_div: 0,
                records_num: this.discussInfor.numberOfNormalPost * this.discussInfor.extendTimesOfNormalPost,
                followed_num: this.discussInfor.numberOfFollowedPost * this.discussInfor.extendTimesOfFollowedPost,
            };
            this.getDiscussInfor(data);
        },
        searchQuestion() {
            this.discussInfor.extendTimesOfNormalPost = 1;
            this.discussInfor.extendTimesOfFollowedPost = 1;
            var data = {
                target_id: "",
                tag_list: this.discussInfor.tagList.value,
                screen_div: "/discuss",
                page: 1,
                page_size: 10,
                refer_div: 0,
                records_num: this.discussInfor.numberOfNormalPost * this.discussInfor.extendTimesOfNormalPost,
                followed_num: this.discussInfor.numberOfFollowedPost * this.discussInfor.extendTimesOfFollowedPost,
            };
            const router = useRouter();
            router.push(router.currentRoute.value.meta.rootPath);
            this.getDiscussInfor(data);
        },
        searchQuestionByTag(tag) {
            var tagList = [];
            tagList.push(tag);
            this.discussInfor.extendTimesOfNormalPost = 1;
            this.discussInfor.extendTimesOfFollowedPost = 1;
            var data = {
                target_id: "",
                tag_list: tagList,
                screen_div: "/discuss",
                page: 1,
                page_size: 10,
                refer_div: 0,
                records_num: this.discussInfor.numberOfNormalPost * this.discussInfor.extendTimesOfNormalPost,
                followed_num: this.discussInfor.numberOfFollowedPost * this.discussInfor.extendTimesOfFollowedPost,
            };
            const router = useRouter();
            router.push(router.currentRoute.value.meta.rootPath);
            this.getDiscussInfor(data);
        },
        updatePagingContent(paging, page) {
            if (paging.pagingId == 1) {
                //update paging content
                var { $appMethods } = useNuxtApp();
                $appMethods.getExample(page);
            }
        },
        toggleQuestionForm() {
            this.showQuestionForm = !this.showQuestionForm;
        },
        submitQuestion() {
            this.newQuestion.id = Date.now();
            this.questions.push({ ...this.newQuestion, answers: [] });
            this.newQuestion = {
                title: "",
                description: "",
                votes: 0,
                answers: [],
            };
            this.showQuestionForm = false;
        },
        updatePagingContent(paging, page) {
            // if(paging.pagingId==1){
            //update paging content
            var state = this;
            var payload = {
                target_id: this.rightTabInfor.currentData.dataId,
                tag_list: this.discussInfor.tagList.value,
                screen_div: "/discuss",
                page: page,
                page_size: 10,
                refer_div: 1,
                records_num: this.discussInfor.numberOfNormalPost * this.discussInfor.extendTimesOfNormalPost,
                followed_num: this.discussInfor.numberOfFollowedPost * this.discussInfor.extendTimesOfFollowedPost,
            };
            discussRepository.getDiscussInfor(payload).then((res) => {
                const { data } = res.data;
                var discussPostListsTemp = state.discussInfor.discussPostLists.filter((item) => item.targetId != state.rightTabInfor.currentData.dataId);
                Array.prototype.push.apply(discussPostListsTemp, state.updateQuestionLists(data[1].slice(), data[3].slice()));
                state.discussInfor.discussPostLists = discussPostListsTemp;
                var answerListsTemp = state.discussInfor.answerLists.filter((item) => item.targetId != state.rightTabInfor.currentData.dataId);
                Array.prototype.push.apply(answerListsTemp, state.updateQuestionLists(data[4].slice(), data[5].slice()));
                state.discussInfor.answerLists = answerListsTemp;
                var pagingListTemp = state.discussInfor.pagingList.filter((item) => item.dataId != state.rightTabInfor.currentData.dataId);
                pagingListTemp.push(data[6][0]);
                state.discussInfor.pagingList = pagingListTemp;
                // state.setDiscussInfor(res);
            });
            // this.getDiscussInfor(data);
            // }
        },
        getMorePost() {
            if (this.rightTabInfor.isRememberedTab) {
                this.discussInfor.extendTimesOfFollowedPost++;
            } else {
                this.discussInfor.extendTimesOfNormalPost++;
            }
            this.refreshScreen();
        },
        // submitAnswer() {
        //     var route = useRoute();
        //     const payload = {
        //         target_id: this.rightTabInfor.currentData.dataId,
        //         reply_id: this.rightTabInfor.currentData.dataId,
        //         text: this.discussInfor.answerText,
        //         comment_div: 1,
        //         screen_div: route.meta.rootPath,
        //     };
        //     repository.addComment(payload).then((res) => {
        //         const data = res.data.data;
        //         var answer = data[1][0];
        //         answer["comments"] = [];
        //         this.currentPaging.totalRecord = parseInt(this.currentPaging.totalRecord) + 1;
        //         this.discussInfor.answerLists.push(answer);
        //         this.discussInfor.answerText = "";
        //     });
        // },
        upvote(item) {
            item.votes++;
        },
        downvote(item) {
            if (item.votes > 0) {
                item.votes--;
            }
        },
    },
};
export default defineNuxtComponent(Discuss);
</script>
<style lang="scss">
@import "./style.scss";
</style>
