import { appStore } from "@/store";

export const fixedBottomBarStore = defineStore('fixedBottomBar',{
    namespaced: true,
    state: () => ({
        fixedBottomBarInfor: {
            isShowBottomBar: false,
            lastScrollPosition:0,
            bottomBarTitle:'Học Ngữ Pháp Tiếng Anh',
        }
    }),
    mutations: {
        updateFixedBottomBarInfor(state, fixedBottomBarInfor) {
            Object.keys(fixedBottomBarInfor).forEach(function (key) {
                state.fixedBottomBarInfor[key] = fixedBottomBarInfor[key];
            })
        },
    },
    // getters: {
    //     remainQuestionIdList: state => state.fixedBottomBarInfor.remainQuestionIdList,
    // },
    actions: {
        updateFixedBottomBarInfor(fixedBottomBarInfor) {
            var state = this;
            Object.keys(fixedBottomBarInfor).forEach(function (key) {
                state.fixedBottomBarInfor[key] = fixedBottomBarInfor[key];
            })
        },
        backToTop() {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
            // setTimeout(() => {
            //     context.commit('updateFixedBottomBarInfor',{
            //         isShowBottomBar:false
            //     });
            // }, 500);
		},
        showFixedBottomBar(){
            var app = appStore();
			this.updateFixedBottomBarInfor({
                isShowBottomBar:app.appInfor.isMobile
            });
		},
    }
});