import repository from "@/repository";
// import store from "@/store";
import { rightTabStore } from "../right-tab/store";
export const wordRelationshipStore = defineStore('wordRelationship', {
    namespaced: true,
    state: () => ({
        wordRelationshipInfor: {
            isLoading: false,
            componentId: '0',
            wordRelationshipLabel: 'Từ Đồng Nghĩa',
            wordRelationshipLabelIcon: 'handshake',
            wordRelationshipResource: 'common',
            wordRelationshipLists: [
                {
                    dataId: '1',
                    wordRelationshipId: '1',
                    wordRelationshipContributeUserName: 'Kirito',
                    wordRelationshipContributeUserRank: 'S+',
                    wordRelationshipContributeUserAvartaImage: '/web-content/images/avarta/default_avarta.jpg',
                    wordRelationshipContributeDateTime: '17/10/2022 14:46',
                    wordRelationshipEnglish: 'trust your self',
                    wordRelationshipVietNamese: 'tin vào bản thân',
                    wordRelationshipRatingValue: '2114',
                    myVote: 0,
                },
            ],
            wordRelationshipDisplayLists: [],
            currentPaging: {},
            pagingLists: [
                {
                    dataId: "1",
                    pagingId: "1",
                    currentPage: "3",
                    totalPage: "3",
                    totalRecord: "1",
                    pagingSize: "4",
                },
            ],
            rules:{
                'wordRelationshipInputEnglish.value': 'required',
                'wordRelationshipInputVietNamese.value': 'required',
            },
        }
    }),
    actions: {
        updateWordRelationshipInfor(wordRelationshipInfor) {
            var state = this;
            Object.keys(wordRelationshipInfor).forEach(function (key) {
                state.wordRelationshipInfor[key] = wordRelationshipInfor[key];
            })
        },
        addWordRelationship() {
            try {
                var rightTab = rightTabStore();
                var state = this;
                const { $Validator } = useNuxtApp();
                var validator = $Validator.checkValidate(state.wordRelationshipInfor.rules,state.wordRelationshipInfor);
                if(validator){
                    var currentDataId = rightTab.currentDataId;
                    var payload = {
                        target_id: currentDataId,
                        target_div: 2, //wordRelationship for a post
                        screen_div: localStorage.getItem("currentUrl"),
                        english_clause: state.wordRelationshipInfor.wordRelationshipInputEnglish.value ?? '',
                        vietnamese_clause: state.wordRelationshipInfor.wordRelationshipInputVietNamese.value ?? '',
                    };
                    repository.addWordRelationship(payload)
                        .then((res) => {
                            // const { data } = res;
                            if (res.status === 200) {
                                var data = res.data.data;
                                state.wordRelationshipInfor.wordRelationshipLists.unshift(data[1][0]);
                                state.wordRelationshipInfor.wordRelationshipInputEnglish = '';
                                state.wordRelationshipInfor.wordRelationshipInputVietNamese = '';
                                return res;
                            }
                        });
                }
            } catch (e) {
                console.log("Action addWordRelationship: " + e.message);
            }
        },
        getWordRelationship(page) {
            try {
                var rightTab = rightTabStore();
                var state = this;
                var userInfor = JSON.parse(localStorage.getItem("userInfor"));
                var currentDataId = rightTab.currentDataId;
                var payload = {
                    target_id: currentDataId,
                    target_div: 2, //wordRelationship for a post
                    account_id: userInfor ? userInfor.account_id : '',
                    screen_div: localStorage.getItem("currentUrl"),
                    page: page,
                    page_size: 5,
                };
                repository.getWordRelationship(payload)
                    .then((res) => {
                        // const { data } = res;
                        if (res.status === 200) {
                            var data = res.data.data;
                            var currentWordRelationshipList = state.wordRelationshipInfor.wordRelationshipLists.filter(function (el) {
                                return el.dataId !== currentDataId;
                            });
                            //convert from vue object to normal object
                            currentWordRelationshipList = JSON.parse(JSON.stringify(currentWordRelationshipList));
                            // state.wordRelationshipInfor.grammarPostWordRelationshipLists = data[0].concat(currentWordRelationshipList);
                            this.updateWordRelationshipInfor({
                                wordRelationshipLists: data[0].concat(currentWordRelationshipList),
                            });
                            var currentPaging = state.wordRelationshipInfor.pagingLists.filter(function (el) {
                                return el.dataId != currentDataId;
                            });
                            //convert from vue object to normal object
                            currentPaging = JSON.parse(JSON.stringify(currentPaging));
                            state.wordRelationshipInfor.pagingLists = data[1].concat(currentPaging);
                            this.updateWordRelationshipInfor({
                                currentPaging: data[1][0],
                            });
                            return res;
                        }
                    });
            } catch (e) {
                console.log("Action getWordRelationshipPage: " + e.message);
            }
        },
        voteWordRelationship(payload,component) {
            try {
                var params = {
                    source_id: payload.wordRelationship.dataId,
                    target_id: payload.wordRelationship.wordRelationshipId,
                    my_vote: payload.voteValue, //wordRelationship for a post
                    execute_div: payload.voteDiv, //this is a vote
                    screen_div: localStorage.getItem("currentUrl"),
                };
                repository.voteAction(params)
                    .then((res) => {
                        // const { data } = res;
                        if (res.status === 200) {
                            var data = res.data.data;
                            var currentWordRelationshipList = component.wordRelationshipLists.filter(function (el) {
                                return el.wordRelationshipId == payload.wordRelationship.wordRelationshipId;
                            });
                            if(currentWordRelationshipList[0]!=undefined){
                                var index = component.wordRelationshipLists.indexOf(currentWordRelationshipList[0]);
                                component.wordRelationshipLists[index].myVote = data[1][0].myVote;
                                component.wordRelationshipLists[index].wordRelationshipRatingValue = data[1][0].voteValue;
                            }
                            return res;
                        }
                    });
            } catch (e) {
                console.log("Action voteWordRelationship: " + e.message);
            }
        },
    }
});