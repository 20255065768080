// import repository from "@/repository";
import vocabularyRepository from "./repository";
import { appStore } from "@/store";
import { rightTabStore } from "~~/components/right-tab/store";
import { leftTabStore } from "~~/components/left-tab/store";
import { exampleStore } from "~~/components/example/store";
import { postContentStore } from "~~/components/post-content/store";
import { exerciseStore } from "~~/components/exercise/store";
import { vocabularyContentStore } from "~~/components/vocabulary-content/store";
import { wordRelationshipStore } from "~~/components/word-relationship/store";
export const vocabularyStore = defineStore('vocabulary', {
    namespaced: true,
    state: () => ({
        vocabularyInfor: {
            isLoading: false,
            questionListTitle: 'Bài Tập Tự Luyện',
            welcomeSlogan: 'Chào mừng bạn đến với khóa học từ vựng của Eplus365!',
            hintList: [
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Danh sách bên phải bao gồm tất cả các từ vựng có trong hệ thống.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Bạn có thể lọc và chọn bài viết từ vựng mong muốn dựa theo danh mục và nhóm ở tab bên phải.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Bạn cũng có thể lưu danh mục/nhóm từ vựng mong muốn bằng chức năng "Lưu Danh Mục/Nhóm" danh sách đã lưu sẽ được hiển thị ở bên trái.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Bạn có thể lưu/học lại từ vựng trong danh sách bằng chức năng "Đã học" hoặc "Đã quên" tương ứng với tab "Ngữ Pháp Chưa Học" và "Ngữ Pháp Đã Học".',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Bên dưới mỗi từ vựng đều sẽ có danh sách các bài tập để các bạn luyện tập cho từ vựng đó.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Các bạn cũng có thể đóng góp các ví dụ bổ ích cho Eplus365,mỗi đóng góp đều rất là đáng quý đối với cộng đồng.',
                },
            ],
            learnVocabularyOptions: {
                checkedOptions: [],
                allOption: [
                    {
                        optionId: '1',
                        optionTitle: 'Hình ảnh',
                    },
                    {
                        optionId: '2',
                        optionTitle: 'Âm thanh',
                    },
                    {
                        optionId: '3',
                        optionTitle: 'Từ tiếng anh',
                    },
                    {
                        optionId: '4',
                        optionTitle: 'Loại từ',
                    },
                    {
                        optionId: '5',
                        optionTitle: 'Nghĩa',
                    },
                ],
            },
            vocabularyPostLists: [
                {
                    vocabularyId: '1',
                    vocabularyTitle: 'hello',
                    vocabularyDiv: '1',
                    vocabularyDivName: 'Danh từ',
                    vocabularySpecializedDiv: '1',
                    vocabularySpecializedName: 'Kinh tế',
                    vocabularyFieldDiv: '1',
                    vocabularyFieldName: 'Âm nhạc',
                    vocabularySpelling: 'æmp (æmplɪfaɪə)',
                    vocabularyMean: 'Xin chào',
                    vocabularyAudio: '',
                    vocabularyRemembered: false,
                },
            ],
            wordRelationshipSynonymsList: [],
            wordRelationshipOppositeList: [],
        }
    }),
    getters: {
        vocabularyShowList(state) {
            var { $appStates } = useNuxtApp();
            var rightTabInfor = $appStates.rightTab.rightTabInfor;
            var vocabularyShowList = state.vocabularyInfor.vocabularyPostLists.filter(function (el) {
                var catalogueId = rightTabInfor.rightTabItems[0].value ?? '';
                var groupId = rightTabInfor.rightTabItems[1].value ?? '';
                catalogueId = (catalogueId == '' ? el.catalogueId : catalogueId);
                groupId = (groupId == '' ? el.groupId : groupId);
                return el.catalogueId == catalogueId && el.groupId == groupId;
            });
            return vocabularyShowList;
        },
    },
    actions: {
        updateVocabularyInfor(vocabularyInfor) {
            var state = this;
            Object.keys(vocabularyInfor).forEach(function (key) {
                state.vocabularyInfor[key] = vocabularyInfor[key];
            })
        },
        // updateVocabularyPostQuestionLists(sqlQuestionLists) {
        //     var state = this;
        //     var questionLists = state.vocabularyInfor.vocabularyPostQuestionLists;
        //     questionLists = sqlQuestionLists['question'];
        //     Object.keys(questionLists).forEach(function (key) {
        //         var questionAnswer = sqlQuestionLists['answer'].filter(function (el) {
        //             return el.questionId == questionLists[key].questionId;
        //         });
        //         questionLists[key]['answerLists'] = questionAnswer;
        //     })
        //     state.vocabularyInfor.vocabularyPostQuestionLists = questionLists;
        // },
        getVocabularyInfor(payload) {
            try {
                var app = appStore();
                // var rightTab = rightTabStore();
                var state = this;
                app.appInfor.isSwitching = true;
                // state.vocabularyInfor.isLoading = true;
                // rightTab.updateRightTabInfor({
                //     isLoading: true,
                // });
                vocabularyRepository.getVocabularyInfor(payload)
                    .then((res) => {
                        // const { data } = res;
                        state.setVocabularyInfor(res);
                    });
            } catch (e) {
                console.log("Action getVocabularyInfor: " + e.message);
            }
        },
        setVocabularyInfor(res) {
            // const { data } = res;
            var app = appStore();
            var rightTab = rightTabStore();
            var leftTab = leftTabStore();
            var example = exampleStore();
            var exercise = exerciseStore();
            var vocabularyContent = vocabularyContentStore();
            var wordRelationship = wordRelationshipStore();
            var state = this;
            var route = useRoute();
            var router = useRouter();
            state.vocabularyInfor.isLoading = true;
            // rightTab.updateRightTabInfor({
            //     isLoading: true,
            // });
            if (res.status === 200) {
                var data = res.data.data;
                if (data[9][0] && data[9][0]['missionId'] != 0) {
                    app.appInfor.isMissionMode = true;
                    app.appInfor.doingMission = data[9][0];
                    leftTab.leftTabInfor.renderLessonList = false;
                    rightTab.rightTabInfor.isDisplaySelect = false;
                    rightTab.rightTabInfor.isDisplayButton = false;
                    rightTab.rightTabInfor.isChangeRouter = false;
                    router.push(data[9][0]['missionLink']);
                } else {
                    app.appInfor.isMissionMode = false;
                    app.appInfor.doingMission = {};
                    leftTab.leftTabInfor.renderLessonList = true;
                    rightTab.rightTabInfor.isDisplaySelect = true;
                    rightTab.rightTabInfor.isDisplayButton = true;
                    rightTab.rightTabInfor.isChangeRouter = true;
                }
                rightTab.rightTabInfor.isDisplayVote = false;
                vocabularyContent.vocabularyContentInfor.isShowVocabularyOptions = true;
                var selectedCategory = data[0].filter(obj => {
                    return obj.selected === true
                })
                rightTab.updateRightTabItems([
                    {
                        selectItems: data[0],
                        value: selectedCategory[0] ? selectedCategory[0].catalogueId : '',
                    },
                    0,
                ]);
                var selectedGroup = data[1].filter(obj => {
                    return obj.selected === true
                })
                rightTab.updateRightTabItems([
                    {
                        selectItems: data[1],
                        value: selectedGroup[0] ? selectedGroup[0].groupId : '',
                    },
                    1
                ]);
                leftTab.updateLeftTabInfor({
                    lessonLists: data[2],
                    isLessonListLoading: false,
                });
                var rightTabMenus = [];
                if (app.appInfor.isMissionMode) {
                    rightTabMenus = [
                        {
                            rightTabMenuLink: 'tab1',
                            rightTabMenuName: 'Nội Dung Nhiệm Vụ',
                            isActive: true
                        }
                    ];
                } else {
                    rightTabMenus = [
                        {
                            rightTabMenuLink: 'tab1',
                            rightTabMenuName: 'Từ Vựng Chưa Học',
                            isActive: true
                        },
                        {
                            rightTabMenuLink: 'tab2',
                            rightTabMenuName: 'Từ Vựng Đã Học',
                            isActive: false
                        },
                    ];
                }
                rightTab.updateRightTabInfor({
                    rightTabMenus: rightTabMenus,
                });
                var selectedTab = data[4].filter(obj => {
                    return obj.isActive === true
                })
                if (selectedTab[0] != undefined) {
                    rightTab.updateRightTabInfor({
                        isRememberedTab: selectedTab[0].remembered,
                    });
                }
                rightTab.updateRightTabInfor({
                    allDataTabLists: data[4],
                    isLoading: false,
                    isEncrypt: !vocabularyContent.vocabularyContentInfor.learnVocabularyOptions.raw.isChecked,
                });
                rightTab.updateRightTabInfor({
                    rightTabLists: rightTab.rightTabLists,
                });
                rightTab.updateRightTabInfor({
                    unRememberedTabLists: rightTab.unRememberedTabLists,
                    rememberedTabLists: rightTab.rememberedTabLists,
                });
                rightTab.selectRightTabItem(route.params.id);
                vocabularyContent.updateVocabularyContentInfor({
                    vocabularyLists: data[3].slice()
                });
                // exercise.updateQuestionLists(data[5].slice(),data[6].slice());
                this.updateVocabularyInfor({
                    //use slice to copy array
                    wordRelationshipSynonymsList: data[5].slice(),
                    wordRelationshipOppositeList: data[6].slice(),
                });
                example.updateExampleInfor({
                    //use slice to copy array
                    exampleLists: data[7].slice(),
                    pagingLists: data[8].slice(),
                });
                state.vocabularyInfor.vocabularyPostLists = data[3];
                this.initPost();
                // if(selectedTab[0].dataId!=undefined){
                //     this.dispatch('rightTab/selectRightTabItem',selectedTab[0].dataId);
                // }
                state.vocabularyInfor.isLoading = false;
                app.appInfor.isReloadContent = false;
                app.appInfor.isSwitching = false;
                return res;
            }
        },
        initPost() {
            var postContent = postContentStore();
            postContent.updateWelcomeContentInfor({
                welcomeSlogan: this.vocabularyInfor.welcomeSlogan,
                hintList: this.vocabularyInfor.hintList,
            });
        }
    },
});