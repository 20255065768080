import repository from "@/repository";
export const exerciseStore = defineStore('exercise', {
    namespaced: true,
    state: () => ({
        exerciseInfor: {
            isLoading: false,
            exerciseLabel: 'Bài Tập Tự Luyện',
            exerciseLabelIcon: 'book',
            questionResource: 'common',
            remainQuestionIdList: [],
            questionLists: [],
        }
    }),
    getters: {
        remainQuestionIdList: state => state.exerciseInfor.remainQuestionIdList,
        currentDataQuestions(state) {
            var { $appStates } = useNuxtApp();
            var questionLists = state.exerciseInfor.questionLists.filter(function (el) {
                return el.dataId == Number($appStates.rightTab.rightTabInfor.currentData.dataId);
            });
            return questionLists;
        },
    },
    actions: {
        updateExerciseInfor(exerciseInfor) {
            var state = this;
            Object.keys(exerciseInfor).forEach(function (key) {
                state.exerciseInfor[key] = exerciseInfor[key];
            })
        },
        checkQuestionAnswer() {
            var state = this;
            var { $callHelpers, $appStates, $appMethods } = useNuxtApp();
            var incorrectCount = 0;
            var questionLists = state.exerciseInfor.questionLists.filter(function (el) {
                return el.dataId == Number($appStates.rightTab.rightTabInfor.currentData.dataId);
            });
            questionLists.forEach(question => {
                if (question.questionDiv == 0) {
                    //radio question
                    var userAnswered = question.answerLists.filter(function (el) {
                        return el.questionAnswerId == Number(question.userAnsweredList);
                    });
                    //if user do not checked any answer-->skip
                    if (userAnswered[0] === undefined) {
                        question.answerResult = '';
                        question.isShowExplan = false;
                        incorrectCount++;
                        return;
                    }
                    //if user checked any answer-->check
                    if (userAnswered[0].isTrueAnswer) {
                        question.answerResult = 'right-answer';
                        setTimeout(() => {
                            question.isShowExplan = true;
                        }, 500);
                    } else {
                        question.answerResult = 'wrong-answer';
                        question.isShowExplan = false;
                        incorrectCount++;
                        setTimeout(() => {
                            question.answerResult = '';
                            question.userAnsweredList = [];
                            question.answerLists = $callHelpers('shuffleArray', [question.answerLists]);
                        }, 1000);
                    }
                } else {
                    //checkbox question
                    //if user do not checked any answer-->skip
                    if (question.userAnsweredList.length == 0) {
                        question.answerResult = '';
                        question.isShowExplan = false;
                        incorrectCount++;
                        return;
                    }
                    //if user checked any answer-->check
                    //get true answer object
                    var filtered = question.answerLists.filter(function (el) {
                        return el.isTrueAnswer == true
                    });
                    //get true answer dataId list
                    var trueAnswerList = filtered.flatMap((object) => object.questionAnswerId);
                    //compare true answer dataId list with user answered list
                    if (JSON.stringify(trueAnswerList) == JSON.stringify(question.userAnsweredList)) {
                        question.answerResult = 'right-answer';
                        setTimeout(() => {
                            question.isShowExplan = true;
                        }, 500);
                    } else {
                        question.answerResult = 'wrong-answer';
                        question.isShowExplan = false;
                        incorrectCount++;
                        setTimeout(() => {
                            question.answerResult = '';
                            question.userAnsweredList = [];
                            question.answerLists = $callHelpers('shuffleArray', [question.answerLists]);
                        }, 1000);
                    }
                }
            });
            var activeData = $appStates.rightTab.rightTabInfor.rightTabLists.filter(function (el) {
                return el.isActive == true;
            });
            if ($appStates.app.appInfor.isMissionMode && incorrectCount == 0) {
                activeData[0].dataIcon = 'circle-check';
                var unAnsweredQuestions = state.exerciseInfor.questionLists.filter(function (el) {
                    return el.answerResult != 'right-answer';
                });
                if (unAnsweredQuestions.length != 0) {
                    $appMethods.showDialog(2, 'S006', function () {
                        $appStates.dialogPopup.dialogPopupInfor.dialogButtons[0].buttonTitle = 'OK';
                        $appStates.dialogPopup.dialogPopupInfor.dialogButtons[0].buttonAction = function () {
                            $appMethods.selectRightTabItem(unAnsweredQuestions[0].dataId);
                            $appMethods.hideDialog();
                        }
                    })
                } else {
                    // repository.completeMission({
                    //     mission_id: $appStates.app.appInfor.doingMission.missionId,
                    // })
                    //     .then((res) => {
                    //         const { data } = res.data;
                    //         if (res.status === 200) {
                    //             $appMethods.showDialog(2, 'S007', function () {
                    //                 var messageParams = [
                    //                     'S007',
                    //                     [
                    //                         data[1][0].successMissionExp * data[1][0].difficultLevel,
                    //                         data[1][0].successMissionCtp * data[1][0].difficultLevel,
                    //                     ]
                    //                 ];
                    //                 $appStates.dialogPopup.dialogPopupInfor.dialogMessage = $callHelpers('getMessage', messageParams);
                    //                 $appStates.dialogPopup.dialogPopupInfor.dialogButtons[0].buttonAction = function () {
                    //                     $appMethods.getAppState();
                    //                     $appStates.app.appInfor.isReloadContent = true;
                    //                     $appMethods.hideDialog();
                    //                 }
                    //             });
                    //             return res;
                    //         }
                    //     });
                    $appMethods.completeMission();
                }
            } else {
                if ($appStates.app.appInfor.isMissionMode) {
                    activeData[0].dataIcon = "circle-question";
                }
            }
        },
        initQuestion() {
            var state = this;
            if (this.remainQuestionIdList.length !== 0) {
                state.exerciseInfor.answeredLists = [];
                state.exerciseInfor.answerResult = '';
                state.exerciseInfor.isShowExplan = false;
                state.exerciseInfor.currentQuestion = state.exerciseInfor.questionLists.filter(function (el) {
                    return el.questionId == state.exerciseInfor.remainQuestionIdList[0].questionId;
                });
                state.exerciseInfor.remainQuestionIdList.shift();
                state.exerciseInfor.isLoading = false;
            }
        },
        refreshQuestionList() {
            try {
                var state = this;
                repository.refreshQuestionList({
                    dataId: { targetId: state.currentDataQuestions[0].dataId },
                }, state.exerciseInfor.questionResource)
                    .then((res) => {
                        // const { data } = res;
                        if (res.status === 200) {
                            var data = res.data.data;
                            this.updateQuestionLists(data[0], data[1]);
                            return res;
                        }
                    });
            } catch (e) {
                console.log("Action getAppState: " + e.message);
            }
        },
        updateQuestionLists(sqlQuestions, sqlAnswers) {
            var state = this;
            var questionLists = state.exerciseInfor.questionLists;
            sqlQuestions.forEach(function (sqlQuestion) {
                questionLists = questionLists.filter(function (el) {
                    return el.dataId != sqlQuestion.dataId;
                });
            });
            Object.keys(sqlQuestions).forEach(function (key) {
                var questionAnswer = sqlAnswers.filter(function (el) {
                    return el.questionId == sqlQuestions[key].questionId;
                });
                sqlQuestions[key]['answerLists'] = questionAnswer;
                sqlQuestions[key]['userAnsweredList'] = [];
            })
            questionLists = JSON.parse(JSON.stringify(questionLists));
            Object.keys(sqlQuestions).forEach(function (key1) {
                questionLists.push(sqlQuestions[key1]);
            });
            state.exerciseInfor.questionLists = questionLists;
        },
    },
});