import repository from "@/repository";
// import router from "@/router";
// import appStore from "@/store";
import { appStore } from "@/store";
import { leftTabStore } from "../left-tab/store";

export const rightTabStore = defineStore('rightTab', {
    namespaced: true,
    state: () => ({
        rightTabInfor: {
            rightTabSize: 'col-lg-4 col-md-push-8',
            isLoading: true,
            isEncrypt: false,
            isDisplaySelect: true,
            isDisplayButton: true,
            isDisplayVote: false,
            isChangeRouter: true,
            currentData: {},
            currentLinkId: 0,
            allDataTabLists: [],
            unRememberedTabLists: [],
            rememberedTabLists: [],
            isRememberedTab: false,
            rightTabMenus: [
                {
                    rightTabMenuLink: 'tab1',
                    rightTabMenuName: 'Ngữ Pháp Chưa Học',
                    isActive: true
                },
                {
                    rightTabMenuLink: 'tab2',
                    rightTabMenuName: 'Ngữ Pháp Đã Học',
                    isActive: false
                },
            ],
            rightTabItems: [
                {
                    itemId: 'catalogue_nm',
                    itemName: 'Danh Mục Ngữ Pháp',
                    itemType: 'select',
                    selected: '',
                    value: '',
                    isError: false,
                    errorMessage: '',
                    selectItems: [
                        { catalogueName: 'Canada2', catalogueId: '1' },
                        { catalogueName: 'Canada3', catalogueId: '2' },
                        { catalogueName: 'Canada4', catalogueId: '3' },
                    ],
                },
                {
                    itemId: 'group_nm',
                    itemName: 'Nhóm Ngữ Pháp',
                    itemType: 'select',
                    selected: '',
                    value: '',
                    isError: false,
                    errorMessage: '',
                    selectItems: [
                        { catalogueId: '1', groupName: 'Canada2', groupId: 'ca2' },
                        { catalogueId: '1', groupName: 'Canada3', groupId: 'ca3' },
                        { catalogueId: '2', groupName: 'Canada4', groupId: 'ca4' },
                    ],
                    displaySelectItems: [
                        { catalogueId: '1', groupName: 'Canada2', groupId: 'ca2' },
                        { catalogueId: '1', groupName: 'Canada3', groupId: 'ca3' },
                        { catalogueId: '2', groupName: 'Canada4', groupId: 'ca4' },
                    ],
                },
            ],
            rightTabLists: [],
            rules: {
                'rightTabItems[0].value': 'required',
                'rightTabItems[1].value': 'required',
            },
        },
    }),
    getters: {
        currentDataId(state) {
            return state.rightTabInfor.currentData.dataId;
        },
        currentTabLists(state) {
            return state.rightTabInfor.isRememberedTab ? state.rightTabInfor.rememberedTabLists : state.rightTabInfor.unRememberedTabLists;
        },
        nextDataId(state) {
            var dataIdList = this.currentTabLists.map(a => a.dataId);
            var currentIndex = dataIdList.indexOf(state.rightTabInfor.currentData.dataId);
            var nextIndex = 0;
            if (currentIndex == (this.currentTabLists.length - 1)) {
                nextIndex = 0;
            } else {
                nextIndex = currentIndex + 1;
            }
            return this.currentTabLists[nextIndex].dataId;
        },
        previousDataId(state) {
            var dataIdList = this.currentTabLists.map(a => a.dataId);
            var currentIndex = dataIdList.indexOf(state.rightTabInfor.currentData.dataId);
            var previousIndex = 0;
            if (currentIndex == 0) {
                previousIndex = this.currentTabLists.length - 1;
            } else {
                previousIndex = currentIndex - 1;
            }
            return this.currentTabLists[previousIndex].dataId;
        },
        unRememberedTabLists(state) {
            var unRememberedTabLists = state.rightTabInfor.rightTabLists.filter(function (el) {
                return el.remembered == false;
            });
            return unRememberedTabLists;
        },
        rememberedTabLists(state) {
            var rememberedTabLists = state.rightTabInfor.rightTabLists.filter(function (el) {
                return el.remembered == true;
            });
            return rememberedTabLists;
        },
        rightTabLists(state) {
            var rightTabLists = state.rightTabInfor.allDataTabLists.filter(function (el) {
                var catalogueId = state.rightTabInfor.rightTabItems[0].value ?? '';
                var groupId = state.rightTabInfor.rightTabItems[1].value ?? '';
                catalogueId = (catalogueId == '' ? el.catalogueId : catalogueId);
                groupId = (groupId == '' ? el.groupId : groupId);
                return el.catalogueId == catalogueId && el.groupId == groupId;
            });
            return rightTabLists;
        },
        displaySelectItems(state) {
            var displaySelectItems = state.rightTabInfor.rightTabItems[1].selectItems.filter(function (el) {
                var catalogueId = state.rightTabInfor.rightTabItems[0].value ?? '';
                catalogueId = (catalogueId == '' ? el.catalogueId : catalogueId);
                return el.catalogueId == catalogueId;
            });
            return displaySelectItems;
        },
    },
    actions: {
        updateRightTabInfor(rightTabInfor) {
            var state = this;
            Object.keys(rightTabInfor).forEach(function (key) {
                state.rightTabInfor[key] = rightTabInfor[key];
            })
        },
        updateRightTabItems([rightTabItems, index]) {
            var state = this;
            Object.keys(rightTabItems).forEach(function (key) {
                state.rightTabInfor.rightTabItems[index][key] = rightTabItems[key];
            })
        },
        setLinkSelectItem(linkId) {
            var state = this;
            const router = useRouter();
            var app = appStore();
            var { $callHelpers } = useNuxtApp();
            if (linkId == '') {
                if (state.rightTabInfor.isRememberedTab) {
                    linkId = state.rightTabInfor.rememberedTabLists[0] ? state.rightTabInfor.rememberedTabLists[0].dataId : '';
                } else {
                    linkId = state.rightTabInfor.unRememberedTabLists[0] ? state.rightTabInfor.unRememberedTabLists[0].dataId : '';
                }
            }
            var selectedItem = state.rightTabInfor.rightTabLists.filter(function (el) {
                return el.dataId == linkId;
            })[0];
            if (selectedItem != undefined) {
                //     router.push({ name: 'grammar', params: { id: 404,description:'khong-co-du-lieu' }});
                // }else{
                // history.pushState(
                //     {},
                //     null,
                //     router.currentRoute.value.meta.rootPath + '/' + selectedItem.dataId + '/' + selectedItem.postLink
                // );
                if(state.rightTabInfor.isChangeRouter){
                    var postId = (selectedItem.postId == undefined || selectedItem.postId == ''?'':'/'+ selectedItem.postId);
                    router.push(router.currentRoute.value.meta.rootPath + postId + '/' + selectedItem.dataId + '/' + $callHelpers('toSlug',[selectedItem.dataTitle]));
                }else{
                    this.selectRightTabItem(selectedItem.dataId);
                }
            }
        },
        selectRightTabItem(dataId) {
            // this.dispatch('fixedBottomBar/backToTop');
            var state = this;
            Object.keys(state.rightTabInfor.rightTabLists).forEach(function (key) {
                if (state.rightTabInfor.rightTabLists[key]['dataId'] != dataId) {
                    state.rightTabInfor.rightTabLists[key]['isActive'] = false;
                } else {
                    state.rightTabInfor.rightTabLists[key]['isActive'] = true;
                    //use JSON to copy object
                    state.rightTabInfor.currentData = JSON.parse(JSON.stringify(state.rightTabInfor.rightTabLists[key]));
                    state.rightTabInfor.isRememberedTab = state.rightTabInfor.currentData.remembered;
                }
            })
            if (dataId == undefined || dataId == '') {
                state.rightTabInfor.currentData = {};
            }
            if (dataId == 404) {
                state.rightTabInfor.currentData.dataId = 404;
            }
            // if(state.rightTabInfor.isSetURL && dataId != '' && dataId != 404){
            //     this.setLinkSelectItem(dataId);
            // }else{
            //     state.rightTabInfor.isSetURL = true;
            // }
        },
        nextRightTabItem() {
            this.setLinkSelectItem(this.nextDataId);
        },
        previousRightTabItem() {
            this.setLinkSelectItem(this.previousDataId);
        },
        bookmarkData(data) {
            var state = this;
            var payload = {
                screen_div: localStorage.getItem("currentUrl"),
                connect_div: data.dataType, // the target type
                target_id: data.dataId,
            };
            try {
                repository.bookmarkData(payload)
                    .then((res) => {
                        // const { data } = res;
                        if (res.status === 200) {
                            var data = res.data.data;
                            if (data.length == 0) {
                                // var index = state.rightTabInfor.rightTabLists.indexOf(state.rightTabInfor.currentData);
                                var nextDataId = this.nextDataId;
                                var dataIdList = state.rightTabInfor.rightTabLists.map(a => a.dataId);
                                var index = dataIdList.indexOf(state.rightTabInfor.currentData.dataId);
                                state.rightTabInfor.rightTabLists[index].remembered = true;
                                this.updateRightTabInfor({
                                    unRememberedTabLists: this.unRememberedTabLists,
                                    rememberedTabLists: this.rememberedTabLists,
                                });
                                this.setLinkSelectItem(nextDataId);
                            }
                            // console.log(state);
                            return res;
                        }
                    });
            } catch (e) {
                console.log("Action bookmarkData: " + e.message);
            }
        },
        unbookmarkData(data) {
            var state = this;
            var payload = {
                screen_div: localStorage.getItem("currentUrl"),
                connect_div: data.dataType, // the target type
                target_id: data.dataId,
            };
            try {
                repository.unbookmarkData(payload)
                    .then((res) => {
                        // const { data } = res;
                        if (res.status === 200) {
                            var data = res.data.data;
                            if (data.length == 0) {
                                var dataIdList = state.rightTabInfor.rightTabLists.map(a => a.dataId);
                                var index = dataIdList.indexOf(state.rightTabInfor.currentData.dataId);
                                var nextDataId = this.nextDataId;
                                state.rightTabInfor.rightTabLists[index].remembered = false;
                                this.updateRightTabInfor({
                                    unRememberedTabLists: this.unRememberedTabLists,
                                    rememberedTabLists: this.rememberedTabLists,
                                });
                                this.setLinkSelectItem(nextDataId);
                            }
                            return res;
                        }
                    });
            } catch (e) {
                console.log("Action unbookmarkData: " + e.message);
            }
        },
        registerLesson() {
            const { $Validator } = useNuxtApp();
            var leftTab = leftTabStore();
            var state = this;
            var payload = {
                screen_div: localStorage.getItem("currentUrl"),
                catalogue_id: state.rightTabInfor.rightTabItems[0].value ?? '',
                group_id: state.rightTabInfor.rightTabItems[1].value ?? '',
            };
            try {
                var validator = $Validator.checkValidate(state.rightTabInfor.rules, state.rightTabInfor);
                if (validator) {
                    repository.registerLesson(payload)
                        .then((res) => {
                            // const { data } = res;
                            if (res.status === 200) {
                                var data = res.data.data;
                                leftTab.updateLeftTabInfor({
                                    lessonLists: data,
                                });
                                return res;
                            }
                        });
                } else {
                    setTimeout(() => {
                        $Validator.clearValidateError(state.rightTabInfor.rules, state.rightTabInfor);
                    }, 5000);
                }
            } catch (e) {
                console.log("Action registerLesson: " + e.message);
            }
        },
        // changeTabMenu(context){
        //     context.commit('updateRightTabInfor', {
        //         currentRightTabLists: context.getters.currentRightTabLists,
        //     });
        // },
    }
});