<template>
    <div class="col-xs-12 no-padding">
        <div class="panel-group" id="example-list">
            <div class="panel panel-default">
                <div class="panel-heading" data-toggle="collapse" data-parent="#example-list" href="#collapse1" aria-expanded="true">
                    <h5 class="panel-title">
                        <span><font-awesome-icon :icon="['fas', 'list-ul']" class="text-danger"/> Danh Sách Từ Mới</span>
                    </h5>
                </div>
                <div id="collapse1" class="panel-collapse collapse in" aria-expanded="true" style="">
                    <table class="table vocabulary-table table-hover">
                        <tbody>
                            <template v-if="wordList.length != 0">
                                <tr v-for="word in wordList" class="vocabulary-box" target-id="2">
                                    <td width="33%">
                                        <NuxtLink :to="'/dictionary?type=0&query=' + word.vocabularyName + '&root='+word.vocabularyId">{{word.vocabularyName}}</NuxtLink>
                                    </td>
                                    <td width="33%">{{word.vocabularySpelling}}</td>
                                    <td>{{word.vocabularyMean}}</td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr>
                                    <td colspan="11">Hiện chưa có từ mới nào!</td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/**
 * Import libraries
 */
// import { mapState, mapActions } from "pinia";
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import NewWordMenu from '@/share-layout/menu';

/**
 * import template
 */
// import template from './template.html';
// import store from '@/store';
// import { newWordStore } from "./store";

/**
 * Define newWord layout
 */
const NewWord = {
    name: "NewWord",
    // template: template,
    components: {
        // NewWordMenu
    },
    beforeCreate() {
        // if (!store.hasModule('newWord')) {
        //     store.registerModule('newWord', newWordStore);
        // }
    },
    props: ["wordList"],
    computed: {
        // ...mapState(newWordStore, ["newWordInfor"]),
    },
    methods: {
        // favorite() {
        //     this.tracks[this.currentTrackIndex].favorited = !this.tracks[this.currentTrackIndex].favorited;
        // },
    },
    created() {},
    mounted() {},
};
export default NewWord;
</script>
<style lang="scss">
@import "./style.scss";
</style>
