<script setup>
definePageMeta({
    key: "static",
});
import { appStore } from "@/store/index.js";
import { dictionaryStore } from "./store";
const { isMobile } = useDevice();
var app = appStore();
var dictionary = dictionaryStore();
var route = useRoute();
var data = {
    root_id: route.query["root"] ?? "",
    query: route.query["query"] ?? "",
    dictionary_type: route.query["type"] ?? 0,
    screen_div: "/dictionary",
};
if (app.appInfor.isRefresh) {
    const { $repository } = useNuxtApp();
    var res = await $repository({
        method: "post",
        url: `/dictionary/get-dictionary-infor`,
        data: data,
        showProgress: true,
        headers: {
            Authorization: `Bearer ${useCookie("eplus365token").value}`, //set token for ssr request
        },
    });
    dictionary.setDictionaryInfor(res);
    app.appInfor.isRefresh = false;
}
app.appInfor.isSwitching = false;
//phai tach cac watcher ra thanh cac ham roi gọi luon
</script>
<template>
    <div class="col-xs-12 web-panel middle-content">
        <left-tab />
        <div class="col-lg-9 col-xs-12 no-padding change-content border-left">
            <div class="right-header col-xs-12 no-padding" data-target="#search-box" data-toggle="collapse">
                <div class="col-xs-12 no-padding">
                    <table class="full-width">
                        <tbody>
                            <tr>
                                <td class="text-left no-padding">
                                    <h5>
                                        <template v-if="appInfor.isMissionMode">
                                            <font-awesome-icon icon="fa-solid fa-futbol" spin />
                                            Nhiệm Vụ: {{ appInfor.doingMission.missionTitle }}
                                        </template>
                                        <template v-else>
                                            <font-awesome-icon :icon="['fas', 'magnifying-glass']" />
                                            KHUNG TÌM KIẾM
                                        </template>
                                    </h5>
                                </td>
                                <td class="collapse-icon" width="5%"><i class="glyphicon glyphicon-menu-down" style="float: right; margin-right: 2px"></i></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-xs-12 no-padding right-content">
                <div class="col-md-12 collapse in no-padding open-when-small" id="search-box">
                    <div class="col-md-12 no-padding select-group">
                        <div class="form-group no-margin-bt has-feedback search-box">
                            <div class="input-group">
                                <v-select v-model="dictionaryInfor.searchInput" id="key-word" @search="getAutocomplete" :options="dictionaryInfor.searchedList" :label="dictionaryInfor.dictionaryType.value==0?'vocabularyName':'vocabularyMean'" :reduce="(item) => dictionaryInfor.dictionaryType.value==0?item.vocabularyName:item.vocabularyMean">
                                    <template v-slot:no-options="{ search, searching }">
                                        <template v-if="searching">
                                            <em style="opacity: 0.5">Không có kết quả trùng khớp</em>
                                        </template>
                                        <template v-else>
                                            <em style="opacity: 0.5">Nhập từ khóa để tìm kiếm.</em>
                                        </template>
                                    </template>
                                    <template #open-indicator="{ attributes }">
                                        <span v-bind="attributes"></span>
                                    </template>
                                    <!-- <template slot="option" slot-scope="option">
                                        <div class="d-center">
                                            {{ option.vocabularyName }}
                                        </div>
                                    </template>
                                    <template slot="selected-option" slot-scope="option">
                                        <div class="selected d-center">
                                            {{ option.vocabularyName }}
                                        </div>
                                    </template> -->
                                </v-select>
                                <!-- <input type="text" class="form-control ui-autocomplete-input ui-autocomplete-loading" id="key-word" placeholder="Nhập từ vựng cần tìm" maxlength="100" v-model="dictionaryInfor.searchInput" /> -->
                                <div class="input-group-btn">
                                    <select class="form-control" name="" id="dictionary-type" v-model="dictionaryInfor.dictionaryType.value">
                                        <option v-for="option in dictionaryInfor.dictionaryType.selectItems" :value="option.dictionaryType" :selected="option.dictionaryType == dictionaryInfor.dictionaryType.value">{{option.dictionaryTypeName}}</option>
                                    </select>
                                </div>
                                <!-- /btn-group -->
                            </div>
                            <!-- /input-group -->
                        </div>
                    </div>
                    <div class="col-xs-12 bookmark">
                        <ul>
                            <li v-for="index in 12" class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
                                <a class="history-item text-overflow" @click="dictionaryInfor.searchHistory[index - 1]['searchValue'] != '' && (dictionaryInfor.searchInput = dictionaryInfor.searchHistory[index - 1]['searchValue'])">
                                    <span class="bookmark_cd" value="5855090">{{ index }}</span
                                    ><span class="bookmark_nm" maxlength="30" title="fiddlesticks">{{ dictionaryInfor.searchHistory[index - 1] ? dictionaryInfor.searchHistory[index - 1]["searchValue"] : "" }}</span>
                                </a>
                                <span class="bookmark_delete" :class="dictionaryInfor.searchHistory[index - 1] ? '' : 'disabled'" @click="dictionaryInfor.searchHistory[index - 1]['searchValue'] != '' && deleteBookmark(dictionaryInfor.searchHistory[index - 1]['searchValue'])">X</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div v-if="dictionaryInfor.isSearched" class="right-header col-xs-12 no-padding">
                <div class="col-md-8 col-xs-12 no-padding">
                    <table class="full-width">
                        <tbody>
                            <tr>
                                <td class="text-left no-padding">
                                    <h5>
                                        <template v-if="appInfor.isMissionMode">
                                            <font-awesome-icon icon="fa-solid fa-futbol" spin />
                                            Nhiệm Vụ: {{ appInfor.doingMission.missionTitle }}
                                        </template>
                                        <template v-else>
                                            <font-awesome-icon :icon="['fas', 'list']" />
                                            KẾT QUẢ TÌM KIẾM
                                        </template>
                                    </h5>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <right-tab v-if="dictionaryInfor.isSearched">
                <!-- <template v-slot:extends>
                    <button class="btn btn-sm btn-primary full-width" @click="showVocabularyQuizPopup(dictionaryShowList)">{{ appInfor.isMissionMode ? "Làm Nhiệm Vụ" : "Bài Tập Tự Luyện" }}</button>
                </template> -->
            </right-tab>
            <div class="col-xs-12 web-main" :class="[appInfor.isMobile ? '' : 'padding-left', dictionaryInfor.isSearched ? 'col-md-8 col-md-pull-4' : '']">
                <content-loader v-if="dictionaryInfor.isLoading && !appInfor.isMobile" viewBox="0 0 400 124" :speed="2" primaryColor="#aaa" secondaryColor="#888888">
                    <rect x="45%" y="3" rx="3" ry="3" width="15%" height="6" />
                    <rect x="65%" y="3" rx="3" ry="3" width="15%" height="6" />
                    <rect x="85%" y="3" rx="3" ry="3" width="15%" height="6" />
                    <rect x="0" y="13" rx="3" ry="3" width="100%" height="6" />
                    <rect x="0" y="25" rx="3" ry="3" width="100%" height="500" />
                </content-loader>
                <content-loader v-if="dictionaryInfor.isLoading && appInfor.isMobile" viewBox="0 0 200 124" :speed="2" primaryColor="#aaa" secondaryColor="#888888">
                    <rect x="0" y="3" rx="3" ry="3" width="100%" height="6" />
                    <rect x="0" y="13" rx="3" ry="3" width="45%" height="6" />
                    <rect x="55%" y="13" rx="3" ry="3" width="45%" height="6" />
                    <rect x="0" y="23" rx="3" ry="3" width="45%" height="6" />
                    <rect x="55%" y="23" rx="3" ry="3" width="45%" height="6" />
                    <rect x="0" y="33" rx="3" ry="3" width="100%" height="6" />
                    <rect x="0" y="45" rx="3" ry="3" width="100%" height="500" />
                </content-loader>
                <template v-if="!dictionaryInfor.isLoading && rightTabInfor.currentData.dataId != undefined">
                    <template v-if="rightTabInfor.currentData.dataId == 404">
                        <post-content contentType="404"></post-content>
                    </template>
                    <template v-else="!dictionaryInfor.isLoading && rightTabInfor.currentData.dataId != undefined">
                        <vocabulary-content></vocabulary-content>
                        <div class="col-xs-12 no-padding panel-group" id="example-list">
                            <template v-if="!appInfor.isMissionMode">
                                <WordRelationship wordRelationshipLabel="Từ Đồng Nghĩa" wordRelationshipLabelIcon="handshake" popupType="1" :wordRelationshipLists="dictionaryInfor.wordRelationshipSynonymsList"></WordRelationship>
                                <WordRelationship wordRelationshipLabel="Từ Trái Nghĩa" wordRelationshipLabelIcon="handshake-slash" popupType="2" :wordRelationshipLists="dictionaryInfor.wordRelationshipOppositeList"></WordRelationship>
                                <Example></Example>
                            </template>
                        </div>
                    </template>
                </template>
                <template v-else-if="!dictionaryInfor.isLoading && appInfor.isMissionMode">
                    <post-content contentType="missionMode"></post-content>
                </template>
                <template v-else-if="!dictionaryInfor.isSearched">
                    <!-- <vocabulary-content></vocabulary-content> -->
                    <post-content contentType="welcome"></post-content>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
import { dictionaryStore } from "./store";
import { vocabularyQuizPopupStore } from "@/components/popup/vocabulary-quiz/store.js";
import _debounce from "lodash.debounce";

/**
 * Define main layout
 */

const Dictionary = {
    name: "Dictionary",
    components: {},
    beforeCreate() {},
    computed: {
        ...mapState(dictionaryStore, ["dictionaryInfor", "dictionaryShowList"]),
    },
    mounted() {
        this.$watch(
            () => ({
                currentData: this.rightTabInfor.currentData,
            }),
            (rightTab) => {
                this.initPost(rightTab);
            },
            {
                deep: true,
            }
        );
        this.$watch(
            () => ({
                searchInput: this.dictionaryInfor.searchInput,
            }),
            (newValue) => {
                this.doSearch(newValue.searchInput);
            }
        );
        this.$watch(
            () => ({
                searchInput: this.dictionaryInfor.dictionaryType.value,
            }),
            (newValue,oldValue) => {
                if(newValue.searchInput == oldValue.searchInput == ''){
                    this.doSearch('');
                }else{
                    this.dictionaryInfor.searchInput = '';
                }
            }
        );
        const { $bus } = useNuxtApp();

        $bus.$on("saveVocabularySuccess", (res) => {
            this.updateWordRelationship(res);
        });
    },
    data: function () {
        var _this = this;
        return {
            getAutocomplete : _debounce(function(keyWord,loading){
                _this.searchVocabulary(keyWord,loading);
            },500) 
        };
    },
    methods: {
        ...mapActions(dictionaryStore, ["updateDictionaryInfor", "getDictionaryInfor", "setDictionaryInfor", "getExample", "initPost", "searchVocabulary", "deleteBookmark","voteDictionaryWord"]),
        ...mapActions(vocabularyQuizPopupStore, ["showVocabularyQuizPopup"]),
        refreshScreen() {
            var data = {
                root_id: this.$route.query["root"] ?? "",
                query: this.$route.query["query"] ?? "",
                dictionary_type: this.$route.query["type"] ?? 0,
                screen_div: "/dictionary",
            };
            this.getDictionaryInfor(data);
        },
        doSearch(keyWord) {
            var data = {
                root_id: "",
                query: keyWord,
                dictionary_type: this.dictionaryInfor.dictionaryType.value,
                screen_div: "/dictionary",
            };
            this.getDictionaryInfor(data);
        },
        updatePagingContent(paging, page) {
            if (paging.pagingId == 1) {
                //update paging content
                var { $appMethods } = useNuxtApp();
                $appMethods.getExample(page, 1);
            }
        },
        updateWordRelationship(res) {
            if(res.popupType == 1){
                this.dictionaryInfor.wordRelationshipSynonymsList = res.data[1].slice();
            }else{
                this.dictionaryInfor.wordRelationshipOppositeList = res.data[2].slice();
            }
        },
    },
};
export default defineNuxtComponent(Dictionary);
</script>
<style lang="scss">
@import "./style.scss";
</style>
