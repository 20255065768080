// import repository from '@/utils/repository';

const resource = '/dictionary';

export default {
    getDictionaryInfor: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/get-dictionary-infor`,
            data: data,
            showProgress: true,
        });
    },
    searchVocabulary: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/search-vocabulary`,
            data: data,
            // showProgress: true,
        });
    },
    deleteBookmark: (data) => {
        const { $repository } = useNuxtApp();
        return $repository({
            method: 'post',
            url: `${resource}/delete-bookmark`,
            data: data,
            showProgress: true,
        });
    },
};
