<template>
    <div class="question">
        <slot name="extendsTop"></slot>
        <div v-if="!isAnswer" class="question-infor">
            <h2>{{ question.discussTitle }}</h2>
            <!-- <div class="infor">
                <span class="infor-value">
                    <div class="avarta-box">
                        <img :src="appInfor.domain + question.discussAuthorAvarta" alt="avarta" />
                    </div>
                    {{ question.discussAuthor }}
                </span>
            </div> -->
            <!-- <div class="infor">
                <span class="infor-title">Đã sửa: </span>
                <span class="infor-value">05/06/2023 11:22:22</span>
            </div> -->
            <div class="infor">
                <span class="infor-title">Lượt xem: </span>
                <span class="infor-value">{{ question.discussViews }}</span>
            </div>
        </div>
        <div class="question-content">
            <div class="vote-controls">
                <!-- <button @click="upvote(question)">Upvote</button> -->
                <div class="controls">
                    <div :tooltip="callHelpers('getPermissionText', appInfor.userPermission.isCanVoteForQuestion)" position="right">
                        <button class="btn btn-sm btn-link up-vote btn-vote vote-up no-padding" :disabled="appInfor.userPermission.isCanVoteForQuestion != 1">
                            <font-awesome-icon :icon="['far', 'circle-up']" :class="question.myVote == 1 ? 'active' : ''" @click="appInfor.userPermission.isCanVoteForQuestion == 1 && question.myVote != 1 && voteQuestion(question, 1, isAnswer ? 6 : 2)" />
                        </button>
                    </div>
                    <span>{{ question.discussRating }}</span>
                    <div :tooltip="callHelpers('getPermissionText', appInfor.userPermission.isCanVoteForQuestion)" position="right">
                        <button class="btn btn-sm btn-link down-vote btn-vote vote-down no-padding" :disabled="appInfor.userPermission.isCanVoteForQuestion != 1">
                            <font-awesome-icon :icon="['far', 'circle-down']" :class="question.myVote == -1 ? 'active' : ''" @click="appInfor.userPermission.isCanVoteForQuestion == 1 && question.myVote != -1 && voteQuestion(question, -1, isAnswer ? 6 : 2)" />
                        </button>
                    </div>
                </div>
                <!-- <button @click="downvote(question)">Downvote</button> -->
            </div>
            <div class="content">
                <div class="description">
                    <span class="infor-value">
                        <div class="user-name">{{ question.discussAuthor }} - {{ question.createDate }}</div>
                    </span>
                    <span class="ql-editor no-padding" v-html="question.discussContent"></span>
                    <slot name="tagList"></slot>
                </div>
                <div class="btn-box">
                    <button class="btn btn-link btn-add-question" @click="toggleCommentForm(true)">Bình luận</button>
                    <button v-if="question.isOwner && !isAnswer" class="btn btn-link btn-add-question" @click="showContributeDiscussPopup(question.discussId, 0)">Sửa</button>
                    <button v-if="question.isOwner && isAnswer" class="btn btn-link btn-add-question" @click="showContributeDiscussPopup(question.discussId, 1)">Sửa</button>
                    <button v-if="question.isOwner && !isAnswer" class="btn btn-link btn-add-question" @click="deleteDiscuss(question, 0)">Xóa</button>
                    <button v-if="question.isOwner && isAnswer" class="btn btn-link btn-add-question" @click="deleteDiscuss(question, 1)">Xóa</button>
                    <button v-if="!isAnswer" class="btn btn-link btn-add-question" @click="showContributeDiscussPopup('', 1)">Trả lời</button>
                    <span class="infor-value">
                        <div class="avarta-box">
                            <img :src="appInfor.domain + question.discussAuthorAvarta" alt="avarta" />
                        </div>
                    </span>
                    <div class="answer-box" v-if="showCommentForm">
                        <div class="input-group">
                            <span class="input-group-btn cancel-btn">
                                <!-- <button class="btn btn-default" type="button" @click="sendInputMessage()">Gửi Tin Nhắn</button> -->
                                <button class="btn btn-link" @click="toggleCommentForm(false)">
                                    <font-awesome-icon :icon="['fas', 'close']" />
                                </button>
                            </span>
                            <template v-if="!appInfor.isMobile">
                                <resize-textarea class="form-control" placeholder="Nhấn enter để gửi hoặc shift+enter để xuống hàng" @keydown.enter.exact.prevent @keyup.enter.exact="submitComment()" @keydown.enter.shift.exact="newline" :rows="1" :cols="4" :maxHeight="150" v-model="newAnswer" :modelValue="newAnswer"> </resize-textarea>
                            </template>
                            <template v-else>
                                <resize-textarea class="form-control" placeholder="Xin nhập nội dung sau đó nhấn gửi" :rows="1" :maxHeight="150" v-model="newAnswer" :modelValue="newAnswer"> </resize-textarea>
                            </template>
                            <!-- <input type="text" class="form-control" v-model="inputText" @keydown.enter.prevent="sendInputMessage()" placeholder="Nhập tin nhắn sau đó nhấn enter..." /> -->
                            <span class="input-group-btn" @click="newAnswer.trim() != '' && submitComment()">
                                <!-- <button class="btn btn-default" type="button" @click="sendInputMessage()">Gửi Tin Nhắn</button> -->
                                <font-awesome-icon :class="newAnswer.trim() == '' ? 'disabled' : ''" :icon="['fas', 'paper-plane']" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ul class="comment-list">
            <template v-for="comment in question.comments">
                <li v-if="comment.discussId != discuss_id" :key="comment.discussId">
                    <span class="infor-value">
                        <div class="avarta-box">
                            <div class="user-name">{{ comment.discussAuthor }} - {{ comment.createDate }}</div>
                            <img :src="appInfor.domain + comment.discussAuthorAvarta" alt="avarta" />
                        </div>
                        <div class="answer-content" v-html="comment.discussContent"></div>
                    </span>
                    <div class="btn-box">
                        <button v-if="comment.isOwner" class="btn btn-link btn-add-question" @click="toggleCommentForm(true, comment)">Sửa</button>
                        <button v-if="comment.isOwner" class="btn btn-link btn-add-question" @click="deleteDiscuss(comment, 2)">Xóa</button>
                    </div>
                    <!-- <div class="vote-controls">
                    <button @click="upvote(answer)">Upvote</button>
                    <span>{{ answer.votes }}</span>
                    <button @click="downvote(answer)">Downvote</button>
                </div> -->
                </li>
            </template>
        </ul>
        <h4 v-if="!isAnswer && paging.totalRecord > 0" for="input-group">{{ paging.totalRecord }} câu trả lời</h4>
        <Paging v-if="!isAnswer && paging.totalPage > 1" :pagingId="paging.dataId" :currentPage="paging.currentPage" :totalPage="paging.totalPage" pagingClass="text-center inline-block margin-bottom" />
        <slot name="extendBottom"></slot>
        <Paging v-if="!isAnswer && paging.totalPage > 1" :pagingId="paging.dataId" :currentPage="paging.currentPage" :totalPage="paging.totalPage" pagingClass="text-center inline-block margin-bottom" />
        <!-- <ClientOnly>
            <div v-if="!isAnswer" class="answer-box">
                <label for="input-group">Câu Trả Lời Của Bạn</label>
                <div class="input-group editor-box">
                    <QuillEditor toolbar="minimal" v-model:content="answerText" :content="answerText" contentType="html" @ready="getEditor" />
                </div>
                <button class="btn btn-sm btn-primary margin-top full-width" type="button" :disabled="answerText.trim() == ''" @click="answerText.trim() != '' && submitAnswer()">Thêm Câu Trả Lời</button>
            </div>
        </ClientOnly> -->
    </div>
</template>

<script>
import repository from "@/repository";
import { mapState, mapActions } from "pinia";
import { contributeDiscussPopupStore } from "@/components/popup/contribute-discuss/store.js";

const qaContent = {
    props: {
        question: Object,
        paging: Object,
        isAnswer: Boolean,
    },
    data() {
        return {
            showCommentForm: false,
            newAnswer: "",
            answerText: "",
            editor: null,
            discuss_id: "",
            answer_id: "",
        };
    },
    methods: {
        ...mapActions(contributeDiscussPopupStore, ["showContributeDiscussPopup"]),
        toggleCommentForm(value, discuss) {
            this.discuss_id = discuss ? discuss.discussId : "";
            this.newAnswer = discuss ? discuss.discussContent : "";
            this.showCommentForm = value;
        },
        toggleAnswerForm(value, discuss) {
            this.answer_id = discuss ? discuss.discussId : "";
            this.answerText = discuss ? discuss.discussContent : "";
            // this.showCommentForm = value;
        },
        getEditor(editor) {
            this.editor = editor;
        },
        submitComment() {
            var route = useRoute();
            const payload = {
                target_id: this.rightTabInfor.currentData.dataId,
                comment_id: this.discuss_id,
                reply_id: this.question.discussId,
                text: this.newAnswer,
                comment_div: 0,
                screen_div: route.meta.rootPath,
            };
            repository.addComment(payload).then((res) => {
                const data = res.data.data;
                // var commentsTemp = this.question.comments.filter((item)=>item.discussId != this.discuss_id);
                // commentsTemp.push(data[1][0]);
                this.question.comments = data[1];
                this.newAnswer = "";
                this.showCommentForm = false;
                this.discuss_id = "";
            });
        },
        submitAnswer(comment_id) {
            var route = useRoute();
            var _this = this;
            comment_id = comment_id ?? "";
            const payload = {
                target_id: this.rightTabInfor.currentData.dataId,
                comment_id: comment_id,
                reply_id: this.rightTabInfor.currentData.dataId,
                text: this.answerText,
                comment_div: 1,
                screen_div: route.meta.rootPath,
            };
            repository.addComment(payload).then((res) => {
                var { $bus } = useNuxtApp();
                const data = res.data.data;
                // this.editor.setContents("", "silent");
                _this.answerText = "";
                $bus.$emit("submitedAnswer", data);
                // var answer = data[1][0];
                // answer["comments"] = [];
                // this.currentPaging.totalRecord = parseInt(this.currentPaging.totalRecord) + 1;
                // this.discussInfor.answerLists.push(answer);
                // this.discussInfor.answerText = "";
            });
        },
        deleteDiscuss(discuss, delete_div) {
            var route = useRoute();
            var state = this;
            var { $bus } = useNuxtApp();
            state.showDialog(3, "W001", function () {
                state.dialogPopupInfor.dialogButtons[0].buttonAction = function () {
                    const payload = {
                        target_id: discuss.targetId,
                        comment_id: discuss.discussId,
                        reply_id: discuss.replyId ?? "",
                        delete_div: delete_div,
                        screen_div: route.meta.rootPath,
                    };
                    repository.deleteDiscuss(payload).then((res) => {
                        const data = res.data.data;
                        $bus.$emit("deletedDiscuss", { data: data, discuss: discuss, delete_div: delete_div });
                        state.dialogPopupInfor.showDialog = false;
                    });
                };
            });
        },
        voteQuestion(question, value, vote_div) {
            try {
                var state = this;
                var payload = {
                    source_id: question.targetId,
                    target_id: question.discussId,
                    my_vote: value, //example for a post
                    execute_div: vote_div, //this is a vote for post or answers
                    screen_div: localStorage.getItem("currentUrl"),
                };
                repository.voteAction(payload).then((res) => {
                    // const { data } = res;
                    if (res.status === 200) {
                        var data = res.data.data;
                        // var currentQuestionList = state.exampleInfor.exampleDisplayLists.filter(function (el) {
                        //     return el.exampleId == payload.example.exampleId;
                        // });
                        // if(currentExampleList[0]!=undefined){
                        // var index = state.exampleInfor.exampleDisplayLists.indexOf(currentExampleList[0]);
                        question.myVote = data[1][0].myVote;
                        question.discussRating = data[1][0].voteValue;
                        // }
                        return res;
                    }
                });
            } catch (e) {
                console.log("Action voteQuestion: " + e.message);
            }
        },
        // upvote(question) {
        //     question.discussRating++;
        // },
        // downvote(question) {
        //     question.discussRating--;
        // },
    },
};
export default defineNuxtComponent(qaContent);
</script>

<style lang="scss">
@import "./style.scss";
</style>
