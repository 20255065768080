// import repository from "@/repository";
import writingRepository from "./repository";
import { appStore } from "@/store";
import { rightTabStore } from "~~/components/right-tab/store";
import { leftTabStore } from "~~/components/left-tab/store";
import { exampleStore } from "~~/components/example/store";
import { postContentStore } from "~~/components/post-content/store";
import { exerciseStore } from "~~/components/exercise/store";
export const writingStore = defineStore('writing', {
    namespaced: true,
    state: () => ({
        writingInfor: {
            isLoading: true,
            isAnalysis: false,
            isUserPostCollapsed: false,
            questionListTitle: 'Bài Tập Tự Luyện',
            welcomeSlogan: 'Chào mừng bạn đến với khóa luyện viết của Eplus365!',
            hintList: [
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Danh sách bên phải bao gồm tất cả các viết của bạn có trong hệ thống.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Bạn có thể lọc và chọn bài viết viết của bạn mong muốn dựa theo danh mục và nhóm ở tab bên phải.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Bạn cũng có thể lưu danh mục/nhóm viết của bạn mong muốn bằng chức năng "Lưu Danh Mục/Nhóm" danh sách đã lưu sẽ được hiển thị ở bên trái.',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Bạn có thể lưu/học lại viết của bạn trong danh sách bằng chức năng "Đã học" hoặc "Đã quên" tương ứng với tab "Ngữ Pháp Chưa Học" và "Ngữ Pháp Đã Học".',
                },
                {
                    hintIcon: 'hand-point-right',
                    hintText: 'Mỗi viết của bạn sẽ đều được phân tách thành từng câu,nhập nội dung bạn nghe được sau đó kiểm tra thành quả',
                },
            ],
            writingPostLists: [
                {
                    postId: '1',
                    postAverageRating: '0',
                    postViews: '100',
                    postPublicDate: '2022/09/08',
                    postAuthor: 'Quynd1',
                    postUpdateDate: '2022/10/10',
                    postTitle: 'Cách dùng quán từ xác định "The"1',
                    postContent: 'ありがとうございます。',
                },
            ],
            grammarPostTagLists: [],
            grammarSuggestList: [],
            customChatCommands: [
                {
                    commandLabel: 'create',
                    commandIcon: 'hand-point-right',
                    commandTitle: 'Tạo Bài Viết Mẫu',
                    commands: [
                        { role: "user", content: 'Bạn có thể giúp tôi tạo một bài viết Tiếng Anh \nCó chủ đề về [trái cây] viết bằng trình độ [tiếng anh sơ cấp] và có khoảng [100] từ?', isMe: true, isShow: false },
                        { role: "assistant", content: 'Dưới đây là bài viết Tiếng Anh đã được tạo cho bạn. \nCó chủ đề [trái cây] viết bằng trình độ [tiếng anh sơ cấp] và có khoảng [100] từ.\n\nFruits are a delicious and healthy addition to any diet. They are packed with vitamins, minerals, and fiber that are essential for our well-being. There are many different types of fruits available all over the world, each with their unique taste and nutritional benefits.', isMe: false, isShow: false },
                        { role: "user", content: 'Bạn có thể giúp tôi tạo một bài viết Tiếng Anh \nCó chủ đề về [cao bồi] viết bằng trình độ [tiếng anh trung cấp] và có khoảng [100] từ?', isMe: true, isShow: false },
                        { role: "assistant", content: 'Dưới đây là bài viết Tiếng Anh đã được tạo cho bạn. \nCó chủ đề [cao bồi] viết bằng trình độ [tiếng anh trung cấp] và có khoảng [100] từ.\n\nCowboys are a popular icon of the American West. They are known for their distinctive clothing, which includes boots, hats, and denim jeans. Cowboys are skilled at riding horses, herding cattle, and working on ranches. They often carry a lasso, which they use to catch cattle. Cowboys are an important part of American culture, and their influence can be seen in movies, music, and fashion.', isMe: false, isShow: false },
                        { role: "user", content: 'Bạn có thể giúp tôi tạo một bài viết Tiếng Anh \nCó chủ đề về [gia đình] viết bằng trình độ [tiếng anh cao cấp] và có khoảng [100] từ?', isMe: true, isShow: false },
                        { role: "assistant", content: "Dưới đây là bài viết Tiếng Anh đã được tạo cho bạn. \nCó chủ đề [gia đình] viết bằng trình độ [tiếng anh cao cấp] và có khoảng [100] từ.\n\nFamily is an important part of our lives. It is where we learn our values, beliefs, and traditions. A family can consist of parents, siblings, grandparents, aunts, uncles, and cousins. Each member of the family plays a unique role and contributes to its overall well-being. Families can face challenges, but they also provide us with support and love. Spending time together, whether it's through family dinners, vacations, or game nights, helps to strengthen the bond between family members. In a world that can be unpredictable and difficult, the love and support of a family can provide a sense of security and stability.", isMe: false, isShow: false },
                    ],
                },
                {
                    commandLabel: 'improve',
                    commandIcon: 'hand-point-right',
                    commandTitle: 'Cải Thiện Bài Viết',
                    commands: [
                        { role: "user", content: 'Bạn có thể giúp tôi chỉnh sửa bài viết Tiếng Anh sau đây:"i has a cat.she is very cute.when i saw her i fell like a princess." \nCó chủ đề về [tôi có 1 con mèo] viết bằng trình độ [tiếng anh sơ cấp] và có khoảng [20] từ?', isMe: true, isShow: false },
                        { role: "assistant", content: 'Dưới đây là bài viết đã được cải thiện dành cho bạn.\nCó chủ đề [tôi có 1 con mèo] viết bằng trình độ [tiếng anh sơ cấp] và có khoảng [20] từ.\n\nI have a cat. She is very cute. When I saw her, I felt like a princess.', isMe: false, isShow: false },
                        // { role: "user", content: 'Could you please speak in intermediate English so that I can understand you better?', isMe: true, isShow: false }
                    ],
                },
                {
                    commandLabel: 'suggest',
                    commandIcon: 'hand-point-right',
                    commandTitle: 'Đưa Ra Lời Khuyên',
                    commands: [
                        { role: "user", content: 'Bạn có thể chỉ cho tôi những điểm cần cải thiện của bài viết Tiếng Anh sau đây bằng Tiếng Việt:"i has a cat.she is very cute.when i saw her i fell like a princess." \nCó chủ đề về [tôi có 1 con mèo] viết bằng trình độ [tiếng anh sơ cấp] và có khoảng [20] từ?', isMe: true, isShow: false },
                        { role: "assistant", content: 'Dưới đây những điểm bạn nên cải thiện để có một bài viết tuyệt vời hơn.\nCó chủ đề [tôi có 1 con mèo] viết bằng trình độ [tiếng anh sơ cấp] và có khoảng [20] từ.\n\nI have a cat. She is very cute. When I saw her, I felt like a princess.', isMe: false, isShow: false },
                    ],
                },
            ],
            autoSendCommands: [],
            rightTabAutoSendCommands: [],
            rightTabDefaultCommands: [
                { role: "user", content: "Kiểm tra ngữ pháp của câu tiếng anh sau:i have take your bags", isMe: true, isShow: false },
                { role: "assistant", content: 'Đánh giá ngữ pháp cho [i have take your bags].\n- Câu của bạn đang bị sai ngữ pháp!\n+chưa chia động từ đúng thì\n+thiếu "n" ở "taken"\n\nBạn có thể sửa thành: I have taken your bags.', isMe: false, isShow: false },
                { role: "user", content: "Kiểm tra ngữ pháp của câu tiếng anh sau:I will go to school tomorrow", isMe: true, isShow: false },
                { role: "assistant", content: "Đánh giá ngữ pháp cho [I will go to school tomorrow].\nCâu của bạn đã đúng ngữ pháp!", isMe: false, isShow: false },
            ],
            currentCustomCommandLabel: '',
            englishLevel: {
                selected: '',
                options: [
                    {
                        value: 'tiếng anh sơ cấp',
                        text: 'Tiếng Anh Sơ Cấp',
                    },
                    {
                        value: 'tiếng anh trung cấp',
                        text: 'Tiếng Anh Trung Cấp',
                    },
                    {
                        value: 'tiếng anh cao cấp',
                        text: 'Tiếng Anh Cao Cấp',
                    },
                ]
            },
            writingText: {
                value: '',
                isError: false,
                errorMessage: '',
            },
            writingDescription: {
                value: '',
                isError: false,
                errorMessage: '',
            },
            currentTextSentences: [],
            currentTextSentenceslength: 1,
            numberOfWords: '',
            writingTextRules: {
                'writingText.value': 'required',
            },
            writingDescriptionRules: {
                'writingDescription.value': 'required',
            },
            savePostRules: {
                'writingDescription.value': 'required',
                'writingText.value': 'required',
            },
        }
    }),
    actions: {
        updateWritingInfor(writingInfor) {
            var state = this;
            Object.keys(writingInfor).forEach(function (key) {
                state.writingInfor[key] = writingInfor[key];
            })
        },
        // updateWritingPostQuestionLists(sqlQuestionLists) {
        //     var state = this;
        //     var questionLists = state.writingInfor.writingPostQuestionLists;
        //     questionLists = sqlQuestionLists['question'];
        //     Object.keys(questionLists).forEach(function (key) {
        //         var questionAnswer = sqlQuestionLists['answer'].filter(function (el) {
        //             return el.questionId == questionLists[key].questionId;
        //         });
        //         questionLists[key]['answerLists'] = questionAnswer;
        //     })
        //     state.writingInfor.writingPostQuestionLists = questionLists;
        // },
        getWritingInfor(payload) {
            try {
                var rightTab = rightTabStore();
                var app = appStore();
                var state = this;
                // state.writingInfor.isLoading = true;
                // rightTab.updateRightTabInfor({
                //     isLoading: true,
                // });
                app.appInfor.isSwitching = true;
                writingRepository.getWritingInfor(payload)
                    .then((res) => {
                        // const { data } = res;
                        state.setWritingInfor(res);
                    });
            } catch (e) {
                console.log("Action getWritingInfor: " + e.message);
            }
        },
        setWritingInfor(res) {
            var app = appStore();
            var leftTab = leftTabStore();
            var state = this;
            var router = useRouter();

            if (res.status === 200) {
                var data = res.data.data;
                if (data[3][0] && data[3][0]['missionId'] != 0) {
                    app.appInfor.isMissionMode = true;
                    app.appInfor.doingMission = data[3][0];
                    router.push(data[3][0]['missionLink']);
                } else {
                    app.appInfor.isMissionMode = false;
                    app.appInfor.doingMission = {};
                }
                leftTab.leftTabInfor.renderLessonList = false;
                state.writingInfor.englishLevel.options = data[0];
                var englishLevel = data[0].filter((level) => level.isActive == true);
                state.writingInfor.englishLevel.selected = englishLevel[0] ? englishLevel[0]['libId'] : data[0][0]['libId'];
                state.writingInfor.writingPostLists = data[1];
                state.writingInfor.grammarPostTagLists = data[2];
                this.initPost();
                state.writingInfor.isLoading = false;
                app.appInfor.isReloadContent = false;
                app.appInfor.isSwitching = false;
                return res;
            }
        },
        initPost() {
            var postContent = postContentStore();
            const router = useRouter();
            var { $callHelpers } = useNuxtApp();
            postContent.updateWelcomeContentInfor({
                welcomeSlogan: this.writingInfor.welcomeSlogan,
                hintList: this.writingInfor.hintList,
            });
            var currentPost = this.writingInfor.writingPostLists.filter(function (el) {
                return el.isActive == true;
            });
            if (currentPost.length != 0) {
                this.writingInfor.writingText.value = currentPost[0].postContent;
                this.writingInfor.writingDescription.value = currentPost[0].postTitle;
                this.writingInfor.englishLevel.selected = currentPost[0].englishLevelId;
                this.writingInfor.numberOfWords = currentPost[0].limitWord;
                router.push(router.currentRoute.value.meta.rootPath + '/' + currentPost[0].postId + '/' + $callHelpers('toSlug', [currentPost[0].postTitle]));
            } else {
                this.writingInfor.writingText.value = "";
                this.writingInfor.writingDescription.value = "";
                this.writingInfor.englishLevel.selected = this.writingInfor.englishLevel.options[0]['libId'];
                this.writingInfor.numberOfWords = "";
                router.push(router.currentRoute.value.meta.rootPath);
            }
        },
        saveUserPost() {
            var { $Validator, $appStates, $appMethods } = useNuxtApp();
            var validator = $Validator.checkValidate(this.writingInfor.savePostRules, this.writingInfor);
            var currentPost = this.writingInfor.writingPostLists.filter((post) => post.isActive == true);
            var payload = {
                post_id: currentPost[0] ? currentPost[0]['postId'] : '',
                english_level: this.writingInfor.englishLevel.selected,
                limit_word: this.writingInfor.numberOfWords,
                post_title: this.writingInfor.writingDescription.value,
                post_content: this.writingInfor.writingText.value,
            };
            var _this = this;
            try {
                if (validator) {
                    $appMethods.showDialog(1, 'C013', function () {
                        // $appStates.dialogPopup.dialogPopupInfor.dialogButtons[0].buttonTitle = '';
                        $appStates.dialogPopup.dialogPopupInfor.dialogButtons[0].buttonAction = function () {
                            // $appMethods.selectRightTabItem(unAnsweredQuestions[0].dataId);
                            writingRepository.saveUserPost(payload)
                                .then((res) => {
                                    const { data } = res.data;
                                    _this.writingInfor.writingPostLists = data[1];
                                    _this.initPost();
                                    $appMethods.hideDialog();
                                    // state.setWritingInfor(res);
                                });
                        }
                    })
                }
            } catch (e) {
                console.log("Action getWritingInfor: " + e.message);
            }
        },
        deleteUserPost(userPost) {
            var { $appStates, $appMethods } = useNuxtApp();
            // var currentPost = this.writingInfor.writingPostLists.filter((post) => post.isActive == true);
            var payload = {
                post_id: userPost ? userPost.postId : '',
            };
            var _this = this;
            try {
                $appMethods.showDialog(1, 'C012', function () {
                    // $appStates.dialogPopup.dialogPopupInfor.dialogButtons[0].buttonTitle = '';
                    $appStates.dialogPopup.dialogPopupInfor.dialogButtons[0].buttonAction = function () {
                        // $appMethods.selectRightTabItem(unAnsweredQuestions[0].dataId);
                        writingRepository.deleteUserPost(payload)
                            .then((res) => {
                                const { data } = res.data;
                                _this.writingInfor.writingPostLists = data[1];
                                _this.initPost();
                                $appMethods.hideDialog();
                                // state.setWritingInfor(res);
                            });
                    }
                })
            } catch (e) {
                console.log("Action getWritingInfor: " + e.message);
            }
        },
    },
});