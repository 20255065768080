// import repository from '@/utils/repository';

const resource = '/speech';

export default {
    pronunciationAssessment: (data) => {
        const { $repository } = useNuxtApp();
        const config = useRuntimeConfig()
        return $repository({
            method: 'post',
            baseURL: `${config.public.SPEECH_URL}${resource}/pronunciation-assessment`,
            data: data,
            showProgress: true,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    getNewsList: (data) => {
        const { $repository } = useNuxtApp();
        const config = useRuntimeConfig()
        return $repository({
            method: 'post',
            baseURL: `${config.public.SPEECH_URL}${resource}/get-news-list`,
            data: data,
            showProgress: true,
        });
    },
};
