export const fillTextStore = defineStore('fillText', {
    namespaced: true,
    state: () => ({
        fillTextInfor: {
            fillText: '',
            filledText: '',
            isCorrect : null,
            showText: '',
            showTextmean: '',
            showCharracters: [],
            showList: [],
            isShowBottomBar: false,
            lastScrollPosition: 0,
            componentTitle: 'Thử Thách Điền Từ',
        }
    }),
    mutations: {
        updateFillTextInfor(state, fillTextInfor) {
            Object.keys(fillTextInfor).forEach(function (key) {
                state.fillTextInfor[key] = fillTextInfor[key];
            })
        },
    },
    // getters: {
    //     remainQuestionIdList: state => state.fillTextInfor.remainQuestionIdList,
    // },
    actions: {
        updateFillTextInfor(fillTextInfor) {
            var state = this;
            Object.keys(fillTextInfor).forEach(function (key) {
                state.fillTextInfor[key] = fillTextInfor[key];
            })
        },
        checkFillTextAnswer() {
            var { $callHelpers, $appStates, $appMethods } = useNuxtApp();
            var result = {
                status: 0,
                message: ''
            };
            //delete all none utf-8 charracter,space then uppercase to compare
            if($callHelpers('cleanString',[this.fillTextInfor.filledText]).replace(/\s/g, "").toUpperCase() === $callHelpers('cleanString',[this.fillTextInfor.showText]).replace(/\s/g, "").toUpperCase()){
                result.status = 1;
                result.message = 'nhap chinh xac';
                this.fillTextInfor.isCorrect = true;
            }else{
                result.status = 0;
                result.message = 'chua nhap dung';
                this.fillTextInfor.isCorrect = false;
            }
            return result;
        }
        // backToTop() {
        // 	window.scrollTo({
        // 		top: 0,
        // 		left: 0,
        // 		behavior: "smooth",
        // 	});
        //     // setTimeout(() => {
        //     //     context.commit('updateFillTextInfor',{
        //     //         isShowBottomBar:false
        //     //     });
        //     // }, 500);
        // },
        // showFillText(){
        //     var app = appStore();
        // 	this.updateFillTextInfor({
        //         isShowBottomBar:app.appInfor.isMobile
        //     });
        // },
    }
});