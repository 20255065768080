<template>
    <vue-final-modal v-model="contributeDiscussPopupInfor.showModal" :transition="contributeDiscussPopupInfor.modalTransition" :clickToClose="false">
        <div class="modal-dialog contribute-discuss" ref="abc">
            <!-- Modal content-->
            <div class="modal-content col-xs-12 no-padding">
                <div class="modal-header col-xs-12">
                    <h5 class="modal-title">ĐÓNG GÓP THẢO LUẬN</h5>
                    <!-- <button class="close" data-dismiss="modal" type="button" @click="contributeDiscussPopupInfor.showModal = false">×</button> -->
                </div>
                <div class="modal-body col-xs-12 no-padding">
                    <!-- <div class="col-xs-12 dialog-padding no-fixed search-panel" data-target="#select-condition" data-toggle="collapse">
                        <h5>
                            <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
                            Điều Kiện Tìm Kiếm
                        </h5>
                    </div> -->
                    <div class="col-xs-12 no-padding margin-top collapse in" min-width="1024px" id="select-condition">
                        <div v-if="contributeDiscussPopupInfor.popupType == 0" class="col-xs-12 dialog-padding">
                            <div class="form-group">
                                <label>Tiêu Đề</label>
                                <div class="input-group">
                                    <resize-textarea class="form-control full-width" placeholder="Nhập tiêu đề của câu hỏi" :rows="1" :cols="4" :maxHeight="150" v-model="contributeDiscussPopupInfor.discussTitle.value" :modelValue="contributeDiscussPopupInfor.discussTitle.value"> </resize-textarea>
                                </div>
                            </div>
                        </div>
                        <div v-if="contributeDiscussPopupInfor.popupType == 0" class="col-xs-12 dialog-padding">
                            <div class="form-group">
                                <label>Thêm Hashtag</label>
                                <v-select taggable placeholder="Thêm hashtag của câu hỏi" multiple v-model="contributeDiscussPopupInfor.discussTagList.selected">
                                    <template v-slot:no-options>
                                        <em style="opacity: 0.5">Thêm hashtag của câu hỏi</em>
                                    </template>
                                </v-select>
                            </div>
                        </div>
                        <div class="col-xs-12 dialog-padding">
                            <div class="form-group">
                                <label>Nội Dung</label>
                                <div class="input-group editor-box">
                                    <!-- <QuillEditor toolbar="minimal" v-model:content="contributeDiscussPopupInfor.discussContent.value" :content="contributeDiscussPopupInfor.discussContent.value" contentType="html" @ready="getEditor" /> -->
                                    <VueEditor v-model="contributeDiscussPopupInfor.discussContent.value" :editorToolbar="customToolbar" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer col-xs-12">
                    <button class="btn btn-primary btn-sm" id="btn-do-mission" type="button" @click="saveDiscussContribute()">
                        <font-awesome-icon icon="download" />
                        Lưu Đóng Góp
                    </button>
                    <button class="btn btn-default btn-sm" data-dismiss="modal" type="button" @click="contributeDiscussPopupInfor.showModal = false">
                        <font-awesome-icon icon="close" />
                        Đóng
                    </button>
                </div>
            </div>
            <ClientOnly>
                <v-loading :active="contributeDiscussPopupInfor.isSearching" :is-full-page="false">
                    <slot name="default">
                        <font-awesome-icon :icon="['fas', 'compact-disc']" spin size="3x" style="color: #777" />
                    </slot>
                </v-loading>
            </ClientOnly>
        </div>
    </vue-final-modal>
</template>
<script>
/**
 * Import libraries
 */
import { mapState, mapActions } from "pinia";
// import helpers from '@/utils/helpers';

/**
 * import component
 */
// import MainMenu from '@/share-layout/menu';

/**
 * import template
 */
// import template from "./template.html";
// import store from "@/store";
import { contributeDiscussPopupStore } from "./store";
import { vocabularyStore } from "~~/pages/vocabulary/store.js";

/**
 * Define main layout
 */
const ContributeDiscussPopup = {
    name: "ContributeDiscussPopup",
    // template: template,
    components: {
        // MainMenu,
    },
    beforeCreate() {
        // if (!store.hasModule("contributeDiscussPopup")) {
        // 	store.registerModule("contributeDiscussPopup", contributeDiscussPopupStore);
        // }
    },
    created() {},
    computed: {
        ...mapState(contributeDiscussPopupStore, ["contributeDiscussPopupInfor"]),
    },
    data() {
        // var contributeDiscussPopup = contributeDiscussPopupStore();
        return {
            file: null,
            editor: null,
            customToolbar: [
                [
                    {
                        header: [false, 1, 2, 3, 4, 5, 6],
                    },
                ],
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["code-block"],
            ],
            // difficultLevel: contributeDiscussPopup.contributeDiscussPopupInfor.difficultLevel,
            // successMissionExp: contributeDiscussPopup.contributeDiscussPopupInfor.successMissionExp,
            // successMissionCtp: contributeDiscussPopup.contributeDiscussPopupInfor.successMissionCtp,
            // failedMissionExp: contributeDiscussPopup.contributeDiscussPopupInfor.failedMissionExp,
            // failedMissionCtp: contributeDiscussPopup.contributeDiscussPopupInfor.failedMissionCtp,
        };
    },
    mounted() {},
    methods: {
        ...mapActions(contributeDiscussPopupStore, ["showContributeDiscussPopup", "updateContributeDiscussPopupInfor", "uploadCropImage", "saveDiscussContribute"]),
        getEditor(editor) {
            this.contributeDiscussPopupInfor.editor = editor;
        },
        confirm() {
            // some code...
            this.show = false;
        },
        cancel(close) {
            // some code...
            close();
        },
        previewAudio(mode) {
            mode = mode ?? 0;
            let audio = document.getElementById("audio-preview");
            let reader = new FileReader();
            if (mode == 1 && this.file) {
                reader.readAsDataURL(this.file);
                reader.addEventListener("load", function () {
                    audio.src = reader.result;
                });
            } else {
                this.file = this.contributeDiscussPopupInfor.displayWord.vocabularyAudio;
                audio.src = this.appInfor.domain + this.file;
            }
        },
        handleFileUpload(event) {
            this.file = event.target.files[0];
            this.contributeDiscussPopupInfor.displayWord.vocabularyAudio = this.file;
            this.previewAudio(1);
        },
        cropUploadSuccess(jsonData, field) {
            console.log(jsonData);
            console.log(field);
        },
        // cropSuccess(imageDataUrl, field){
        //     this.
        // },
        // updatePagingContent(paging, page) {
        //     if (paging.pagingId == 999) {
        //         //update paging content
        //         this.getVocabularyList(page);
        //     }
        // },
        // updateResizedScreen() {
        //     // this.updateContributeDiscussPopupInfor({
        //     // 	optionFontSize: this.$refs.optionHeader[0].clientWidth/12,
        //     // });
        // },
        // contributeDiscuss(vocabulary, index) {
        //     this.contributeDiscussPopupInfor.historyList.splice(index, 1);
        //     this.contributeDiscussPopupInfor.selectedList.unshift(vocabulary);
        // },
        // uncontributeDiscuss(vocabulary, index) {
        //     this.contributeDiscussPopupInfor.selectedList.splice(index, 1);
        //     this.contributeDiscussPopupInfor.historyList.push(vocabulary);
        // },
    },
};
export default defineNuxtComponent(ContributeDiscussPopup);
</script>
<style lang="scss">
@import "./style.scss";
</style>
