import type { RouterConfig } from "@nuxt/schema";
import PageNotFound from "@/pages/common/404/index.vue";
// import Portal from "../views/portal";
import HomePage from "@/pages/homepage/index.vue";
import Conversation from "@/pages/conversation/index.vue";
import Vocabulary from "@/pages/vocabulary/index.vue";
import Listening from "@/pages/listening/index.vue";
import Dictionary from "@/pages/dictionary/index.vue";
import Grammar from "@/pages/grammar/index.vue";
import Reading from "@/pages/reading/index.vue";
import Writing from "@/pages/writing/index.vue";
import Pronunciation from "@/pages/pronunciation/index.vue";
import Discuss from "@/pages/discuss/index.vue";

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterConfig>{
    routes: (_routes) => [
        {
            path: "/:pathMatch(.*)*",
            name: "page-not-found",
            component: PageNotFound,
            meta: {
                title: "Không Tìm Thấy Trang",
            },
        },
        // {
        //     path: "/portal",
        //     name: "portal",
        //     component: Portal,
        //     meta: {
        //         rootPath: "/portal",
        //         title: "Common Preview",
        //     },
        // },
        {
            path: "/",
            name: "home",
            component: HomePage,
            meta: {
                rootPath: "/",
                title: "Trang Chủ",
            },
        },
        {
            path: "/register",
            name: "register",
            component: HomePage,
            meta: {
                rootPath: "/register",
                title: "Đăng Ký Tài Khoản",
            },
        },
        {
            path: "/vocabulary",
            name: "vocabulary",
            component: Vocabulary,
            meta: {
                rootPath: "/vocabulary",
                title: "Học Từ Vựng",
            },
            children: [{ path: '/vocabulary/:postId(\\d+)*/:id(\\d+)/:description*', name: 'vocabulary',component: Vocabulary}],
        },
        {
            path: "/grammar/:id(\\d+)*/:description*",
            name: "grammar",
            component: Grammar,
            meta: {
                rootPath: "/grammar",
                title: "Học Ngữ Pháp",
            },
        },
        {
            path: "/dictionary",
            name: "dictionary",
            component: Dictionary,
            meta: {
                rootPath: "/dictionary",
                title: "Từ Điển",
            },
            children: [{ path: '/dictionary/:postId(\\d+)*/:id(\\d+)/:description*', name: 'dictionary',component: Dictionary}],
        },
        {
            path: "/listening/:id(\\d+)*/:description*",
            name: "listening",
            component: Listening,
            meta: {
                rootPath: "/listening",
                title: "Học Nghe",
            },
        },
        {
            path: "/conversation",
            name: "conversation",
            component: Conversation,
            meta: {
                rootPath: "/conversation",
                title: "Hội Thoại",
            },
        },
        {
            path: "/reading/:id(\\d+)*/:description*",
            name: "reading",
            component: Reading,
            meta: {
                rootPath: "/reading",
                title: "Đọc Hiểu",
            },
        },
        {
            path: "/writing/:id(\\d+)*/:description*",
            name: "writing",
            component: Writing,
            meta: {
                rootPath: "/writing",
                title: "Học Viết",
            },
        },
        {
            path: "/Pronunciation/:id(\\d+)*/:description*",
            name: "pronunciation",
            component: Pronunciation,
            meta: {
                rootPath: "/pronunciation",
                title: "Học Phát Âm",
            },
        },
        {
            path: "/discuss/:id(\\d+)*/:description*",
            name: "discuss",
            component: Discuss,
            meta: {
                rootPath: "/discuss",
                title: "Hỏi Đáp",
            },
        },
    ],
};
