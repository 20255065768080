export const headerStore = defineStore('header',{
    namespaced: true,
    state: () => ({
        headerInfor: {
            webId: '1',
            webName: 'EPLUS',
			slogan: 'COME HERE TO LEARN , PLAY AND EXPERIENCE11111!',
        }
    }),
    actions: {
        updateHeaderInfor(headerInfor){
            var state = this;
            Object.keys(headerInfor).forEach(function(key){
                state.headerInfor[key] = headerInfor[key];
            })
        },
    }
});