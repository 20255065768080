export default {
    // Confirm message
    C001: 'Bạn có muốn thực hiện thao tác này?',
    C002: 'Bạn có chắc chắn muốn đổi mật khẩu của tài khoản này?',
    C003: 'Thêm câu hỏi thành công! Bạn có muốn hiển thị ngay bây giờ?',
    C004: 'Bạn có muốn phê duyệt cho những dữ liệu đã chọn? (sau khi phê duyệt bạn có thể sử dụng trong hệ thống)',
    C005: 'Bạn có muốn công khai cho những dữ liệu đã chọn? (sau khi công khai người dùng có thể thấy những dữ liệu này)',
    C006: 'Bạn có muốn khôi phục lại trạng thái cho những dữ liệu đã chọn? (bạn sẽ phải phê duyệt và công khai lại sau khi đã khôi phục)',
    C007: 'Chúc mừng bạn đã hoàn thành xuất sắc bài kiểm tra',
    C008: 'Từ chối nhiệm vụ vẫn sẽ phải chịu hình phạt bằng 1 nửa giá trị khi thất bại<br>Bạn vẫn muốn từ chối nhiệm vụ này?',
    C009: 'Nếu bạn hủy thì nhiệm vụ này chỉ còn # lần thử!<br>Bạn có chắc chắn muốn hủy?',
    C010: 'Số lần thử của nhiệm vụ này đã hết nếu bạn hủy thì nhiệm vụ sẽ bị coi như thất bại!<br>Bạn có chắc chắn muốn hủy?',
    C011: 'Thêm bài viết từ vựng thành công! Bạn có muốn hiển thị ngay bây giờ?',
    C012: 'Bạn có chắc chắn muốn xóa dữ liệu này?',
    C013: 'Bạn mong muốn lưu bài viết hiện tại?',
    // Success
    S001: 'Thao tác đã thực hiện thành công',
    S002: 'Đổi mật khẩu thành công xin kiểm tra email để biết mật khẩu mới',
    S003: 'Tài khoản của bạn đã được đăng ký thành công và sẽ được tự động đăng nhập vào hệ thống!',
    S004: 'Thông tin của bạn đã được cập nhật',
    S005: 'Mật khẩu của bạn đã được đổi',
    S006: 'Trả lời hoàn toàn chính xác!<br>Tiếp tục thử thách nào!!!',
    S007: 'Chúc mừng bạn đã hoàn thành nhiệm vụ<br><span class="text-left">Phần Thưởng Nhận Được</span><br><span class="text-left">Điểm kinh nghiệm : + #</span><br><span class="text-left">Điểm đóng góp : + #</span>',
    S008: '#',
    S009: 'Bạn vừa hoàn thành 1 nhiệm vụ ẩn<br><label style ="font-size:16px;color:#a94242">#</label><br><span class="text-left">Phần Thưởng Nhận Được</span><br><span class="text-left">Điểm kinh nghiệm : + #</span><br><span class="text-left">Điểm đóng góp : + #</span>',
    S010: 'Chúc mừng bạn đã thăng hạng từ <label style ="font-family: headerfont1;font-size:16px;color:#a94242">#</label> lên <label style ="font-family: headerfont1;font-size:16px;color:#a94242">#</label><br>Hãy tiếp tục cố gắng nữa nhé!',
    S011: 'Cảm ơn bạn đã đóng góp tri thức của mình cho cộng đồng!<br>Dữ liệu bạn đóng góp sẽ được xét duyệt và bạn sẽ được cộng 1 lượng điểm đóng góp tương ứng nếu được xét duyệt thành công',
    S012: 'Chúc mừng! Bạn đã hoàn thành tất cả thử thách.',
    S013: 'Chúc mừng! Bạn đã hoàn thành #.',
    S014: 'Eplus365 cảm ơn bạn rất nhiều vì đã đóng góp tri thức cho cộng đồng!<br>Đóng góp của bạn sẽ được xét duyệt bởi đội ngũ chuyên môn của chúng tôi trước khi đến với những người dùng khác.',
    // Warning message
    W001: 'Bạn có chắc chắn muốn xóa?',
    W002: 'Dữ liệu đã sửa sẽ được lưu khi chuyển trang Bạn có muốn chuyển?',
    W003: 'Bạn có muốn lưu dữ liệu hiện tại dưới dạng 1 phiên bản khác?',
    W004: 'Bạn mới chỉ trả lời được #/# câu hỏi!<br>Cố gắng lần nữa nào!',
    W005: 'Nhiệm vụ đã được hủy để tiếp tục thực hiện hãy chọn lại trong bảng nhiệm vụ!',
    W006: 'Nhiệm vụ thất bại!<br><span class="text-left">Hình phạt phải nhận</span><br><span class="text-left">Điểm kinh nghiệm : - #</span><br><span class="text-left">Điểm đóng góp : - #</span>',
    W007: 'Xem đáp án đồng nghĩa với việc bạn đã đầu hàng đoạn nghe này!<br>Bạn chắc chắn chứ?',
    // Error message
    E001: 'Tài khoản hoặc mật khẩu chưa đúng!',
    E002: 'Tài khoản này đã bị xóa bởi hệ thống xử phạt!',
    E003: 'Tài khoản này đã bị khóa trong # giây do bạn đã đăng nhập sai quá # lần!',
    E004: 'Tài khoản của bạn đã bị khóa bởi hệ thống xử phạt cho đến ngày #!',
    E998: 'Đã xảy ra lỗi không mong muốn trong quá trình cập nhật dữ liệu!',
    E999: 'Đã xảy ra lỗi không mong muốn trong quá trình thực hiện tác vụ!',
    // api error
    E400: 'エラーが発生しました。もう一度お願いします。',
    E401: 'ログインセッションの有効期限が切れています。もう一度ログインしてください。',
    E403: 'この機能にアクセスする権限がありません。',
    E404: 'エラーが発生しました。もう一度お願いします。',
    E422: 'エラーが発生しました。もう一度お願いします。',
    E423: 'エラーが発生しました。もう一度お願いします。',
    E500: 'エラーが発生しました。もう一度お願いします。',
    // E999: 'エラーが発生しました。もう一度お願いします。',
    // interface message
    I001: 'Bạn phải đăng nhập để sử dụng tính năng này!',
    I002: '',
    I003: 'Bạn phải đạt được mức rank # mới có thể sử dụng tính năng này!',
    I004: 'Chức năng này hiện không khả dụng!',
    I005: 'Còn # câu của bài nghe chưa được hoàn thành! Bạn chưa thể xem toàn bộ nội dung bài nghe.',
    I006: 'Bạn chỉ có thể xem đáp án của câu sau khi đã nghe được ít nhất #%.',
};

export const MSG_TITLE = {
    // Confirm message
    C001: 'Xác nhận',
    C002: 'Đổi Mật Khẩu',
    C003: 'Xem Ngay',
    C004: 'Xác Nhận Phê Duyệt',
    C005: 'Xác Nhận Công Khai',
    C006: 'Xác Nhận Khôi Phục',
    C007: 'Kiểm Tra Hoàn Thành',
    C008: 'Xác Nhận Từ Chối',
    C009: 'Hủy Nhiệm Vụ',
    C010: 'Hủy Nhiệm Vụ',
    C011: 'Xem Ngay',
    C012: 'Xóa Dữ Liệu',
    C013: 'Lưu Dữ Liệu',
    // Success
    S001: 'Thao Tác Thành Công',
    S002: 'Đổi Mật Khẩu',
    S003: 'Đăng Ký Thành Công',
    S004: 'Cập Nhật Thành Công',
    S005: 'Cập Nhật Thành Công',
    S006: 'Qua Ải Thành Công',
    S007: 'Nhiệm Vụ Hoàn Thành',
    S008: 'Kết Quả Nghe',
    S009: 'Nhiệm Vụ Ẩn',
    S010: 'Thăng Hạng Thành Công',
    S011: 'Đóng Góp Thành Công',
    S012: 'Hoàn Thành',
    S013: 'Thử Thách Thành Công',
    S014: 'Lời Cảm Ơn',
    // Warning message
    W001: 'Cảnh Báo Xóa',
    W002: 'Xác Nhận Chuyển Trang',
    W003: 'Thêm Phiên Bản',
    W004: 'Qua Ải Thất Bại',
    W005: 'Đã Hủy Nhiêm Vụ',
    W006: 'Nhiêm Vụ Thất Bại',
    W007: 'Từ Bỏ',
    // Error message
    E001: 'HỦY NHIỆM VỤ',
    E999: 'エラー',
    S999: '保存しました'
};

export const MSG_TYPE = {
    CONFIRM: 1,
    WARNING: 2,
    SUCCESS: 3,
    ERROR: 4
};
